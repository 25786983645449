<!-- need to add size dependencies and hint functionality -->
<template>
  <div class="input__wrapper">
    <label v-if="labelOutside" for="input-textarea" class="label label_size_S">{{ label }}</label>
    <div class="input-field">
      <textarea
        :class="[
          'input',
          `input_size_${size}`,
          'input_type_textarea'
        ]"
        type="text"
        v-model="internalValue"
        :placeholder="placeholder"
        :disabled="readOnly"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
        id="input-textarea"
      />
      <label v-if="!labelOutside" :class="{ 'input-label': true, 'input-label_active': internalValue || isFocused }" for="input-textarea">{{ label }}</label>
      <!-- <div class="input-icon" v-if="internalValue">
        <svg-icon class="icon icon_type_close" v-if="size==='M' || size==='L'" :iconName="'kuiIconCloseLarge'" :size="'24px'" @click="clearInput"/>
        <svg-icon class="icon icon_type_close" v-if="size!=='M' && size!=='L'" :iconName="'kuiIconClose'" :size="'16px'" @click="clearInput"/>
      </div> -->
    </div>
    <label v-if="description" for="input-textarea" class="label label_size_S">{{ description }}</label>
  </div>
</template>

<script>
export default{
  name: 'input-textarea',
  props: {
    size:{
      type: String,
      default: 'M',
    },
    value: {
      type: String,
      default: ''
    },
    label: String,
    placeholder: String,
    readOnly:{
      type: Boolean,
      default: false
    },
    autocomplete: String,
    maxlength: [String, Number],
    label:{
      type: String,
      default: 'Label is here'
    },
    description:{
      type: String,
      default: ''
    },
    labelOutside:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      internalValue: this.value,
      isFocused: false
    };
  },
  watch: {
    value: function(newVal) {
      this.internalValue = newVal;
    },
    internalValue: function(newVal) {
      this.$emit('update:value', newVal);
    }
  },
  methods:{
    clearInput() {
      this.internalValue = '';
      this.isFocused = false;
    },
    onInput(event) {
      this.internalValue = event.target.value;
      this.$emit('update:value', this.internalValue);
    },
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      if (!this.internalValue) {
        this.isFocused = false;
      }
    },
  }
}
</script>

<style scoped>
@import '../inputs.css';

.input__wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.input-field {
  position: relative;
  display: inline-block;
  width: 100%;
}

.input-label {
  position: absolute;
  top: 16px;
  left: 16px;
  transform: translateY(-50%);
  transition: 0.3s ease all;
}

.input-label_active {
  top: 6px;
  transform: translateY(0%);
  font-size: 0.75em;
  color: var(--kaz-textIcons-text-02);
}

.input {
  position: relative;
  width: 100%;
}

.input_type_textarea{
  height: 254px;
}

.input_type_textarea:not(:placeholder-shown) {
  padding-top: 22px;
  padding-bottom: 10px;
}

.label{
  color: var(--kaz-textIcons-text-02);
}

.input-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.input-icon .icon{
  --kaz-base-base-09: var(--kaz-textIcons-text-03);
}

.input-icon:hover .icon{
  --kaz-base-base-09: var(--kaz-textIcons-text-02);
}

.input_size_L{
  padding: 16px 8px 16px 16px;
}

.input_size_M{
  padding: 12px 48px 12px 44px;
}

.input_size_S{
  padding: 6px 38px 6px 32px;
}

.input-icon:last-child {
  cursor: pointer;
}

@media screen and (max-width: 568px) {
  .input__wrapper{
    width: 100%;
  }
}

</style>
