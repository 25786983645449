<template>
  <label
    :style="{ color: textColor, fontWeight: textWeight }"
    class="label"
  >
    <slot></slot>
  </label>
</template>
<script>
export default {
  name: 'label-text',
  props: {
    textColor: {
      type: String,
      default: "var(--text-black)"
    },
    textWeight: {
      type: String,
      default: '500',
    }
  }
};
</script>
<style scoped>

.label{
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
}
</style>