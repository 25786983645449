function guessLevelId(levelsList, bookTitle) {
  const result = [...bookTitle.matchAll(/(\d+) класс/gi)];
  let levelId = null;
  if (result.length && levelsList.length) {
    let candidateId = +result[0][1];
    if (levelsList.includes(candidateId)) {
      levelId = candidateId;
    } else {
      levelId = levelsList[0];
    }
  }
  return levelId
}

export {
  guessLevelId,
}