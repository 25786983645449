/**
 * @fileoverview gRPC-Web generated client stub for kazatel.entities
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.23.3
// source: entities/entities_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var entities_entities_pb = require('../entities/entities_pb.js')

var entities_tasks_pb = require('../entities/tasks_pb.js')

var entities_task_cards_pb = require('../entities/task_cards_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')
const proto = {};
proto.kazatel = {};
proto.kazatel.entities = require('./entities_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.kazatel.entities.EntitiesServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.kazatel.entities.EntitiesServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.kazatel.entities.Level>}
 */
const methodDescriptor_EntitiesService_Levels = new grpc.web.MethodDescriptor(
  '/kazatel.entities.EntitiesService/Levels',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  entities_entities_pb.Level,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  entities_entities_pb.Level.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.entities.Level>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.entities.EntitiesServiceClient.prototype.levels =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.entities.EntitiesService/Levels',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_Levels);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.entities.Level>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.entities.EntitiesServicePromiseClient.prototype.levels =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.entities.EntitiesService/Levels',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_Levels);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.kazatel.entities.Subject>}
 */
const methodDescriptor_EntitiesService_Subjects = new grpc.web.MethodDescriptor(
  '/kazatel.entities.EntitiesService/Subjects',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  entities_entities_pb.Subject,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  entities_entities_pb.Subject.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.entities.Subject>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.entities.EntitiesServiceClient.prototype.subjects =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.entities.EntitiesService/Subjects',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_Subjects);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.entities.Subject>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.entities.EntitiesServicePromiseClient.prototype.subjects =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.entities.EntitiesService/Subjects',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_Subjects);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.kazatel.entities.NodeType>}
 */
const methodDescriptor_EntitiesService_NodeTypes = new grpc.web.MethodDescriptor(
  '/kazatel.entities.EntitiesService/NodeTypes',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  entities_entities_pb.NodeType,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  entities_entities_pb.NodeType.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.entities.NodeType>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.entities.EntitiesServiceClient.prototype.nodeTypes =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.entities.EntitiesService/NodeTypes',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_NodeTypes);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.entities.NodeType>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.entities.EntitiesServicePromiseClient.prototype.nodeTypes =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.entities.EntitiesService/NodeTypes',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_NodeTypes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.kazatel.entities.SubjectsByLevel>}
 */
const methodDescriptor_EntitiesService_SubjectsByLevel = new grpc.web.MethodDescriptor(
  '/kazatel.entities.EntitiesService/SubjectsByLevel',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  entities_entities_pb.SubjectsByLevel,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  entities_entities_pb.SubjectsByLevel.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.entities.SubjectsByLevel>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.entities.EntitiesServiceClient.prototype.subjectsByLevel =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.entities.EntitiesService/SubjectsByLevel',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_SubjectsByLevel);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.entities.SubjectsByLevel>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.entities.EntitiesServicePromiseClient.prototype.subjectsByLevel =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.entities.EntitiesService/SubjectsByLevel',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_SubjectsByLevel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.kazatel.entities.LevelsBySubject>}
 */
const methodDescriptor_EntitiesService_LevelsBySubject = new grpc.web.MethodDescriptor(
  '/kazatel.entities.EntitiesService/LevelsBySubject',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  entities_entities_pb.LevelsBySubject,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  entities_entities_pb.LevelsBySubject.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.entities.LevelsBySubject>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.entities.EntitiesServiceClient.prototype.levelsBySubject =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.entities.EntitiesService/LevelsBySubject',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_LevelsBySubject);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.entities.LevelsBySubject>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.entities.EntitiesServicePromiseClient.prototype.levelsBySubject =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.entities.EntitiesService/LevelsBySubject',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_LevelsBySubject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.entities.EntitiesRequest,
 *   !proto.kazatel.entities.Entity>}
 */
const methodDescriptor_EntitiesService_Entities = new grpc.web.MethodDescriptor(
  '/kazatel.entities.EntitiesService/Entities',
  grpc.web.MethodType.SERVER_STREAMING,
  entities_entities_pb.EntitiesRequest,
  entities_entities_pb.Entity,
  /**
   * @param {!proto.kazatel.entities.EntitiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  entities_entities_pb.Entity.deserializeBinary
);


/**
 * @param {!proto.kazatel.entities.EntitiesRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.entities.Entity>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.entities.EntitiesServiceClient.prototype.entities =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.entities.EntitiesService/Entities',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_Entities);
};


/**
 * @param {!proto.kazatel.entities.EntitiesRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.entities.Entity>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.entities.EntitiesServicePromiseClient.prototype.entities =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.entities.EntitiesService/Entities',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_Entities);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.StringValue,
 *   !proto.kazatel.entities.EntityDetail>}
 */
const methodDescriptor_EntitiesService_Entity = new grpc.web.MethodDescriptor(
  '/kazatel.entities.EntitiesService/Entity',
  grpc.web.MethodType.UNARY,
  google_protobuf_wrappers_pb.StringValue,
  entities_entities_pb.EntityDetail,
  /**
   * @param {!proto.google.protobuf.StringValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  entities_entities_pb.EntityDetail.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.entities.EntityDetail)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.entities.EntityDetail>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.entities.EntitiesServiceClient.prototype.entity =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.entities.EntitiesService/Entity',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_Entity,
      callback);
};


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.entities.EntityDetail>}
 *     Promise that resolves to the response
 */
proto.kazatel.entities.EntitiesServicePromiseClient.prototype.entity =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.entities.EntitiesService/Entity',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_Entity);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.entities.NodesRequest,
 *   !proto.kazatel.entities.Node>}
 */
const methodDescriptor_EntitiesService_Nodes = new grpc.web.MethodDescriptor(
  '/kazatel.entities.EntitiesService/Nodes',
  grpc.web.MethodType.SERVER_STREAMING,
  entities_entities_pb.NodesRequest,
  entities_entities_pb.Node,
  /**
   * @param {!proto.kazatel.entities.NodesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  entities_entities_pb.Node.deserializeBinary
);


/**
 * @param {!proto.kazatel.entities.NodesRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.entities.Node>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.entities.EntitiesServiceClient.prototype.nodes =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.entities.EntitiesService/Nodes',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_Nodes);
};


/**
 * @param {!proto.kazatel.entities.NodesRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.entities.Node>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.entities.EntitiesServicePromiseClient.prototype.nodes =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.entities.EntitiesService/Nodes',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_Nodes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.entities.NodeRequest,
 *   !proto.kazatel.entities.NodeDetail>}
 */
const methodDescriptor_EntitiesService_Node = new grpc.web.MethodDescriptor(
  '/kazatel.entities.EntitiesService/Node',
  grpc.web.MethodType.UNARY,
  entities_entities_pb.NodeRequest,
  proto.kazatel.entities.NodeDetail,
  /**
   * @param {!proto.kazatel.entities.NodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.entities.NodeDetail.deserializeBinary
);


/**
 * @param {!proto.kazatel.entities.NodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.entities.NodeDetail)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.entities.NodeDetail>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.entities.EntitiesServiceClient.prototype.node =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.entities.EntitiesService/Node',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_Node,
      callback);
};


/**
 * @param {!proto.kazatel.entities.NodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.entities.NodeDetail>}
 *     Promise that resolves to the response
 */
proto.kazatel.entities.EntitiesServicePromiseClient.prototype.node =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.entities.EntitiesService/Node',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_Node);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.tasks.CardCreate,
 *   !proto.kazatel.tasks.Card>}
 */
const methodDescriptor_EntitiesService_Create = new grpc.web.MethodDescriptor(
  '/kazatel.entities.EntitiesService/Create',
  grpc.web.MethodType.UNARY,
  entities_task_cards_pb.CardCreate,
  entities_tasks_pb.Card,
  /**
   * @param {!proto.kazatel.tasks.CardCreate} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  entities_tasks_pb.Card.deserializeBinary
);


/**
 * @param {!proto.kazatel.tasks.CardCreate} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.tasks.Card)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.tasks.Card>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.entities.EntitiesServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.entities.EntitiesService/Create',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_Create,
      callback);
};


/**
 * @param {!proto.kazatel.tasks.CardCreate} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.tasks.Card>}
 *     Promise that resolves to the response
 */
proto.kazatel.entities.EntitiesServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.entities.EntitiesService/Create',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.tasks.CardDelete,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_EntitiesService_Delete = new grpc.web.MethodDescriptor(
  '/kazatel.entities.EntitiesService/Delete',
  grpc.web.MethodType.UNARY,
  entities_task_cards_pb.CardDelete,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.kazatel.tasks.CardDelete} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.kazatel.tasks.CardDelete} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.entities.EntitiesServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.entities.EntitiesService/Delete',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_Delete,
      callback);
};


/**
 * @param {!proto.kazatel.tasks.CardDelete} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.entities.EntitiesServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.entities.EntitiesService/Delete',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.tasks.CardCansel,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_EntitiesService_Cancel = new grpc.web.MethodDescriptor(
  '/kazatel.entities.EntitiesService/Cancel',
  grpc.web.MethodType.UNARY,
  entities_task_cards_pb.CardCansel,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.kazatel.tasks.CardCansel} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.kazatel.tasks.CardCansel} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.entities.EntitiesServiceClient.prototype.cancel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.entities.EntitiesService/Cancel',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_Cancel,
      callback);
};


/**
 * @param {!proto.kazatel.tasks.CardCansel} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.entities.EntitiesServicePromiseClient.prototype.cancel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.entities.EntitiesService/Cancel',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_Cancel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.tasks.CardUpdate,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_EntitiesService_Update = new grpc.web.MethodDescriptor(
  '/kazatel.entities.EntitiesService/Update',
  grpc.web.MethodType.UNARY,
  entities_task_cards_pb.CardUpdate,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.kazatel.tasks.CardUpdate} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.kazatel.tasks.CardUpdate} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.entities.EntitiesServiceClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.entities.EntitiesService/Update',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_Update,
      callback);
};


/**
 * @param {!proto.kazatel.tasks.CardUpdate} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.entities.EntitiesServicePromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.entities.EntitiesService/Update',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_Update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.kazatel.tasks.Card>}
 */
const methodDescriptor_EntitiesService_List = new grpc.web.MethodDescriptor(
  '/kazatel.entities.EntitiesService/List',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  entities_tasks_pb.Card,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  entities_tasks_pb.Card.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.tasks.Card>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.entities.EntitiesServiceClient.prototype.list =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.entities.EntitiesService/List',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_List);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.tasks.Card>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.entities.EntitiesServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.entities.EntitiesService/List',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.tasks.CardRequest,
 *   !proto.kazatel.tasks.CardData>}
 */
const methodDescriptor_EntitiesService_Task = new grpc.web.MethodDescriptor(
  '/kazatel.entities.EntitiesService/Task',
  grpc.web.MethodType.UNARY,
  entities_task_cards_pb.CardRequest,
  entities_tasks_pb.CardData,
  /**
   * @param {!proto.kazatel.tasks.CardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  entities_tasks_pb.CardData.deserializeBinary
);


/**
 * @param {!proto.kazatel.tasks.CardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.tasks.CardData)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.tasks.CardData>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.entities.EntitiesServiceClient.prototype.task =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.entities.EntitiesService/Task',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_Task,
      callback);
};


/**
 * @param {!proto.kazatel.tasks.CardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.tasks.CardData>}
 *     Promise that resolves to the response
 */
proto.kazatel.entities.EntitiesServicePromiseClient.prototype.task =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.entities.EntitiesService/Task',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_Task);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.StringValue,
 *   !proto.kazatel.tasks.Content>}
 */
const methodDescriptor_EntitiesService_Content = new grpc.web.MethodDescriptor(
  '/kazatel.entities.EntitiesService/Content',
  grpc.web.MethodType.UNARY,
  google_protobuf_wrappers_pb.StringValue,
  entities_tasks_pb.Content,
  /**
   * @param {!proto.google.protobuf.StringValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  entities_tasks_pb.Content.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.tasks.Content)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.tasks.Content>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.entities.EntitiesServiceClient.prototype.content =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.entities.EntitiesService/Content',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_Content,
      callback);
};


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.tasks.Content>}
 *     Promise that resolves to the response
 */
proto.kazatel.entities.EntitiesServicePromiseClient.prototype.content =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.entities.EntitiesService/Content',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_Content);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.kazatel.tasks.Residue>}
 */
const methodDescriptor_EntitiesService_Residue = new grpc.web.MethodDescriptor(
  '/kazatel.entities.EntitiesService/Residue',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  entities_tasks_pb.Residue,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  entities_tasks_pb.Residue.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.tasks.Residue)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.tasks.Residue>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.entities.EntitiesServiceClient.prototype.residue =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.entities.EntitiesService/Residue',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_Residue,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.tasks.Residue>}
 *     Promise that resolves to the response
 */
proto.kazatel.entities.EntitiesServicePromiseClient.prototype.residue =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.entities.EntitiesService/Residue',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_Residue);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.kazatel.entities.SaveContentInitResponse>}
 */
const methodDescriptor_EntitiesService_InitiateMultipartUpload = new grpc.web.MethodDescriptor(
  '/kazatel.entities.EntitiesService/InitiateMultipartUpload',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.kazatel.entities.SaveContentInitResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.entities.SaveContentInitResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.entities.SaveContentInitResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.entities.SaveContentInitResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.entities.EntitiesServiceClient.prototype.initiateMultipartUpload =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.entities.EntitiesService/InitiateMultipartUpload',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_InitiateMultipartUpload,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.entities.SaveContentInitResponse>}
 *     Promise that resolves to the response
 */
proto.kazatel.entities.EntitiesServicePromiseClient.prototype.initiateMultipartUpload =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.entities.EntitiesService/InitiateMultipartUpload',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_InitiateMultipartUpload);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.entities.SaveGenerateUrlParts,
 *   !proto.kazatel.entities.SaveGenerateUrlPartsResponse>}
 */
const methodDescriptor_EntitiesService_GeneratePresignedUrlsParts = new grpc.web.MethodDescriptor(
  '/kazatel.entities.EntitiesService/GeneratePresignedUrlsParts',
  grpc.web.MethodType.UNARY,
  proto.kazatel.entities.SaveGenerateUrlParts,
  proto.kazatel.entities.SaveGenerateUrlPartsResponse,
  /**
   * @param {!proto.kazatel.entities.SaveGenerateUrlParts} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.entities.SaveGenerateUrlPartsResponse.deserializeBinary
);


/**
 * @param {!proto.kazatel.entities.SaveGenerateUrlParts} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.entities.SaveGenerateUrlPartsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.entities.SaveGenerateUrlPartsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.entities.EntitiesServiceClient.prototype.generatePresignedUrlsParts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.entities.EntitiesService/GeneratePresignedUrlsParts',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_GeneratePresignedUrlsParts,
      callback);
};


/**
 * @param {!proto.kazatel.entities.SaveGenerateUrlParts} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.entities.SaveGenerateUrlPartsResponse>}
 *     Promise that resolves to the response
 */
proto.kazatel.entities.EntitiesServicePromiseClient.prototype.generatePresignedUrlsParts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.entities.EntitiesService/GeneratePresignedUrlsParts',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_GeneratePresignedUrlsParts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.entities.SaveComplete,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_EntitiesService_CompleteMultipartUpload = new grpc.web.MethodDescriptor(
  '/kazatel.entities.EntitiesService/CompleteMultipartUpload',
  grpc.web.MethodType.UNARY,
  proto.kazatel.entities.SaveComplete,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.kazatel.entities.SaveComplete} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.kazatel.entities.SaveComplete} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.entities.EntitiesServiceClient.prototype.completeMultipartUpload =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.entities.EntitiesService/CompleteMultipartUpload',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_CompleteMultipartUpload,
      callback);
};


/**
 * @param {!proto.kazatel.entities.SaveComplete} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.entities.EntitiesServicePromiseClient.prototype.completeMultipartUpload =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.entities.EntitiesService/CompleteMultipartUpload',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_CompleteMultipartUpload);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.entities.Links,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_EntitiesService_NodeLinksAdd = new grpc.web.MethodDescriptor(
  '/kazatel.entities.EntitiesService/NodeLinksAdd',
  grpc.web.MethodType.UNARY,
  entities_entities_pb.Links,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.kazatel.entities.Links} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.kazatel.entities.Links} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.entities.EntitiesServiceClient.prototype.nodeLinksAdd =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.entities.EntitiesService/NodeLinksAdd',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_NodeLinksAdd,
      callback);
};


/**
 * @param {!proto.kazatel.entities.Links} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.entities.EntitiesServicePromiseClient.prototype.nodeLinksAdd =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.entities.EntitiesService/NodeLinksAdd',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_NodeLinksAdd);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.entities.Links,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_EntitiesService_NodeLinksDelete = new grpc.web.MethodDescriptor(
  '/kazatel.entities.EntitiesService/NodeLinksDelete',
  grpc.web.MethodType.UNARY,
  entities_entities_pb.Links,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.kazatel.entities.Links} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.kazatel.entities.Links} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.entities.EntitiesServiceClient.prototype.nodeLinksDelete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.entities.EntitiesService/NodeLinksDelete',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_NodeLinksDelete,
      callback);
};


/**
 * @param {!proto.kazatel.entities.Links} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.entities.EntitiesServicePromiseClient.prototype.nodeLinksDelete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.entities.EntitiesService/NodeLinksDelete',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_NodeLinksDelete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.StringValue,
 *   !proto.kazatel.entities.Link>}
 */
const methodDescriptor_EntitiesService_NodeLinks = new grpc.web.MethodDescriptor(
  '/kazatel.entities.EntitiesService/NodeLinks',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_wrappers_pb.StringValue,
  entities_entities_pb.Link,
  /**
   * @param {!proto.google.protobuf.StringValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  entities_entities_pb.Link.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.StringValue} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.entities.Link>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.entities.EntitiesServiceClient.prototype.nodeLinks =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.entities.EntitiesService/NodeLinks',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_NodeLinks);
};


/**
 * @param {!proto.google.protobuf.StringValue} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.entities.Link>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.entities.EntitiesServicePromiseClient.prototype.nodeLinks =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.entities.EntitiesService/NodeLinks',
      request,
      metadata || {},
      methodDescriptor_EntitiesService_NodeLinks);
};


module.exports = proto.kazatel.entities;

