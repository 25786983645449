const routes = [
  {
    path: '/404',
    name: '404',
    meta: { is404: true, layout: "profile" },
    component: () => import('@/views/notFoundPage/notFoundPage.vue')
  }
];

export default routes;
