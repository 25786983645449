<template>
  <div class="skeleton-loader" :style="styleObject"></div>
</template>

<script>
export default {
  name: 'SkeletonLoader',
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    },
    borderRadius: {
      type: String,
      default: '4px'
    }
  },
  computed: {
    styleObject() {
      return {
        width: this.width,
        height: this.height,
        borderRadius: this.borderRadius,
        background: 'linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%)',
        backgroundSize: '200% 100%',
        animation: 'skeleton-loading 1.5s infinite'
      };
    }
  }
};
</script>

<style scoped>
@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
.skeleton-loader {
  display: inline-block;
  width: 100%;
  height: 100%;
}
</style>
