<template>
  <div class="banner" :class="bannerClass" @click="handleClick">
    <div class="banner__text-wrapper">
      <heading-size-h2 :textColor="headingTextColor">
        {{ headingText }}
      </heading-size-h2>
      <paragraph-size-medium :textColor="paragraphTextColor">
        {{ paragraphText }}
      </paragraph-size-medium>
    </div>
    <button-primary :styleType="buttonStyle">
      {{ buttonText }}
    </button-primary>
  </div>
</template>

<script>
import ButtonPrimary from '@/components/UI/buttons/ButtonPrimary.vue';
import HeadingSizeH2 from '@/components/UI/texts/headings/HeadingSizeH2.vue';
import ParagraphSizeMedium from '@/components/UI/texts/paragraphs/ParagraphSizeMedium.vue';

export default {
  name: 'Banner',
  props: {
    bannerClass: String,
    headingText: {
      type: String,
      default: ''
    },
    headingTextColor:{
      type: String,
    },
    paragraphText:{
      type: String,
      default: ''
    },
    paragraphTextColor: {
      type: String,
    },
    buttonText:{
      type: String,
      default: ''
    },
    buttonStyle: {
      type: String,
      default: 'primary'
    },
  },
  components: {
    HeadingSizeH2,
    ParagraphSizeMedium,
    ButtonPrimary
  },
  methods: {
    handleClick() {
      this.$emit('click');
    }
  },
  created(){
    console.log(this.headingTextColor)
  }
}
</script>

<style scoped>
.banner{
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 16px 32px;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
  transition: .3s ease-in-out;
}
.banner:hover{
  cursor: pointer;
  scale: 1.03;
  transition: .3s ease-in-out;
}
.banner_type_tasks{
  background-image: url('@/assets/images/banners/background-banner-tasks.png');
  background-color: #B5BCF3;
}
.banner_type_gpt{
  background-image: url('@/assets/images/banners/background-banner-gpt.png');
  background-size: contain;
  background-position: right;
  background-color: #FFA566;
}
.banner button{
  max-width: 340px;
}
.banner__text-wrapper{
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 340px;
}

@media screen and (max-width: 992px) {
  .banner{
    padding: 16px;
  }
}

@media screen and (max-width: 768px) {
  .banner{
    background-image: none;
  }
}
</style>
