export default{
  install(app, options) {
    app.config.globalProperties.$setMetrikaGoal = (type) => {
      if (+process.env.VUE_APP_ENABLE_ANALYTICS) {
        try {
          ym(95026746, 'reachGoal', type);
        } catch (error) {
          console.error('Яндекс Метрика не инициализирована или не передан тип цели');
        }
      }
    };
  }
}