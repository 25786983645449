import store from '@/store';
import STATES from "@/utils/states";
import { extractTokenInfo } from '@/utils/auth';
import { _arrayBufferToBase64 } from "@/utils/converter";
import { fetchStreamData } from "@/utils/loaders.js";
import { fetchMethodData } from "@/utils/loaders.js";

import { UserControlClient } from "@/generated/user/usercontrol_grpc_web_pb.js";
import { AccessManagerClient } from "@/generated/accessmanager/accessmanager_grpc_web_pb.js";

// import { defaultPhotos } from './utils';
import { getRandomPhoto } from './utils';

function getTokenInfo() {
  return store.getters['authData/getTokenInfo']
}


export const userData = {
  namespaced: true,
  state: () => ({
    isModerator: false,
    dataState: STATES.INIT,
    student: {},
    dataStateTeacher: STATES.INIT,
    teacher: {
      works: {},
      education: {},
    },
    dataStateParent: STATES.INIT,
    parent: {
      works: {},
    },
    teacherworks: {},
    parent: {},
    parentwork: { workplace: "wwwww" },
    dataStateBonus: STATES.INIT,
    bonus: 0,
    activeRole: 0,
  }),

  getters: {
    getStudent(state) {
      return state.student;
    },
    getSrcAvatar(state) {
      var src =
        "data:image/jpeg;base64," + _arrayBufferToBase64(state.student.photo);
      return src;
    },
    getTeacherUser(state) {
      return state.teacherworks;
    },
    getUserData(state) {
      if (state.activeRole === 0) {
        return state.student;
      } else if (state.activeRole === 1) {
        return state.teacher;
      } else if (state.activeRole === 2) {
        return state.parent;
      }
    },
    getBonus(state) {
      return state.bonus;
    },
    isModerator(state) {
      return state.isModerator;
    },
    getActiveRole(state) {
      return state.activeRole;
    },
    getWorkPlace(state) {
      if (state.activeRole === 1) {
        return state.teacher.works;
      } else if (state.activeRole === 2) {
        return state.parent.works;
      }
    },
    getEducation(state) {
      return state.teacher.education;
    },
    getLoadingState(state) {
      return state.dataState
    }
  },

  mutations: {
    addStudent(state, newStudent) {
      state.student = newStudent;
    },
    setState(state, newState) {
      state.dataState = newState;
    },
    setStateTeacher(state, newState) {
      state.dataStateTeacher = newState;
    },
    setStateParent(state, newState) {
      state.dataStateParent = newState;
    },
    updatePhoto(state, newPhoto) {
      state.student.photo = newPhoto;
    },
    addTeacherWork(state, newTeacherWork) {
      //Пока делаю одно место работы, если будет несколько поменять на массив
      state.teacher.works = newTeacherWork;
    },
    addTeacherEducation(state, newTeacherEducation) {
      state.teacher.education = newTeacherEducation;
    },
    addParentWork(state, newParent) {
      state.parent.works = newParent;
    },
    setStateBonus(state, newState) {
      state.dataStateBonus = newState;
    },
    setModerator(state, mode) {
      state.isModerator = mode;
    },
    addBonus(state, newBonus) {
      state.bonus = newBonus;
    },
    setActiveRole(state, newRole) {
      state.activeRole = newRole;
    },
  },

  actions: {
    async fetchUser({ commit, dispatch }) {
      console.log("start to fetch user from vuex...");
      commit("setState", STATES.LOADING);
      try {
        const serviceURL = process.env.VUE_APP_REGISTRATION_SERVICE_URL;
        const UserControlService = new UserControlClient(
          serviceURL,
          null,
          null
        );
        var Request = new proto.kazatel.user.GetRequest();
        const tokens = getTokenInfo();
        let metadata = { token: tokens.accessToken.token };
        // console.log('Получение данных пользователя');
        // console.log(Request);
        
        /*const response = await fetchMethodData(
          UserControlService,
          'get',
          { request: requestCreate, metadata }
        );
        const newStudent = {
          login: response.getLogin(),
          email: response.getEmail(),
          lastName: response.getLastName(),
          firstName: response.getFirstName(),
          patronymic: response.getPatronymic(),
          phone: response.getPhone(),
          birthDate: null,
          photo: response.getPhoto(),
          about: response.getAbout(),
          defaultLanguage: response.getDefaultLanguage(),
        };
        if (response.getBirthDate() !== null) {
          const dr = new Date(
            response.getBirthDate().getYear(),
            response.getBirthDate().getMonth() - 1,
            response.getBirthDate().getDay() + 1
          );
          newStudent.birthDate = dr.toISOString().slice(0, 10);
        }
        commit("addStudent", newStudent);
        commit("setState", STATES.LOADED);*/

        UserControlService.get(Request, metadata, async function (err, response) {
          let resaveData = false;
          try {
            if (err) {
              throw new Error('The user is not authorized')
            }
            let newStudent = {
              login: response.getLogin(),
              email: response.getEmail(),
              lastName: response.getLastName(),
              firstName: response.getFirstName() || 'Пользователь',
              patronymic: response.getPatronymic(),
              phone: response.getPhone(),
              birthDate: null,
              photo: response.getPhoto(),
              about: response.getAbout(),
              defaultLanguage: response.getDefaultLanguage(),
            };
            if (!newStudent.photo) {
              resaveData = true;
              newStudent.photo = await getRandomPhoto();
              commit("updatePhoto", newStudent.photo);
            }
            if (response.getBirthDate() != null) {
              let dr = new Date(
                response.getBirthDate().getYear(),
                response.getBirthDate().getMonth() - 1,
                response.getBirthDate().getDay() + 1
              );
              newStudent.birthDate = dr.toISOString().slice(0, 10);
            }
            commit("addStudent", newStudent);
            commit("setState", STATES.LOADED);
            console.log("finish of fetch user from vuex...");
          } catch (err) {
            commit("setState", STATES.ERROR);
            console.error("fetchUser:", err);
          } finally {
            if (resaveData) {
              await dispatch("saveUser");
            }
          }
        });
      } catch (err) {
        commit("setState", STATES.ERROR);
        throw new Error(err);
      }
    },

    async findUserByLogin({ commit }, login) {
      return new Promise((resolve, reject) => {
        const serviceURL = process.env.VUE_APP_REGISTRATION_SERVICE_URL;
        const UserControlService = new UserControlClient(
          serviceURL,
          null,
          null
        );
        var Request = new proto.kazatel.user.GetRequest();
        Request.setLogin(login);

        const tokens = getTokenInfo();
        let metadata = { token: tokens.accessToken.token };

        UserControlService.get(Request, metadata, (err, response) => {
          if (err) {
            console.error(err);
            reject('The user is not authorized');
          }

          const userLookingFor = {
            firstName: response.getFirstName() || 'Имя не задано',
            lastName: response.getLastName() || 'Фамилия не задана',
            photo: response.getPhoto(),
            email: response.getEmail(),
          }

          resolve(userLookingFor);
        });
      });
    },

    async saveUser({ commit, state }) {
      console.log("start to save user data from vuex...");
      try {
        const serviceURL = process.env.VUE_APP_REGISTRATION_SERVICE_URL;
        const UserControlService = new UserControlClient(
          serviceURL,
          null,
          null
        );
        var Request = new proto.kazatel.user.UpdatableUserData();
        var newUser = new proto.kazatel.user.UpdatableData();
        newUser.setFirstName(state.student.firstName);
        newUser.setLastName(state.student.lastName);
        newUser.setPatronymic(state.student.patronymic);
        var newBirthDate = new proto.google.type.Date();
        if (state.student.birthDate != null) {
          let year = state.student.birthDate.split("-");
          newBirthDate.setYear(year[0]);
          newBirthDate.setMonth(year[1]);
          newBirthDate.setDay(year[2]);
        }
        newUser.setBirthDate(newBirthDate);
        newUser.setDefaultLanguage(state.student.defaultLanguage);
        newUser.setPhoto(state.student.photo);
        newUser.setAbout(state.student.about);
        Request.setUpdatableData(newUser);
        Request.setLogin(state.student.login);
        Request.setLanguage(state.student.defaultLanguage);
        var mask = new proto.google.protobuf.FieldMask();
        mask.setPathsList([
          "last_name",
          "first_name",
          "patronymic",
          "birth_date",
          "photo",
          "default_language",
          "about",
        ]);
        Request.setUpdateMask(mask);
        let metadata = { token: extractTokenInfo().accessToken.token };
        UserControlService.update(Request, metadata, function (err, response) {
          if (err) {
            console.log(err);
          } else {
            console.log("user data has been saved");
          }
        });
      } catch (err) {
        commit("setState", STATES.ERROR);
        throw new Error(err);
      }
    },

    async saveTeacherWork({ commit, state }) {
      console.log("start to save user from vuex...");
      try {
        const serviceURL = process.env.VUE_APP_REGISTRATION_SERVICE_URL;
        const UserControlService = new UserControlClient(
          serviceURL,
          null,
          null
        );
        var Request = new proto.kazatel.user.UpdatableTeacherWork();
        var newTeacherWork = new proto.kazatel.user.TeacherWork();
        newTeacherWork.setId(state.teacher.works.id);
        newTeacherWork.setWorkplace(state.teacher.works.workplace);
        newTeacherWork.setPost(state.teacher.works.post);
        newTeacherWork.setExperience(state.teacher.works.experience);
        Request.setTeacherWork(newTeacherWork);
        var mask = new proto.google.protobuf.FieldMask();
        mask.setPathsList(["workplace", "post", "experience"]);
        Request.setUpdateMask(mask);
        let metadata = { token: extractTokenInfo().accessToken.token };
        UserControlService.updateTeacherWork(
          Request,
          metadata,
          function (err, response) {
            console.log(err);
            console.log("Сохранение данных пользователя выполнено успешно");
          }
        );
      } catch (err) {
        commit("setState", STATES.ERROR);
        throw new Error(err);
      }
    },

    async saveTeacherEducation({ commit, state }) {
      console.log("start to save user from vuex...");
      try {
        const serviceURL = process.env.VUE_APP_REGISTRATION_SERVICE_URL;
        const UserControlService = new UserControlClient(
          serviceURL,
          null,
          null
        );
        var Request = new proto.kazatel.user.UpdatableTeacherEducation();
        var newTeacherEducation = new proto.kazatel.user.TeacherEducation();
        newTeacherEducation.setId(state.teacher.education.id);
        newTeacherEducation.setInstitutionName(
          state.teacher.education.institutionName
        );
        newTeacherEducation.setSpecialization(
          state.teacher.education.specialization
        );
        Request.setTeacherEducation(newTeacherEducation);
        var mask = new proto.google.protobuf.FieldMask();
        mask.setPathsList(["institution_name", "specialization"]);
        Request.setUpdateMask(mask);
        let metadata = { token: extractTokenInfo().accessToken.token };
        UserControlService.updateTeacherEducation(
          Request,
          metadata,
          function (err, response) {
            console.log(err);
            console.log("Сохранение данных пользователя выполнено успешно");
          }
        );
      } catch (err) {
        commit("setState", STATES.ERROR);
        throw new Error(err);
      }
    },

    async saveParentWork({ commit, state }) {
      console.log("start to save user from vuex...");
      try {
        const serviceURL = process.env.VUE_APP_REGISTRATION_SERVICE_URL;
        const UserControlService = new UserControlClient(
          serviceURL,
          null,
          null
        );
        var Request = new proto.kazatel.user.ParentWork();
        Request.setId(state.parent.works.id);
        Request.setWorkplace(state.parent.works.workplace);
        let metadata = { token: extractTokenInfo().accessToken.token };
        UserControlService.updateParentWork(
          Request,
          metadata,
          function (err, response) {
            console.log(err);
            console.log(
              "Сохранение данных пользователя как родителя выполнено"
            );
          }
        );
      } catch (err) {
        commit("setState", STATES.ERROR);
        throw new Error(err);
      }
    },

    async fetchTeacherWork({ commit }) {
      console.log("start to fetch userTeacher from vuex...");
      commit("setStateTeacher", STATES.LOADING);
      try {
        const serviceURL = process.env.VUE_APP_REGISTRATION_SERVICE_URL;
        const UserControlService = new UserControlClient(
          serviceURL,
          null,
          null
        );
        //var Request = new proto.kazatel.user.ListTeacherWork;
        const streamRequest = new proto.google.protobuf.StringValue();
        let metadata = { token: extractTokenInfo().accessToken.token };
        const stream = UserControlService.listTeacherWork(
          streamRequest,
          metadata
        );
        const streamData = await fetchStreamData(stream);
        for (const response of streamData) {
          const newTeacherWork = {
            id: response.getId(),
            workplace: response.getWorkplace(),
            experience: response.getExperience(),
            post: response.getPost(),
          };
          commit("addTeacherWork", newTeacherWork);
        }
        //Запрос информации об образовании и курсах
        console.log("Запрос информации об образовании и курсах");
        const streamEducation = UserControlService.listTeacherEducation(
          streamRequest,
          metadata
        );
        const streamDataEducation = await fetchStreamData(streamEducation);
        for (const response of streamDataEducation) {
          const newTeacherEducation = {
            id: response.getId(),
            institutionName: response.getInstitutionName(),
            specialization: response.getSpecialization(),
          };
          commit("addTeacherEducation", newTeacherEducation);
        }
        commit("setStateTeacher", STATES.LOADED);
      } catch (err) {
        commit("setStateTeacher", STATES.ERROR);
        throw new Error(err);
      }
    },

    async fetchParent({ commit }) {
      console.log("start to fetch userTeacher from vuex...");
      commit("setStateParent", STATES.LOADING);
      try {
        const serviceURL = process.env.VUE_APP_REGISTRATION_SERVICE_URL;
        const UserControlService = new UserControlClient(
          serviceURL,
          null,
          null
        );
        const streamRequest = new proto.google.protobuf.StringValue();
        let metadata = { token: extractTokenInfo().accessToken.token };
        const stream = UserControlService.listParentWork(
          streamRequest,
          metadata
        );
        const streamData = await fetchStreamData(stream);
        for (const response of streamData) {
          const newParentWork = {
            id: response.getId(),
            workplace: response.getWorkplace(),
          };
          commit("addParentWork", newParentWork);
        }
        commit("setStateParent", STATES.LOADED);
      } catch (err) {
        commit("setStateParent", STATES.ERROR);
        throw new Error(err);
      }
    },

    async fetchBonus({ commit }) {
      console.log("start to fetch bonus from vuex...");
      commit("setStateBonus", STATES.LOADING);
      try {
        const serviceURL = process.env.VUE_APP_REGISTRATION_SERVICE_URL;
        const UserControlService = new UserControlClient(
          serviceURL,
          null,
          null
        );
        var Request = new proto.google.protobuf.Empty();
        let metadata = { token: extractTokenInfo().accessToken.token };
        console.log("Получение данных пользователя");

        UserControlService.getCurrentBonuses(
          Request,
          metadata,
          function (err, response) {
            try {
              let newbonus = response.getSum();
              commit("addBonus", newbonus);
              commit("setStateBonus", STATES.LOADED);
              // console.log('fetch user is done from vuex...');
            } catch (err) {
              commit("setStateBonus", STATES.ERROR);
              console.error("getSum:", err);
            }
          }
        );
      } catch (err) {
        commit("setState", STATES.ERROR);
        throw new Error(err);
      }
    },

    async fetchUserRoles({ commit }, login) {
      const serviceURL = process.env.VUE_APP_REGISTRATION_SERVICE_URL;
      const accessManagerService = new AccessManagerClient(
        serviceURL,
        null,
        null
      );
      var Request = new proto.google.protobuf.StringValue();
      Request.setValue(login);

      const tokens = getTokenInfo();
      let metadata = { token: tokens.accessToken.token };
      console.log('fetch user roles');
      const stream = accessManagerService.getUserRoles(Request, metadata);

      const streamData = await fetchStreamData(stream);
      for (const response of streamData) {
        console.log('user has roles = ', response.getValue());
        if(response.getValue() == 4)
          commit("setModerator", true);
      }
    },
  },
};
