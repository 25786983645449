<template>
  <a @click="traverse(distance)" class="link_type_back">
    <svg v-if="distance < 0" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.52594 9.16666H16.6693V10.8333H6.52594L10.9959 15.3033L9.8176 16.4817L3.33594 9.99999L9.8176 3.51833L10.9959 4.69666L6.52594 9.16666Z"/>
    </svg>
    <p class="link__text"><slot></slot></p>
    <svg v-if="distance > 0" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.4741 10.8333H3.33073V9.16667H13.4741L9.00406 4.69667L10.1824 3.51833L16.6641 10L10.1824 16.4817L9.00406 15.3033L13.4741 10.8333Z"/>
    </svg>
  </a>
</template>

<script>
export default {
  name: 'link-history-traversal',

  props: {
    distance: {
      type: Number,
      default: -1
    }
  },

  methods: {
    traverse(distance) {
      if (this.$route.name === 'task') {
        this.$router.push({ name: 'book-content' })
      } else {
        this.$router.go(distance);
      }
    }
  }
}
</script>

<style scoped>
.link_type_back {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  width: fit-content;
  cursor: pointer;
  text-decoration: none;
  padding: 4px 8px 4px 0;
  margin-bottom: 16px;
}

.link__text {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.048px;
}

.link_type_back > * {
  color: var(--background-red);
  fill: var(--background-red);
  transition: .3s;
}

.link_type_back:hover > * {
  color: var(--red-indicator);
  fill: var(--red-indicator);
  transition: .3s;
}
</style>
