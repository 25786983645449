<template>
  <div class="card sidebar-footer shadow mt-3">
    <a href="#" class="nav-link sidebar-footer__link">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="#7D7DA3" xmlns="http://www.w3.org/2000/svg" class="simple">
                  <g clip-path="url(#clip0_1_4444)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2992 18.1555C22.9047 13.9656 22.7212 7.63903 18.7487 3.66658C14.5839 -0.498237 7.83141 -0.498237 3.66658 3.66658C-0.498237 7.83141 -0.498237 14.5839 3.66658 18.7487C7.639 22.7212 13.9655 22.9047 18.1555 19.2993L22.075 23.2188C22.3908 23.5346 22.9029 23.5346 23.2187 23.2188C23.5345 22.9029 23.5345 22.3908 23.2187 22.075L19.2992 18.1555ZM17.605 4.81032C21.1381 8.34348 21.1381 14.0718 17.605 17.605C14.0718 21.1381 8.34348 21.1381 4.81032 17.605C1.27717 14.0718 1.27717 8.34348 4.81032 4.81032C8.34348 1.27717 14.0718 1.27717 17.605 4.81032ZM10.4798 5.02165C10.9302 4.7616 11.4851 4.7616 11.9355 5.02165L16.2009 7.48428C16.6514 7.74433 16.9288 8.22491 16.9288 8.74499V13.6703C16.9288 14.1904 16.6514 14.6709 16.2009 14.931L11.9355 17.3936C11.4851 17.6537 10.9302 17.6537 10.4798 17.3936L6.21438 14.931C5.76398 14.6709 5.48651 14.1904 5.48651 13.6703V8.74499C5.48651 8.22491 5.76398 7.74433 6.21438 7.48428L10.4798 5.02165ZM7.10401 9.78406V13.5769L10.3989 15.4792V11.6864L7.10401 9.78406ZM7.90252 8.37736L11.2077 10.2856L14.5128 8.37737L11.2077 6.46913L7.90252 8.37736ZM12.0164 15.4792V11.6864L15.3113 9.78407V13.5769L12.0164 15.4792Z"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_1_4444">
                  <rect width="24" height="24" fill="white"/>
                  </clipPath>
                  </defs>
      </svg>
      <p>Заказать решение</p>
    </a>  
    <a href="#" class="nav-link sidebar-footer__link">
      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" class="complex">
                <path d="M1.95312 3.54059C1.95312 3.45345 2.02376 3.38281 2.1109 3.38281C4.62839 3.38281 7.13524 3.7095 9.56865 4.35468L9.83156 4.42438C9.92107 4.44812 9.9834 4.52912 9.9834 4.62172V16.6095C9.9834 16.9671 9.61887 17.2091 9.28929 17.0703L8.95511 16.9295C7.05046 16.1272 5.00459 15.7139 2.93785 15.7139H2.45312C2.17698 15.7139 1.95312 15.49 1.95312 15.2139V3.54059Z" stroke="#7D7DA3"/>
                <path d="M18.043 3.54059C18.043 3.45345 17.9723 3.38281 17.8852 3.38281C15.3677 3.38281 12.8609 3.7095 10.4274 4.35468L10.1645 4.42438C10.075 4.44812 10.0127 4.52912 10.0127 4.62172V16.6095C10.0127 16.9671 10.3772 17.2091 10.7068 17.0703L11.041 16.9295C12.9456 16.1272 14.9915 15.7139 17.0582 15.7139H17.543C17.8191 15.7139 18.043 15.49 18.043 15.2139V3.54059Z" stroke="#7D7DA3"/>
                <path d="M3.92188 6.43262H4.12068C5.4174 6.43262 6.69449 6.7493 7.84097 7.35515V7.35515" stroke="#7D7DA3" stroke-linecap="round"/>
                <path d="M3.92188 8.61426H4.12068C5.4174 8.61426 6.69449 8.93094 7.84097 9.53679V9.53679" stroke="#7D7DA3" stroke-linecap="round"/>
                <path d="M3.92188 10.6689H4.12068C5.4174 10.6689 6.69449 10.9856 7.84097 11.5915V11.5915" stroke="#7D7DA3" stroke-linecap="round"/>
                <path d="M3.92188 12.9023H4.12068C5.4174 12.9023 6.69449 13.219 7.84097 13.8249V13.8249" stroke="#7D7DA3" stroke-linecap="round"/>
                <path d="M15.9883 6.43262H15.7895C14.4928 6.43262 13.2157 6.7493 12.0692 7.35515V7.35515" stroke="#7D7DA3" stroke-linecap="round"/>
                <path d="M15.9883 8.61426H15.7895C14.4928 8.61426 13.2157 8.93094 12.0692 9.53679V9.53679" stroke="#7D7DA3" stroke-linecap="round"/>
                <path d="M15.9883 10.6689H15.7895C14.4928 10.6689 13.2157 10.9856 12.0692 11.5915V11.5915" stroke="#7D7DA3" stroke-linecap="round"/>
                <path d="M15.9883 12.9023H15.7895C14.4928 12.9023 13.2157 13.219 12.0692 13.8249V13.8249" stroke="#7D7DA3" stroke-linecap="round"/>
      </svg>
      <p>Заказать сочинение</p>
    </a>
    <a href="#" class="nav-link sidebar-footer__link">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="complex">
                <path d="M6.94531 12.0466V9.29102L8.23888 9.49934V12.3939L7.56032 11.6553L6.94531 12.0466Z" fill="#D8473A"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.89061 2.2748C9.97853 2.22724 10.0806 2.21287 10.1782 2.23431L18.9078 4.15143C19.0754 4.18824 19.2033 4.32404 19.23 4.49354C19.2567 4.66305 19.1767 4.8316 19.0286 4.91816L11.1542 9.51892C11.0635 9.5719 10.9562 9.58869 10.8536 9.56591L2.04762 7.60964C2.00131 7.67216 1.96028 7.73887 1.92514 7.80912C1.7459 8.16747 1.73513 8.58693 1.89576 8.954L2.05942 9.32801L6.02249 10.2421C6.24672 10.2938 6.38657 10.5175 6.33485 10.7418C6.28313 10.966 6.05943 11.1059 5.8352 11.0541L2.0646 10.1844L2.064 10.1856C1.79449 10.7245 1.7715 11.3537 2.00096 11.9108L2.0966 12.1431L10.8784 14.1544L18.2873 9.92908C18.4872 9.81507 18.7416 9.8847 18.8556 10.0846C18.9696 10.2845 18.9 10.539 18.7001 10.653L11.1502 14.9588C11.0594 15.0105 10.9525 15.0263 10.8507 15.003L2.0446 12.9861L2.03788 13.0005C1.78464 13.543 1.77707 14.1683 2.0171 14.7168L2.09932 14.9047L10.8787 16.9155L18.2875 12.6902C18.4874 12.5762 18.7419 12.6458 18.8559 12.8457C18.9699 13.0456 18.9003 13.3 18.7004 13.414L11.1504 17.7199C11.0597 17.7716 10.9528 17.7874 10.851 17.7641L1.70871 15.6702C1.58004 15.6407 1.47294 15.552 1.42002 15.431L1.25366 15.0509C0.917617 14.2829 0.928219 13.4076 1.28276 12.648L1.3468 12.5108L1.23042 12.2282C0.909177 11.4482 0.941366 10.5673 1.31868 9.81287L1.34177 9.7667L1.13232 9.28807C0.872519 8.69437 0.889929 8.01593 1.17984 7.43633C1.38033 7.0355 1.69934 6.70609 2.09353 6.49284L9.89061 2.2748ZM18.8556 7.27779C18.9696 7.47769 18.9 7.73215 18.7001 7.84616L11.1502 12.152C11.0602 12.2033 10.9545 12.2193 10.8534 12.1968L9.08489 11.804C8.86024 11.7542 8.71858 11.5316 8.76847 11.307C8.81836 11.0823 9.04091 10.9406 9.26556 10.9905L10.877 11.3484L18.2873 7.12227C18.4872 7.00827 18.7416 7.0779 18.8556 7.27779ZM7.59188 5.35132L10.3921 3.74967L15.1223 4.79607L12.2968 6.39803L7.59188 5.35132Z" fill="#7D7DA3"/>
      </svg>
      <p>Заказать пересказы</p>
    </a>
    <a href="#" class="nav-link sidebar-footer__link">
      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" class="complex">
                <g clip-path="url(#clip0_1_4477)">
                <path d="M1.95312 3.54059C1.95312 3.45345 2.02376 3.38281 2.1109 3.38281C4.62839 3.38281 7.13524 3.7095 9.56865 4.35468L9.83156 4.42438C9.92107 4.44812 9.9834 4.52912 9.9834 4.62172V16.6095C9.9834 16.9671 9.61887 17.2091 9.28929 17.0703L8.95511 16.9295C7.05046 16.1272 5.00459 15.7139 2.93785 15.7139H2.45312C2.17698 15.7139 1.95312 15.49 1.95312 15.2139V3.54059Z" stroke="#9C9CC9"/>
                <path d="M18.043 3.54059C18.043 3.45345 17.9723 3.38281 17.8852 3.38281C15.3677 3.38281 12.8609 3.7095 10.4274 4.35468L10.1645 4.42438C10.075 4.44812 10.0127 4.52912 10.0127 4.62172V16.6095C10.0127 16.9671 10.3772 17.2091 10.7068 17.0703L11.041 16.9295C12.9456 16.1272 14.9915 15.7139 17.0582 15.7139H17.543C17.8191 15.7139 18.043 15.49 18.043 15.2139V3.54059Z" stroke="#9C9CC9"/>
                <path d="M3.92188 6.43262H4.12068C5.4174 6.43262 6.69449 6.7493 7.84097 7.35515V7.35515" stroke="#9C9CC9" stroke-linecap="round"/>
                <path d="M3.92188 8.61426H4.12068C5.4174 8.61426 6.69449 8.93094 7.84097 9.53679V9.53679" stroke="#9C9CC9" stroke-linecap="round"/>
                <path d="M3.92188 10.6689H4.12068C5.4174 10.6689 6.69449 10.9856 7.84097 11.5915V11.5915" stroke="#9C9CC9" stroke-linecap="round"/>
                <path d="M3.92188 12.9023H4.12068C5.4174 12.9023 6.69449 13.219 7.84097 13.8249V13.8249" stroke="#9C9CC9" stroke-linecap="round"/>
                <path d="M15.9883 6.43262H15.7895C14.4928 6.43262 13.2157 6.7493 12.0692 7.35515V7.35515" stroke="#9C9CC9" stroke-linecap="round"/>
                <path d="M15.9883 8.61426H15.7895C14.4928 8.61426 13.2157 8.93094 12.0692 9.53679V9.53679" stroke="#9C9CC9" stroke-linecap="round"/>
                <path d="M15.9883 10.6689H15.7895C14.4928 10.6689 13.2157 10.9856 12.0692 11.5915V11.5915" stroke="#9C9CC9" stroke-linecap="round"/>
                <path d="M15.9883 12.9023H15.7895C14.4928 12.9023 13.2157 13.219 12.0692 13.8249V13.8249" stroke="#9C9CC9" stroke-linecap="round"/>
                <circle cx="14.5469" cy="15.1309" r="4.5" fill="#0ADBCE" stroke="white"/>
                <path d="M12.6211 15.2108L14.2661 16.495L16.4726 13.7666" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_1_4477">
                <rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
                </defs>
      </svg>
      <p>Готовые сочинения</p>
    </a>
  </div>
</template>

<script>
export default {
  name: 'sidebar footer',
}
</script>

<style scoped>
.sidebar-footer {
  padding: 8px;
}

.sidebar-footer > .sidebar-footer__link:not(:last-child) {
  margin-bottom: 8px;
}

.sidebar-footer__link {
  display: flex;
  align-items: center;

  color: #505156;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;

  padding: 10px 12px;
  border-radius: 14px;
}

.sidebar-footer__link > p {
  margin-left: 8px;
}

.sidebar-footer__link:hover {
  background-color: rgba(64, 171, 255, 0.1);
}

.sidebar-footer__link:hover svg.simple {
  fill: #40ABFF;
}

.sidebar-footer__link:hover svg.complex  path {
  stroke: #40ABFF;
}
</style>
