<template>
  <div :class="['badge', `badge_size_${size}`, `badge_appearance_${appearance}`]">
    <div class="badge__visual" v-if="icon || status">
      <div class="badge__visual_type_status" v-if="status">
        <div :class="['status__icon', `status__icon_size_${size}`]"></div>
      </div>

      <div class="badge__visual_type_icon" v-if="icon">
        <svg-icon :class="`icon__${appearance}`" :iconName="`${iconName}`" :size="'16px'" />
      </div>
    </div>
    <div :class="['badge__content', `badge__content_size_${size}`]">
      <p :class="['label', size === 'S' ? 'label_size_XS' : 'label_size_S']">
        {{ label }}
      </p>
    </div>
  </div>
</template>

<script>
export default{
  name: 'Badge',
  props:{
    label:{
      type: [Number, String],
      default: 'hello user',
      requred: false
    },
    icon:{
      type: [String, Boolean],
      requred: false,
      default: false
    },
    iconName:{
      type: String,
      required: false,
    },
    status:{
      type: Boolean,
      requred: false
    },
    size:{
      type: String,
      requred: false,
      default: 'L'
    },
    appearance:{
      type: String,
      requred: false,
      default: 'default'
    }
  },
}
</script>

<style scoped>
.badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border-radius: 30px;

}
.badge__visual{
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}
.badge__content{
  width: 100%;
  height: 100%;
}
.badge__visual_type_status{
  display: flex;
  align-items: center;
}
.badge__visual_type_icon{
  display: flex;
  align-items: center;
}

.icon__default{
  --kaz-base-base-09: var(--kaz-base-base-01);
}
.icon__accent{
  --kaz-base-base-09: var(--kaz-base-base-01);
}
.icon__error{
  --kaz-base-base-09: var(--kaz-textIcons-text-01);
}
.icon__success{
  --kaz-base-base-09: var(--kaz-textIcons-text-01);
}
.icon__clear{
  --kaz-base-base-09: var(--kaz-textIcons-text-01);
}

.status__icon{
  border-radius: 100px;
}

.status__icon_size_L{
  width: 8px;
  height: 8px;
  margin: 12px 4px;
}
.status__icon_size_M{
  width: 6px;
  height: 6px;
  margin: 9px 4px;
}
.status__icon_size_S{
  width: 4px;
  height: 4px;
  margin: 8px 0px 8px 4px;
}

.badge_appearance_default{
  background-color: var(--kaz-base-base-06);
}
.badge_appearance_primary{
  background-color: var(--kaz-base-primary);
}
.badge_appearance_accent{
  background-color: var(--kaz-base-accent);
}
.badge_appearance_success{
  background-color: var(--kaz-status-success-bg);
}
.badge_appearance_error{
  background-color: var(--kaz-status-error-bg);
}
.badge_appearance_clear{
  background-color: var(--kaz-status-neutral-bg);
}
.badge_appearance_info{
  background-color: var(--kaz-status-info-bg);
}
.badge_appearance_warning{
  background-color: var(--kaz-status-warning-bg);
}
.badge_appearance_default .label, 
.badge_appearance_accent .label,
.badge_appearance_primary .label {
  color: var(--kaz-textIcons-day-text-01);
}
.badge_appearance_default .status__icon, .badge_appearance_accent .status__icon, .badge_appearance_success .status__icon{
  background-color: var(--kaz-status-success-fill);
}
.badge_appearance_error .status__icon{
  background-color: var(--kaz-status-error-fill);
}
.badge_appearance_clear .status__icon{
  background-color: var(--kaz-status-neutral-fill);
}
.badge_appearance_warning .status__icon{
  background-color: var(--kaz-status-warning-fill);
}

.badge_size_L {
  padding: 0px 8px;
  height: 32px;
}
.badge_size_M {
  padding: 0px 4px;
  height: 24px;
}
.badge_size_S {
  padding: 0px 2px;
  height: 20px;
}

.badge__content_size_L{
  padding: 6px 4px;
}
.badge__content_size_M{
  padding: 2px 4px;
}
.badge__content_size_S{
  padding: 2px 4px;
}
</style>