import Book from "@/views/tasks/Book.vue";
import BookContent from "@/views/tasks/BookContent.vue";
import Tasks from "@/views/tasks/task/index.vue";
import TaskEditor from "@/views/tasks/task-editor/index.vue";


const routes = [
  {
    path: "/",
    name: "tasks",
    meta: { layout: "tasks", auth: false },
    component: () => import("@/views/tasks/Home.vue"),
  },
  {
    path: "/grade",
    name: "grade",
    meta: { layout: "tasks", auth: false },
    component: () => import("@/views/tasks/Grade.vue"),
  },

  {
    // WARNING: deprecated route
    path: "/course",
    name: "course",
    meta: { layout: "tasks", auth: false },
    // component: () => import("@/views/tasks/Course.vue"),
  },

  {
    path: "/:subject",
    children: [
      {
        path: "",
        name: "subject-books",
        meta: { layout: "tasks", auth: false },
        component: () => import('@/views/tasks/books/SubjectBooks.vue'),
      },
      {
        path: ":level",
        name: "subject-level-books",
        meta: { layout: "tasks", auth: false },
        component: () => import('@/views/tasks/books/SubjectLevelBooks.vue'),
      }
    ]
  },
  {
    path: "/:level(\\d+-klass)",
    // path: "/all-subjects/:level",
    name: "level-books",
    meta: { layout: "tasks", auth: false },
    component: () => import('@/views/tasks/books/LevelBooks.vue')
  },

  {
    path: "/books/:bid",
    name: "book",
    meta: { layout: "tasks", auth: false },
    component: Book,
    children: [
      {
        path: "",
        name: "book-content",
        meta: { layout: "tasks", auth: false },
        component: BookContent
      },
      {
        path: "tasks/:tid",
        name: "task",
        meta: { layout: "tasks", auth: false },
        component: Tasks,
      },
      {
        path: "editor/:tid",
        name: "editor",
        meta: { layout: "tasks", auth: true },
        // component: () => import(/* webpackChunkName: "group-editor" */ "@/views/tasks/task-editor/index.vue"),
        component: TaskEditor,
      }
    ]
  },
];

export default routes;
