<template>
  <p
    :style="{ color: textColor, fontWeight: textWeight }"
    class="paragraph paragraph_size_S"
  >
    <slot></slot>
  </p>
</template>
<script>
export default {
  name: 'paragraph-size-small',
  props: {
    textColor: {
      type: String,
      default: "var(--text-black)"
    },
    textWeight: {
      type: String,
      default: '500',
    }
  }
};
</script>
<style scoped>

.paragraph_size_S {
  font-size: 14px;
  font-weight: 500;
  /* line-height: 100%; */
  line-height: 18px;
}
</style>