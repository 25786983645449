<template>
  <nav v-if="links?.length">
    <ol class="bread-crumb">
      <li class="bread-crumb__item" v-for="(link, ind) in links" :key="link.id">
        <div v-if="ind !== 0" class="bread-crumb__icon">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 6H10M10 6L7 3M10 6L7 9" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <router-link
          class="bread-crumb__link"
          :class="{ 'bread-crum__link_active' : link.active }"
          :to="link.active ? {} : link.path"
        >
          <paragraph-size-medium :textColor="link.active ? 'var(--background-brown)' : 'var(--text-secondary)'">
            {{ link.name }}
          </paragraph-size-medium>
        </router-link>
      </li>
    </ol>
  </nav>
</template>

<script>
/*
Link format:
  {
    id,
    path: object acceptable by router-link component,
    name: text of link,
    active: true for current page,
  }
*/
import ParagraphSizeMedium from '@/components/UI/texts/paragraphs/ParagraphSizeMedium.vue';


export default {
  name: 'breadcrumb',

  components: {
    ParagraphSizeMedium
  },

  props: {
    links: {
      type: Array,
      default: null
    },
  },

  data() {
    return {
      homeLink: {title: 'tasks', name: 'tasks'},
      levelList: null,
    }
  },

  watch: {
    $route() {
      this.$route.matched.forEach(r => {
        const { params } = this.$route
        // console.error('brrrr:', params);
      });
    }
  },

  mounted() {
    const links = [];
    this.$route.matched.forEach(r => {
      if (r?.name?.includes('-books')) {
        // console.warn('info:', r);
        //const query = {
        //  subject: r.instances.default.subject,
        //  level: r.instances.default.level,
        //}
        //links.push({title: `${query.subject} subject`, name: r.name, query});
        //links.push({title: `${query.level} class`, name: r.name, query});
      }
      // console.error('brp:', r);
      // console.error('brn:', r.name);
      // console.error('xi:', r.instances.default.bookId);
    });
    this.levelList = [this.homeLink, ...links];
    /*
    // console.error('xi:', this.$router.currentRoute.value.params);
    console.error('brr:', this.$router.currentRoute.value.matched);
    */
    const { params, query } = this.$route
    // console.error('br-params:', params);
    // console.error('br-:', this.$route.fullPath);
  }
}
</script>

<style scoped>

.bread-crumb {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.bread-crumb__item {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;
}

.bread-crumb__icon {
  display: flex;
  align-items: center;
  stroke: var(--text-secondary);
}

.bread-crumb__link {
  text-decoration: none;
}

.bread-crumb__link:hover > .paragraph {
  color: var(--background-red) !important;
}

.bread-crum__link_active {
  pointer-events: none;
}
</style>
