<template>
  <div class="kaz-dropdown">
    <div
      class="kaz-dropdown-button" 
      @click.stop="toggleMenu"
      ref="dropdown-button"
    >
      <slot name="button"></slot>
    </div>
    <div
      class="kaz-dropdown-menu"
      :class="[{ 'kaz-menu-visible': visible }, `kaz-dropdown-menu_size_${size}`]" 
      tabindex="-1"
      v-click-outside="hideMenu"
      ref="dropdown-menu"
    >
      <div>
        <slot name="menu"></slot>
      </div>
    </div>
  </div>
</template>

<script>
const globalState = {
  openedDropdown: null
};

export default {
  name: 'kaz-dropdown',

  props: {
    positionX: {
      type: String,
      default: 'left'
    },
    positionY: {
      type: String,
      default: 'top'
    },
    size: {
      type: String,
      default: 'M'
    }
  },

  data() {
    return {
      visible: false,
    };
  },

  methods: {
    toggleMenu() {
      const buttonRect = this.$refs['dropdown-button']?.getBoundingClientRect();
      const dropdownMenu = this.$refs['dropdown-menu'];

      const spaceRight = window.innerWidth - buttonRect.right;
      const spaceLeft = buttonRect.left;
      const spaceBelow = window.innerHeight - buttonRect.bottom;
      const spaceAbove = buttonRect.top;

      let preferredSideX;
      if (this.positionX === 'right') {
        preferredSideX = 'right';
      } else if (this.positionX === 'left') {
        preferredSideX = 'left';
      } else {
        if (spaceRight <= dropdownMenu.offsetWidth && spaceRight <= spaceLeft) {
          preferredSideX = 'right';
        } else {
          preferredSideX = 'left';
        }
      }

      let preferredSideY;
      if (this.positionY === 'top') {
        preferredSideY = 'top';
      } else if (this.positionY === 'bottom') {
        preferredSideY = 'bottom';
      } else {
        if (spaceBelow <= dropdownMenu.offsetHeight && spaceBelow <= spaceAbove) {
          preferredSideY = 'bottom';
        } else {
          preferredSideY = 'top';
        }
      }

      dropdownMenu.style.left = preferredSideX === 'left' ? '0' : 'auto';
      dropdownMenu.style.right = preferredSideX === 'right' ? '0' : 'auto';

      dropdownMenu.style.bottom = preferredSideY === 'bottom' ? '100%' : 'auto';
      dropdownMenu.style.top = preferredSideY === 'top' ? '100%' : 'auto';
      dropdownMenu.style.marginBottom = preferredSideY === 'bottom' ? '4px' : '0px';
      dropdownMenu.style.marginTop = preferredSideY === 'top' ? '4px' : '0px';

      if (globalState.openedDropdown && globalState.openedDropdown !== this) {
        globalState.openedDropdown.hideMenu();
      }
      this.visible = !this.visible;
      if (this.visible) {
        globalState.openedDropdown = this;
      } else {
        if (globalState.openedDropdown === this) {
          globalState.openedDropdown = null;
        }
      }
    },

    hideMenu() {
      this.visible = false;
      if (globalState.openedDropdown === this) {
        globalState.openedDropdown = null;
      }
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.kaz-dropdown {
  position: relative;
  display: inline-block;
}
.kaz-dropdown-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.kaz-dropdown-menu {
  position: absolute;
  top: 100%;
  right: 100%;
  visibility: hidden;
  opacity: 0;
  padding: 8px;
  margin-top: 4px;
  overflow-y: auto;
  background: var(--kaz-base-base-01);
  border: 1px solid var(--kaz-base-base-04);
  border-radius: 12px;
  box-shadow: 0px 25px 15px rgba(0, 0, 0, 0.03), 0px 11px 11px rgba(0, 0, 0, 0.04), 0px 3px 6px rgba(0, 0, 0, 0.05);
  z-index: 200;
  transition: all var(--kaz-transition-molecules);
}
.kaz-dropdown-menu_size_M {
  width: 320px;
  max-height: 440px;
}
.kaz-dropdown-menu_size_L {
  width: 550px;
  max-height: 640px;
}
.kaz-dropdown-menu.kaz-menu-visible {
  visibility: visible;
  opacity: 1;
}
</style>
