import { ModerationServiceClient } from '@/generated/moderation/moderation_service_grpc_web_pb.js';
import { fetchStreamData, fetchMethodData } from '@/utils/loaders.js';
import { createMetadataWithToken } from '@/services/utils';


const moderationService = new ModerationServiceClient(
  process.env.VUE_APP_REGISTRATION_SERVICE_URL,
  null,
  null
);

async function fetchHistory(contentId) {
  try {
    const request = new proto.google.protobuf.StringValue();
    request.setValue(contentId);
    const metadata = createMetadataWithToken();
    return await fetchMethodData(moderationService, 'history', { request, metadata });
  } catch (error) {
    throw error
  }
}

async function blockContent(id) {
  try {
    const request = new proto.google.protobuf.StringValue();
    request.setValue(id);
    const metadata = createMetadataWithToken();
    return await fetchMethodData(moderationService, 'blocking', { request, metadata });
  } catch (error) {
    throw error;
  }
}

export {
  fetchHistory,
  blockContent,
}