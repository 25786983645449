!<template>
  <kaz-dialog 
    v-if="visible !== null"
    :visible="visible" 
    @close="$emit('close')"
  >
    <h2 class="heading heading_size_h2">Условие задания</h2>
    <tabs 
      :tabs="prepareTabs"
      class="condition-tabs" 
      size="M" 
      @input="tabInd => activeConInd = tabInd"
    />
    <div class="condition-viewer">
      <content-viewer-quill
        v-bind="getConditionProps"
        :taskType="TASK_TYPES.CONDITION"
        @over-limit="console.log('over-limit')"
        @change-task-state="data => $emit('change-task-state', data)"
      />
    </div>
    <kaz-button
      class="condition-ok-button"
      label="Хорошо"
      mode="normal"
      size="L"
      @click.stop="$emit('close')"
    />
  </kaz-dialog>
</template>

<script>
import { defineAsyncComponent, ref } from 'vue';

import Tabs from '@/components/KazUI/atoms/tabs';
import KazDialog from '@/components/KazUI/slots/dialog';
import KazButton from '@/components/KazUI/atoms/button';

import useTaskTypes from '@/views/tasks/hooks/useTaskTypes.js';


export default {
  name: 'popup-condition',

  components: {
    Tabs,
    KazDialog,
    KazButton,
    ContentViewerQuill: defineAsyncComponent({
      loader: () => import('../../components/ContentViewerQuill.vue')
    }),
  },

  props: {
    conditions: {
      type: Array,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    }
  },

  emits: ['close', 'over-limit', 'change-task-state'],

  setup() {
    const { TASK_TYPES } = useTaskTypes();
    const activeConInd = ref(0);
    return { TASK_TYPES, activeConInd }
  },

  computed: {
    prepareTabs() {
      const tabs = [];
      for (let i = 0; i < this.conditions.length; ++i) {
        tabs.push({...this.conditions[i], title: `Вариант ${i + 1}`})
      }
      return tabs
    },
    getConditionProps() {
      const activeCondition = this.conditions[this.activeConInd];
      const {taskId, isViewed: isTaskViewed, isPremium } = activeCondition;
      return { taskId, isPremium, isTaskViewed }
    }
  }
}
</script>

<style scoped>
.condition-viewer {
  width: 100%;
  max-height: 248px;
  overflow: auto;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  margin-bottom: 16px;
}

.condition-ok-button {
  display: block;
  margin-left: auto;
  margin-top: auto;
}

@media screen and (min-width: 768px) {
  .condition-viewer {
    max-height: none;
  }
  .condition-ok-button {
    margin-left: 0;
  }
}

</style>
