import { ref } from 'vue';

import { extractTokenInfo } from '@/utils/auth';
import { fetchStreamData } from "@/utils/loaders.js";

import { SubscriptionsClient } from '@/generated/subscriptions/subscriptions_grpc_web_pb.js';


export default function useSubscriptions() {
  const allSubscriptions = ref({});
  const activeSubscriptions = ref({});
  const isLoaded = ref(false);

  const subscriptionsService = new SubscriptionsClient(process.env.VUE_APP_REGISTRATION_SERVICE_URL, null, null);

  const fetchAllSubscriptions = async () => {
    /*
    Returns object that keys correspond to subscription's id 
    and values correspond to subscription object itself.
    */
    try {
      const tokens = extractTokenInfo();
      const metadata = { 'token': tokens.accessToken.token };
      const request = new proto.google.protobuf.Empty();
      const stream = subscriptionsService.getTypes(request, metadata);

      const subscriptions = {};
      const response = await fetchStreamData(stream);
      response.forEach(res => {
        const objRes = res.toObject();
        objRes['codeInterval'] = objRes['codeInterval'].toLowerCase();
        objRes['id'] = objRes['ratesId'];
        // objRes['usualSolution'] = 0;  // KAZ-2616
        subscriptions[objRes.id] = objRes;
      });
      return subscriptions
    } catch(e) {
      throw new Error(e)
    }
  }

  const fetchActiveSubscriptions = async () => {
    /*
    Returns object that keys correspond to active subscription's 
    id and values correspond to subscription object itself.
    Note: these values dont match to fetchAllSubscriptions's ones.
    */
    try {
      const tokens = extractTokenInfo();
      const metadata = { 'token': tokens.accessToken.token };
      const request = new proto.google.protobuf.Empty();
      const stream = subscriptionsService.getCurrent(request, metadata);

      const  activeSubscriptions = {};
      const response = await fetchStreamData(stream);
      response.forEach(res => {
        const objRes = res.toObject();
        const subId = objRes['ratesId'];
        // objRes['usualSolution'] = 0;  // KAZ-2616
        activeSubscriptions[subId] = objRes;
      });
      return activeSubscriptions
    } catch (e) {
      throw new Error(e.details)
    }
  }

  const initSubsInfo = async () => {
    try {
      isLoaded.value = false;
      const _allSubscriptions = await fetchAllSubscriptions();
      const _activeSubscriptions = await fetchActiveSubscriptions();
      console.log('_allSubscriptions', _allSubscriptions);
      console.log('_activeSubscriptions', _activeSubscriptions);

      Object.entries(_activeSubscriptions).forEach(([id, sub]) => {
        const objRes = sub.type;
        // console.log(objRes);
        objRes['codeInterval'] = objRes['codeInterval'].toLowerCase();
        objRes['id'] = objRes['ratesId'];
        _allSubscriptions[objRes.id] = objRes;
      });


      Object.entries(_activeSubscriptions).forEach(([id, sub]) => {
        // Mark subscription as active
        if (_allSubscriptions.hasOwnProperty(id)) {
          _allSubscriptions[id].isActive = true;
        }
        // Extend active subscription info
        _activeSubscriptions[id] = {
          ..._allSubscriptions[id],
          ...sub
        }
      });

      allSubscriptions.value = _allSubscriptions;
      activeSubscriptions.value = _activeSubscriptions;
      isLoaded.value = true;
    } catch (e) {
      throw e
    }
  }

  const initAllSubsInfo = async () => {
    try {
      isLoaded.value = false;
      const _allSubscriptions = await fetchAllSubscriptions();

      allSubscriptions.value = _allSubscriptions;
      isLoaded.value = true;
    } catch (e) {
      throw e
    }
  }

  return {
    isLoaded,
    allSubscriptions,
    activeSubscriptions,
    fetchAllSubscriptions,
    fetchActiveSubscriptions,
    initSubsInfo,
    initAllSubsInfo
  }
}