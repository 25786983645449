import { loadStructureTree } from '@/views/generation/hooks/useBook.js';
import { findNodePath } from '@/utils/book-structure';
import { fetchNodeTypes } from '@/services/TaskReader';
import STATES from '@/utils/states';


export const bookTree = {
  namespaced: true,

  state: () => ({
    /**
    * Node of the tree is an object with the following properties:
    *  - parentId
    *  - id, prevId, nextId
    *  - order
    *  - title
    *  - typeId
    *  - children
    */
    ts: 0,
    tree: null,
    bookId: null,
    readonly: false,
    nodeTypesState: STATES.INIT,
    nodeTypes: {
      1: { value: 1, title: 'Глава', children: true },
      2: { value: 2, title: 'Параграф', children: true },
      3: { value: 3, title: 'Подпараграф', children: true },
      4: { value: 4, title: 'Вопросы', children: false },
      5: { value: 5, title: 'Упражнение', children: false },
      6: { value: 6, title: 'Упражнение для повторения', children: false },
      7: { value: 7, title: 'Дополнительные задачи', children: false },
      8: { value: 8, title: 'Рефлексия', children: false },
      9: { value: 9, title: 'Другое', children: true },
      10: { value: 10, title: 'Пример', children: false },
      11: { value: 11, title: 'Тест', children: false },
      12: { value: 12, title: 'Карта', children: false },
      13: { value: 13, title: 'Алгоритм', children: false },
      14: { value: 14, title: 'Теорема', children: false },
      15: { value: 15, title: 'Свойство', children: false },
      16: { value: 16, title: 'Правило', children: false },
      17: { value: 17, title: 'Фрагмент учебного материала', children: false },
      // 18: { value: 18, title: 'Корень', children: true },
      19: { value: 19, title: 'Определение', children: false },
      20: { value: 20, title: 'Рисунок', children: false },
    }
  }),

  getters: {
    getTree: state => state.tree,
    getBookId: state => state.bookId,
    getNodeTypes: state => state.nodeTypes,
    isNodeTypesFetched: state => state.nodeTypesState === STATES.LOADED,
    getTimestamp: state => state.ts,
    getReadonly: state => state.readonly,
  },

  mutations: {
    setTree(state, newTree) {
      state.tree = newTree;
    },
    setBookId(state, bookId) {
      state.bookId = bookId;
    },
    setTimestamp(state, ts) {
      state.ts = ts;
    },
    setReadonly(state, readonly) {
      state.readonly = readonly;
    },
    removeNode(state, nodeId) {
      const { isFound, nodePath } = findNodePath(state.tree, nodeId);
      console.log('Remove node:', isFound, nodePath);
      if (isFound) {
        if (nodePath.length) {
          try {
            const pathLength = nodePath.length;
            const objectPath = nodePath.map(
              (nodeInd, i) => {
                if (i < pathLength - 1) {
                  return `.children[${nodeInd}]`;
                }
            }).join('');
            const removeExpression = `state.tree${objectPath}.children.splice(${nodePath[pathLength - 1]}, 1)`;
            // console.log('Remove expression:', removeExpression);
            eval(removeExpression);
          } catch (error) {
            throw error;
          }
        } else {
          // Case of root node. Probably it is not removable.
        }
      }
    },
    insertNode(state, { parentId, node }) {
      const { isFound, nodePath } = findNodePath(state.tree, parentId);
      console.log('Insert node:', isFound, nodePath);
      if (isFound) {
        if (nodePath.length) {
          try {
            let currentNode = state.tree;
            const pathLength = nodePath.length;
            nodePath.forEach((nodeInd, i) => {
              currentNode = currentNode.children[nodeInd];
            });
            currentNode.children.push(node);
          } catch (error) {
            throw error;
          }
        } else {
          state.tree.children.push(node);
        }
      }
    },
    updateNode(state, node) {
      const { isFound, nodePath } = findNodePath(state.tree, node.id);
      console.log('Update node:', isFound, nodePath);
      if (isFound) {
        let currentNode = state.tree;
        nodePath.forEach((nodeInd, i) => {
          currentNode = currentNode.children[nodeInd];
        });
        for(const [key, val] of Object.entries(node)) {
          currentNode[key] = val;
        }
      }
    },
    setNodeTypes(state, types) {
      state.nodeTypes = types;
    },
    setNodeTypesState(state, newState) {
      state.nodeTypesState = newState;
    }
  },

  actions: {
    async fetchTree({ commit, dispatch }, bookId) {
      try {
        const tree = await loadStructureTree(bookId);
        commit('setTree', tree);
        commit('setBookId', bookId);
        await dispatch('fetchNodeTypes');
        commit('setTimestamp', Date.now());
      } catch (error) {
        throw error;
      }
    },
    async fetchNodeTypes({ commit }) {
      try {
        commit('setNodeTypesState', STATES.LOADING);
        const res = await fetchNodeTypes();
        const nodeTypes = res.reduce((res, val) => {
          const id = val.getId();
          const children = val.getChildrenPossible();
          const title = JSON.parse(val.getName())['ru'];
          res[id] = { value: id, title, children };
          return res;
        }, {});
        commit('setNodeTypes', nodeTypes);
        commit('setNodeTypesState', STATES.LOADED);
      } catch (error) {
        commit('setNodeTypesState', STATES.ERROR);
        throw error;
      }
    },
    removeNode({ commit }, nodeId) {
      try {
        commit('removeNode', nodeId);
        commit('setTimestamp', Date.now());
      } catch (error) {
        throw error;
      }
    },
    insertNode({ commit }, { parentId, node }) {
      try {
        commit('insertNode', { parentId, node });
        commit('setTimestamp', Date.now());
      } catch (error) {
        throw error;
      }
    },
    updateNode({ commit }, node) {
      try {
        commit('updateNode', node);
        commit('setTimestamp', Date.now());
      } catch (error) {
        throw error;
      }
    }
  }
};