<template>
  <a
    href="https://vkplay.ru/play/game/victory_way/"
    target="_blank"
  >
    <img
      style="border-radius: 8px;"
      src='@/assets/images/banners/path-to-victory.jpg' 
      alt="Пусть к победе Второй Мировой"
    >
  </a>
</template>

<script>
export default {
  name: 'banner-path-to-victory',
}
</script>

<style scoped>
@media screen and (max-width: 992px) {
  a {
    align-self: center;
  }
}
</style>
