<template>
  <div class="book-card">
    <div class="book__image-wrapper">
      <router-link class="book-card__link" :to="{ name: getDestRouteName, params: { bid: id } }">
        <img
          :src="displayedBookImg"
          class="book-card__image"
          :alt="bookAlt"
          @error="handleImageError"
        />
      </router-link>
      <button-like class="btn-like" @click="handleAddToFav" :isLiked="isBookLiked" v-if="sessionActive"></button-like>
    </div>
    <div class="book-card__body">
      <div class="body__tags-wrapper" v-if="this.isPremium">
        <premium-tag :isBookPremium="bookDescription.isPremium"/>
      </div>
      <router-link class="book-card__link" :to="{ name: getDestRouteName, params: { bid: id } }">
        <p classs="heading heading_size_h4">{{ bookTitle }}</p>
      </router-link>
      <div class="body__authors-wrapper">
        <paragraph-size-small
          :textColor="'var(--text-black)'"
        >
          {{ authors.length > 0 ? authors[0].name : '' }}
        </paragraph-size-small>
      </div>
      <div class="body__book-type-wrapper" v-if="bookDescription.part !== null && bookDescription.part !== 'null' && bookDescription.part !== undefined && bookDescription.part !== '' || bookDescription.type">
        <paragraph-size-extra-small :textColor="'var(--text-secondary)'">{{ bookDescription.type }}</paragraph-size-extra-small>
        <paragraph-size-extra-small v-if="bookDescription.part !== null && bookDescription.part !== 'null' && bookDescription.part !== undefined && bookDescription.part !== ''" :textColor="'var(--text-secondary)'">•</paragraph-size-extra-small>
        <paragraph-size-extra-small :textColor="'var(--text-secondary)'" v-if="bookDescription.part !== null && bookDescription.part !== 'null' && bookDescription.part !== undefined && bookDescription.part !== ''">{{ bookDescription.part }}</paragraph-size-extra-small>
      </div>
      <paragraph-size-extra-small :textColor="'var(--text-secondary)'">{{ bookDescription.year }}</paragraph-size-extra-small>
    </div>
  </div>
</template>

<script>
/*
Props:
  - bookImg is a name of the book image file with extention

  - bookDescription is an object with keys `type` and `year` (both are strings)

  - authors is an array of an object with keys `id` and `name` (both are strings)
*/

import { mapGetters } from 'vuex'

import PremiumTag from '@/components/UI/PremiumTag'
import HeadingSizeH3 from '@/components/UI/texts/headings/HeadingSizeH3.vue';
import HeadingSizeH4 from '@/components/UI/texts/headings/HeadingSizeH4.vue';
import ParagraphSizeSmall from '@/components/UI/texts/paragraphs/ParagraphSizeSmall.vue';
import ParagraphSizeExtraSmall from '@/components/UI/texts/paragraphs/ParagraphSizeExtraSmall.vue';
import ParagraphSizeMedium from '@/components/UI/texts/paragraphs/ParagraphSizeMedium.vue';
import CardTip from '@/components/UI/CardTip.vue';
import ButtonLike from '@/components/UI/buttons/ButtonLike.vue';

import useEditorIntro from '@/hooks/routes/useEditorIntro.js';

import { FavoritesClient } from '@/generated/favorites/favorites_grpc_web_pb.js';


export default {
  name: 'book-card',

  components: {
    PremiumTag,
    HeadingSizeH3,
    ParagraphSizeSmall,
    ParagraphSizeMedium,
    CardTip,
    ButtonLike,
    HeadingSizeH4,
    ParagraphSizeExtraSmall
  },

  props: {
    id: {
      type: String,
      required: true
    },
    bookTitle: {
      type: String,
      default: ''
    },
    bookImg: {
      type: String,
    },
    bookAlt: {
      type: String,
      default: ''
    },
    bookDescription: {
      type: Object,
      default: null
    },
    authors: {
      type: Array,
      default: []
    },
  },

  setup() {
    const { getDestRouteName } = useEditorIntro('book-content');
    return { getDestRouteName }
  },

  data() {
    return {
      displayedBookImg: this.bookImg || '',
      serviceURL: process.env.VUE_APP_REGISTRATION_SERVICE_URL,
      favoritesService: null,
      isBookLiked: false,
      thereIsTags: false,
    };
  },

  computed: {
    ...mapGetters({
      sessionActive: 'authData/sessionActive',
    }),
    notEmpty() {
      return this.bookDescription && Object.keys(this.bookDescription)
    },
    isPremium() {
      return this.bookDescription && this.bookDescription.isPremium
    },
  },

  methods: {
    handleImageError() {
      this.displayedBookImg = require('@/assets/images/books/not-loaded-book2.png');
    },
    handleAddToFav() {
      this.setFavorites();
    },
    async setFavorites() {
      const requestFavorites = new proto.kazatel.favorites.ResourceData();
      requestFavorites.setId(this.id);
      const tokens = this.$getTokenInfo();
      const metadata = { 'token': tokens.accessToken.token };
      if (this.isBookLiked) {
        this.favoritesService.delete(requestFavorites, metadata, this.resultHandlerSet);
      } else {
        this.favoritesService.add(requestFavorites, metadata, this.resultHandlerSet);
      }
    },
    initContent() {
      this.favoritesService = new FavoritesClient(this.serviceURL, null, null);
      try {
        const requestFavorites = new proto.kazatel.favorites.ResourceData();
        requestFavorites.setId(this.id);
        const tokens = this.$getTokenInfo();
        const metadata = { 'token': tokens.accessToken.token };

        const stream = this.favoritesService.get(requestFavorites, metadata);
        stream.on('data', this.fetchStreamData);
        // stream.on('end', this.fetchStreamData);
        stream.on('status', (status) => {})
      } catch (error) {
        console.error('fetchFavorites:', e)
        alert('Ошибка запроса информации', e)
      }
    },
    fetchStreamData(response) {
      console.log(response.toObject(), ' ', this.id);
      if(this.id == response.getId()) {
        this.isBookLiked = response.getAttr();
      }
    },
    async setFavorites() {
      const requestFavorites = new proto.kazatel.favorites.ResourceData();
      requestFavorites.setId(this.id);
      const tokens = this.$getTokenInfo();
      const metadata = { 'token': tokens.accessToken.token };
      console.log('this.isBookLiked: ',this.isBookLiked);
      if(this.isBookLiked) {
        this.favoritesService.delete(requestFavorites, metadata, this.resultHandlerSet);
        console.log('delete: ',this.isBookLiked);
      }
      else {
        this.favoritesService.add(requestFavorites, metadata, this.resultHandlerSet);
        console.log('add: ',this.isBookLiked);
      }
    },
    resultHandlerSet(err, response) {
      if (err) {
        switch (err.code) {
          case 2:
            window.alert('Сервис недоступен\n' + err.message);
            break;
          case 13:
            window.alert('Ошибка сервиса избранного\n' + err.message);
            break;
          case 14:
            window.alert('Сервис избранного недоступен\n' + err.message);
            break;
          default:
            console.log(err.code);
            console.log(err.message);
        }
      } else {
        this.isBookLiked = !this.isBookLiked;
      }
    }
  },

  created() {
    this.initContent();
  }
}
</script>

<style scoped>
.book-card{
  display: flex;
  flex-direction: column;
  padding: 8px;
  width: 160px;
  border-radius: 8px;
  background-color: var(--white);
  gap: 8px;
  transition: .3s ease-in-out;
  outline: none;
}
.book-card:hover{
  outline: 2px solid var(--background-light-20);
  background-color: rgba(243, 243, 255, 0.50);
  transition: .3s ease-in-out;
}
.book__image-wrapper{
  width: 100%;
  display: flex;
  position: relative;
}
.book-card__link{
  width: 100%;
  display: flex;
  text-decoration: none;
}
.book-card__image{
  width: 100%;
  height: 200px;
  border-radius: 4px;
}
.book-card__body{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.body__tags-wrapper{
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.body__authors-wrapper{
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: flex-start;
  flex-wrap: nowrap;
}
.btn-like{
  position: absolute;
  right: 8px;
  top: 8px;
}
.body__book-type-wrapper{
  display: flex;
  flex-direction: row;
  gap: 4px;
}

@media screen and (max-width: 568px) {
  .book-card{
    padding: 4px;
    width: 100%;
  }
  .book-card__image{
    height: 300px;
  }
}

@media screen and (max-width: 400px) {
  .book-card__image{
    height: 200px;
  }
}
</style>
