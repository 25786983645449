import { getRandomItem } from "@/store/utils";

export const bookData = {
  namespaced: true,

  state: () => ({
    bookImgs: ['b1.webp', 'b2.webp', 'b3.jpg', 'b4.jpg'],
    bookTypes: [
      'Учебник', 
      'Рабочая тетрадь (часть 1)', 
      'Контрольные работы', 
      'Практикум',
    ],
    book: {
      id: '',
      bookTitle: 'Алгебра, 8 класс',
      bookImg: 'b1.webp',
      bookDescription: {
        type: 'Учебник',
        year: '2022 г.'
      },
      authors: [
        {id: 1, name: 'Колягин Ю.М.'},
        {id: 2, name: 'Ткачева В.М.'},
      ],
    },
    books: []
  }),

  getters: {
    getBookImgs: state => state.bookImgs,
    getBookTypes: state => state.bookTypes,
    getBooks: state => state.books,
    getBook: state => state.book,
  },

  mutations: {
    addBook(state, newBook) {
      state.books.push(newBook)
    }
  },

  actions: {
    createRandomBooks({getters}, bookNum) {
      // 1st argument uses argument destructuring to get only getters
      const books = []
      const originBook = getters.getBook
      const bookImgs = getters.getBookImgs
      const bookTypes = getters.getBookTypes
      for (let i = 0; i < bookNum; ++i) {
        originBook.id = `${i + 1}`;
        originBook.bookImg = getRandomItem(bookImgs);
        originBook.bookDescription = {
          ...originBook.bookDescription, 
          type: getRandomItem(bookTypes)
        };
        // spread operator is essential since whithout it books 
        // will have the same pointer to the originBook object
        books.push({...originBook});
      }
      return books
    },

    createBooks({getters}) {
      
       return getters.getBooks;
    },

    // getBooks({getters, dispatch}, subject, level) { 
    // }

  }
}