import { SeoServiceClient } from '@/generated/seo/seo_service_grpc_web_pb.js';

import { fetchMethodData } from '@/utils/loaders.js';
import { createMetadataWithToken } from '@/services/utils';


const seoService = new SeoServiceClient(
  process.env.VUE_APP_REGISTRATION_SERVICE_URL, 
  null, 
  null
);

async function fetchSeoData(domain, path) {
  try {
    console.log('IN:', domain, path);
    const request = new proto.kazatel.seo.PageId();
    request.setDomainName(domain);
    request.setPath(path);
    const metadata = createMetadataWithToken();
    return await fetchMethodData(seoService, 'seoData', { request, metadata });
  } catch (error) {
    throw error;
  }
}


export {
  fetchSeoData,
}