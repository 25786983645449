<template>
  <div :class="['avatar', `avatar_size_${size}`]" :style="{ backgroundImage: `url(${avatarImage})` }">
    <loader class="loader" v-if="showLoader"/>
  </div>
</template>

<script>
import Loader from '@/components/KazUI/atoms/loader';

export default{
  name: 'avatar',
  components:{
    Loader,
  },
  props:{
    size:{
      type: String,
      default: 'M',
      required: false,
    },
    avatarImage: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      showLoader: false,
    }
  },
  watch: {
    avatarImage: {
      handler(newValue) {
        if (!newValue) {
          this.showLoader = true;
        } else {
          this.showLoader = false;
        }
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>
.avatar{
  border-radius: 1000px;
  position: relative;
  background-size: cover;
  background-position: center;
  background-color: var(--kaz-base-base-02);
  border: 1px solid var(--kaz-base-base-04);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader{
  /* position: absolute;
  top: 50%;
  right: 50%; */
}
.avatar_size_XS{
  width: 32px;
  height: 32px;
}
.avatar_size_S{
  width: 48px;
  height: 48px;
}
.avatar_size_M{
  width: 64px;
  height: 64px;
}
.avatar_size_L{
  width: 96px;
  height: 96px;
}
.avatar_type_active{
  border: 2px solid var(--kaz-base-primary);
}
</style>
