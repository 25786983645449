<template>
  <div :class="['loader', `loader_size_${size}`]">
    <svg class="circular" viewBox="25 25 50 50">
      <circle :class="['path', `path_mode_${mode}`]" cx="50" cy="50" r="20" fill="none" stroke-width="3" stroke-miterlimit="10"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    mode: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: 'M',
    }
  }
}
</script>

<style scoped>
.loader {
  position: relative;
  margin: 0 auto;
}

.circular {
  animation: rotate 2s linear infinite;
  transform-origin: center center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

.loader_size_XXL {
  width: 80px;
  height: 80px;
}

.loader_size_XL {
  width: 56px;
  height: 56px;
}

.loader_size_L {
  width: 40px;
  height: 40px;
}

.loader_size_M {
  width: 24px;
  height: 24px;
}

.loader_size_S {
  width: 16px;
  height: 16px;
}

.loader_size_XS {
  width: 12px;
  height: 12px;
}

.path_mode_primary {
  stroke: var(--kaz-base-primary);
}

.path_mode_onLight {
  stroke: var(--kaz-base-base-09);
}

.path_mode_onDark {
  stroke: var(--kaz-textIcons-day-text-01);
}
</style>
