<template>
  <!-- <div class="book-card">
    <div class="book__image-wrapper">
      <router-link class="book-card__link" :to="bookLink">
        <img
          :src="displayedBookImg"
          class="book-card__image"
          :alt="bookAlt"
          @error="handleImageError"
        />
      </router-link>
      <button-like class="btn-like" @click="handleAddToFav" :isLiked="isBookLiked"></button-like>
    </div>
    <div class="book-card__body">
      <div class="body__tags-wrapper" v-if="bookDescription.isPremium">
        <premium-tag :isBookPremium="bookDescription.isPremium"/>
      </div>
      <router-link class="book-card__link" :to="bookLink">
        <heading-size-h4>{{ bookTitle }}</heading-size-h4>
      </router-link>
    </div>
  </div> -->


  <!-- <card-background class="fav-book-card"> -->
  <div class="fav-wrap">
    <router-link class="fav-book-card" :to="bookLink">
      <img
        :src="displayedBookImg"
        class="fav-book-card__cover"
        :alt="bookAlt"
        @error="handleImageError"
      />
      <div class="fav-book-card__content">
        <p class="body body_size_M body_type_bold" :title="bookTitle">
          {{ bookTitle }}
        </p>

        <p class="body body_size_XS">
          {{ formattedAuthors }}
        </p>

        <kaz-badge
          :size="'S'"
          :label="bookDescription.type"
          :appearance="'clear'"
        />
      </div>
    </router-link>
    <kaz-button
      :size="'M'"
      :appearance="'flat'"
      :mode="'normal'"
      :rightIcon="true"
      :rightIconName="isBookLiked ? 'kuiIconHeartFilledLarge'  : 'kuiIconHeartLarge'"
      @click="handleAddToFav"
    />
  </div>
  <!-- </card-background> -->
</template>

<script>
import CardBackground from '@/components/UI/card/CardBackground.vue';
import KazBadge from '@/components/KazUI/atoms/badge'
import KazButton from '@/components/KazUI/atoms/button'

import { FavoritesClient } from '@/generated/favorites/favorites_grpc_web_pb.js';


export default {
  name: 'fav-book-card',

  components: {
    CardBackground,
    KazBadge,
    KazButton
  },

  props: {
    id: {
      type: String,
      required: true
    },
    bookTitle: {
      type: String,
      default: ''
    },
    bookImg: {
      type: String,
    },
    bookAlt: {
      type: String,
      default: ''
    },
    bookDescription: {
      type: Object,
      default: null
    },
    authors: {
      type: Array,
      default: []
    },
  },

  data() {
    return {
      serviceURL: process.env.VUE_APP_REGISTRATION_SERVICE_URL,
      favoritesService: null,
      isBookLiked: false,
      displayedBookImg: this.bookImg || '',
    };
  },

  computed: {
    formattedAuthors() {
      const authorsList = this.authors.map(author => author.name);
      const authorsCount = authorsList.length;

      if (authorsCount === 0) return '';
      if (authorsCount === 1) return authorsList[0];
      if (authorsCount === 2) return authorsList.join(', ');

      return `${authorsList[0]}, ${authorsList[1]}`;
    },
    bookLink() {
      return { path: `/books/${this.id}` };
    },

    formatTitle() {
      return this.bookTitle ? this.bookTitle.slice(3).trimStart().replace(/^\w/, c => c.toUpperCase()) : '';
    }
  },

  methods: {
    handleImageError() {
      this.displayedBookImg = require('@/assets/images/books/not-loaded-book2.png');
    },
    handleAddToFav() {
      this.setFavorites();
    },
    async setFavorites() {
      const requestFavorites = new proto.kazatel.favorites.ResourceData();
      requestFavorites.setId(this.id);
      const tokens = this.$getTokenInfo();
      const metadata = { 'token': tokens.accessToken.token };
      // console.log('this.isBookLiked: ',this.isBookLiked);
      if(this.isBookLiked) {
        this.favoritesService.delete(requestFavorites, metadata, this.resultHandlerSet);
        // console.log('delete: ',this.isBookLiked);
      }
      else {
        this.favoritesService.add(requestFavorites, metadata, this.resultHandlerSet);
        // console.log('add: ',this.isBookLiked);
      }
    },
    initContent() {
      this.favoritesService = new FavoritesClient(this.serviceURL, null, null);
      try {
        // console.log('this.id: ', this.id);
        const requestFavorites = new proto.kazatel.favorites.ResourceData();
        requestFavorites.setId(this.id);
        const tokens = this.$getTokenInfo();
        const metadata = { 'token': tokens.accessToken.token };

        const stream = this.favoritesService.get(requestFavorites, metadata);
        stream.on('data', this.fetchStreamData);

        // stream.on('end', this.fetchStreamData);

        stream.on('status', (status) => {
          // if (status.code) {
          //   reject(status);
          // } else {
          // console.log('fetchStreamData.code:', status.code);
          // console.log('fetchStreamData.details:', status.details);
          // console.log('fetchStreamData.metadata:', status.metadata);
          // }
        })

      } catch (error) {
        // console.error('fetchFavorites:', e)
        alert('Ошибка запроса информации', e)
      }
    },
    fetchStreamData(response) {
      // console.log(response.toObject(), ' ', this.id);
      if(this.id == response.getId()) {
        // console.log('this.isBookLiked: ', this.isBookLiked);
        this.isBookLiked = response.getAttr();
        // console.log('this.isBookLiked: ', this.isBookLiked);
      }
    },
    async setFavorites() {
      const requestFavorites = new proto.kazatel.favorites.ResourceData();
      requestFavorites.setId(this.id);
      const tokens = this.$getTokenInfo();
      const metadata = { 'token': tokens.accessToken.token };
      // console.log('this.isBookLiked: ',this.isBookLiked);
      if(this.isBookLiked) {
        this.favoritesService.delete(requestFavorites, metadata, this.resultHandlerSet);
        // console.log('delete: ',this.isBookLiked);
      }
      else {
        this.favoritesService.add(requestFavorites, metadata, this.resultHandlerSet);
        // console.log('add: ',this.isBookLiked);
      }
    },
    resultHandlerSet(err, response) {
      if (err) {
        switch (err.code) {
          case 2:
            window.alert('Сервис недоступен\n' + err.message);
            break;
          case 13:
            window.alert('Ошибка сервиса избранного\n' + err.message);
            break;
          case 14:
            window.alert('Сервис избранного недоступен\n' + err.message);
            break;
          default:
            // console.log(err.code);
            // console.log(err.message);
        }
      } else {
        this.isBookLiked = !this.isBookLiked;
      }
    }
  },

  created() {
    this.initContent();
  }
}
</script>

<style scoped>

.fav-wrap{
  position: relative;
}

.fav-wrap:hover .fav-book-card{
  transition: var(--kaz-transition-molecules);
  background-color: var(--kaz-base-base-02);
}

.fav-book-card{
  padding: 4px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: fit-content;
  background-color: var(--kaz-base-base-01);
  border-radius: 8px;
  text-decoration: none;
  transition: var(--kaz-transition-molecules);
  position: relative;
  border: 1px solid var(--kaz-base-base-04);
}

.fav-book-card:hover{
  transition: var(--kaz-transition-molecules);
  background-color: var(--kaz-base-base-02);
  border: 1px solid var(--kaz-base-base-02);
}

.fav-wrap button{
  position: absolute;
  z-index: 999;
  bottom: 4px;
  right: 4px;
}

.fav-book-card__cover{
  width: 108px;
  height: 144px;
  object-fit: cover;
  object-position: top;
  border-radius: 4px;
}

.fav-book-card__content{
  width: 200px;
  height: 114px;
  overflow: hidden;
  white-space: wrap;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.fav-book-card__content p:first-of-type{
  position: relative;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 60px;
}

@media screen and (max-width: 568px) {
  .fav-book-card__cover{
    width: 80px;
    height: 114px;
  }
  .fav-book-card__content{
    width: 160px;
    height: 114px;
  }
  .fav-book-card__content p:first-of-type{
    -webkit-line-clamp: 2;
    max-height: 40px;
  }
  .fav-book-card__content p:last-of-type{
    max-width: 100%;
    text-overflow: ellipsis;
    overflow-y: hidden;
    max-height: 18px;
  }
}

/* .book-card{
  display: flex;
  flex-direction: column;
  padding: 8px;
  width: 160px;
  border-radius: 8px;
  background-color: var(--white);
  gap: 8px;
  transition: .3s ease-in-out;
  outline: none;
}
.book-card:hover{
  outline: 2px solid var(--background-light-20);
  background-color: rgba(243, 243, 255, 0.50);
  transition: .3s ease-in-out;
}
.book__image-wrapper{
  width: 100%;
  display: flex;
  position: relative;
}
.book-card__link{
  width: 100%;
  display: flex;
  text-decoration: none;
}
.book-card__image{
  width: 100%;
  border-radius: 4px;
  height: 200px;
}
.book-card__body{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.body__tags-wrapper{
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.body__authors-wrapper{
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: flex-start;
  flex-wrap: nowrap;
}
.btn-like{
  position: absolute;
  right: 8px;
  top: 8px;
}
@media screen and (max-width: 568px) {
  .book-card{
    padding: 4px;
    width: 100%;
  }
  .book-card__image{
    min-width: 0px;
    min-height: 0px;
    height: 300px;
  }
}
@media screen and (max-width: 400px) {
  .book-card__image{
    height: 200px;
  }
} */
</style>
