const HEADER_IO_OPTIONS = {
  root: null,
  rootMargin: '-1px 0px 0px 0px',
  threshold: 1.0
}

function HEADER_IO_TOGGLER(className) {
  return ([entry]) => {
    entry.target.classList.toggle(
      className,
      entry.intersectionRatio < 1 && entry.intersectionRatio !== 0 && entry.boundingClientRect.top === 0
    );
  }
}

const BOOK_ENTITY_IO_OPTIONS = {
  root: null,
  rootMargin: '-150px 0px 0px 0px',
  threshold: [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9]
}


export {
  HEADER_IO_OPTIONS,
  HEADER_IO_TOGGLER,
  BOOK_ENTITY_IO_OPTIONS
}