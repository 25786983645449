/**
 * @fileoverview gRPC-Web generated client stub for kazatel.subscriptions
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.23.3
// source: subscriptions/subscriptions.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.kazatel = {};
proto.kazatel.subscriptions = require('./subscriptions_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.kazatel.subscriptions.SubscriptionsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.kazatel.subscriptions.SubscriptionsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.subscriptions.SubscriptionTypesRequest,
 *   !proto.kazatel.subscriptions.SubscriptionType>}
 */
const methodDescriptor_Subscriptions_GetTypes = new grpc.web.MethodDescriptor(
  '/kazatel.subscriptions.Subscriptions/GetTypes',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.kazatel.subscriptions.SubscriptionTypesRequest,
  proto.kazatel.subscriptions.SubscriptionType,
  /**
   * @param {!proto.kazatel.subscriptions.SubscriptionTypesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.subscriptions.SubscriptionType.deserializeBinary
);


/**
 * @param {!proto.kazatel.subscriptions.SubscriptionTypesRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.subscriptions.SubscriptionType>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.subscriptions.SubscriptionsClient.prototype.getTypes =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.subscriptions.Subscriptions/GetTypes',
      request,
      metadata || {},
      methodDescriptor_Subscriptions_GetTypes);
};


/**
 * @param {!proto.kazatel.subscriptions.SubscriptionTypesRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.subscriptions.SubscriptionType>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.subscriptions.SubscriptionsPromiseClient.prototype.getTypes =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.subscriptions.Subscriptions/GetTypes',
      request,
      metadata || {},
      methodDescriptor_Subscriptions_GetTypes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.kazatel.subscriptions.Status>}
 */
const methodDescriptor_Subscriptions_GetStatuses = new grpc.web.MethodDescriptor(
  '/kazatel.subscriptions.Subscriptions/GetStatuses',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.kazatel.subscriptions.Status,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.subscriptions.Status.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.subscriptions.Status>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.subscriptions.SubscriptionsClient.prototype.getStatuses =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.subscriptions.Subscriptions/GetStatuses',
      request,
      metadata || {},
      methodDescriptor_Subscriptions_GetStatuses);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.subscriptions.Status>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.subscriptions.SubscriptionsPromiseClient.prototype.getStatuses =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.subscriptions.Subscriptions/GetStatuses',
      request,
      metadata || {},
      methodDescriptor_Subscriptions_GetStatuses);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.kazatel.subscriptions.Subscription>}
 */
const methodDescriptor_Subscriptions_GetCurrent = new grpc.web.MethodDescriptor(
  '/kazatel.subscriptions.Subscriptions/GetCurrent',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.kazatel.subscriptions.Subscription,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.subscriptions.Subscription.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.subscriptions.Subscription>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.subscriptions.SubscriptionsClient.prototype.getCurrent =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.subscriptions.Subscriptions/GetCurrent',
      request,
      metadata || {},
      methodDescriptor_Subscriptions_GetCurrent);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.subscriptions.Subscription>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.subscriptions.SubscriptionsPromiseClient.prototype.getCurrent =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.subscriptions.Subscriptions/GetCurrent',
      request,
      metadata || {},
      methodDescriptor_Subscriptions_GetCurrent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Int32Value,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Subscriptions_Change = new grpc.web.MethodDescriptor(
  '/kazatel.subscriptions.Subscriptions/Change',
  grpc.web.MethodType.UNARY,
  google_protobuf_wrappers_pb.Int32Value,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Int32Value} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Int32Value} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.subscriptions.SubscriptionsClient.prototype.change =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.subscriptions.Subscriptions/Change',
      request,
      metadata || {},
      methodDescriptor_Subscriptions_Change,
      callback);
};


/**
 * @param {!proto.google.protobuf.Int32Value} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.subscriptions.SubscriptionsPromiseClient.prototype.change =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.subscriptions.Subscriptions/Change',
      request,
      metadata || {},
      methodDescriptor_Subscriptions_Change);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.StringValue,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Subscriptions_AddUser = new grpc.web.MethodDescriptor(
  '/kazatel.subscriptions.Subscriptions/AddUser',
  grpc.web.MethodType.UNARY,
  google_protobuf_wrappers_pb.StringValue,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.StringValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.subscriptions.SubscriptionsClient.prototype.addUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.subscriptions.Subscriptions/AddUser',
      request,
      metadata || {},
      methodDescriptor_Subscriptions_AddUser,
      callback);
};


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.subscriptions.SubscriptionsPromiseClient.prototype.addUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.subscriptions.Subscriptions/AddUser',
      request,
      metadata || {},
      methodDescriptor_Subscriptions_AddUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.StringValue,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Subscriptions_DisableCurrent = new grpc.web.MethodDescriptor(
  '/kazatel.subscriptions.Subscriptions/DisableCurrent',
  grpc.web.MethodType.UNARY,
  google_protobuf_wrappers_pb.StringValue,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.StringValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.subscriptions.SubscriptionsClient.prototype.disableCurrent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.subscriptions.Subscriptions/DisableCurrent',
      request,
      metadata || {},
      methodDescriptor_Subscriptions_DisableCurrent,
      callback);
};


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.subscriptions.SubscriptionsPromiseClient.prototype.disableCurrent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.subscriptions.Subscriptions/DisableCurrent',
      request,
      metadata || {},
      methodDescriptor_Subscriptions_DisableCurrent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.subscriptions.PromocodesUsesRequest,
 *   !proto.kazatel.subscriptions.PromocodeUses>}
 */
const methodDescriptor_Subscriptions_UsesPromocodes = new grpc.web.MethodDescriptor(
  '/kazatel.subscriptions.Subscriptions/UsesPromocodes',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.kazatel.subscriptions.PromocodesUsesRequest,
  proto.kazatel.subscriptions.PromocodeUses,
  /**
   * @param {!proto.kazatel.subscriptions.PromocodesUsesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.subscriptions.PromocodeUses.deserializeBinary
);


/**
 * @param {!proto.kazatel.subscriptions.PromocodesUsesRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.subscriptions.PromocodeUses>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.subscriptions.SubscriptionsClient.prototype.usesPromocodes =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.subscriptions.Subscriptions/UsesPromocodes',
      request,
      metadata || {},
      methodDescriptor_Subscriptions_UsesPromocodes);
};


/**
 * @param {!proto.kazatel.subscriptions.PromocodesUsesRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.subscriptions.PromocodeUses>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.subscriptions.SubscriptionsPromiseClient.prototype.usesPromocodes =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.subscriptions.Subscriptions/UsesPromocodes',
      request,
      metadata || {},
      methodDescriptor_Subscriptions_UsesPromocodes);
};


module.exports = proto.kazatel.subscriptions;

