<template>
  <div class="window">
    <div class="window__content" ref="content">{{ content }}</div>
    <div class="window__shade" :class="[isPremium ? 'window__shade_premium' : 'window__shade_base']" ref="shade">
      <div 
        class="thumb"
        :class="[isPremium ? 'thumb_premium' : 'thumb_base']"
        @pointerdown="onPointerDown"
        ref="thumb"
      >
        <heading-size-h3
          :textColor="isPremium ? 'var(--yellow)' : 'var(--background-red)'"
          class="thumb__text"
        >
          Потяни, чтобы увидеть задание
        </heading-size-h3>
      </div>
    </div>
  </div>
</template>

<script>
import HeadingSizeH3 from '@/components/UI/texts/headings/HeadingSizeH3.vue';


const VISIBILITY_THRESHOLD = 0.8;

export default {
  name: 'task-shade',

  components: {
    HeadingSizeH3
  },

  props: {
    isPremium: {
      type: Boolean,
      default: false
    }
  },

  emits: ['over-limit'],
  
  data() {
    return {
      content: '',
    }
  },

  methods: {
    onPointerDown(event) {
      const thumb = this.$refs.thumb;
      thumb.setPointerCapture(event.pointerId);
      thumb.classList.add('thumb_active');

      const windowShade = this.$refs.shade;
      windowShade.classList.remove('window__shade_reset-animation');

      const windowContent = this.$refs.content;
      const boundingRectContent = windowContent.getBoundingClientRect();

      const ctx = this;
      const shiftX = event.offsetX;

      thumb.addEventListener('pointermove', onPointerMove);
      thumb.addEventListener('pointerup', onPointerUp);

      this.$setMetrikaGoal('CURTAIN_OPEN');

      function onPointerMove(event) {
        const currPosition = event.clientX - boundingRectContent.left;
        if (currPosition / boundingRectContent.width >= VISIBILITY_THRESHOLD) {
          ctx.$emit('over-limit');
          onPointerUp(event);
          return
        }
        let newLeft = event.clientX - shiftX - boundingRectContent.left;
        if (newLeft < 0) {
          newLeft = 0;
        }
        let rightEdge = windowContent.offsetWidth - thumb.offsetWidth;
        if (newLeft > rightEdge) {
          newLeft = rightEdge;
        }
        windowShade.style.left = newLeft + 'px';
      }

      function onPointerUp(event) {
        thumb.removeEventListener('pointermove', onPointerMove);
        thumb.removeEventListener('pointerup', onPointerUp);
        thumb.releasePointerCapture(event.pointerId);
        thumb.classList.remove('thumb_active');

        windowShade.removeAttribute("style");
        windowShade.classList.add('window__shade_reset-animation');
      }
    }
  }
}
</script>

<style scoped>
.window {
  position: relative;
  padding: 16px;
  background-color: var(--white);
}

.window__content {
  line-height: 150%;
  word-break: break-all;
  user-select: none;
  overflow: hidden;
  background-image: url('@/assets/images/tasks/u2591.png');
  background-size: 24px;
  background-repeat: repeat space;
  height: 400px;
}

.window__shade {
  position: absolute;
  top: 0;
  right: 0;
  left: 20%;
  height: 100%;
  background: rgba(255, 255, 255, .1);
  backdrop-filter: blur(4px);
  z-index: 1;
}

.window__shade_premium {
  border-left: 2px solid var(--yellow);
}

.window__shade_base {
  border-left: 2px solid var(--red-indicator);
}

.window__shade_reset-animation {
  transition: left .3s cubic-bezier(.52,0,.27,2);
}

.thumb {
  position: absolute;
  top: 50%;
  left: 0;
  width: 0;
  transform: translateY(-50%);
  border-radius: 0px 8px 8px 0px;
  user-select: none;
  touch-action: none;
}

.thumb_base {
  background: rgba(216 71 58 / 0.20);
}

.thumb_premium {
  background: rgba(233, 185, 16, 0.20); 
}

.thumb_base::before {
  background: var(--background-red, #D8473A);
}

.thumb_premium::before {
  background: var(--yellow, #E9B910);
}

.thumb::before {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-50%);
  content: '';
  width: 66px;
  height: 66px;
  background-image: url("data:image/svg+xml,%3Csvg width='44' height='45' viewBox='0 0 44 45' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.9974 40.487C32.1226 40.487 40.3307 32.2789 40.3307 22.1536C40.3307 12.0284 32.1226 3.82031 21.9974 3.82031C11.8722 3.82031 3.66406 12.0284 3.66406 22.1536C3.66406 32.2789 11.8722 40.487 21.9974 40.487Z' stroke='white' stroke-width='2.5'/%3E%3Cpath d='M15.5781 16.6562L21.0781 22.1563L15.5781 27.6563M22.9115 16.6562L28.4115 22.1563L22.9115 27.6563' stroke='white' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
}

.thumb_active {
  box-shadow: 2px 5px 10px 2px rgba(0, 0, 0, 0.15);
}

.thumb__text {
  display: none;
  white-space: nowrap;
}

@media screen and (min-width: 992px) {
  .thumb {
    padding: 22px 48px 18px 48px;
    width: initial;
  }
  .thumb__text {
    display: block;
  }
}
</style>
