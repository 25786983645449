export const moderationFilters = {
  namespaced: true,

  state: () => ({
    filters: []
  }),

  getters: {
    getFilters: state => state.filters,
  },

  mutations: {
    addFilter(state, filter) {
      state.filters.push(filter);
    },
    removeFilter(state, filterId) {
      state.filters = state.filters.filter(filter => filter.id !== filterId);
    },
  },

  actions: {
    updateFilter({ commit, state }, filter) {
      const existingFilter = state.filters.find(f => f.id === filter.id);

      if (existingFilter) {
        commit('removeFilter', filter.id);
      } 

      if (filter.value) {
        commit('addFilter', filter);
      }
    },
  },
}