import ModerationOrder from '@/views/moderation/ModerationOrder.vue'

const routes = [
  {
    path: '/moderation',
    name: 'moderation-list',
    meta: {layout: 'moderation', auth: true},
    component: () => import('@/views/moderation/ModerationHome.vue'),
  },
  {
    path: "/moderate/:mid",
    name: "moderation-order",
    meta: { layout: 'moderation', auth: true },
    component: ModerationOrder,
    // component: () => import('@/views/moderation/ModerationOrder.vue'),
  },
  {
    path: '/moderation/reports',
    name: 'moderation-reports',
    meta: {layout: 'moderation', auth: true},
    component: () => import('@/views/moderation/ModerationReports.vue')
  },
  {
    path: '/analytics',
    name: 'analytics',
    meta: {layout: 'moderation', auth: true},
    component: () => import('@/views/analytics/Home.vue')
  },
]

export default routes
