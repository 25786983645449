const BOOK_TYPES = Object.freeze({
  TEXTBOOK: 1,
});

const BOOK_STATUS = Object.freeze({
  NEW: 1,
  INWORK: 2,
  DRAFT: 3,
  MODERATION: 4,
  ACCEPTED: 5,
  WORK_PUBLISHED: 6,
  PUBLISHED: 7,
  UNPUBLISHED: 8,
});

const NODE_TYPES = Object.freeze({
  CHAPTER: 1,
  PARAGRAPH: 2,
  SUBPARAGRAPH: 3,
  QUESTIONS: 4,
  EXERSISE: 5,
  EXERSISE_TO_REPEAT: 6,
  ADDITIONAL_TASKS: 7,
  REFLECTION: 8,
  OTHER: 9,
  EXAMPLE: 10,
  TEST: 11,
  MAP: 12,
  ALGORITHM: 13,
  THEOREM: 14,
  PROPERTY: 15,
  RULE: 16,
  FRAGMENT: 17,
  ROOT: 18,
  DEFINITION: 19,
  PICTURE: 20,
});

// Manipulations with book nodes (created for DialogNodeInsertion component)
const ACTION_TYPES = Object.freeze({
  ADD: 1,
  UPDATE: 2,
  REMOVE: 3,
});

function buildTree(nodesArray) {
  const positionMap = {};
  nodesArray.forEach((node, i) => {
    positionMap[node.id] = i;
    node.children = [];
    node.childrenCounter = -1;  // the field is very important for ordering
  });

  let root;
  nodesArray.forEach(node => {
    const parentId = node.parentId;
    if (parentId) {
      nodesArray[positionMap[parentId]].children.push(node);
      nodesArray[positionMap[parentId]].childrenCounter = Math.max(+node.order, nodesArray[positionMap[parentId]].childrenCounter);
    } else {
      root = node;
    }
  });

  return structuredClone(root);
}

function findNodePath(tree, nodeId) {
  function DFS(node, beforeCb, afterCb) {
    let ind = 0;
    for (const child of node.children) {
      if (beforeCb) {
        beforeCb(ind, child);
      }
      DFS(child, beforeCb, afterCb);
      if (afterCb) {
        afterCb(ind, child);
      }
      ++ind;
    }
  }

  let isFound = false;
  if (tree.id === nodeId) {
    isFound = true;
  }
  const nodePath = [];
  DFS(
    tree, 
    (i, n) => {
      if (!isFound) {
        nodePath.push(i);
        if (n.id === nodeId) {
          isFound = true;
        }
      }
    },
    (i, n) => { 
      if (!isFound) {
        nodePath.pop();
      }
    }
  );

  return { isFound, nodePath };
}


export {
  BOOK_TYPES,
  BOOK_STATUS,
  NODE_TYPES,
  ACTION_TYPES,
  buildTree,
  findNodePath,
}