<template>
  <div class="report-link__wrapper" :class="{ 'report-link__wrapper_right' : !isLeft }">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2176_13679)">
        <path d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4ZM12 15C12.2652 15 12.5196 15.1054 12.7071 15.2929C12.8946 15.4804 13 15.7348 13 16C13 16.2652 12.8946 16.5196 12.7071 16.7071C12.5196 16.8946 12.2652 17 12 17C11.7348 17 11.4804 16.8946 11.2929 16.7071C11.1054 16.5196 11 16.2652 11 16C11 15.7348 11.1054 15.4804 11.2929 15.2929C11.4804 15.1054 11.7348 15 12 15ZM12 6C12.2652 6 12.5196 6.10536 12.7071 6.29289C12.8946 6.48043 13 6.73478 13 7V13C13 13.2652 12.8946 13.5196 12.7071 13.7071C12.5196 13.8946 12.2652 14 12 14C11.7348 14 11.4804 13.8946 11.2929 13.7071C11.1054 13.5196 11 13.2652 11 13V7C11 6.73478 11.1054 6.48043 11.2929 6.29289C11.4804 6.10536 11.7348 6 12 6Z" fill="#FF5555"/>
      </g>
      <defs>
        <clipPath id="clip0_2176_13679">
        <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
    <router-link :to="link" class="report-link__link">
      <paragraph-size-medium 
        :textColor="'var(--red-indicator)'" 
        :textWeight="'600'"
        style="line-height: 130%;"
      >
        <slot></slot>
      </paragraph-size-medium>
    </router-link>
  </div>
</template>

<script>
import ParagraphSizeMedium from '@/components/UI/texts/paragraphs/ParagraphSizeMedium.vue';

export default {
  name: 'link-report',

  components: {
    ParagraphSizeMedium,
  },

  props: {
    link: {
      type: Object,
      default: {path: '#'}
    },
    isLeft: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>

.report-link__wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}

.report-link__wrapper_right > svg {
  margin-left: auto;
}

.report-link__link {
  text-decoration: none;
}
</style>
