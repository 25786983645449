export const popupAuth = {
  namespaced: true,

  state: () => ({
    showLoginPopup: false,
  }),
  actions: {
    openLoginPopup({ state }) {
      if (!state.showLoginPopup) {
        state.showLoginPopup = true;
      }
    },
    closeLoginPopup({ state }) {
      if (state.showLoginPopup) {
        state.showLoginPopup = false;
      }
    },
  }
}