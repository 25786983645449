<template>
  <navbar-actions/>
</template>

<script>
import CardBackgorund from '@/components/UI/card/CardBackground.vue';
import NavbarActions from './components/NavbarActions.vue';

export default{
  name: 'sub-header-component',
  components:{
    CardBackgorund,
    NavbarActions
  },
  data(){
    return{
    }
  },
}
</script>

<style scoped>
.wrapper{
  padding: 16px;
}
</style>