
<template>
  <ul
    class="h-100 d-flex gap-3"
    :class="[setDirection, setPosition]"
  >
    <li
      class="navbar-menu__item"
      v-for="(item, idx) in menuList"
      :key="item.title"
      :class="{ active : item.link === currentRoute}"
    >
      <router-link
        class="d-flex align-items-center flex-shrink-0 nav-link w-100"
        :to="{path: item.link}"
        :id="idx"
      >
        <img
          :src="require(`@/assets/images/${item.file}`)"
          :width="iconWidth"
          :height="iconHeight"
          alt="Home"
        />
        <p class="ms-2 m-0">
          {{ item.title }}
        </p>
      </router-link>
    </li>
    <!-- Header of the list -->
    <li class="mt-auto">
      <hr class="w-100">
    </li>
    <li class="navbar-menu__item">
      <a
        class="d-flex align-items-center nav-link w-100"
        href="#"
      >
        <img
          :src="require(`@/assets/images/menu-exit.svg`)"
          :width="iconWidth"
          :height="iconHeight"
          alt="Home"
        />
        <p class="ms-2 m-0">
          Выход
        </p>
      </a>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'navigation-menu',
  props: {
    direction: {
      type: String,
      default: 'row'
    },
    menuList: {
      type: Array
    },
    position: {
      type: String,
      default: ''
    },
    currentRoute: {
      type: String,
      default: '/'
    }
  },
  data() {
    return {
      iconWidth: 32,
      iconHeight: 32,
    }
  },
  computed: {
    setDirection() {
      if (this.direction === 'row') {
        return 'navbar-menu_row'
      } else if (this.direction === 'column') {
        return 'navbar-menu_column'
      }
    },
    setPosition() {
      return this.position;
    }
  }
}
</script>
<style scoped>
p {
  font-family: "Gilroy";
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #9C9CC9;
}
</style>
