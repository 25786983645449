export const lessonsData = {
  namespaced: true,

  state: () => {
    lessons: []
  },

  getters: {
    getLessons(state) {
      return state.lessons
    }
  },

  mutations: {
    setLessons(state, newLessons) {
      state.lessons = newLessons;
    }
  }
}