const routes = [
  {
    path: '/school',
    name: 'school',
    meta: { layout: "profile", auth: false },
    component: () => import('@/views/school/School.vue')
  },
]

export default routes
