// source: taskreader/taskreader.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var taskeditor_taskeditor_pb = require('../taskeditor/taskeditor_pb.js');
goog.object.extend(proto, taskeditor_taskeditor_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_type_datetime_pb = require('../google/type/datetime_pb.js');
goog.object.extend(proto, google_type_datetime_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.kazatel.books.Book', null, global);
goog.exportSymbol('proto.kazatel.books.BookDetail', null, global);
goog.exportSymbol('proto.kazatel.books.BookDictionary', null, global);
goog.exportSymbol('proto.kazatel.books.BookType', null, global);
goog.exportSymbol('proto.kazatel.books.BooksRequest', null, global);
goog.exportSymbol('proto.kazatel.books.BooksResponse', null, global);
goog.exportSymbol('proto.kazatel.books.ContentByTypes', null, global);
goog.exportSymbol('proto.kazatel.books.Dictionary', null, global);
goog.exportSymbol('proto.kazatel.books.LevelsBySubjectResponse', null, global);
goog.exportSymbol('proto.kazatel.books.LevelsResponse', null, global);
goog.exportSymbol('proto.kazatel.books.Link', null, global);
goog.exportSymbol('proto.kazatel.books.LinkedNodes', null, global);
goog.exportSymbol('proto.kazatel.books.Links', null, global);
goog.exportSymbol('proto.kazatel.books.LinksInherit', null, global);
goog.exportSymbol('proto.kazatel.books.Node', null, global);
goog.exportSymbol('proto.kazatel.books.NodeDetail', null, global);
goog.exportSymbol('proto.kazatel.books.NodeOrder', null, global);
goog.exportSymbol('proto.kazatel.books.NodeRequest', null, global);
goog.exportSymbol('proto.kazatel.books.NodeTypesResponse', null, global);
goog.exportSymbol('proto.kazatel.books.NodesOrder', null, global);
goog.exportSymbol('proto.kazatel.books.NodesRequest', null, global);
goog.exportSymbol('proto.kazatel.books.RestTaskResponse', null, global);
goog.exportSymbol('proto.kazatel.books.SubjectsByLevelResponse', null, global);
goog.exportSymbol('proto.kazatel.books.SubjectsResponse', null, global);
goog.exportSymbol('proto.kazatel.books.TaskCardData', null, global);
goog.exportSymbol('proto.kazatel.books.TaskCardRequest', null, global);
goog.exportSymbol('proto.kazatel.books.TaskCardUpdate', null, global);
goog.exportSymbol('proto.kazatel.books.TaskCardVersion', null, global);
goog.exportSymbol('proto.kazatel.books.TaskCommon', null, global);
goog.exportSymbol('proto.kazatel.books.TaskContent', null, global);
goog.exportSymbol('proto.kazatel.books.TaskContentMeta', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.books.LevelsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.books.LevelsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.books.LevelsResponse.displayName = 'proto.kazatel.books.LevelsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.books.SubjectsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.books.SubjectsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.books.SubjectsResponse.displayName = 'proto.kazatel.books.SubjectsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.books.SubjectsByLevelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kazatel.books.SubjectsByLevelResponse.repeatedFields_, null);
};
goog.inherits(proto.kazatel.books.SubjectsByLevelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.books.SubjectsByLevelResponse.displayName = 'proto.kazatel.books.SubjectsByLevelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.books.LevelsBySubjectResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kazatel.books.LevelsBySubjectResponse.repeatedFields_, null);
};
goog.inherits(proto.kazatel.books.LevelsBySubjectResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.books.LevelsBySubjectResponse.displayName = 'proto.kazatel.books.LevelsBySubjectResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.books.Dictionary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.books.Dictionary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.books.Dictionary.displayName = 'proto.kazatel.books.Dictionary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.books.NodeTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.books.NodeTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.books.NodeTypesResponse.displayName = 'proto.kazatel.books.NodeTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.books.BookType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.books.BookType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.books.BookType.displayName = 'proto.kazatel.books.BookType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.books.Book = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kazatel.books.Book.repeatedFields_, null);
};
goog.inherits(proto.kazatel.books.Book, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.books.Book.displayName = 'proto.kazatel.books.Book';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.books.BookDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.books.BookDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.books.BookDetail.displayName = 'proto.kazatel.books.BookDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.books.BooksResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kazatel.books.BooksResponse.repeatedFields_, null);
};
goog.inherits(proto.kazatel.books.BooksResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.books.BooksResponse.displayName = 'proto.kazatel.books.BooksResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.books.Node = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.books.Node, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.books.Node.displayName = 'proto.kazatel.books.Node';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.books.TaskContentMeta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.books.TaskContentMeta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.books.TaskContentMeta.displayName = 'proto.kazatel.books.TaskContentMeta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.books.TaskContent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kazatel.books.TaskContent.repeatedFields_, null);
};
goog.inherits(proto.kazatel.books.TaskContent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.books.TaskContent.displayName = 'proto.kazatel.books.TaskContent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.books.TaskCardVersion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kazatel.books.TaskCardVersion.repeatedFields_, null);
};
goog.inherits(proto.kazatel.books.TaskCardVersion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.books.TaskCardVersion.displayName = 'proto.kazatel.books.TaskCardVersion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.books.TaskCardData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kazatel.books.TaskCardData.repeatedFields_, null);
};
goog.inherits(proto.kazatel.books.TaskCardData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.books.TaskCardData.displayName = 'proto.kazatel.books.TaskCardData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.books.TaskCommon = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.books.TaskCommon, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.books.TaskCommon.displayName = 'proto.kazatel.books.TaskCommon';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.books.ContentByTypes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kazatel.books.ContentByTypes.repeatedFields_, null);
};
goog.inherits(proto.kazatel.books.ContentByTypes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.books.ContentByTypes.displayName = 'proto.kazatel.books.ContentByTypes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.books.NodeDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kazatel.books.NodeDetail.repeatedFields_, null);
};
goog.inherits(proto.kazatel.books.NodeDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.books.NodeDetail.displayName = 'proto.kazatel.books.NodeDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.books.TaskCardUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kazatel.books.TaskCardUpdate.repeatedFields_, null);
};
goog.inherits(proto.kazatel.books.TaskCardUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.books.TaskCardUpdate.displayName = 'proto.kazatel.books.TaskCardUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.books.BooksRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kazatel.books.BooksRequest.repeatedFields_, null);
};
goog.inherits(proto.kazatel.books.BooksRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.books.BooksRequest.displayName = 'proto.kazatel.books.BooksRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.books.NodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.books.NodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.books.NodeRequest.displayName = 'proto.kazatel.books.NodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.books.NodesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kazatel.books.NodesRequest.repeatedFields_, null);
};
goog.inherits(proto.kazatel.books.NodesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.books.NodesRequest.displayName = 'proto.kazatel.books.NodesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.books.TaskCardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.books.TaskCardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.books.TaskCardRequest.displayName = 'proto.kazatel.books.TaskCardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.books.RestTaskResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.books.RestTaskResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.books.RestTaskResponse.displayName = 'proto.kazatel.books.RestTaskResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.books.NodeOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.books.NodeOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.books.NodeOrder.displayName = 'proto.kazatel.books.NodeOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.books.NodesOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kazatel.books.NodesOrder.repeatedFields_, null);
};
goog.inherits(proto.kazatel.books.NodesOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.books.NodesOrder.displayName = 'proto.kazatel.books.NodesOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.books.Link = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.books.Link, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.books.Link.displayName = 'proto.kazatel.books.Link';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.books.Links = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kazatel.books.Links.repeatedFields_, null);
};
goog.inherits(proto.kazatel.books.Links, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.books.Links.displayName = 'proto.kazatel.books.Links';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.books.LinksInherit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.books.LinksInherit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.books.LinksInherit.displayName = 'proto.kazatel.books.LinksInherit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.books.LinkedNodes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kazatel.books.LinkedNodes.repeatedFields_, null);
};
goog.inherits(proto.kazatel.books.LinkedNodes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.books.LinkedNodes.displayName = 'proto.kazatel.books.LinkedNodes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.books.BookDictionary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.books.BookDictionary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.books.BookDictionary.displayName = 'proto.kazatel.books.BookDictionary';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.books.LevelsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.books.LevelsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.books.LevelsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.LevelsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.books.LevelsResponse}
 */
proto.kazatel.books.LevelsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.books.LevelsResponse;
  return proto.kazatel.books.LevelsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.books.LevelsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.books.LevelsResponse}
 */
proto.kazatel.books.LevelsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.books.LevelsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.books.LevelsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.books.LevelsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.LevelsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.kazatel.books.LevelsResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.books.LevelsResponse} returns this
 */
proto.kazatel.books.LevelsResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.kazatel.books.LevelsResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.LevelsResponse} returns this
 */
proto.kazatel.books.LevelsResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.books.SubjectsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.books.SubjectsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.books.SubjectsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.SubjectsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.books.SubjectsResponse}
 */
proto.kazatel.books.SubjectsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.books.SubjectsResponse;
  return proto.kazatel.books.SubjectsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.books.SubjectsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.books.SubjectsResponse}
 */
proto.kazatel.books.SubjectsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.books.SubjectsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.books.SubjectsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.books.SubjectsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.SubjectsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.kazatel.books.SubjectsResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.books.SubjectsResponse} returns this
 */
proto.kazatel.books.SubjectsResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.kazatel.books.SubjectsResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.SubjectsResponse} returns this
 */
proto.kazatel.books.SubjectsResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kazatel.books.SubjectsByLevelResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.books.SubjectsByLevelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.books.SubjectsByLevelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.books.SubjectsByLevelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.SubjectsByLevelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subjectsList: jspb.Message.toObjectList(msg.getSubjectsList(),
    proto.kazatel.books.SubjectsResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.books.SubjectsByLevelResponse}
 */
proto.kazatel.books.SubjectsByLevelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.books.SubjectsByLevelResponse;
  return proto.kazatel.books.SubjectsByLevelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.books.SubjectsByLevelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.books.SubjectsByLevelResponse}
 */
proto.kazatel.books.SubjectsByLevelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.kazatel.books.SubjectsResponse;
      reader.readMessage(value,proto.kazatel.books.SubjectsResponse.deserializeBinaryFromReader);
      msg.addSubjects(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.books.SubjectsByLevelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.books.SubjectsByLevelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.books.SubjectsByLevelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.SubjectsByLevelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.kazatel.books.SubjectsResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.kazatel.books.SubjectsByLevelResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.books.SubjectsByLevelResponse} returns this
 */
proto.kazatel.books.SubjectsByLevelResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.kazatel.books.SubjectsByLevelResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.SubjectsByLevelResponse} returns this
 */
proto.kazatel.books.SubjectsByLevelResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated SubjectsResponse subjects = 3;
 * @return {!Array<!proto.kazatel.books.SubjectsResponse>}
 */
proto.kazatel.books.SubjectsByLevelResponse.prototype.getSubjectsList = function() {
  return /** @type{!Array<!proto.kazatel.books.SubjectsResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.kazatel.books.SubjectsResponse, 3));
};


/**
 * @param {!Array<!proto.kazatel.books.SubjectsResponse>} value
 * @return {!proto.kazatel.books.SubjectsByLevelResponse} returns this
*/
proto.kazatel.books.SubjectsByLevelResponse.prototype.setSubjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.kazatel.books.SubjectsResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.kazatel.books.SubjectsResponse}
 */
proto.kazatel.books.SubjectsByLevelResponse.prototype.addSubjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.kazatel.books.SubjectsResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.books.SubjectsByLevelResponse} returns this
 */
proto.kazatel.books.SubjectsByLevelResponse.prototype.clearSubjectsList = function() {
  return this.setSubjectsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kazatel.books.LevelsBySubjectResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.books.LevelsBySubjectResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.books.LevelsBySubjectResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.books.LevelsBySubjectResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.LevelsBySubjectResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    levelsList: jspb.Message.toObjectList(msg.getLevelsList(),
    proto.kazatel.books.LevelsResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.books.LevelsBySubjectResponse}
 */
proto.kazatel.books.LevelsBySubjectResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.books.LevelsBySubjectResponse;
  return proto.kazatel.books.LevelsBySubjectResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.books.LevelsBySubjectResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.books.LevelsBySubjectResponse}
 */
proto.kazatel.books.LevelsBySubjectResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.kazatel.books.LevelsResponse;
      reader.readMessage(value,proto.kazatel.books.LevelsResponse.deserializeBinaryFromReader);
      msg.addLevels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.books.LevelsBySubjectResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.books.LevelsBySubjectResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.books.LevelsBySubjectResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.LevelsBySubjectResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLevelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.kazatel.books.LevelsResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.kazatel.books.LevelsBySubjectResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.books.LevelsBySubjectResponse} returns this
 */
proto.kazatel.books.LevelsBySubjectResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.kazatel.books.LevelsBySubjectResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.LevelsBySubjectResponse} returns this
 */
proto.kazatel.books.LevelsBySubjectResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated LevelsResponse levels = 3;
 * @return {!Array<!proto.kazatel.books.LevelsResponse>}
 */
proto.kazatel.books.LevelsBySubjectResponse.prototype.getLevelsList = function() {
  return /** @type{!Array<!proto.kazatel.books.LevelsResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.kazatel.books.LevelsResponse, 3));
};


/**
 * @param {!Array<!proto.kazatel.books.LevelsResponse>} value
 * @return {!proto.kazatel.books.LevelsBySubjectResponse} returns this
*/
proto.kazatel.books.LevelsBySubjectResponse.prototype.setLevelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.kazatel.books.LevelsResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.kazatel.books.LevelsResponse}
 */
proto.kazatel.books.LevelsBySubjectResponse.prototype.addLevels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.kazatel.books.LevelsResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.books.LevelsBySubjectResponse} returns this
 */
proto.kazatel.books.LevelsBySubjectResponse.prototype.clearLevelsList = function() {
  return this.setLevelsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.books.Dictionary.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.books.Dictionary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.books.Dictionary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.Dictionary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.books.Dictionary}
 */
proto.kazatel.books.Dictionary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.books.Dictionary;
  return proto.kazatel.books.Dictionary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.books.Dictionary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.books.Dictionary}
 */
proto.kazatel.books.Dictionary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.books.Dictionary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.books.Dictionary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.books.Dictionary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.Dictionary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.kazatel.books.Dictionary.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.books.Dictionary} returns this
 */
proto.kazatel.books.Dictionary.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.kazatel.books.Dictionary.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.Dictionary} returns this
 */
proto.kazatel.books.Dictionary.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.kazatel.books.Dictionary} returns this
 */
proto.kazatel.books.Dictionary.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.books.Dictionary.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.books.NodeTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.books.NodeTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.books.NodeTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.NodeTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    designation: jspb.Message.getFieldWithDefault(msg, 3, ""),
    childrenPossible: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.books.NodeTypesResponse}
 */
proto.kazatel.books.NodeTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.books.NodeTypesResponse;
  return proto.kazatel.books.NodeTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.books.NodeTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.books.NodeTypesResponse}
 */
proto.kazatel.books.NodeTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDesignation(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setChildrenPossible(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.books.NodeTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.books.NodeTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.books.NodeTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.NodeTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDesignation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getChildrenPossible();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.kazatel.books.NodeTypesResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.books.NodeTypesResponse} returns this
 */
proto.kazatel.books.NodeTypesResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.kazatel.books.NodeTypesResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.NodeTypesResponse} returns this
 */
proto.kazatel.books.NodeTypesResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string designation = 3;
 * @return {string}
 */
proto.kazatel.books.NodeTypesResponse.prototype.getDesignation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.NodeTypesResponse} returns this
 */
proto.kazatel.books.NodeTypesResponse.prototype.setDesignation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool children_possible = 4;
 * @return {boolean}
 */
proto.kazatel.books.NodeTypesResponse.prototype.getChildrenPossible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.kazatel.books.NodeTypesResponse} returns this
 */
proto.kazatel.books.NodeTypesResponse.prototype.setChildrenPossible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.books.BookType.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.books.BookType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.books.BookType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.BookType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.books.BookType}
 */
proto.kazatel.books.BookType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.books.BookType;
  return proto.kazatel.books.BookType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.books.BookType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.books.BookType}
 */
proto.kazatel.books.BookType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.books.BookType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.books.BookType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.books.BookType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.BookType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.kazatel.books.BookType.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.books.BookType} returns this
 */
proto.kazatel.books.BookType.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.kazatel.books.BookType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.BookType} returns this
 */
proto.kazatel.books.BookType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kazatel.books.Book.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.books.Book.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.books.Book.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.books.Book} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.Book.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    levelsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    publisher: jspb.Message.getFieldWithDefault(msg, 4, ""),
    statusId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    subject: jspb.Message.getFieldWithDefault(msg, 6, ""),
    subjectId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    viewUrl: jspb.Message.getFieldWithDefault(msg, 8, ""),
    authors: jspb.Message.getFieldWithDefault(msg, 9, ""),
    attrPremium: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    publishYear: jspb.Message.getFieldWithDefault(msg, 11, ""),
    type: jspb.Message.getFieldWithDefault(msg, 12, ""),
    typeId: jspb.Message.getFieldWithDefault(msg, 13, 0),
    part: jspb.Message.getFieldWithDefault(msg, 14, ""),
    isbn: jspb.Message.getFieldWithDefault(msg, 15, ""),
    nameOfficial: jspb.Message.getFieldWithDefault(msg, 16, ""),
    fgos: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    rank: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    description: jspb.Message.getFieldWithDefault(msg, 19, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.books.Book}
 */
proto.kazatel.books.Book.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.books.Book;
  return proto.kazatel.books.Book.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.books.Book} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.books.Book}
 */
proto.kazatel.books.Book.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLevels(values[i]);
      }
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublisher(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatusId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSubjectId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setViewUrl(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthors(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAttrPremium(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublishYear(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTypeId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPart(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsbn(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setNameOfficial(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFgos(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRank(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.books.Book.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.books.Book.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.books.Book} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.Book.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLevelsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
  f = message.getPublisher();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSubjectId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getViewUrl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAuthors();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAttrPremium();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getPublishYear();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTypeId();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getPart();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getIsbn();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getNameOfficial();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getFgos();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getRank();
  if (f !== 0.0) {
    writer.writeDouble(
      18,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.kazatel.books.Book.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.Book} returns this
 */
proto.kazatel.books.Book.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.kazatel.books.Book} returns this
 */
proto.kazatel.books.Book.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.books.Book.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.kazatel.books.Book.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.Book} returns this
 */
proto.kazatel.books.Book.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated int32 levels = 3;
 * @return {!Array<number>}
 */
proto.kazatel.books.Book.prototype.getLevelsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.kazatel.books.Book} returns this
 */
proto.kazatel.books.Book.prototype.setLevelsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.kazatel.books.Book} returns this
 */
proto.kazatel.books.Book.prototype.addLevels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.books.Book} returns this
 */
proto.kazatel.books.Book.prototype.clearLevelsList = function() {
  return this.setLevelsList([]);
};


/**
 * optional string publisher = 4;
 * @return {string}
 */
proto.kazatel.books.Book.prototype.getPublisher = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.Book} returns this
 */
proto.kazatel.books.Book.prototype.setPublisher = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 status_id = 5;
 * @return {number}
 */
proto.kazatel.books.Book.prototype.getStatusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.books.Book} returns this
 */
proto.kazatel.books.Book.prototype.setStatusId = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.kazatel.books.Book} returns this
 */
proto.kazatel.books.Book.prototype.clearStatusId = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.books.Book.prototype.hasStatusId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string subject = 6;
 * @return {string}
 */
proto.kazatel.books.Book.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.Book} returns this
 */
proto.kazatel.books.Book.prototype.setSubject = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.kazatel.books.Book} returns this
 */
proto.kazatel.books.Book.prototype.clearSubject = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.books.Book.prototype.hasSubject = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 subject_id = 7;
 * @return {number}
 */
proto.kazatel.books.Book.prototype.getSubjectId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.books.Book} returns this
 */
proto.kazatel.books.Book.prototype.setSubjectId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string view_url = 8;
 * @return {string}
 */
proto.kazatel.books.Book.prototype.getViewUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.Book} returns this
 */
proto.kazatel.books.Book.prototype.setViewUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string authors = 9;
 * @return {string}
 */
proto.kazatel.books.Book.prototype.getAuthors = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.Book} returns this
 */
proto.kazatel.books.Book.prototype.setAuthors = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool attr_premium = 10;
 * @return {boolean}
 */
proto.kazatel.books.Book.prototype.getAttrPremium = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.kazatel.books.Book} returns this
 */
proto.kazatel.books.Book.prototype.setAttrPremium = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string publish_year = 11;
 * @return {string}
 */
proto.kazatel.books.Book.prototype.getPublishYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.Book} returns this
 */
proto.kazatel.books.Book.prototype.setPublishYear = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string type = 12;
 * @return {string}
 */
proto.kazatel.books.Book.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.Book} returns this
 */
proto.kazatel.books.Book.prototype.setType = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.kazatel.books.Book} returns this
 */
proto.kazatel.books.Book.prototype.clearType = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.books.Book.prototype.hasType = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional int32 type_id = 13;
 * @return {number}
 */
proto.kazatel.books.Book.prototype.getTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.books.Book} returns this
 */
proto.kazatel.books.Book.prototype.setTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string part = 14;
 * @return {string}
 */
proto.kazatel.books.Book.prototype.getPart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.Book} returns this
 */
proto.kazatel.books.Book.prototype.setPart = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string isbn = 15;
 * @return {string}
 */
proto.kazatel.books.Book.prototype.getIsbn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.Book} returns this
 */
proto.kazatel.books.Book.prototype.setIsbn = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string name_official = 16;
 * @return {string}
 */
proto.kazatel.books.Book.prototype.getNameOfficial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.Book} returns this
 */
proto.kazatel.books.Book.prototype.setNameOfficial = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional bool fgos = 17;
 * @return {boolean}
 */
proto.kazatel.books.Book.prototype.getFgos = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.kazatel.books.Book} returns this
 */
proto.kazatel.books.Book.prototype.setFgos = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional double rank = 18;
 * @return {number}
 */
proto.kazatel.books.Book.prototype.getRank = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.books.Book} returns this
 */
proto.kazatel.books.Book.prototype.setRank = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional string description = 19;
 * @return {string}
 */
proto.kazatel.books.Book.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.Book} returns this
 */
proto.kazatel.books.Book.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.books.BookDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.books.BookDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.books.BookDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.BookDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    book: (f = msg.getBook()) && proto.kazatel.books.Book.toObject(includeInstance, f),
    rootNodeId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pages: jspb.Message.getFieldWithDefault(msg, 3, 0),
    tags: (f = msg.getTags()) && proto.kazatel.books.Dictionary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.books.BookDetail}
 */
proto.kazatel.books.BookDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.books.BookDetail;
  return proto.kazatel.books.BookDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.books.BookDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.books.BookDetail}
 */
proto.kazatel.books.BookDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.kazatel.books.Book;
      reader.readMessage(value,proto.kazatel.books.Book.deserializeBinaryFromReader);
      msg.setBook(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRootNodeId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPages(value);
      break;
    case 4:
      var value = new proto.kazatel.books.Dictionary;
      reader.readMessage(value,proto.kazatel.books.Dictionary.deserializeBinaryFromReader);
      msg.setTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.books.BookDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.books.BookDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.books.BookDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.BookDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBook();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.kazatel.books.Book.serializeBinaryToWriter
    );
  }
  f = message.getRootNodeId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPages();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTags();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.kazatel.books.Dictionary.serializeBinaryToWriter
    );
  }
};


/**
 * optional Book book = 1;
 * @return {?proto.kazatel.books.Book}
 */
proto.kazatel.books.BookDetail.prototype.getBook = function() {
  return /** @type{?proto.kazatel.books.Book} */ (
    jspb.Message.getWrapperField(this, proto.kazatel.books.Book, 1));
};


/**
 * @param {?proto.kazatel.books.Book|undefined} value
 * @return {!proto.kazatel.books.BookDetail} returns this
*/
proto.kazatel.books.BookDetail.prototype.setBook = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.books.BookDetail} returns this
 */
proto.kazatel.books.BookDetail.prototype.clearBook = function() {
  return this.setBook(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.books.BookDetail.prototype.hasBook = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string root_node_id = 2;
 * @return {string}
 */
proto.kazatel.books.BookDetail.prototype.getRootNodeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.BookDetail} returns this
 */
proto.kazatel.books.BookDetail.prototype.setRootNodeId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 pages = 3;
 * @return {number}
 */
proto.kazatel.books.BookDetail.prototype.getPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.books.BookDetail} returns this
 */
proto.kazatel.books.BookDetail.prototype.setPages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Dictionary tags = 4;
 * @return {?proto.kazatel.books.Dictionary}
 */
proto.kazatel.books.BookDetail.prototype.getTags = function() {
  return /** @type{?proto.kazatel.books.Dictionary} */ (
    jspb.Message.getWrapperField(this, proto.kazatel.books.Dictionary, 4));
};


/**
 * @param {?proto.kazatel.books.Dictionary|undefined} value
 * @return {!proto.kazatel.books.BookDetail} returns this
*/
proto.kazatel.books.BookDetail.prototype.setTags = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.books.BookDetail} returns this
 */
proto.kazatel.books.BookDetail.prototype.clearTags = function() {
  return this.setTags(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.books.BookDetail.prototype.hasTags = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kazatel.books.BooksResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.books.BooksResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.books.BooksResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.books.BooksResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.BooksResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    booksList: jspb.Message.toObjectList(msg.getBooksList(),
    proto.kazatel.books.Book.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.books.BooksResponse}
 */
proto.kazatel.books.BooksResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.books.BooksResponse;
  return proto.kazatel.books.BooksResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.books.BooksResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.books.BooksResponse}
 */
proto.kazatel.books.BooksResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.kazatel.books.Book;
      reader.readMessage(value,proto.kazatel.books.Book.deserializeBinaryFromReader);
      msg.addBooks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.books.BooksResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.books.BooksResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.books.BooksResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.BooksResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.kazatel.books.Book.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Book Books = 1;
 * @return {!Array<!proto.kazatel.books.Book>}
 */
proto.kazatel.books.BooksResponse.prototype.getBooksList = function() {
  return /** @type{!Array<!proto.kazatel.books.Book>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.kazatel.books.Book, 1));
};


/**
 * @param {!Array<!proto.kazatel.books.Book>} value
 * @return {!proto.kazatel.books.BooksResponse} returns this
*/
proto.kazatel.books.BooksResponse.prototype.setBooksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.kazatel.books.Book=} opt_value
 * @param {number=} opt_index
 * @return {!proto.kazatel.books.Book}
 */
proto.kazatel.books.BooksResponse.prototype.addBooks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.kazatel.books.Book, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.books.BooksResponse} returns this
 */
proto.kazatel.books.BooksResponse.prototype.clearBooksList = function() {
  return this.setBooksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.books.Node.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.books.Node.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.books.Node} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.Node.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    parentId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    prevId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    nextId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    order: jspb.Message.getFieldWithDefault(msg, 7, ""),
    typeId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    page: jspb.Message.getFieldWithDefault(msg, 9, 0),
    contest: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.books.Node}
 */
proto.kazatel.books.Node.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.books.Node;
  return proto.kazatel.books.Node.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.books.Node} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.books.Node}
 */
proto.kazatel.books.Node.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrevId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrder(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTypeId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setContest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.books.Node.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.books.Node.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.books.Node} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.Node.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getParentId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTypeId();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getContest();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.kazatel.books.Node.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.Node} returns this
 */
proto.kazatel.books.Node.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.kazatel.books.Node} returns this
 */
proto.kazatel.books.Node.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.books.Node.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.kazatel.books.Node.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.Node} returns this
 */
proto.kazatel.books.Node.prototype.setType = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.kazatel.books.Node} returns this
 */
proto.kazatel.books.Node.prototype.clearType = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.books.Node.prototype.hasType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string parent_id = 3;
 * @return {string}
 */
proto.kazatel.books.Node.prototype.getParentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.Node} returns this
 */
proto.kazatel.books.Node.prototype.setParentId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.kazatel.books.Node.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.Node} returns this
 */
proto.kazatel.books.Node.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string prev_id = 5;
 * @return {string}
 */
proto.kazatel.books.Node.prototype.getPrevId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.Node} returns this
 */
proto.kazatel.books.Node.prototype.setPrevId = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.kazatel.books.Node} returns this
 */
proto.kazatel.books.Node.prototype.clearPrevId = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.books.Node.prototype.hasPrevId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string next_id = 6;
 * @return {string}
 */
proto.kazatel.books.Node.prototype.getNextId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.Node} returns this
 */
proto.kazatel.books.Node.prototype.setNextId = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.kazatel.books.Node} returns this
 */
proto.kazatel.books.Node.prototype.clearNextId = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.books.Node.prototype.hasNextId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string order = 7;
 * @return {string}
 */
proto.kazatel.books.Node.prototype.getOrder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.Node} returns this
 */
proto.kazatel.books.Node.prototype.setOrder = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.kazatel.books.Node} returns this
 */
proto.kazatel.books.Node.prototype.clearOrder = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.books.Node.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 type_id = 8;
 * @return {number}
 */
proto.kazatel.books.Node.prototype.getTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.books.Node} returns this
 */
proto.kazatel.books.Node.prototype.setTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 page = 9;
 * @return {number}
 */
proto.kazatel.books.Node.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.books.Node} returns this
 */
proto.kazatel.books.Node.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool contest = 10;
 * @return {boolean}
 */
proto.kazatel.books.Node.prototype.getContest = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.kazatel.books.Node} returns this
 */
proto.kazatel.books.Node.prototype.setContest = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.books.TaskContentMeta.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.books.TaskContentMeta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.books.TaskContentMeta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.TaskContentMeta.toObject = function(includeInstance, msg) {
  var f, obj = {
    nativeName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    storageId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    size: jspb.Message.getFieldWithDefault(msg, 3, 0),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.books.TaskContentMeta}
 */
proto.kazatel.books.TaskContentMeta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.books.TaskContentMeta;
  return proto.kazatel.books.TaskContentMeta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.books.TaskContentMeta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.books.TaskContentMeta}
 */
proto.kazatel.books.TaskContentMeta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNativeName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStorageId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSize(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.books.TaskContentMeta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.books.TaskContentMeta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.books.TaskContentMeta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.TaskContentMeta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNativeName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStorageId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string native_name = 1;
 * @return {string}
 */
proto.kazatel.books.TaskContentMeta.prototype.getNativeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.TaskContentMeta} returns this
 */
proto.kazatel.books.TaskContentMeta.prototype.setNativeName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string storage_id = 2;
 * @return {string}
 */
proto.kazatel.books.TaskContentMeta.prototype.getStorageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.TaskContentMeta} returns this
 */
proto.kazatel.books.TaskContentMeta.prototype.setStorageId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 size = 3;
 * @return {number}
 */
proto.kazatel.books.TaskContentMeta.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.books.TaskContentMeta} returns this
 */
proto.kazatel.books.TaskContentMeta.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 type = 4;
 * @return {number}
 */
proto.kazatel.books.TaskContentMeta.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.books.TaskContentMeta} returns this
 */
proto.kazatel.books.TaskContentMeta.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kazatel.books.TaskContent.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.books.TaskContent.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.books.TaskContent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.books.TaskContent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.TaskContent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    created: (f = msg.getCreated()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f),
    dataPathList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    contentData: jspb.Message.getFieldWithDefault(msg, 5, ""),
    premium: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    statusId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    contentMeta: (f = msg.getContentMeta()) && proto.kazatel.books.TaskContentMeta.toObject(includeInstance, f),
    taskId: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.books.TaskContent}
 */
proto.kazatel.books.TaskContent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.books.TaskContent;
  return proto.kazatel.books.TaskContent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.books.TaskContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.books.TaskContent}
 */
proto.kazatel.books.TaskContent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setType(value);
      break;
    case 3:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addDataPath(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentData(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPremium(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatusId(value);
      break;
    case 8:
      var value = new proto.kazatel.books.TaskContentMeta;
      reader.readMessage(value,proto.kazatel.books.TaskContentMeta.deserializeBinaryFromReader);
      msg.setContentMeta(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.books.TaskContent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.books.TaskContent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.books.TaskContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.TaskContent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = message.getDataPathList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPremium();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getStatusId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getContentMeta();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.kazatel.books.TaskContentMeta.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.kazatel.books.TaskContent.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.TaskContent} returns this
 */
proto.kazatel.books.TaskContent.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 type = 2;
 * @return {number}
 */
proto.kazatel.books.TaskContent.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.books.TaskContent} returns this
 */
proto.kazatel.books.TaskContent.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.type.DateTime created = 3;
 * @return {?proto.google.type.DateTime}
 */
proto.kazatel.books.TaskContent.prototype.getCreated = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 3));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.kazatel.books.TaskContent} returns this
*/
proto.kazatel.books.TaskContent.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.books.TaskContent} returns this
 */
proto.kazatel.books.TaskContent.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.books.TaskContent.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated string data_path = 4;
 * @return {!Array<string>}
 */
proto.kazatel.books.TaskContent.prototype.getDataPathList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.kazatel.books.TaskContent} returns this
 */
proto.kazatel.books.TaskContent.prototype.setDataPathList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.kazatel.books.TaskContent} returns this
 */
proto.kazatel.books.TaskContent.prototype.addDataPath = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.books.TaskContent} returns this
 */
proto.kazatel.books.TaskContent.prototype.clearDataPathList = function() {
  return this.setDataPathList([]);
};


/**
 * optional string content_data = 5;
 * @return {string}
 */
proto.kazatel.books.TaskContent.prototype.getContentData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.TaskContent} returns this
 */
proto.kazatel.books.TaskContent.prototype.setContentData = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.kazatel.books.TaskContent} returns this
 */
proto.kazatel.books.TaskContent.prototype.clearContentData = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.books.TaskContent.prototype.hasContentData = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool premium = 6;
 * @return {boolean}
 */
proto.kazatel.books.TaskContent.prototype.getPremium = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.kazatel.books.TaskContent} returns this
 */
proto.kazatel.books.TaskContent.prototype.setPremium = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int32 status_id = 7;
 * @return {number}
 */
proto.kazatel.books.TaskContent.prototype.getStatusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.books.TaskContent} returns this
 */
proto.kazatel.books.TaskContent.prototype.setStatusId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional TaskContentMeta content_meta = 8;
 * @return {?proto.kazatel.books.TaskContentMeta}
 */
proto.kazatel.books.TaskContent.prototype.getContentMeta = function() {
  return /** @type{?proto.kazatel.books.TaskContentMeta} */ (
    jspb.Message.getWrapperField(this, proto.kazatel.books.TaskContentMeta, 8));
};


/**
 * @param {?proto.kazatel.books.TaskContentMeta|undefined} value
 * @return {!proto.kazatel.books.TaskContent} returns this
*/
proto.kazatel.books.TaskContent.prototype.setContentMeta = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.books.TaskContent} returns this
 */
proto.kazatel.books.TaskContent.prototype.clearContentMeta = function() {
  return this.setContentMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.books.TaskContent.prototype.hasContentMeta = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string task_id = 9;
 * @return {string}
 */
proto.kazatel.books.TaskContent.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.TaskContent} returns this
 */
proto.kazatel.books.TaskContent.prototype.setTaskId = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.kazatel.books.TaskContent} returns this
 */
proto.kazatel.books.TaskContent.prototype.clearTaskId = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.books.TaskContent.prototype.hasTaskId = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kazatel.books.TaskCardVersion.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.books.TaskCardVersion.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.books.TaskCardVersion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.books.TaskCardVersion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.TaskCardVersion.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    conditionsList: jspb.Message.toObjectList(msg.getConditionsList(),
    proto.kazatel.books.TaskContent.toObject, includeInstance),
    solutionsList: jspb.Message.toObjectList(msg.getSolutionsList(),
    proto.kazatel.books.TaskContent.toObject, includeInstance),
    contentsList: jspb.Message.toObjectList(msg.getContentsList(),
    proto.kazatel.books.TaskContent.toObject, includeInstance),
    attrPremium: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.books.TaskCardVersion}
 */
proto.kazatel.books.TaskCardVersion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.books.TaskCardVersion;
  return proto.kazatel.books.TaskCardVersion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.books.TaskCardVersion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.books.TaskCardVersion}
 */
proto.kazatel.books.TaskCardVersion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersion(value);
      break;
    case 2:
      var value = new proto.kazatel.books.TaskContent;
      reader.readMessage(value,proto.kazatel.books.TaskContent.deserializeBinaryFromReader);
      msg.addConditions(value);
      break;
    case 3:
      var value = new proto.kazatel.books.TaskContent;
      reader.readMessage(value,proto.kazatel.books.TaskContent.deserializeBinaryFromReader);
      msg.addSolutions(value);
      break;
    case 4:
      var value = new proto.kazatel.books.TaskContent;
      reader.readMessage(value,proto.kazatel.books.TaskContent.deserializeBinaryFromReader);
      msg.addContents(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAttrPremium(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.books.TaskCardVersion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.books.TaskCardVersion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.books.TaskCardVersion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.TaskCardVersion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getConditionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.kazatel.books.TaskContent.serializeBinaryToWriter
    );
  }
  f = message.getSolutionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.kazatel.books.TaskContent.serializeBinaryToWriter
    );
  }
  f = message.getContentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.kazatel.books.TaskContent.serializeBinaryToWriter
    );
  }
  f = message.getAttrPremium();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int32 version = 1;
 * @return {number}
 */
proto.kazatel.books.TaskCardVersion.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.books.TaskCardVersion} returns this
 */
proto.kazatel.books.TaskCardVersion.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated TaskContent conditions = 2;
 * @return {!Array<!proto.kazatel.books.TaskContent>}
 */
proto.kazatel.books.TaskCardVersion.prototype.getConditionsList = function() {
  return /** @type{!Array<!proto.kazatel.books.TaskContent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.kazatel.books.TaskContent, 2));
};


/**
 * @param {!Array<!proto.kazatel.books.TaskContent>} value
 * @return {!proto.kazatel.books.TaskCardVersion} returns this
*/
proto.kazatel.books.TaskCardVersion.prototype.setConditionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.kazatel.books.TaskContent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.kazatel.books.TaskContent}
 */
proto.kazatel.books.TaskCardVersion.prototype.addConditions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.kazatel.books.TaskContent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.books.TaskCardVersion} returns this
 */
proto.kazatel.books.TaskCardVersion.prototype.clearConditionsList = function() {
  return this.setConditionsList([]);
};


/**
 * repeated TaskContent solutions = 3;
 * @return {!Array<!proto.kazatel.books.TaskContent>}
 */
proto.kazatel.books.TaskCardVersion.prototype.getSolutionsList = function() {
  return /** @type{!Array<!proto.kazatel.books.TaskContent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.kazatel.books.TaskContent, 3));
};


/**
 * @param {!Array<!proto.kazatel.books.TaskContent>} value
 * @return {!proto.kazatel.books.TaskCardVersion} returns this
*/
proto.kazatel.books.TaskCardVersion.prototype.setSolutionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.kazatel.books.TaskContent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.kazatel.books.TaskContent}
 */
proto.kazatel.books.TaskCardVersion.prototype.addSolutions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.kazatel.books.TaskContent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.books.TaskCardVersion} returns this
 */
proto.kazatel.books.TaskCardVersion.prototype.clearSolutionsList = function() {
  return this.setSolutionsList([]);
};


/**
 * repeated TaskContent contents = 4;
 * @return {!Array<!proto.kazatel.books.TaskContent>}
 */
proto.kazatel.books.TaskCardVersion.prototype.getContentsList = function() {
  return /** @type{!Array<!proto.kazatel.books.TaskContent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.kazatel.books.TaskContent, 4));
};


/**
 * @param {!Array<!proto.kazatel.books.TaskContent>} value
 * @return {!proto.kazatel.books.TaskCardVersion} returns this
*/
proto.kazatel.books.TaskCardVersion.prototype.setContentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.kazatel.books.TaskContent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.kazatel.books.TaskContent}
 */
proto.kazatel.books.TaskCardVersion.prototype.addContents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.kazatel.books.TaskContent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.books.TaskCardVersion} returns this
 */
proto.kazatel.books.TaskCardVersion.prototype.clearContentsList = function() {
  return this.setContentsList([]);
};


/**
 * optional bool attr_premium = 5;
 * @return {boolean}
 */
proto.kazatel.books.TaskCardVersion.prototype.getAttrPremium = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.kazatel.books.TaskCardVersion} returns this
 */
proto.kazatel.books.TaskCardVersion.prototype.setAttrPremium = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kazatel.books.TaskCardData.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.books.TaskCardData.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.books.TaskCardData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.books.TaskCardData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.TaskCardData.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nodeId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    owner: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    statusId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    created: (f = msg.getCreated()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f),
    versionsList: jspb.Message.toObjectList(msg.getVersionsList(),
    proto.kazatel.books.TaskCardVersion.toObject, includeInstance),
    restTask: jspb.Message.getFieldWithDefault(msg, 8, 0),
    restTaskPremium: jspb.Message.getFieldWithDefault(msg, 9, 0),
    overLimit: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    photo: msg.getPhoto_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.books.TaskCardData}
 */
proto.kazatel.books.TaskCardData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.books.TaskCardData;
  return proto.kazatel.books.TaskCardData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.books.TaskCardData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.books.TaskCardData}
 */
proto.kazatel.books.TaskCardData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOwner(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatusId(value);
      break;
    case 6:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 7:
      var value = new proto.kazatel.books.TaskCardVersion;
      reader.readMessage(value,proto.kazatel.books.TaskCardVersion.deserializeBinaryFromReader);
      msg.addVersions(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRestTask(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRestTaskPremium(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOverLimit(value);
      break;
    case 11:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPhoto(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.books.TaskCardData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.books.TaskCardData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.books.TaskCardData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.TaskCardData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNodeId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOwner();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getStatusId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = message.getVersionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.kazatel.books.TaskCardVersion.serializeBinaryToWriter
    );
  }
  f = message.getRestTask();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getRestTaskPremium();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getOverLimit();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getPhoto_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      11,
      f
    );
  }
};


/**
 * optional string task_id = 1;
 * @return {string}
 */
proto.kazatel.books.TaskCardData.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.TaskCardData} returns this
 */
proto.kazatel.books.TaskCardData.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string node_id = 2;
 * @return {string}
 */
proto.kazatel.books.TaskCardData.prototype.getNodeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.TaskCardData} returns this
 */
proto.kazatel.books.TaskCardData.prototype.setNodeId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.kazatel.books.TaskCardData.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.TaskCardData} returns this
 */
proto.kazatel.books.TaskCardData.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool owner = 4;
 * @return {boolean}
 */
proto.kazatel.books.TaskCardData.prototype.getOwner = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.kazatel.books.TaskCardData} returns this
 */
proto.kazatel.books.TaskCardData.prototype.setOwner = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int32 status_id = 5;
 * @return {number}
 */
proto.kazatel.books.TaskCardData.prototype.getStatusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.books.TaskCardData} returns this
 */
proto.kazatel.books.TaskCardData.prototype.setStatusId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional google.type.DateTime created = 6;
 * @return {?proto.google.type.DateTime}
 */
proto.kazatel.books.TaskCardData.prototype.getCreated = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 6));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.kazatel.books.TaskCardData} returns this
*/
proto.kazatel.books.TaskCardData.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.books.TaskCardData} returns this
 */
proto.kazatel.books.TaskCardData.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.books.TaskCardData.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated TaskCardVersion versions = 7;
 * @return {!Array<!proto.kazatel.books.TaskCardVersion>}
 */
proto.kazatel.books.TaskCardData.prototype.getVersionsList = function() {
  return /** @type{!Array<!proto.kazatel.books.TaskCardVersion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.kazatel.books.TaskCardVersion, 7));
};


/**
 * @param {!Array<!proto.kazatel.books.TaskCardVersion>} value
 * @return {!proto.kazatel.books.TaskCardData} returns this
*/
proto.kazatel.books.TaskCardData.prototype.setVersionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.kazatel.books.TaskCardVersion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.kazatel.books.TaskCardVersion}
 */
proto.kazatel.books.TaskCardData.prototype.addVersions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.kazatel.books.TaskCardVersion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.books.TaskCardData} returns this
 */
proto.kazatel.books.TaskCardData.prototype.clearVersionsList = function() {
  return this.setVersionsList([]);
};


/**
 * optional int32 rest_task = 8;
 * @return {number}
 */
proto.kazatel.books.TaskCardData.prototype.getRestTask = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.books.TaskCardData} returns this
 */
proto.kazatel.books.TaskCardData.prototype.setRestTask = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 rest_task_premium = 9;
 * @return {number}
 */
proto.kazatel.books.TaskCardData.prototype.getRestTaskPremium = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.books.TaskCardData} returns this
 */
proto.kazatel.books.TaskCardData.prototype.setRestTaskPremium = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool over_limit = 10;
 * @return {boolean}
 */
proto.kazatel.books.TaskCardData.prototype.getOverLimit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.kazatel.books.TaskCardData} returns this
 */
proto.kazatel.books.TaskCardData.prototype.setOverLimit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bytes photo = 11;
 * @return {string}
 */
proto.kazatel.books.TaskCardData.prototype.getPhoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * optional bytes photo = 11;
 * This is a type-conversion wrapper around `getPhoto()`
 * @return {string}
 */
proto.kazatel.books.TaskCardData.prototype.getPhoto_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPhoto()));
};


/**
 * optional bytes photo = 11;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPhoto()`
 * @return {!Uint8Array}
 */
proto.kazatel.books.TaskCardData.prototype.getPhoto_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPhoto()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.kazatel.books.TaskCardData} returns this
 */
proto.kazatel.books.TaskCardData.prototype.setPhoto = function(value) {
  return jspb.Message.setProto3BytesField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.books.TaskCommon.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.books.TaskCommon.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.books.TaskCommon} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.TaskCommon.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    premium: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    visible: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.books.TaskCommon}
 */
proto.kazatel.books.TaskCommon.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.books.TaskCommon;
  return proto.kazatel.books.TaskCommon.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.books.TaskCommon} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.books.TaskCommon}
 */
proto.kazatel.books.TaskCommon.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPremium(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisible(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.books.TaskCommon.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.books.TaskCommon.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.books.TaskCommon} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.TaskCommon.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPremium();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getVisible();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.kazatel.books.TaskCommon.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.TaskCommon} returns this
 */
proto.kazatel.books.TaskCommon.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool premium = 2;
 * @return {boolean}
 */
proto.kazatel.books.TaskCommon.prototype.getPremium = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.kazatel.books.TaskCommon} returns this
 */
proto.kazatel.books.TaskCommon.prototype.setPremium = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool visible = 3;
 * @return {boolean}
 */
proto.kazatel.books.TaskCommon.prototype.getVisible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.kazatel.books.TaskCommon} returns this
 */
proto.kazatel.books.TaskCommon.prototype.setVisible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kazatel.books.ContentByTypes.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.books.ContentByTypes.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.books.ContentByTypes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.books.ContentByTypes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.ContentByTypes.toObject = function(includeInstance, msg) {
  var f, obj = {
    typeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tasksList: jspb.Message.toObjectList(msg.getTasksList(),
    proto.kazatel.books.TaskCommon.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.books.ContentByTypes}
 */
proto.kazatel.books.ContentByTypes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.books.ContentByTypes;
  return proto.kazatel.books.ContentByTypes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.books.ContentByTypes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.books.ContentByTypes}
 */
proto.kazatel.books.ContentByTypes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTypeId(value);
      break;
    case 2:
      var value = new proto.kazatel.books.TaskCommon;
      reader.readMessage(value,proto.kazatel.books.TaskCommon.deserializeBinaryFromReader);
      msg.addTasks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.books.ContentByTypes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.books.ContentByTypes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.books.ContentByTypes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.ContentByTypes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTypeId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTasksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.kazatel.books.TaskCommon.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 type_id = 1;
 * @return {number}
 */
proto.kazatel.books.ContentByTypes.prototype.getTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.books.ContentByTypes} returns this
 */
proto.kazatel.books.ContentByTypes.prototype.setTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated TaskCommon tasks = 2;
 * @return {!Array<!proto.kazatel.books.TaskCommon>}
 */
proto.kazatel.books.ContentByTypes.prototype.getTasksList = function() {
  return /** @type{!Array<!proto.kazatel.books.TaskCommon>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.kazatel.books.TaskCommon, 2));
};


/**
 * @param {!Array<!proto.kazatel.books.TaskCommon>} value
 * @return {!proto.kazatel.books.ContentByTypes} returns this
*/
proto.kazatel.books.ContentByTypes.prototype.setTasksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.kazatel.books.TaskCommon=} opt_value
 * @param {number=} opt_index
 * @return {!proto.kazatel.books.TaskCommon}
 */
proto.kazatel.books.ContentByTypes.prototype.addTasks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.kazatel.books.TaskCommon, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.books.ContentByTypes} returns this
 */
proto.kazatel.books.ContentByTypes.prototype.clearTasksList = function() {
  return this.setTasksList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kazatel.books.NodeDetail.repeatedFields_ = [3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.books.NodeDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.books.NodeDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.books.NodeDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.NodeDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    node: (f = msg.getNode()) && proto.kazatel.books.Node.toObject(includeInstance, f),
    bookId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tasksList: jspb.Message.toObjectList(msg.getTasksList(),
    proto.kazatel.books.TaskCardData.toObject, includeInstance),
    taskByContentList: jspb.Message.toObjectList(msg.getTaskByContentList(),
    proto.kazatel.books.ContentByTypes.toObject, includeInstance),
    taskForConditionList: jspb.Message.toObjectList(msg.getTaskForConditionList(),
    proto.kazatel.books.TaskCommon.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.books.NodeDetail}
 */
proto.kazatel.books.NodeDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.books.NodeDetail;
  return proto.kazatel.books.NodeDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.books.NodeDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.books.NodeDetail}
 */
proto.kazatel.books.NodeDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.kazatel.books.Node;
      reader.readMessage(value,proto.kazatel.books.Node.deserializeBinaryFromReader);
      msg.setNode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookId(value);
      break;
    case 3:
      var value = new proto.kazatel.books.TaskCardData;
      reader.readMessage(value,proto.kazatel.books.TaskCardData.deserializeBinaryFromReader);
      msg.addTasks(value);
      break;
    case 4:
      var value = new proto.kazatel.books.ContentByTypes;
      reader.readMessage(value,proto.kazatel.books.ContentByTypes.deserializeBinaryFromReader);
      msg.addTaskByContent(value);
      break;
    case 5:
      var value = new proto.kazatel.books.TaskCommon;
      reader.readMessage(value,proto.kazatel.books.TaskCommon.deserializeBinaryFromReader);
      msg.addTaskForCondition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.books.NodeDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.books.NodeDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.books.NodeDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.NodeDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.kazatel.books.Node.serializeBinaryToWriter
    );
  }
  f = message.getBookId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTasksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.kazatel.books.TaskCardData.serializeBinaryToWriter
    );
  }
  f = message.getTaskByContentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.kazatel.books.ContentByTypes.serializeBinaryToWriter
    );
  }
  f = message.getTaskForConditionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.kazatel.books.TaskCommon.serializeBinaryToWriter
    );
  }
};


/**
 * optional Node node = 1;
 * @return {?proto.kazatel.books.Node}
 */
proto.kazatel.books.NodeDetail.prototype.getNode = function() {
  return /** @type{?proto.kazatel.books.Node} */ (
    jspb.Message.getWrapperField(this, proto.kazatel.books.Node, 1));
};


/**
 * @param {?proto.kazatel.books.Node|undefined} value
 * @return {!proto.kazatel.books.NodeDetail} returns this
*/
proto.kazatel.books.NodeDetail.prototype.setNode = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.books.NodeDetail} returns this
 */
proto.kazatel.books.NodeDetail.prototype.clearNode = function() {
  return this.setNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.books.NodeDetail.prototype.hasNode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string book_id = 2;
 * @return {string}
 */
proto.kazatel.books.NodeDetail.prototype.getBookId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.NodeDetail} returns this
 */
proto.kazatel.books.NodeDetail.prototype.setBookId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated TaskCardData tasks = 3;
 * @return {!Array<!proto.kazatel.books.TaskCardData>}
 */
proto.kazatel.books.NodeDetail.prototype.getTasksList = function() {
  return /** @type{!Array<!proto.kazatel.books.TaskCardData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.kazatel.books.TaskCardData, 3));
};


/**
 * @param {!Array<!proto.kazatel.books.TaskCardData>} value
 * @return {!proto.kazatel.books.NodeDetail} returns this
*/
proto.kazatel.books.NodeDetail.prototype.setTasksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.kazatel.books.TaskCardData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.kazatel.books.TaskCardData}
 */
proto.kazatel.books.NodeDetail.prototype.addTasks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.kazatel.books.TaskCardData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.books.NodeDetail} returns this
 */
proto.kazatel.books.NodeDetail.prototype.clearTasksList = function() {
  return this.setTasksList([]);
};


/**
 * repeated ContentByTypes task_by_content = 4;
 * @return {!Array<!proto.kazatel.books.ContentByTypes>}
 */
proto.kazatel.books.NodeDetail.prototype.getTaskByContentList = function() {
  return /** @type{!Array<!proto.kazatel.books.ContentByTypes>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.kazatel.books.ContentByTypes, 4));
};


/**
 * @param {!Array<!proto.kazatel.books.ContentByTypes>} value
 * @return {!proto.kazatel.books.NodeDetail} returns this
*/
proto.kazatel.books.NodeDetail.prototype.setTaskByContentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.kazatel.books.ContentByTypes=} opt_value
 * @param {number=} opt_index
 * @return {!proto.kazatel.books.ContentByTypes}
 */
proto.kazatel.books.NodeDetail.prototype.addTaskByContent = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.kazatel.books.ContentByTypes, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.books.NodeDetail} returns this
 */
proto.kazatel.books.NodeDetail.prototype.clearTaskByContentList = function() {
  return this.setTaskByContentList([]);
};


/**
 * repeated TaskCommon task_for_condition = 5;
 * @return {!Array<!proto.kazatel.books.TaskCommon>}
 */
proto.kazatel.books.NodeDetail.prototype.getTaskForConditionList = function() {
  return /** @type{!Array<!proto.kazatel.books.TaskCommon>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.kazatel.books.TaskCommon, 5));
};


/**
 * @param {!Array<!proto.kazatel.books.TaskCommon>} value
 * @return {!proto.kazatel.books.NodeDetail} returns this
*/
proto.kazatel.books.NodeDetail.prototype.setTaskForConditionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.kazatel.books.TaskCommon=} opt_value
 * @param {number=} opt_index
 * @return {!proto.kazatel.books.TaskCommon}
 */
proto.kazatel.books.NodeDetail.prototype.addTaskForCondition = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.kazatel.books.TaskCommon, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.books.NodeDetail} returns this
 */
proto.kazatel.books.NodeDetail.prototype.clearTaskForConditionList = function() {
  return this.setTaskForConditionList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kazatel.books.TaskCardUpdate.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.books.TaskCardUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.books.TaskCardUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.books.TaskCardUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.TaskCardUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    versionId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    contentConditionList: jspb.Message.toObjectList(msg.getContentConditionList(),
    proto.kazatel.books.TaskContent.toObject, includeInstance),
    contentSolutionList: jspb.Message.toObjectList(msg.getContentSolutionList(),
    proto.kazatel.books.TaskContent.toObject, includeInstance),
    attrPremium: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.books.TaskCardUpdate}
 */
proto.kazatel.books.TaskCardUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.books.TaskCardUpdate;
  return proto.kazatel.books.TaskCardUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.books.TaskCardUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.books.TaskCardUpdate}
 */
proto.kazatel.books.TaskCardUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersionId(value);
      break;
    case 3:
      var value = new proto.kazatel.books.TaskContent;
      reader.readMessage(value,proto.kazatel.books.TaskContent.deserializeBinaryFromReader);
      msg.addContentCondition(value);
      break;
    case 4:
      var value = new proto.kazatel.books.TaskContent;
      reader.readMessage(value,proto.kazatel.books.TaskContent.deserializeBinaryFromReader);
      msg.addContentSolution(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAttrPremium(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.books.TaskCardUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.books.TaskCardUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.books.TaskCardUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.TaskCardUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersionId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getContentConditionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.kazatel.books.TaskContent.serializeBinaryToWriter
    );
  }
  f = message.getContentSolutionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.kazatel.books.TaskContent.serializeBinaryToWriter
    );
  }
  f = message.getAttrPremium();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string task_id = 1;
 * @return {string}
 */
proto.kazatel.books.TaskCardUpdate.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.TaskCardUpdate} returns this
 */
proto.kazatel.books.TaskCardUpdate.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 version_id = 2;
 * @return {number}
 */
proto.kazatel.books.TaskCardUpdate.prototype.getVersionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.books.TaskCardUpdate} returns this
 */
proto.kazatel.books.TaskCardUpdate.prototype.setVersionId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated TaskContent content_condition = 3;
 * @return {!Array<!proto.kazatel.books.TaskContent>}
 */
proto.kazatel.books.TaskCardUpdate.prototype.getContentConditionList = function() {
  return /** @type{!Array<!proto.kazatel.books.TaskContent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.kazatel.books.TaskContent, 3));
};


/**
 * @param {!Array<!proto.kazatel.books.TaskContent>} value
 * @return {!proto.kazatel.books.TaskCardUpdate} returns this
*/
proto.kazatel.books.TaskCardUpdate.prototype.setContentConditionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.kazatel.books.TaskContent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.kazatel.books.TaskContent}
 */
proto.kazatel.books.TaskCardUpdate.prototype.addContentCondition = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.kazatel.books.TaskContent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.books.TaskCardUpdate} returns this
 */
proto.kazatel.books.TaskCardUpdate.prototype.clearContentConditionList = function() {
  return this.setContentConditionList([]);
};


/**
 * repeated TaskContent content_solution = 4;
 * @return {!Array<!proto.kazatel.books.TaskContent>}
 */
proto.kazatel.books.TaskCardUpdate.prototype.getContentSolutionList = function() {
  return /** @type{!Array<!proto.kazatel.books.TaskContent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.kazatel.books.TaskContent, 4));
};


/**
 * @param {!Array<!proto.kazatel.books.TaskContent>} value
 * @return {!proto.kazatel.books.TaskCardUpdate} returns this
*/
proto.kazatel.books.TaskCardUpdate.prototype.setContentSolutionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.kazatel.books.TaskContent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.kazatel.books.TaskContent}
 */
proto.kazatel.books.TaskCardUpdate.prototype.addContentSolution = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.kazatel.books.TaskContent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.books.TaskCardUpdate} returns this
 */
proto.kazatel.books.TaskCardUpdate.prototype.clearContentSolutionList = function() {
  return this.setContentSolutionList([]);
};


/**
 * optional bool attr_premium = 5;
 * @return {boolean}
 */
proto.kazatel.books.TaskCardUpdate.prototype.getAttrPremium = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.kazatel.books.TaskCardUpdate} returns this
 */
proto.kazatel.books.TaskCardUpdate.prototype.setAttrPremium = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kazatel.books.BooksRequest.repeatedFields_ = [1,2,3,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.books.BooksRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.books.BooksRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.books.BooksRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.BooksRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    levelsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    subjectsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    statusList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    pageSize: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 5, ""),
    orderBy: jspb.Message.getFieldWithDefault(msg, 6, ""),
    booksList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    like: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.books.BooksRequest}
 */
proto.kazatel.books.BooksRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.books.BooksRequest;
  return proto.kazatel.books.BooksRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.books.BooksRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.books.BooksRequest}
 */
proto.kazatel.books.BooksRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLevels(values[i]);
      }
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSubjects(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatus(values[i]);
      }
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderBy(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addBooks(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLike(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.books.BooksRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.books.BooksRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.books.BooksRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.BooksRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLevelsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getSubjectsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOrderBy();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBooksList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * repeated int32 levels = 1;
 * @return {!Array<number>}
 */
proto.kazatel.books.BooksRequest.prototype.getLevelsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.kazatel.books.BooksRequest} returns this
 */
proto.kazatel.books.BooksRequest.prototype.setLevelsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.kazatel.books.BooksRequest} returns this
 */
proto.kazatel.books.BooksRequest.prototype.addLevels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.books.BooksRequest} returns this
 */
proto.kazatel.books.BooksRequest.prototype.clearLevelsList = function() {
  return this.setLevelsList([]);
};


/**
 * repeated int32 subjects = 2;
 * @return {!Array<number>}
 */
proto.kazatel.books.BooksRequest.prototype.getSubjectsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.kazatel.books.BooksRequest} returns this
 */
proto.kazatel.books.BooksRequest.prototype.setSubjectsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.kazatel.books.BooksRequest} returns this
 */
proto.kazatel.books.BooksRequest.prototype.addSubjects = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.books.BooksRequest} returns this
 */
proto.kazatel.books.BooksRequest.prototype.clearSubjectsList = function() {
  return this.setSubjectsList([]);
};


/**
 * repeated int32 status = 3;
 * @return {!Array<number>}
 */
proto.kazatel.books.BooksRequest.prototype.getStatusList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.kazatel.books.BooksRequest} returns this
 */
proto.kazatel.books.BooksRequest.prototype.setStatusList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.kazatel.books.BooksRequest} returns this
 */
proto.kazatel.books.BooksRequest.prototype.addStatus = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.books.BooksRequest} returns this
 */
proto.kazatel.books.BooksRequest.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};


/**
 * optional int32 page_size = 4;
 * @return {number}
 */
proto.kazatel.books.BooksRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.books.BooksRequest} returns this
 */
proto.kazatel.books.BooksRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string page_token = 5;
 * @return {string}
 */
proto.kazatel.books.BooksRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.BooksRequest} returns this
 */
proto.kazatel.books.BooksRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string order_by = 6;
 * @return {string}
 */
proto.kazatel.books.BooksRequest.prototype.getOrderBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.BooksRequest} returns this
 */
proto.kazatel.books.BooksRequest.prototype.setOrderBy = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated string books = 7;
 * @return {!Array<string>}
 */
proto.kazatel.books.BooksRequest.prototype.getBooksList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.kazatel.books.BooksRequest} returns this
 */
proto.kazatel.books.BooksRequest.prototype.setBooksList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.kazatel.books.BooksRequest} returns this
 */
proto.kazatel.books.BooksRequest.prototype.addBooks = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.books.BooksRequest} returns this
 */
proto.kazatel.books.BooksRequest.prototype.clearBooksList = function() {
  return this.setBooksList([]);
};


/**
 * optional string like = 8;
 * @return {string}
 */
proto.kazatel.books.BooksRequest.prototype.getLike = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.BooksRequest} returns this
 */
proto.kazatel.books.BooksRequest.prototype.setLike = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.kazatel.books.BooksRequest} returns this
 */
proto.kazatel.books.BooksRequest.prototype.clearLike = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.books.BooksRequest.prototype.hasLike = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.books.NodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.books.NodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.books.NodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.NodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tasksContent: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.books.NodeRequest}
 */
proto.kazatel.books.NodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.books.NodeRequest;
  return proto.kazatel.books.NodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.books.NodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.books.NodeRequest}
 */
proto.kazatel.books.NodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTasksContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.books.NodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.books.NodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.books.NodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.NodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTasksContent();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.kazatel.books.NodeRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.NodeRequest} returns this
 */
proto.kazatel.books.NodeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool tasks_content = 2;
 * @return {boolean}
 */
proto.kazatel.books.NodeRequest.prototype.getTasksContent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.kazatel.books.NodeRequest} returns this
 */
proto.kazatel.books.NodeRequest.prototype.setTasksContent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kazatel.books.NodesRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.books.NodesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.books.NodesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.books.NodesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.NodesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    depth: jspb.Message.getFieldWithDefault(msg, 1, 0),
    bookId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    parentId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    typesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.books.NodesRequest}
 */
proto.kazatel.books.NodesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.books.NodesRequest;
  return proto.kazatel.books.NodesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.books.NodesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.books.NodesRequest}
 */
proto.kazatel.books.NodesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDepth(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentId(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTypes(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.books.NodesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.books.NodesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.books.NodesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.NodesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getBookId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getParentId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTypesList();
  if (f.length > 0) {
    writer.writePackedInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 depth = 1;
 * @return {number}
 */
proto.kazatel.books.NodesRequest.prototype.getDepth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.books.NodesRequest} returns this
 */
proto.kazatel.books.NodesRequest.prototype.setDepth = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.kazatel.books.NodesRequest} returns this
 */
proto.kazatel.books.NodesRequest.prototype.clearDepth = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.books.NodesRequest.prototype.hasDepth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string book_id = 2;
 * @return {string}
 */
proto.kazatel.books.NodesRequest.prototype.getBookId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.NodesRequest} returns this
 */
proto.kazatel.books.NodesRequest.prototype.setBookId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string parent_id = 3;
 * @return {string}
 */
proto.kazatel.books.NodesRequest.prototype.getParentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.NodesRequest} returns this
 */
proto.kazatel.books.NodesRequest.prototype.setParentId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated int32 types = 4;
 * @return {!Array<number>}
 */
proto.kazatel.books.NodesRequest.prototype.getTypesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.kazatel.books.NodesRequest} returns this
 */
proto.kazatel.books.NodesRequest.prototype.setTypesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.kazatel.books.NodesRequest} returns this
 */
proto.kazatel.books.NodesRequest.prototype.addTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.books.NodesRequest} returns this
 */
proto.kazatel.books.NodesRequest.prototype.clearTypesList = function() {
  return this.setTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.books.TaskCardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.books.TaskCardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.books.TaskCardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.TaskCardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    allVersions: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.books.TaskCardRequest}
 */
proto.kazatel.books.TaskCardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.books.TaskCardRequest;
  return proto.kazatel.books.TaskCardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.books.TaskCardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.books.TaskCardRequest}
 */
proto.kazatel.books.TaskCardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllVersions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.books.TaskCardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.books.TaskCardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.books.TaskCardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.TaskCardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAllVersions();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string task_id = 1;
 * @return {string}
 */
proto.kazatel.books.TaskCardRequest.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.TaskCardRequest} returns this
 */
proto.kazatel.books.TaskCardRequest.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool all_versions = 2;
 * @return {boolean}
 */
proto.kazatel.books.TaskCardRequest.prototype.getAllVersions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.kazatel.books.TaskCardRequest} returns this
 */
proto.kazatel.books.TaskCardRequest.prototype.setAllVersions = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.books.RestTaskResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.books.RestTaskResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.books.RestTaskResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.RestTaskResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    restTask: jspb.Message.getFieldWithDefault(msg, 1, 0),
    restTaskPremium: jspb.Message.getFieldWithDefault(msg, 2, 0),
    overLimit: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.books.RestTaskResponse}
 */
proto.kazatel.books.RestTaskResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.books.RestTaskResponse;
  return proto.kazatel.books.RestTaskResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.books.RestTaskResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.books.RestTaskResponse}
 */
proto.kazatel.books.RestTaskResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRestTask(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRestTaskPremium(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOverLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.books.RestTaskResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.books.RestTaskResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.books.RestTaskResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.RestTaskResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRestTask();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getRestTaskPremium();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getOverLimit();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int32 rest_task = 1;
 * @return {number}
 */
proto.kazatel.books.RestTaskResponse.prototype.getRestTask = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.books.RestTaskResponse} returns this
 */
proto.kazatel.books.RestTaskResponse.prototype.setRestTask = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 rest_task_premium = 2;
 * @return {number}
 */
proto.kazatel.books.RestTaskResponse.prototype.getRestTaskPremium = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.books.RestTaskResponse} returns this
 */
proto.kazatel.books.RestTaskResponse.prototype.setRestTaskPremium = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool over_limit = 3;
 * @return {boolean}
 */
proto.kazatel.books.RestTaskResponse.prototype.getOverLimit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.kazatel.books.RestTaskResponse} returns this
 */
proto.kazatel.books.RestTaskResponse.prototype.setOverLimit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.books.NodeOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.books.NodeOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.books.NodeOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.NodeOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    order: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.books.NodeOrder}
 */
proto.kazatel.books.NodeOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.books.NodeOrder;
  return proto.kazatel.books.NodeOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.books.NodeOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.books.NodeOrder}
 */
proto.kazatel.books.NodeOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.books.NodeOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.books.NodeOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.books.NodeOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.NodeOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrder();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.kazatel.books.NodeOrder.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.NodeOrder} returns this
 */
proto.kazatel.books.NodeOrder.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string order = 2;
 * @return {string}
 */
proto.kazatel.books.NodeOrder.prototype.getOrder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.NodeOrder} returns this
 */
proto.kazatel.books.NodeOrder.prototype.setOrder = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kazatel.books.NodesOrder.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.books.NodesOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.books.NodesOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.books.NodesOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.NodesOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodesList: jspb.Message.toObjectList(msg.getNodesList(),
    proto.kazatel.books.NodeOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.books.NodesOrder}
 */
proto.kazatel.books.NodesOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.books.NodesOrder;
  return proto.kazatel.books.NodesOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.books.NodesOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.books.NodesOrder}
 */
proto.kazatel.books.NodesOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.kazatel.books.NodeOrder;
      reader.readMessage(value,proto.kazatel.books.NodeOrder.deserializeBinaryFromReader);
      msg.addNodes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.books.NodesOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.books.NodesOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.books.NodesOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.NodesOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.kazatel.books.NodeOrder.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NodeOrder nodes = 1;
 * @return {!Array<!proto.kazatel.books.NodeOrder>}
 */
proto.kazatel.books.NodesOrder.prototype.getNodesList = function() {
  return /** @type{!Array<!proto.kazatel.books.NodeOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.kazatel.books.NodeOrder, 1));
};


/**
 * @param {!Array<!proto.kazatel.books.NodeOrder>} value
 * @return {!proto.kazatel.books.NodesOrder} returns this
*/
proto.kazatel.books.NodesOrder.prototype.setNodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.kazatel.books.NodeOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.kazatel.books.NodeOrder}
 */
proto.kazatel.books.NodesOrder.prototype.addNodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.kazatel.books.NodeOrder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.books.NodesOrder} returns this
 */
proto.kazatel.books.NodesOrder.prototype.clearNodesList = function() {
  return this.setNodesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.books.Link.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.books.Link.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.books.Link} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.Link.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inherited: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.books.Link}
 */
proto.kazatel.books.Link.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.books.Link;
  return proto.kazatel.books.Link.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.books.Link} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.books.Link}
 */
proto.kazatel.books.Link.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInherited(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.books.Link.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.books.Link.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.books.Link} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.Link.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.kazatel.books.Link.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.Link} returns this
 */
proto.kazatel.books.Link.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool inherited = 2;
 * @return {boolean}
 */
proto.kazatel.books.Link.prototype.getInherited = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.kazatel.books.Link} returns this
 */
proto.kazatel.books.Link.prototype.setInherited = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.kazatel.books.Link} returns this
 */
proto.kazatel.books.Link.prototype.clearInherited = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.books.Link.prototype.hasInherited = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kazatel.books.Links.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.books.Links.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.books.Links.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.books.Links} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.Links.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    linksList: jspb.Message.toObjectList(msg.getLinksList(),
    proto.kazatel.books.Link.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.books.Links}
 */
proto.kazatel.books.Links.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.books.Links;
  return proto.kazatel.books.Links.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.books.Links} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.books.Links}
 */
proto.kazatel.books.Links.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.kazatel.books.Link;
      reader.readMessage(value,proto.kazatel.books.Link.deserializeBinaryFromReader);
      msg.addLinks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.books.Links.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.books.Links.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.books.Links} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.Links.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.kazatel.books.Link.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.kazatel.books.Links.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.Links} returns this
 */
proto.kazatel.books.Links.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Link links = 2;
 * @return {!Array<!proto.kazatel.books.Link>}
 */
proto.kazatel.books.Links.prototype.getLinksList = function() {
  return /** @type{!Array<!proto.kazatel.books.Link>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.kazatel.books.Link, 2));
};


/**
 * @param {!Array<!proto.kazatel.books.Link>} value
 * @return {!proto.kazatel.books.Links} returns this
*/
proto.kazatel.books.Links.prototype.setLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.kazatel.books.Link=} opt_value
 * @param {number=} opt_index
 * @return {!proto.kazatel.books.Link}
 */
proto.kazatel.books.Links.prototype.addLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.kazatel.books.Link, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.books.Links} returns this
 */
proto.kazatel.books.Links.prototype.clearLinksList = function() {
  return this.setLinksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.books.LinksInherit.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.books.LinksInherit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.books.LinksInherit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.LinksInherit.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inherit: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.books.LinksInherit}
 */
proto.kazatel.books.LinksInherit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.books.LinksInherit;
  return proto.kazatel.books.LinksInherit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.books.LinksInherit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.books.LinksInherit}
 */
proto.kazatel.books.LinksInherit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInherit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.books.LinksInherit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.books.LinksInherit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.books.LinksInherit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.LinksInherit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInherit();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.kazatel.books.LinksInherit.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.LinksInherit} returns this
 */
proto.kazatel.books.LinksInherit.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool inherit = 2;
 * @return {boolean}
 */
proto.kazatel.books.LinksInherit.prototype.getInherit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.kazatel.books.LinksInherit} returns this
 */
proto.kazatel.books.LinksInherit.prototype.setInherit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kazatel.books.LinkedNodes.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.books.LinkedNodes.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.books.LinkedNodes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.books.LinkedNodes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.LinkedNodes.toObject = function(includeInstance, msg) {
  var f, obj = {
    book: (f = msg.getBook()) && proto.kazatel.books.BookDetail.toObject(includeInstance, f),
    nodesList: jspb.Message.toObjectList(msg.getNodesList(),
    proto.kazatel.books.Node.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.books.LinkedNodes}
 */
proto.kazatel.books.LinkedNodes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.books.LinkedNodes;
  return proto.kazatel.books.LinkedNodes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.books.LinkedNodes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.books.LinkedNodes}
 */
proto.kazatel.books.LinkedNodes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.kazatel.books.BookDetail;
      reader.readMessage(value,proto.kazatel.books.BookDetail.deserializeBinaryFromReader);
      msg.setBook(value);
      break;
    case 2:
      var value = new proto.kazatel.books.Node;
      reader.readMessage(value,proto.kazatel.books.Node.deserializeBinaryFromReader);
      msg.addNodes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.books.LinkedNodes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.books.LinkedNodes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.books.LinkedNodes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.LinkedNodes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBook();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.kazatel.books.BookDetail.serializeBinaryToWriter
    );
  }
  f = message.getNodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.kazatel.books.Node.serializeBinaryToWriter
    );
  }
};


/**
 * optional BookDetail book = 1;
 * @return {?proto.kazatel.books.BookDetail}
 */
proto.kazatel.books.LinkedNodes.prototype.getBook = function() {
  return /** @type{?proto.kazatel.books.BookDetail} */ (
    jspb.Message.getWrapperField(this, proto.kazatel.books.BookDetail, 1));
};


/**
 * @param {?proto.kazatel.books.BookDetail|undefined} value
 * @return {!proto.kazatel.books.LinkedNodes} returns this
*/
proto.kazatel.books.LinkedNodes.prototype.setBook = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.books.LinkedNodes} returns this
 */
proto.kazatel.books.LinkedNodes.prototype.clearBook = function() {
  return this.setBook(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.books.LinkedNodes.prototype.hasBook = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Node nodes = 2;
 * @return {!Array<!proto.kazatel.books.Node>}
 */
proto.kazatel.books.LinkedNodes.prototype.getNodesList = function() {
  return /** @type{!Array<!proto.kazatel.books.Node>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.kazatel.books.Node, 2));
};


/**
 * @param {!Array<!proto.kazatel.books.Node>} value
 * @return {!proto.kazatel.books.LinkedNodes} returns this
*/
proto.kazatel.books.LinkedNodes.prototype.setNodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.kazatel.books.Node=} opt_value
 * @param {number=} opt_index
 * @return {!proto.kazatel.books.Node}
 */
proto.kazatel.books.LinkedNodes.prototype.addNodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.kazatel.books.Node, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.books.LinkedNodes} returns this
 */
proto.kazatel.books.LinkedNodes.prototype.clearNodesList = function() {
  return this.setNodesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.books.BookDictionary.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.books.BookDictionary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.books.BookDictionary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.BookDictionary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: (f = msg.getValue()) && proto.kazatel.books.Dictionary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.books.BookDictionary}
 */
proto.kazatel.books.BookDictionary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.books.BookDictionary;
  return proto.kazatel.books.BookDictionary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.books.BookDictionary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.books.BookDictionary}
 */
proto.kazatel.books.BookDictionary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.kazatel.books.Dictionary;
      reader.readMessage(value,proto.kazatel.books.Dictionary.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.books.BookDictionary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.books.BookDictionary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.books.BookDictionary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.books.BookDictionary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.kazatel.books.Dictionary.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.kazatel.books.BookDictionary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.books.BookDictionary} returns this
 */
proto.kazatel.books.BookDictionary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Dictionary value = 2;
 * @return {?proto.kazatel.books.Dictionary}
 */
proto.kazatel.books.BookDictionary.prototype.getValue = function() {
  return /** @type{?proto.kazatel.books.Dictionary} */ (
    jspb.Message.getWrapperField(this, proto.kazatel.books.Dictionary, 2));
};


/**
 * @param {?proto.kazatel.books.Dictionary|undefined} value
 * @return {!proto.kazatel.books.BookDictionary} returns this
*/
proto.kazatel.books.BookDictionary.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.books.BookDictionary} returns this
 */
proto.kazatel.books.BookDictionary.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.books.BookDictionary.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.kazatel.books);
