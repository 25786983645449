export const documents = [
  {
    title: "Пользовательское соглашение",
    link: "/docs/user-agreement",
    target: '_blank',
  },
  {
    title: "Обработка персональных данных",
    link: "/docs/personal-data",
    target: '_blank',
  },
  {
    title: "Договор-оферта",
    link: "/docs/user-oferta",
    target: '_blank',
  },
  {
    title: "Оферта агентского договора",
    link: "/docs/agent-oferta",
    target: '_blank',
  }
];

export const common = [
  {
    title: "Партнёрам",
    link: "/cooperation/partners"
  },
  {
    title: "Издательствам",
    link: "/cooperation/publishers"
  },
  {
    title: "Правообладателям",
    link: "/cooperation/rightholders"
  },
  {
    title: "Связь с модератором",
    link: "/cooperation/moderator"
  }
];

export const cooperation = [
  {
    title: "Школам",
    link: "/cooperation/schools"
  },
  {
    title: "Библиотекам",
    link: "/cooperation/libraries"
  },
  {
    title: "Самозанятым",
    link: "/cooperation/selfEmployed"
  },
  {
    title: "Юридическим лицам",
    link: "/cooperation/organizations"
  },
  {
    title: "Индивидуальным предпринимателям",
    link: "/cooperation/entrepreneurs"
  }
];

export const socials = [
  {
    title: 'ВКонтакте',
    link: 'https://vk.com/kazatel_su',
    target: '_blank'
  },
  {
    title: 'Telegram',
    link: 'https://t.me/kazatel_su',
    target: '_blank'
  },
];
