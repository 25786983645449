<template>
  <main class="layout">
    <router-view/>
  </main>
</template>

<script>
export default {
  name: 'landing-layout'
}
</script>
<style scoped>
.layout{
  margin: 0 auto;
  max-width: 1420px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>