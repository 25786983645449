import store from '@/store';
import { createRouter, createWebHistory } from "vue-router";
import { nextTick } from 'vue';

// import landingRoutes from "@/router/landing";
import authRoutes from "@/router/auth";
import profileRoutes from "@/router/profile";
import tasksRoutes from "@/router/tasks";
import docsRoutes from "@/router/docs";
import paymentsRoutes from "@/router/payments";
import moderationRoutes from "@/router/moderation";
import homeRoutes from "@/router/home";
import cooperationRoutes from "@/router/cooperation";
import lessonsRoutes from "@/router/lessons";
import schoolRoutes from "@/router/school";
import monetizationRoutes from "@/router/monetization"
import notFoundPageRoutes from "@/router/notFoundPage"
import favoritesRoutes from "@/router/favorites/"
import gptRoutes from "@/router/gpt"
import editorRoutes from "@/router/editor";
import promocodeRoutes from "@/router/promocodes";
import userContentRoutes from "@/router/userContent";
import generationRoutes from "@/router/generation";
import subscriptionRoutes from "@/router/tarifs";
import financeRoutes from "@/router/finance"

import { ROUTE_TITLES, NUXT_PAGES } from "./utility.js";
import { setCanonicalUrl } from '@/utils/meta';
import { useRemoteTitleDescription } from '@/utils/seo';


const routes = [
  // ...landingRoutes,
  ...authRoutes,
  ...profileRoutes,
  ...tasksRoutes,
  ...docsRoutes,
  ...paymentsRoutes,
  ...moderationRoutes,
  ...homeRoutes,
  ...cooperationRoutes,
  ...lessonsRoutes,
  ...schoolRoutes,
  ...monetizationRoutes,
  ...notFoundPageRoutes,
  ...favoritesRoutes,
  ...gptRoutes,
  ...editorRoutes,
  ...promocodeRoutes,
  ...userContentRoutes,
  ...generationRoutes,
  ...subscriptionRoutes,
  ...financeRoutes
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // Redirection to nuxt app
  const doRedirection = +process.env.VUE_APP_ENABLE_NUXT_REDIRECT;
  if (doRedirection && NUXT_PAGES.some(name => name === to.name)) {
    console.log('REDIRECTION:', `${process.env.VUE_APP_NUXT_DOMAIN}` + to.fullPath);
    window.location.href = process.env.VUE_APP_NUXT_DOMAIN + to.fullPath;
    return false
  }
  if (
    process.env.VUE_APP_ENABLE_GUARD === "false" &&
    process.env.NODE_ENV === "development"
  ) {
    console.log("skip guard");
    next();
    return;
  }

  const requireAuth = to.matched.some((route) => route.meta.auth);
  if (to.matched.length === 0) {
    next('/404');
  } else {
    if (requireAuth) {
      const isValid = store.getters['authData/sessionActive'];
      if (!isValid) {
        next({ name: 'login' });
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

router.afterEach((to, from) => {
  nextTick(async () => {
    try {
      let title = 'Казатель';
      if (ROUTE_TITLES.hasOwnProperty(to.name)) {
        title = ROUTE_TITLES[to.name];
      }
      document.title = title;
      setCanonicalUrl(window.location.href);
      const seoUrl = to.name === 'task' ? to.path : to.fullPath;
      // console.log('seo url:', seoUrl);
      await useRemoteTitleDescription(seoUrl);
    } catch (error) {
      console.warn(error);
    }
  });
});

router.onError((err) => {
  console.log("err:", err);
});

export default router;