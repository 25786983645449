<template>
  <button 
    :class="[
      'button',
      `button_size_${size}`,
      `button_appearance_${appearance}_${mode}`,
      { [`button_${appearance}_active`]: isActive }
    ]"
    :disabled="disabled"
    @mousedown="handleMouseDown"
    @mouseup="handleMouseUp"
    @mouseleave="handleMouseLeave"
  >
    <div 
      :class="[
        'button__content', 
        `button__content_size_${size === 'S' && !label ? 'S_C': size}`,
        { [`button_content_center`]: centerContent }
      ]"
    >
      <svg-icon v-if="leftIcon" class="button__icon button_icon_left" :iconName="leftIconName" :size="size === 'S' ? '16px' : '24px'"/>
      <span v-if="label" :class="['button__text-wrapper', `button__text-wrapper_size_${size}`]">
        <span :class="['button-label', size === 'S' ? 'button-label_size_M' : 'button-label_size_L']">
          {{ label }}
        </span>
      </span>
      <svg-icon v-if="rightIcon" class="button__icon button_icon_left" :iconName="rightIconName" :size="size === 'S' ? '16px' : '24px'"/>
    </div>
  </button>
</template>

<script>
export default{
  name: 'Button',
  data() {
    return {
      isActive: false
    }
  },
  props:{
    label:{
      type: String,
      requred: false
    },
    appearance:{
      type: String,
      requred: false,
      default: 'primary'
    },
    size:{
      type: String,
      requred: false,
      default: 'L'
    },
    mode:{
      type: String,
      requred: false,
      default: 'onLight'
    },
    leftIcon:{
      type: Boolean,
      requred: false,
      default: false,
    },
    rightIcon:{
      type: Boolean,
      requred: false,
      default: false,
    },
    leftIconName:{
      type: String,
      requred: false,
      default: 'kuiIconAlertCircleLarge'
    },
    rightIconName:{
      type: String,
      requred: false,
      default: 'kuiIconAlertCircleLarge'
    },
    centerContent:{
      type: Boolean,
      requred: false,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    handleMouseDown() {
      this.isActive = true;
    },
    handleMouseUp() {
      this.isActive = false;
    },
    handleMouseLeave() {
      this.isActive = false;
    }
  }
}
</script>

<style scoped>
.button{
  transition: background-color var(--kaz-transition-molecules);
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  border-radius: 12px;
}
.button:hover{
  transition: var(--kaz-transition-molecules);
}
.button:focus-visible {
  outline: 2px solid var(--kaz-base-primary);
  outline-offset: 2px;
}
.button:disabled {
  opacity: 0.56;
}
button:not(:disabled) {
  cursor: pointer;
}
.button__content{
  display: flex;
  align-items: center;
}
.button_content_center{
  justify-content: center;
}
.button__content_size_L{
  padding: 16px;
}
.button__content_size_M{
  padding: 10px;
}
.button__content_size_S{
  padding: 6px 8px;
}

.button__content_size_S_C{
  padding: 6px;
  justify-content: center;
}

.button__text-wrapper{
  height: fit-content;
}
.button__text-wrapper_size_L{
  padding: 0px 8px;
}
.button__text-wrapper_size_M{
  padding: 0px 8px;
}
.button__text-wrapper_size_S{
  padding: 0px 4px;
}

.button_appearance_primary_normal{
  background-color: var(--kaz-base-primary);
}
.button_appearance_primary_normal .button__icon{
  --kaz-base-base-09: var(--kaz-textIcons-day-text-01);
}
.button_appearance_primary_normal .button-label{
  color: var(--kaz-textIcons-day-text-01);
}
.button_appearance_primary_normal:hover{
  background-color: var(--kaz-base-primary-hover);
}
.button_appearance_primary_normal:disabled{
  opacity: .56;
}
.button_appearance_primary_normal:disabled:hover{
  background-color: var(--kaz-base-primary);
}
.button_primary_active:focus{
  background-color: var(--kaz-base-primary-active);
}

.button_appearance_secondary_normal{
  background-color: var(--kaz-base-secondary);
}
.button_appearance_secondary_normal .button__icon{
  --kaz-base-base-09: var(--kaz-textIcons-link);
}
.button_appearance_secondary_normal .button-label{
  color: var(--kaz-textIcons-link);
}
.button_appearance_secondary_normal:hover{
  background-color: var(--kaz-base-secondary-hover);
}
.button_appearance_secondary_normal:disabled{
  opacity: .56;
}
.button_appearance_secondary_normal:disabled:hover{
  background-color: var(--kaz-base-secondary);
}
.button_secondary_active:focus{
  background-color: var(--kaz-base-secondary-active);
}

.button_appearance_accent_normal{
  background-color: var(--kaz-base-accent);
}
.button_appearance_accent_normal .button__icon{
  --kaz-base-base-09: var(--kaz-textIcons-day-text-01);
}
.button_appearance_accent_normal .button-label{
  color: var(--kaz-textIcons-day-text-01);
}
.button_appearance_accent_normal:hover{
  background-color: var(--kaz-base-accent-hover);
}
.button_appearance_accent_normal:disabled{
  opacity: .56;
}
.button_appearance_accent_normal:disabled:hover{
  background-color: var(--kaz-base-accent);
}
.button_accent_active:focus{
  background-color: var(--kaz-base-accent-active);
}

.button_appearance_outline_normal{
  background-color: transparent;
  outline: 1px solid var(--kaz-base-base-04);
}
.button_appearance_outline_normal .button__icon{
  --kaz-base-base-09: var(--kaz-textIcons-link);
}
.button_appearance_outline_normal .button-label{
  color: var(--kaz-textIcons-link);
}
.button_appearance_outline_normal:hover{
  background-color: var(--kaz-base-secondary);
}
.button_appearance_outline_normal:disabled{
  opacity: .56;
}
.button_appearance_outline_normal:disabled:hover{
  background-color: transparent;
}
.button_outline_active:focus{
  background-color: var(--kaz-base-secondary-hover);
}

.button_appearance_flat_normal{
  background-color: transparent;
}
.button_appearance_flat_normal .button__icon{
  --kaz-base-base-09: var(--kaz-textIcons-link);
}
.button_appearance_flat_normal .button-label{
  color: var(--kaz-textIcons-link);
}
.button_appearance_flat_normal:hover{
  background-color: var(--kaz-base-secondary);
}
.button_appearance_flat_normal:disabled{
  opacity: .56;
}
.button_appearance_flat_normal:disabled:hover{
  background-color: transparent;
}
.button_flat_active:focus{
  background-color: var(--kaz-base-secondary-hover);
}

/* onLight mode styles */

.button_appearance_primary_onLight{
  background-color: var(--kaz-base-primary);
}
.button_appearance_primary_onLight .button__icon{
  --kaz-base-base-09: var(--kaz-textIcons-day-text-01);
}
.button_appearance_primary_onLight .button-label{
  color: var(--kaz-textIcons-day-text-01);
}
.button_appearance_primary_onLight:hover{
  background-color: var(--kaz-base-primary-hover);
}
.button_appearance_primary_onLight:disabled{
  opacity: .56;
}
.button_appearance_primary_onLight:disabled:hover{
  background-color: var(--kaz-base-primary);
}
.button_primary_active:focus{
  background-color: var(--kaz-base-primary-active);
}

.button_appearance_secondary_onLight{
  background-color: var(--kaz-base-clear);
}
.button_appearance_secondary_onLight .button__icon{
  --kaz-base-base-09: var(--kaz-textIcons-text-01);
}
.button_appearance_secondary_onLight .button-label{
  color: var(--kaz-textIcons-text-01);
}
.button_appearance_secondary_onLight:hover{
  background-color: var(--kaz-base-clear-hover);
}
.button_appearance_secondary_onLight:disabled{
  opacity: .56;
}
.button_appearance_secondary_onLight:disabled:hover{
  background-color: var(--kaz-base-clear);
}
.button_secondary_active:focus{
  background-color: var(--kaz-base-clear-active);
}

.button_appearance_accent_onLight{
  background-color: var(--kaz-base-accent);
}
.button_appearance_accent_onLight .button__icon{
  --kaz-base-base-09: var(--kaz-textIcons-day-text-01);
}
.button_appearance_accent_onLight .button-label{
  color: var(--kaz-textIcons-day-text-01);
}
.button_appearance_accent_onLight:hover{
  background-color: var(--kaz-base-accent-hover);
}
.button_appearance_accent_onLight:disabled{
  opacity: .56;
}
.button_appearance_accent_onLight:disabled:hover{
  background-color: var(--kaz-base-accent);
}
.button_accent_active:focus{
  background-color: var(--kaz-base-accent-active);
}

.button_appearance_outline_onLight{
  background-color: transparent;
  outline: 1px solid var(--kaz-base-base-04);
}
.button_appearance_outline_onLight .button__icon{
  --kaz-base-base-09: var(--kaz-textIcons-text-01);
}
.button_appearance_outline_onLight .button-label{
  color: var(--kaz-textIcons-text-01);
}
.button_appearance_outline_onLight:hover{
  background-color: var(--kaz-base-clear);
}
.button_appearance_outline_onLight:disabled{
  opacity: .56;
}
.button_appearance_outline_onLight:disabled:hover{
  background-color: transparent;
}
.button_outline_active:focus{
  background-color: var(--kaz-base-clear-hover);
}

.button_appearance_flat_onLight{
  background-color: transparent;
}
.button_appearance_flat_onLight .button__icon{
  --kaz-base-base-09: var(--kaz-textIcons-text-01);
}
.button_appearance_flat_onLight .button-label{
  color: var(--kaz-textIcons-text-01);
}
.button_appearance_flat_onLight:hover{
  background-color: var(--kaz-base-clear);
}
.button_appearance_flat_onLight:disabled{
  opacity: .56;
}
.button_appearance_flat_onLight:disabled:hover{
  background-color: transparent;
}
.button_flat_active:focus{
  background-color: var(--kaz-base-clear-hover);
}

</style>