const routes = [
  {
    path: "/personal-info",
    name: "personal-info",
    redirect: { name: 'user-profile' },
    meta: { layout: "profile", auth: true },
    component: () => import("@/views/profile/home-page"),
  },
  {
    path: "/profile",
    name: "user-profile",
    meta: { layout: "profile", auth: true },
    component: () => import("@/views/userProfile"),
  },
  {
    path: "/profile/subscription",
    name: "user-subscription",
    meta: { layout: "profile", auth: true },
    component: () => import("@/views/userSubscription"),
  },
  {
    path: "/security",
    name: "security",
    meta: { layout: "profile", auth: true },
    component: () => import("@/views/profile/Security.vue"),
  },
  {
    path: "/payments",
    name: "payments",
    meta: { layout: "profile", auth: true },
    component: () => import("@/views/profile/Payments.vue"),
  },
  {
    path: "/notifications",
    name: "notifications",
    meta: { layout: "profile", auth: true },
    component: () => import("@/views/profile/Notifications.vue"),
  },
  {
    path: "/settings",
    name: "settings",
    meta: { layout: "profile", auth: true },
    component: () => import("@/views/profile/PersonalInfo.vue"),
  },
  {
    path: "/archive",
    name: "archive",
    meta: { layout: "profile", auth: true },
    component: () => import("@/views/profile/Archive.vue"),
  },
];

export default routes;
