import { LocalStorageWrapper, transformJSON } from '@/utils/localStorage.js';


const LOCAL_STORAGE_JSON = new LocalStorageWrapper(transformJSON);

const AUTH_STORAGE_KEY = Object.freeze({
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  TIME_CREATION: 'tokenTimeCreated',
  IS_REGISTERED: 'isRegistered',
  GUEST_ID: 'guestId',
});


const TOKEN_STATE = Object.freeze({
  INVALID: 0,
  VALID: 1,
  REFRESH: 2
})

function extractTokenInfo() {
  const accessToken = LOCAL_STORAGE_JSON.getItem(AUTH_STORAGE_KEY.ACCESS_TOKEN);
  const refreshToken = LOCAL_STORAGE_JSON.getItem(AUTH_STORAGE_KEY.REFRESH_TOKEN);
  const tokenTimeCreated = LOCAL_STORAGE_JSON.getItem(AUTH_STORAGE_KEY.TIME_CREATION);
  const isRegistered = LOCAL_STORAGE_JSON.getItem(AUTH_STORAGE_KEY.IS_REGISTERED);
  const guestId = LOCAL_STORAGE_JSON.getItem(AUTH_STORAGE_KEY.GUEST_ID);
  return {
    accessToken,
    refreshToken,
    tokenTimeCreated,
    isRegistered,
    guestId,
  }
}

function saveTokenInfo(tokenInfo) {
  // Saves only presented keys of tokenInfo object.
  Object.keys(tokenInfo).forEach(tokenKey => {
    if (tokenInfo[tokenKey] !== null || typeof tokenInfo[tokenKey] !== 'undefined') {
      LOCAL_STORAGE_JSON.setItem(tokenKey, tokenInfo[tokenKey]);
    }
  })
}

function normalizeTokenInfo(tokenInfo) {
  Object.keys(tokenInfo).forEach(tokenKey => {
    if (tokenInfo[tokenKey] === null || tokenInfo[tokenKey] === undefined) {
      let newValue = null;
      switch (tokenKey) {
        case AUTH_STORAGE_KEY.ACCESS_TOKEN:
        case AUTH_STORAGE_KEY.REFRESH_TOKEN:
          newValue = {token: "", lifetime: 0};
          break;
        case AUTH_STORAGE_KEY.TIME_CREATION:
          newValue = 0;
          break;
        case AUTH_STORAGE_KEY.IS_REGISTERED:
          newValue = false;
          break;
        case AUTH_STORAGE_KEY.GUEST_ID:
          newValue = '';
          break;
      }
      if (newValue !== null) {
        tokenInfo[tokenKey] = newValue;
      }
    }
  })
  return tokenInfo
}

function extractResponseTokens(response) {
  let token = response.getAccessToken();
  const accessToken = {
    token: token.getToken(),
    lifetime: token.getLifetime(),
  };

  token = response.getRefreshToken();
  const refreshToken = {
    token: token.getToken(),
    lifetime: token.getLifetime(),
  };

  return {accessToken, refreshToken}
}

export {
  AUTH_STORAGE_KEY,
  TOKEN_STATE,
  extractTokenInfo,
  saveTokenInfo,
  normalizeTokenInfo,
  extractResponseTokens,
}