<template>
  <aside
    class="card aside-card"
    :class="{ hidden : !isVisible }"
  >
    <logo 
      :iconWidth="iconWidth" 
      :iconHeight="iconHeight"
      :title="title"
      :direction="direction"
      :position="position"
    />

    <navigation-menu
      :direction="'column'"
      :menuList="menuList"
      :position="'mt-4'"
      :currentRoute="currentRoutePath"
    />
  </aside>
</template>

<script>
import NavigationMenu from '@/components/common/NavigationMenu.vue';
import Logo from '@/components/common/Logo.vue';

export default {
  name: 'sidebar',
  components: {
    Logo,
    NavigationMenu
  },

  props: {
    isVisible: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      iconWidth: 48,
      iconHeight: 48,
      title: 'Казатель',
      direction: 'column',
      position: 'p-2 mt-4',
      menuList: [
        {title: 'Личная информация', file: 'menu-profile.svg', link: '/personal-info'},
        {title: 'Безопасность', file: 'menu-home.svg', link: '/security'},
        {title: 'Платежи', file: 'menu-payments.svg', link: '/payments'},
        {title: 'Уведомления', file: 'menu-notification.svg', link: '/notifications'},
        // {title: 'Настройки', file: 'menu-settings.svg', link: '/settings'},
        // {title: 'Архив', file: 'menu-archive.svg', link: '/archive'},
        {title: 'Монетизация', file: 'menu-monitization.svg', link: '#'},
      ]
    }
  },

  computed: {
    currentRoutePath() {
      return this.$route.path;
    }
  }
  
}
</script>

<style scoped>
.aside-card {
  position: relative;
  width: 30%;
  height: 85vh;
  padding: 16px;
  margin-right: 16px;

  background: #FFFFFF;
  box-shadow: 4px 0px 16px rgba(74, 74, 74, 0.15);
  border-radius: 8px;

  transition: width 0.5s, padding 0.5s, margin-right 0.5s;
}

.aside-card.hidden {
  width: 0;
  border: none;
  overflow: hidden;
  padding: 0;
  margin-right: 0;
}

@media screen and (max-width:992px) {
  aside {
    display: none;
  }
}

</style>
