import { ref, shallowRef, computed } from 'vue';

import { extractTokenInfo } from '@/utils/auth';
import { fetchStreamData, fetchMethodData } from '@/utils/loaders.js';

const {EntitiesServiceClient} = require('@/generated/entities/entities_service_grpc_web_pb.js');


export default function useNodesList() {
  const nodesList = shallowRef({});
  const isNodesLoading = ref(true);
  const firstNodeId = ref('');

  let entitiesService = null;
  try {
    entitiesService = new EntitiesServiceClient(
      process.env.VUE_APP_REGISTRATION_SERVICE_URL, null, null
    );
  } catch(err) {
    console.error(err);
  }

  const fetchLessonNodes = async (nodeId = null, levelId = null) => {
    console.log(`Start fetch lesson nodes [${nodeId}], level = ${levelId}`);
    try {
      if (!nodeId) {
        throw new Error(`Lesson node ID is empty: ${nodeId}`)
      }
      const tokens = extractTokenInfo();
      const metadata = { 'token': tokens.accessToken.token };
      const requestNodes = new proto.kazatel.entities.NodesRequest();
      requestNodes.setParentId(nodeId);
      requestNodes.setDepth(1);

      if(levelId !== null) {
        let arrayLevels = [levelId];
        // console.log('DDDDDDDDDDDDDDDDDDDDDDDD', arrayLevels);
        requestNodes.setLevelsList(arrayLevels);
      }
      const streamNodes = entitiesService.nodes(requestNodes, metadata);

      isNodesLoading.value = true;
      firstNodeId.value = '';
      nodesList.value = {};
      const streamData = await fetchStreamData(streamNodes);

      streamData.forEach(response => {
        // console.log('GGGGGGGGGGGGG', response.toObject());
        const nodeId = response.getId();
        nodesList.value[nodeId] = {
          id: nodeId,
          parentId: response.getParentId(),
          title: response.getTitle(),
          prevId: response.getPrevId(),
          nextId: response.getNextId(),
          children: [],
          content: [],
          type: response.getType(),
          image: response.getViewUrl()
        };
        if (!response.getPrevId().length) {
          firstNodeId.value = nodeId;
        }
      });
      console.log('Fetch lesson nodes has been done');
    } catch(err) {
      // console.error('Fetch lesson nodes:', err);
      throw err
    } finally {
      isNodesLoading.value = false;
    }
  }

  const fetchNode = async (id, options = {}) => {
    console.log(`Start fetch lesson node [${id}]`);
    try {
      const request = new proto.kazatel.entities.NodeRequest();
      request.setId(id);
      if ('setTasksContent' in (options?.request || {})) {
        request.setTasksContent(options.request['setTasksContent']);
      }
      const tokens = extractTokenInfo();
      const metadata = { 'token': tokens.accessToken.token };
      return await fetchMethodData(entitiesService, 'node', { request, metadata });
    } catch (err) {
      // console.error(error);
      throw err
    }
  }

  const emptyLessonNodes = () => {
    nodesList.value = {};
  }

  const getSortedNodes = computed(() => {
    const nodes = [];
    if (firstNodeId.value.length && !isNodesLoading.value) {
      let nodeId = firstNodeId.value;
      while (nodeId in nodesList.value) {
        const node = nodesList.value[nodeId];
        nodes.push({ id: node.id, title: node.title, image: node.image });
        nodeId = node.nextId;
      }
    }
    return nodes
  })

  return {
    nodesList,
    firstNodeId,
    isNodesLoading,
    getSortedNodes,
    fetchLessonNodes,
    fetchNode,
    emptyLessonNodes,
  }
}