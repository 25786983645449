<template>
  <div
    :class="[
      'filter__item',
      `filter__item_size_${size}`,
      { [`filter__item_active`]: isSelected }
    ]"
    @click="toggleSelection"
  >
    <div :class="['item__text', { [`item__text_size_S`]: size === 'S' }]">
      <p
        :class="[
          'label',
          { [`label_size_S`]: size === 'S' },
          { [`label_size_XS`]: size === 'XS' },
          { [`label_size_M`]: size === 'M' || size === 'L' }
        ]"
      >{{ label }}</p>
    </div>

    <Badge v-if="badgeNeeded" :appearance="'clear'" :label="badgeTitle" :size="'S'" />

    <svg-icon :iconName="'kuiIconChevronDownLarge'" :size="'24px'" :style="{ transform: isSelected ? 'rotate(-180deg)' : 'rotate(0)', transition: '.3s ease-in-out' }"/>
    <slot name="dropdown"></slot>
  </div>
</template>

<script>
import Badge from '@/components/KazUI/atoms/badge';

export default {
  name: 'filter-item',
  components: {
    Badge,
  },
  props: {
    label: {
      type: String,
      default: 'Choose me now!',
      required: false,
    },
    size: {
      type: String,
      required: false,
      default: 'S',
    },
    isSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
    badgeNeeded:{
      type: Boolean,
      required: false,
      default: false,
    },
    badgeTitle:{
      type: Number,
      required: false,
      default: 1
    }
  },
  methods: {
    toggleSelection() {
      this.$emit('update:isSelected', !this.isSelected);
    },
  },
};
</script>

<style scoped>
.filter__item{
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 4px;
  outline: 1px solid var(--kaz-base-base-04);
  background-color: var(--kaz-base-base-01);
  border-radius: 12px;
  width: fit-content;
  cursor: pointer;
  transition: var(--kaz-transition-molecules);
  transition: transform 0.3s ease-in-out;
  position: relative;
}
.item__text{
  padding: 0px 4px;
}
.filter__item_active{
  outline: 2px solid var(--kaz-base-primary);
  transition: var(--kaz-transition-molecules);
}
.filter__item_size_L{
  padding: 16px 36px;
}
.filter__item_size_M{
  padding: 10px 20px;
}
.filter__item_size_S{
  padding: 6px 12px;
}
.filter__item_size_XS{
  padding: 2px 8px;
}
</style>
