<template>
<h4
  :style="{ color: textColor }"
  class="heading heading_type_H4">
  <slot></slot>
</h4>
</template>

<script>
export default {
  name: 'heading-size-h4',
  props: {
    textColor: {
      type: String,
      default: "var(--text-black)"
    },
  }
};
</script>

<style scoped>
.heading_type_H4 {
  font-size: 18px;
  font-weight: 600;
  /* line-height: 100%; */
  line-height: 22px;
  letter-spacing: 0.16px;
  color: var(--text-black);
}

</style>