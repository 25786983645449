<template>
  <p
    :style="{ color: textColor, fontWeight: textWeight }"
    @mouseover="changeColor(hoverColor)"
    @mouseout="changeColor(textColor)"
    class="paragraph paragraph_size_L"
  >
    <slot></slot>
  </p>
</template>

<script>
export default {
  name: 'paragraph-size-large',
  props: {
    textColor: {
      type: String,
      default: "var(--text-black)"
    },
    textWeight: {
      type: String,
      default: '500',
    },
    hoverColor: {
      type: String,
    }
  },
  methods: {
    changeColor(color) {
      this.$el.style.color = color;
    }
  }
};
</script>

<style scoped>
  .paragraph_size_L {
    font-size: 20px;
    /* line-height: 100%; */
    line-height: 24px;
    letter-spacing: 0.06px;
    transition: color 0.3s;
  }
  .paragraph_size_L:hover {
    color: var(--hoverColor);
  }
</style>
