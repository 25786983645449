<template>
  <div 
    href="#"
    class="banner-card best-authors"
    target="_blank"
  >
    <span class="heading heading_size_h2">
      Наши лучшие авторы
    </span>
    <ul class="body body_size_S banner-card__description best-authors__list">
      <li
        v-for="author in bestAuthors"
      >
        <span class="author-name" :title="author">
          {{ author }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'banner-best-authors',

  data() {
    return {
      bestAuthors: [
        'Артем Артемов Школа №18',
        'Елена Дмитриевна Математика',
        'Александр Константинович МОК',
        'Юрий Владимиров',
        'Екатерина Добролюбова',
      ]
    }
  }

}
</script>

<style scoped>
@import url('./styles.css');

.best-authors {
  background: var(--kaz-status-error-bg);
}

.author-name {
  display: block;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.003em;
  color: var(--kaz-textIcons-link);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.best-authors__list > li:not(:last-child) {
  margin-bottom: 8px;
}
</style>
