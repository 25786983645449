<template>
  <button class="button button_type_primary" :type="type">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'button-primary',

  props: {
    type: {
      type: String,
      default: "button",
    },
  }
};
</script>

<style scoped>
  .button_type_primary {
    font-family: 'Rubik';
    padding: 16px 12px;
    border-radius: 8px;
    background: var(--background-red);
    color: var(--white);
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 120%;
    transition: 0.3s ease-in-out;
  }
  .button_type_primary:hover {
    background: #fc5e50;
    transition: 0.3s ease-in-out;
    box-shadow: 4px 0px 16px 0px rgba(74, 74, 74, 0.15);
  }
  .button_type_primary:disabled {
    background: #da8078;
  }

  @media screen and (max-width: 568px) {
    .button_type_primary {
      padding: 16px 12px;
      font-size: 18px;
    }
  }
</style>
