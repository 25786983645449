<template>
  <audio controls preload="metadata" @error="onError">
    <source :src="srcLink" type="audio/wav">
    <a :href="srcLink">Загрузить аудиозапись</a>
  </audio>
</template>

<script>
export default {
  name: 'player-audio',

  props: {
    srcLink: {
      type: String,
      required: true
    }
  },

  methods: {
    onError(event) {
      const errorCodes = Object.entries(MediaError);
      const ind = errorCodes.findIndex(err => err[1] === event.target.error.code);
      let errorType = 'MEDIA_ERR_UNKNOWN';
      if (ind > -1) {
        errorType = errorCodes[ind][0]
      }
      console.error(`${errorType}: audio [${this.srcLink}]`);
    }
  }
}
</script>

<style scoped>
audio::-webkit-media-controls-enclosure {
  background: #E0E0E0;
}
</style>
