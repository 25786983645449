export const lessonsFilter = {
  namespaced: true,

  state: () => ({
    currentSubjectId: null,
    currentLevelId: null,
  }),

  getters: {
    getCurrentState(state) {
      return { 
        subjectId: state.currentSubjectId, 
        levelId: state.currentLevelId 
      }
    }
  },

  mutations: {
    setCurrentState(state, { levelId, subjectId }) {
      console.log('setting filter state:', subjectId, levelId);
      state.currentLevelId = levelId;
      state.currentSubjectId = subjectId;
    },
    resetCurrentState(state) {
      state.currentLevelId = null;
      state.currentSubjectId = null;
    }
  },
}