<template>
  <router-link 
    class="navbar-brand" 
    :class="getPosition"
    :to="{name: 'tasks'}"
  >
    <div 
      class="d-flex align-items-center"
      :class="setDirection"
    >
      <img 
        src="@/assets/images/kazatel-logo.svg" 
        alt="Logo" 
        :width="iconWidth" 
        :height="iconHeight" 
      >
      <!-- <h1 
        class="logo__title my-3"
        :class="{'ms-1' : isRowDirection}"
      >
        {{ title }}
      </h1> -->
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'logo',

  props: {
    iconWidth: {
      type: Number,
      default: 64
    },
    iconHeight: {
      type: Number,
      default: 64
    },
    title: {
      type: String,
      default: ''
    },
    direction: {
      type: String,
      default: 'row'
    },
    position: {
      type: String,
      default: ''
    }
  },

  computed: {
    setDirection() {
      if (this.direction === 'row') {
        return 'flex-row justify-content-start'
      } else if (this.direction === 'column') {
        return 'flex-column justify-content-center'
      }
    },
    isRowDirection() {
      return this.direction === 'row' ? true : false;
    },
    getPosition() {
      return this.position;
    }
  }
}
</script>

<style scoped>

.navbar-brand{
  margin: 0;
}
.logo__title {
  font-family: "Gilroy";
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
}
</style>
