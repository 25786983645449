<template>
  <div class="tag">
    <p class="tag__text">{{ text }}</p>
  </div>
</template>

<script>
export default{
  name: 'promo-tag',
  components:{

  },
  props:{
    text:{
      type: String,
      default: 'новое'
    }
  }
}
</script>

<style scoped>
.tag{
  padding: 2px 4px;
  background-color: rgba(216, 71, 58, 0.1);
  max-width: fit-content;
  border-radius: 4px;
}
.tag__text{
  font-size: 13px;
  font-weight: 500;
  /* line-height: 100%; */
  line-height: 18px;
  color: var(--background-red);
}
</style>