<template>
  <div class="dropdown__wrapper">
    <div class="dropdown__item dropdown__item_type_content" v-for="(item, index) in list" :key="index">
      <checkbox
        :id="index + filterId"
        :filterId="filterId"
        :checked="selectedItems[index]"
        :label="item"
      />
    </div>
  </div>
</template>

<script>
import Checkbox from '@/components/KazUI/atoms/checkbox'

export default{
  name: 'dropdown',
  components:{
    Checkbox
  },
  props:{
    list:{
      type: Array,
    },
    filterId:{
      type: [String, Number]
    }
  },
  data() {
    return {
      selectedItems: []
    }
  },
  methods: {

  },
}
</script>

<style scoped>
.dropdown__wrapper{
  margin-left: -20px;
  z-index: 99;
  position: absolute;
  top: 48px;
  background-color: var(--kaz-base-base-01);
  outline: 1px solid var(--kaz-base-base-04);
  width: 50%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  border-radius: 12px;
  min-width: 320px;
  box-shadow: 0px 15px 25px 0px rgba(00, 00, 00, 0.03), 0px 11px 11px 0px rgba(00, 00, 00, 0.04), 0px 3px 6px 0px rgba(00, 00, 00, 0.05);
  max-height: 382px;
  overflow-y: auto;
}
.dropdown__item_type_content{
  padding: 8px 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
}
.dropdown__item:hover{
  background-color: var(--kaz-base-base-02);

}
</style>