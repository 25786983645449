import {fetchStreamData} from '@/utils/loaders';
import STATES from '@/utils/states';

import { getCustomOrder } from '@/utils/courses';


const {TaskReaderClient, default: books} = require(
  '@/generated/taskreader/taskreader_grpc_web_pb.js'
);


export const gradeData = {
  namespaced: true,
  state: () => ({
    dataState: STATES.INIT,
    grades: [],
    activeGradeId: null,
    activeCourseId: null
  }),

  getters: {
    getGrades(state) {
      return state.grades;
    },
    getState(state) {
      return state.dataState;
    },
    getActiveGradeId(state) {
      return state.activeGradeId;
    },
    getActiveCourseId(state) {
      return state.activeCourseId;
    }
  },

  mutations: {
    addGrade(state, newGrade) {
      state.grades.push(newGrade)
    },
    setState(state, newState) {
      state.dataState = newState;
    },
    setActiveGradeId(state, newActiveGradeId) {
      state.activeGradeId = newActiveGradeId;
    }
  },

  actions: {
    async fetchGrades({commit}) {
      console.log('start to fetch grades from vuex...');
      commit('setState', STATES.LOADING);
      try {
        const serviceURL = process.env.VUE_APP_REGISTRATION_SERVICE_URL;
        const taskreaderService = new TaskReaderClient(serviceURL, null, null);
        const streamRequest = new proto.google.protobuf.Empty();
        const stream = taskreaderService.subjectsByLevel(streamRequest, {});
        const streamData = await fetchStreamData(stream);
        
        for (const response of streamData) {
          const newGrade = {
            id: response.getId(), 
            title: response.getName(), 
            courses: []
          };
          const arr = response.getSubjectsList();
          if (arr) {
            const customOrder = getCustomOrder();
            for (let index = 0; index < arr.length; index++) {
              const element = arr[index];
              const el = {
                id: element.getId(), 
                title: element.getName(),
                order: customOrder(element.getName()),
              };
              newGrade.courses.push(el);        
            }  
            commit('addGrade', newGrade);
          }
        }
        commit('setState', STATES.LOADED);
        console.log('fetch grades has been done');
      } catch(err) {
        commit('setState', STATES.ERROR);
        throw new Error(err);
      }
    }
  } 
   
  
}