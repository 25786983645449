import { computed } from 'vue';
import { useRoute } from 'vue-router';

export default function useEditorIntro(destinationRouteName) {
  if (destinationRouteName === undefined) {
    throw new Error('Destination route name is not defined.')
  }

  const route = useRoute();
  const prefixName = "editor-";

  const getDestRouteName = computed(() => {
    let finalRouteName = destinationRouteName
    if (route.path.startsWith('/editor/resh')) {
      finalRouteName = prefixName + destinationRouteName;
    }
    return finalRouteName
  })

  return {
    getDestRouteName
  }
}