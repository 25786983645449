import { ref } from 'vue';


export default function useBookOverview() {

  function handleOverviewLoadingError(error) {
    const { code = null } = error || {};
    let errorMessage = null;
    switch (code) {
      case 7:
      case 13:
        errorMessage = 'Данный материал не доступен на платформе';
        break;
      default:
        errorMessage = 'Что-то пошло не так...'
    }
    return errorMessage
  }

  return {
    handleOverviewLoadingError
  }
}