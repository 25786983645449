!<template>
  <div
    class="book-search" 
    v-click-outside="closeResult"
    @keydown.esc="closeResult"
    ref="bookSearch"
  >
    <KazInputSearch
      class="book-search__input"
      :class="{ 'book-search__input_focus': focusInSearchInput || resultDialogVisibility}"
      size="M"
      placeholder="Поиск среди тысяч решений"
      v-model:value.trim="searchQuery"
      :isErrorActive="false"
      :errorText="''"
      :readOnly="false"
      @focusin="focusIn"
      @focusout="focusOut"
    />
    <section
      class="book-search__result" 
      :class="{ 'book-search__result_visible' : resultDialogVisibility }"
    >
      <KazLoader v-if="isSearching" size="M"/>
      <template v-else>
        <span class="book-search__subtitle">Результаты поиска</span>
        <p v-if="!searchedBooksList.length" style="padding: 0 8px;">
          По запросу ничего не найдено
        </p>
        <template v-else>
          <ol>
            <li
              v-for="book in searchedBooksList"
              :key="book.id"
              class="book-search__item"
            >
              <router-link
                :to="{ name: 'book-content', params: { bid: book.id } }"
                custom 
                v-slot="{ href, route, isActive, isExactActive }"
              >
                <a
                  class="search-card" 
                  :href="href" 
                  @click.prevent="gotoSearchedBook(route)"
                >
                  <img 
                    class="search-card__img"
                    :src="book.cover" 
                    :alt="`${book.subject}, ${book.level} класс`"
                    :ref="addRef(book.id)"
                    @error="onErrorImg(book.id)"
                    width="60" 
                    height="80"
                  />
                  <div>
                    <h4 class="heading heading_size_h4" style="margin-bottom: 16px;">
                      {{ `${book.subject}, ${book.level} класс` }}
                    </h4>
                    <p class="body body_size_M">
                      {{ book.authors.join(', ') }}
                    </p>
                    <p class="body body_size_XS">
                      {{ getTypeDescription(book) }}
                    </p>
                  </div>
                </a>
              </router-link>
            </li>
          </ol>  
        </template>
      </template>
      <!-- <template>
        <h3 class="book-search__subtitle">История поиска</h3>
        <ol>
          <li
            v-for="item in searchHistory"
            :key="item.id"
            class="book-search__item"
          >
            <div class="book-search__header">
              <svg-icon :iconName="'kuiIconTimeLarge'" size="24px"/>
              <router-link
                :to="{ name: 'book-content', params: { bid: item.id } }"
                custom 
                v-slot="{ href, route, isActive, isExactActive }"
              >
                <a
                  class="book-search__link" 
                  :href="href" 
                  @click.prevent="gotoSearchedBook(route)"
                >
                  {{ item.title  }}
                </a>
              </router-link>
            </div>
            <kaz-button
              label="Удалить"
              size="S"
              appearance="flat"
              mode="normal"
              @click="console.log('removing search item')"
            />
          </li>
        </ol>
        <h3 class="book-search__subtitle">Может подойти вам</h3>
        <ol>
          <li
            v-for="(item, ind) in searchRecommendations"
            :key="item.id"
            class="book-search__item"
          >
            <div class="book-search__header">
              <svg-icon :iconName="'kuiIconChartLineLarge'" size="24px"/>
              <router-link 
                :to="{ name: 'book-content', params: { bid: item.id } }"
                custom 
                v-slot="{ href, route, isActive, isExactActive }"
              >
                <a
                  class="book-search__link" 
                  :href="href" 
                  @click.prevent="gotoSearchedBook(route)"
                  :is-last="ind === searchRecommendations.length - 1 ? 1 : 0"
                >
                  {{ item.title  }}
                </a>
              </router-link>
            </div>
            <KazBadge
              v-if="item.isNew"
              label="New"
              appearance="accent"
              size="S"
            />
          </li>
        </ol>
      </template> -->
    </section>
  </div>
</template>

<script>
import KazInputSearch from '@/components/KazUI/atoms/inputs/search';
import KazBadge from '@/components/KazUI/atoms/badge';
import KazButton from '@/components/KazUI/atoms/button';
import KazLoader from '@/components/KazUI/atoms/loader';

import { fetchBooks, blockBook, draftBook } from '@/services/TaskReader';
import { BOOK_STATUS } from '@/utils/book-structure/index.js';


const SEARCH_DELAY_MS = 500;
let searchTimeoutId = null;


export default {
  name: 'search-book-input',

  components: {
    KazInputSearch,
    KazBadge,
    KazButton,
    KazLoader,
  },

  data() {
    return {
      activeElement: null,
      focusInSearchInput: false,
      searchQuery: '',
      searchedBooksList: [],
      searchCardRefs: {},
      resultDialogVisibility: false,
      isSearching: false,
      searchHistory: [
        {
          id: 'c0a83801-893c-12d1-8189-3c6d0df0117c',
          title: 'Алгебра Березов',
        },
        {
          id: 'c0a83801-893c-12d1-8189-3c6d0df0117c',
          title: 'Сочинения 9 класс',
        },
      ],
      searchRecommendations: [
        {
          id: 'c0a83801-893c-12d1-8189-3c6d0df0117c',
          title: 'Уроки геометрии за 9 класс',
          isNew: false,
        },
        {
          id: 'c0a83801-893c-12d1-8189-3c6d0df0117c',
          title: 'ГДЗ по математике Борисов Е.В.',
          isNew: true,
        },
      ],
    }
  },

  watch: {
    activeElement: {
      handler(newElement) {
        const anchorElement = this.$refs?.bookSearch || null;
        if (anchorElement && !anchorElement.contains(newElement)) {
          this.resultDialogVisibility = false;
        }
      },
      immediate: true
    },
    searchQuery(newQuery) {
      clearTimeout(searchTimeoutId);
      if (newQuery.trim().length > 3) {
        // this.resultDialogVisibility = true;
        searchTimeoutId = setTimeout(
          async () => {
            try {
              this.resultDialogVisibility = true;
              this.isSearching = true;
              this.searchedBooksList = await this.makeSearchRequest(this.searchQuery);
            } catch (error) {
              this.searchedBooksList = [];
              console.warn(error);
            } finally {
              this.isSearching = false;
            }
          }, 
          SEARCH_DELAY_MS
        );
      } else {
        this.searchedBooksList = [];
      }
    }
  },

  methods: {
    async makeSearchRequest(searchQuery) {
      try {
        const streamResponse = await fetchBooks({
          statusList: [BOOK_STATUS.PUBLISHED],
          searchValue: searchQuery,
        });
        return this.handleBookStreamData(streamResponse) || [];
      } catch (error) {
        console.warn('search request:', error);
      }
    },

    handleBookStreamData(stream) {
      // Extract book data and place it in right place
      // return [{
      //   id: '123',
      //   subject: 'Русский язык',
      //   level: 1,
      //   authors: ['Громов', 'Алисова'],
      //   type: 'Учебник',
      //   part: 1,
      //   cover: ''
      // }]; 
      // console.log('str:', stream[0]?.toObject());
      return stream?.map(res => ({
          id: res.getId(),
          // titleSeo: res.getName(),
          // titleSystem: res.getNameOfficial(),
          cover: res.getViewUrl(),
          authors: res.getAuthors().split(','),
          // year: res.getPublishYear(),
          level: res.getLevelsList(),
          type: res.getType(),
          part: res.getPart(),
          subject: res.getSubject(),
          // publisher: res.getPublisher(),
          // studyDepth: 'Углубленный уровень',
          // FGOS: res.getFgos(),
          // statusBadge: true,
          // statusId: res.getStatusId(),
          })
        );
    },


    focusIn() {
      this.focusInSearchInput = true;
      if (this.searchedBooksList.length) {
        this.resultDialogVisibility = true;
      }
    },

    focusOut(e) {
      this.focusInSearchInput = false;
    },

    closeResult(e) {
      if (this.$refs['bookSearch']?.contains(document.activeElement)) {
        document.activeElement.blur();
      }
      this.resultDialogVisibility = false;
    },

    gotoSearchedBook(route) {
      this.closeResult();
      this.$router.push(route);
    },

    focusChanged(event) {
      this.activeElement = event.target;
    },

    addRef(id) {
      return el => {
        if (id) {
          this.searchCardRefs[id] = el;
        }
      }
    },

    onErrorImg(bId) {
      const img = this.searchCardRefs[bId] || null;
      if (img) {
        img.src = require('@/assets/images/books/not-loaded-book2.png');
      }
    },

    getTypeDescription(book) {
      var desc = book?.type || '';
      if (book?.part) {
        var dlmtr = book?.type ? ', ' : '';
        var part = book?.part ? book.part + ' часть' : '';
        desc += dlmtr + part;
      }
      return desc;
    }
  },

  mounted() {
    document.addEventListener('focusin', this.focusChanged);
  },

  beforeUnmount() {
    document.removeEventListener('focusin', this.focusChanged);
  }
}
</script>

<style scoped>
.book-search {
  position: relative;
  width: fit-content;
}

.book-search__input {
  width: 320px;
  transition: width var(--kaz-transition-molecules);
  will-change: width;
}

.book-search__input_focus {
  width: 550px;
}

.book-search__result {
  display: flex;
  flex-direction: column;
  gap: 2px;
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  padding: 8px;
  opacity: 0;
  max-height: 580px;
  overflow: auto;
  visibility: hidden;
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  box-shadow: 0px 25px 15px rgba(0, 0, 0, 0.03), 0px 11px 11px rgba(0, 0, 0, 0.04), 0px 3px 6px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  z-index: 1000;
  transition: opacity var(--kaz-transition-molecules);
}

.book-search__result_visible {
  opacity: 1;
  visibility: visible;
}

.book-search__subtitle {
  padding: 4px 8px;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: rgba(27, 31, 59, 0.65);
}

.book-search__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 10px 8px;
  border-radius: 8px;
}

.book-search__item:hover {
  background-color: var( --kaz-base-secondary-hover);
}

.book-search__header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.book-search__link {
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: var(--kaz-textIcons-text-01);
}

.search-card {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  text-decoration: none;
  cursor: pointer;
}

.search-card__img {
  border: 1px solid #B0B0B0;
  border-radius: 4px;
}

@media screen and (max-width: 576px) {
  .book-search {
    width: 100%;
  } 
  .book-search__input {
    width: 100%;
  }
  .book-search__result {
    max-height: 480px;
  }
}
</style>
