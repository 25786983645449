import { swapObjectEntries } from '@/utils/shared.js';
import STATES from '@/utils/states';


const subjectsToIdsTmp = {
  'algebra': 1,
  'angliyskiy-yazyk': 2,
  'biologiya': 3,
  'geographia': 4,
  'geometriya': 5,
  'estestvoznanie': 6,
  'izo': 7,
  'informatika': 8,
  'iskusstvo': 9,
  'ispanskiy-yazyk': 10,
  'istoriya': 11,
  'kitayskiy-yazyk': 12,
  'literatura': 13,
  'literaturnoe-chteniye': 14,
  'matematika': 15,
  'mir-prirody': 16,
  'muzyka': 17,
  'nemetskiy-yazyk': 18,
  'obzh': 19,
  'obschestvoznanie': 20,
  'okruzhayuschiy-mir': 21,
  'prirodovedeniye': 22,
  'russkiy-yazyk': 23,
  'tehnologiya': 24,
  'fizika': 25,
  'frantsuzskiy-yazyk': 26,
  'himiya': 27,
  'ekologiya': 28,
  'sochineniya': 29,
};

const levelsToIdsTmp = {
  '1-klass': 1,
  '2-klass': 2,
  '3-klass': 3,
  '4-klass': 4,
  '5-klass': 5,
  '6-klass': 6,
  '7-klass': 7,
  '8-klass': 8,
  '9-klass': 9,
  '10-klass': 10,
  '11-klass': 11,
};


export const coursesMapping = {
  namespaced: true,

  state: () => ({
    subjectsToIds: {},
    levelsToIds: {},
    loadingState: STATES.INIT
  }),

  getters: {
    getSubjectsToIds: state => state.subjectsToIds,
    getIdsToSubjects: state => swapObjectEntries(state.subjectsToIds),
    getLevelsToIds: state => state.levelsToIds,
    getIdsToLevels: state => swapObjectEntries(state.levelsToIds),
    getLoadingState: state => state.loadingState,
  },

  mutations: {
    setSubjectsToIds(state, newSubjectsToIds) {
      state.subjectsToIds = newSubjectsToIds;
    },
    setLevelsToIds(state, newLevelsToIds) {
      state.levelsToIds = newLevelsToIds;
    },
    setLoadingState(state, newState) {
      state.loadingState = newState;
    }
  },

  actions: {
    async fetchMapping({ commit }) {
      try {
        commit('setLoadingState', STATES.LOADING);
        commit('setSubjectsToIds', subjectsToIdsTmp);
        commit('setLevelsToIds', levelsToIdsTmp);
        commit('setLoadingState', STATES.LOADED);
      } catch (error) {
        commit('setLoadingState', STATES.ERROR);
        throw error;
      }
    }
  }
}