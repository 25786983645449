<template>
  <div
    class="section-title-wrapper"
  >
    <heading-size-h2

    >
      {{ title }}
    </heading-size-h2>
    <slot></slot>
  </div>
</template>

<script>
import HeadingSizeH2 from '@/components/UI/texts/headings/HeadingSizeH2.vue';

export default {
  name: 'section-header',

  components:{
    HeadingSizeH2
  },

  props: {
    title: {
      type: String,
      required: true
    },
  }
}
</script>

<style scoped>
.section-title-wrapper {
  display: flex;
  align-items: center;
  min-height: 38px;
}

.section-header {
  color: #505156;
  font-size: 26px;
  font-weight: 600;
  line-height: 100%;
}

.section-header_small {
  font-size: 20px;
}

@media screen and (max-width: 992px) {
  .section-title-wrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
}
</style>
