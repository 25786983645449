<template>
  <div v-if="display" class="auth-error">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'message-error',

  props: {
    display: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.auth-error {
  color: #dc3545;
  margin: 8px 0;
}
</style>
