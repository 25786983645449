<template>
  <div class="breadcrumbs-container">
    <ol v-if="state === LOADING_STATES.LOADED" class="kaz-breadcrumbs">
      <template v-if="getHiddenLinks.length">
        <li class="kaz-breadcrumbs__item">
          <kaz-dropdown>
            <template v-slot:button>
              <button class="kaz-breadcrumbs__more-button">
                <svg-icon iconName="kuiIconMoreHorLarge" size="24px" />
              </button>
            </template>
            <template v-slot:menu>
              <ol class="kaz-breadcrumbs__hidden-links">
                <li 
                  v-for="(link, ind) in getHiddenLinks" 
                  class="kaz-breadcrumbs__link_hidden"
                >
                  <router-link 
                    class="kaz-breadcrumbs__link dropdown__item dropdown__item_type_content dropdown__item_size_M"
                    :to="link.path"
                  >
                    {{ link.title }}
                  </router-link>
                </li>
              </ol>
            </template>
          </kaz-dropdown>
        </li>
        <span class="kaz-breadcrumbs__separator">
          <svg-icon iconName="kuiIconChevronRightLarge" size="24px"/>
        </span>
      </template>
      <template v-for="(link, ind) in getVisibleLinks">
        <li 
          class="kaz-breadcrumbs__item"
          :class="{ 'kaz-breadcrumbs__item_full' : ind === getVisibleLinks.length - 1 }"
          :title="link.title"
        >
          <router-link 
            class="kaz-breadcrumbs__link label label_size_M"
            :class="[{ 'kaz-breadcrumbs__link_disabled': isDisabled(getVisibleLinks, ind) }]"
            :to="link.path"
          >
            {{ link.title }}
          </router-link>
        </li>
        <span 
          v-if="ind !== getVisibleLinks.length - 1" 
          class="kaz-breadcrumbs__separator"
        >
          <svg-icon iconName="kuiIconChevronRightLarge" size="24px"/>
        </span>
      </template>
    </ol>
    <kaz-loader v-else-if="state === LOADING_STATES.LOADING" size="S"/>
  </div>
</template>

<script>
import useLoadingStates from '@/hooks/loading/useLoadingStates.js';
import KazLoader from '@/components/KazUI/atoms/loader';
import KazDropdown from '@/components/KazUI/slots/dropdown';
import KazButton from '@/components/KazUI/atoms/button';


const WIDTH_BREAKPOINT_VIEWPORT = 576;

export default {
  name: 'kaz-breadcrumbs',

  components: { KazLoader, KazDropdown, KazButton, },

  props: {
    links: {
      /**
       * Array items must be an object with required keys 'path' and 'title'.
       * The items may contain optional key 'disabled'.
       */
      type: Array,
      required: true,
      validator(value) {
        if (!Array.isArray(value)) return false;
        let isValid = true;
        for (let i = 0; i < value.length; ++i) {
          if (!('path' in value[i] && 'title' in value[i])) {
            isValid = false;
            break;
          }
        }
        return isValid
      }
    },
    state: {
      type: Number,
      required: true
    }
  },

  setup() {
    const { LOADING_STATES } = useLoadingStates();
    return { LOADING_STATES }
  },

  data() {
    return {
      viewportWidth: window.innerWidth,
    }
  },

  methods: {
    isDisabled(links, linkIndex) {
      if (links[linkIndex].hasOwnProperty('disabled')) {
        return links[linkIndex]['disabled'] ?? false;
      } else {
        return linkIndex === links.length - 1;
      }
    },
    onResize(event) {
      this.viewportWidth = window.innerWidth;
    }    
  },

  computed: {
    getStartIndex() {
      let visibleLinks = 4;
      if (this.viewportWidth <= WIDTH_BREAKPOINT_VIEWPORT) {
        visibleLinks = 2;
      }
      let startIndex = 0;
      if (visibleLinks < this.links.length) {
        startIndex = this.links.length - visibleLinks;
      }
      return startIndex;
    },
    getVisibleLinks() {
      return this.links.slice(this.getStartIndex)
    },
    getHiddenLinks() {
      return this.links.slice(0, this.getStartIndex)
    }
  },

  mounted() {
    window.addEventListener('resize', this.onResize);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  }
}
</script>

<style scoped>
.kaz-breadcrumbs {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  /* overflow: hidden; // this property causes hidding of absolute positioning menu */
  gap: 8px;
}

.kaz-breadcrumbs__separator {
  display: inline-block;
  width: 24px;
  height: 24px;
  --kaz-base-base-09: var(--kaz-textIcons-text-03);
}

.kaz-breadcrumbs__more-button {
  /* width: 32px;
  height: 32px; */
  border: none;
  border-radius: 50%;
  padding: 6px;
  background-color: var(--kaz-status-neutral-bg);
  --kaz-base-base-09: var(--kaz-status-neutral-fill);
}

.kaz-breadcrumbs__more-button:hover {
  background-color: var(--kaz-status-neutral-bg-hover);
}

.kaz-breadcrumbs__hidden-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.kaz-breadcrumbs__link_hidden {
  width: 100%;
}

.kaz-breadcrumbs__item {
  max-width: 120px;
}

.kaz-breadcrumbs__link {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  color: var(--kaz-textIcons-text-02);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: color var(--kaz-transition-molecules);
}

.dropdown__item .kaz-breadcrumbs__link {
  color: var(--kaz-textIcons-text-01);
}

.kaz-breadcrumbs__link:hover {
  color: var(--kaz-textIcons-text-01);
}

.kaz-breadcrumbs__link_disabled {
  pointer-events: none;
}

@media screen and (min-width: 576px) {
  .kaz-breadcrumbs__item {
    max-width: 140px;
  }
}

.kaz-breadcrumbs__item_full {
  flex: 1;
  width: 0;
  max-width: none;
}
</style>