<template>
  <div class="filter__wrapper">
    <Filter-Item
      v-for="(item, index) in data"
      :key="index"
      :label="item.filterName"
      :size="'M'"
      v-on:click="toggleDropdown(index)"
      :isSelected="activeDropdown === index"
      :badgeNeeded="atLeastOneSelected(item.filterId)"
      :badgeTitle="badgeQuontity(item.filterId)"
    >
      <template v-slot:dropdown>
        <dropdown
          v-if="activeDropdown === index"
          :list="item.items"
          :filterId="item.filterId"
        />
      </template>
    </Filter-Item>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import FilterItem from '@/components/KazUI/atoms/filter-item'
import Dropdown from '@/components/KazUI/molecules/dropdown'

export default{
  name: 'filter-group',
  components:{
    FilterItem,
    Dropdown
  },
  props:{
    data: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      activeDropdown: null,
    }
  },
  methods: {
    toggleDropdown(index) {
      this.activeDropdown = this.activeDropdown === index ? null : index;
    },
  },
  computed: {
    ...mapGetters('moderationFilters', ['getFilters']),

    atLeastOneSelected() {
      return (filterId) => {
        return this.getFilters.filter(item => item.filterId === filterId).length > 0;
      }
    },

    badgeQuontity() {
      return (filterId) => {
        let count = this.getFilters.filter(item => item.filterId === filterId).length;
        return count > 0 ? count : null;
      }
    },
  },
}
</script>

<style scoped>
.filter__wrapper{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  position: relative;
}
.dropdown {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
}

@media screen and (max-width: 992px) {
  .filter__wrapper{
    flex-wrap: wrap;
  }
}
</style>
