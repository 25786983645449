<template>
  <button class="button" :type="type" :class="deriveStyleType">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'button-primary',

  props: {
    type: {
      type: String,
      default: "button",
    },
    styleType: {
      type: String,
      default: 'primary'
    }
  },

  computed: {
    deriveStyleType() {
      switch (this.styleType) {
        case 'primary':
          return ['button_type_primary']
        case 'light20':
          return ['button_type_light20']
        case 'light10':
          return['button_type_light10']
      }
    }
  }
};
</script>

<style src="./button.css"></style>
<style scoped>
  .button_type_primary {
    padding: 16px 16px;

    border-radius: 8px;
    background: var(--background-brown);

    color: var(--white);
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 130%;

    transition: 0.3s ease-in-out;
  }
  .button_type_primary:hover {
    background: var(--background-red);
    transition: 0.3s ease-in-out;
  }
  .button_type_primary:disabled {
    opacity: .5;
  }
  .button_type_primary:disabled:hover {
    opacity: .5;
    background: var(--background-brown);
  }

  .button_type_light20 {
    padding: 16px 4px;

    border-radius: 8px;
    background: var( --background-light-20);

    color: var(--text-light);
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 130%;

    transition: 0.3s ease-in-out;
  }

  .button_type_light20:hover {
    background: var(--background-light);
  }

  .button_type_light10{
    padding: 16px 4px;

    border-radius: 8px;
    background:var(--background-light);

    color: var(--text-black);
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 130%;

    transition: 0.3s ease-in-out;
  }
  .button_type_light10:hover{
    background: var(--white);
  }
</style>
