/**
 * @fileoverview gRPC-Web generated client stub for kazatel.user
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.23.3
// source: user/usercontrol.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js')

var google_type_date_pb = require('../google/type/date_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.kazatel = {};
proto.kazatel.user = require('./usercontrol_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.kazatel.user.UserControlClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.kazatel.user.UserControlPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.user.GetRequest,
 *   !proto.kazatel.user.UserData>}
 */
const methodDescriptor_UserControl_Get = new grpc.web.MethodDescriptor(
  '/kazatel.user.UserControl/Get',
  grpc.web.MethodType.UNARY,
  proto.kazatel.user.GetRequest,
  proto.kazatel.user.UserData,
  /**
   * @param {!proto.kazatel.user.GetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.user.UserData.deserializeBinary
);


/**
 * @param {!proto.kazatel.user.GetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.user.UserData)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.user.UserData>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.UserControlClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.user.UserControl/Get',
      request,
      metadata || {},
      methodDescriptor_UserControl_Get,
      callback);
};


/**
 * @param {!proto.kazatel.user.GetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.user.UserData>}
 *     Promise that resolves to the response
 */
proto.kazatel.user.UserControlPromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.user.UserControl/Get',
      request,
      metadata || {},
      methodDescriptor_UserControl_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.user.UpdatableUserData,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_UserControl_Update = new grpc.web.MethodDescriptor(
  '/kazatel.user.UserControl/Update',
  grpc.web.MethodType.UNARY,
  proto.kazatel.user.UpdatableUserData,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.kazatel.user.UpdatableUserData} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.kazatel.user.UpdatableUserData} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.UserControlClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.user.UserControl/Update',
      request,
      metadata || {},
      methodDescriptor_UserControl_Update,
      callback);
};


/**
 * @param {!proto.kazatel.user.UpdatableUserData} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.user.UserControlPromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.user.UserControl/Update',
      request,
      metadata || {},
      methodDescriptor_UserControl_Update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.StringValue,
 *   !proto.kazatel.user.UpdateVerificationInfo>}
 */
const methodDescriptor_UserControl_ChangeMail = new grpc.web.MethodDescriptor(
  '/kazatel.user.UserControl/ChangeMail',
  grpc.web.MethodType.UNARY,
  google_protobuf_wrappers_pb.StringValue,
  proto.kazatel.user.UpdateVerificationInfo,
  /**
   * @param {!proto.google.protobuf.StringValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.user.UpdateVerificationInfo.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.user.UpdateVerificationInfo)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.user.UpdateVerificationInfo>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.UserControlClient.prototype.changeMail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.user.UserControl/ChangeMail',
      request,
      metadata || {},
      methodDescriptor_UserControl_ChangeMail,
      callback);
};


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.user.UpdateVerificationInfo>}
 *     Promise that resolves to the response
 */
proto.kazatel.user.UserControlPromiseClient.prototype.changeMail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.user.UserControl/ChangeMail',
      request,
      metadata || {},
      methodDescriptor_UserControl_ChangeMail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.user.ChangeVerificationCode,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_UserControl_VerifyChanges = new grpc.web.MethodDescriptor(
  '/kazatel.user.UserControl/VerifyChanges',
  grpc.web.MethodType.UNARY,
  proto.kazatel.user.ChangeVerificationCode,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.kazatel.user.ChangeVerificationCode} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.kazatel.user.ChangeVerificationCode} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.UserControlClient.prototype.verifyChanges =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.user.UserControl/VerifyChanges',
      request,
      metadata || {},
      methodDescriptor_UserControl_VerifyChanges,
      callback);
};


/**
 * @param {!proto.kazatel.user.ChangeVerificationCode} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.user.UserControlPromiseClient.prototype.verifyChanges =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.user.UserControl/VerifyChanges',
      request,
      metadata || {},
      methodDescriptor_UserControl_VerifyChanges);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.StringValue,
 *   !proto.kazatel.user.UpdateVerificationInfo>}
 */
const methodDescriptor_UserControl_ChangePhone = new grpc.web.MethodDescriptor(
  '/kazatel.user.UserControl/ChangePhone',
  grpc.web.MethodType.UNARY,
  google_protobuf_wrappers_pb.StringValue,
  proto.kazatel.user.UpdateVerificationInfo,
  /**
   * @param {!proto.google.protobuf.StringValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.user.UpdateVerificationInfo.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.user.UpdateVerificationInfo)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.user.UpdateVerificationInfo>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.UserControlClient.prototype.changePhone =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.user.UserControl/ChangePhone',
      request,
      metadata || {},
      methodDescriptor_UserControl_ChangePhone,
      callback);
};


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.user.UpdateVerificationInfo>}
 *     Promise that resolves to the response
 */
proto.kazatel.user.UserControlPromiseClient.prototype.changePhone =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.user.UserControl/ChangePhone',
      request,
      metadata || {},
      methodDescriptor_UserControl_ChangePhone);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.google.protobuf.StringValue>}
 */
const methodDescriptor_UserControl_Permissions = new grpc.web.MethodDescriptor(
  '/kazatel.user.UserControl/Permissions',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  google_protobuf_wrappers_pb.StringValue,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_wrappers_pb.StringValue.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.StringValue)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.StringValue>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.UserControlClient.prototype.permissions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.user.UserControl/Permissions',
      request,
      metadata || {},
      methodDescriptor_UserControl_Permissions,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.StringValue>}
 *     Promise that resolves to the response
 */
proto.kazatel.user.UserControlPromiseClient.prototype.permissions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.user.UserControl/Permissions',
      request,
      metadata || {},
      methodDescriptor_UserControl_Permissions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.StringValue,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_UserControl_CreateParentWork = new grpc.web.MethodDescriptor(
  '/kazatel.user.UserControl/CreateParentWork',
  grpc.web.MethodType.UNARY,
  google_protobuf_wrappers_pb.StringValue,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.StringValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.UserControlClient.prototype.createParentWork =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.user.UserControl/CreateParentWork',
      request,
      metadata || {},
      methodDescriptor_UserControl_CreateParentWork,
      callback);
};


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.user.UserControlPromiseClient.prototype.createParentWork =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.user.UserControl/CreateParentWork',
      request,
      metadata || {},
      methodDescriptor_UserControl_CreateParentWork);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.user.ParentWork,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_UserControl_UpdateParentWork = new grpc.web.MethodDescriptor(
  '/kazatel.user.UserControl/UpdateParentWork',
  grpc.web.MethodType.UNARY,
  proto.kazatel.user.ParentWork,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.kazatel.user.ParentWork} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.kazatel.user.ParentWork} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.UserControlClient.prototype.updateParentWork =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.user.UserControl/UpdateParentWork',
      request,
      metadata || {},
      methodDescriptor_UserControl_UpdateParentWork,
      callback);
};


/**
 * @param {!proto.kazatel.user.ParentWork} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.user.UserControlPromiseClient.prototype.updateParentWork =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.user.UserControl/UpdateParentWork',
      request,
      metadata || {},
      methodDescriptor_UserControl_UpdateParentWork);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Int32Value,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_UserControl_DeleteParentWork = new grpc.web.MethodDescriptor(
  '/kazatel.user.UserControl/DeleteParentWork',
  grpc.web.MethodType.UNARY,
  google_protobuf_wrappers_pb.Int32Value,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Int32Value} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Int32Value} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.UserControlClient.prototype.deleteParentWork =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.user.UserControl/DeleteParentWork',
      request,
      metadata || {},
      methodDescriptor_UserControl_DeleteParentWork,
      callback);
};


/**
 * @param {!proto.google.protobuf.Int32Value} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.user.UserControlPromiseClient.prototype.deleteParentWork =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.user.UserControl/DeleteParentWork',
      request,
      metadata || {},
      methodDescriptor_UserControl_DeleteParentWork);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.StringValue,
 *   !proto.kazatel.user.ParentWork>}
 */
const methodDescriptor_UserControl_ListParentWork = new grpc.web.MethodDescriptor(
  '/kazatel.user.UserControl/ListParentWork',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_wrappers_pb.StringValue,
  proto.kazatel.user.ParentWork,
  /**
   * @param {!proto.google.protobuf.StringValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.user.ParentWork.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.StringValue} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.user.ParentWork>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.UserControlClient.prototype.listParentWork =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.user.UserControl/ListParentWork',
      request,
      metadata || {},
      methodDescriptor_UserControl_ListParentWork);
};


/**
 * @param {!proto.google.protobuf.StringValue} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.user.ParentWork>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.UserControlPromiseClient.prototype.listParentWork =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.user.UserControl/ListParentWork',
      request,
      metadata || {},
      methodDescriptor_UserControl_ListParentWork);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.user.TeacherWork,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_UserControl_CreateTeacherWork = new grpc.web.MethodDescriptor(
  '/kazatel.user.UserControl/CreateTeacherWork',
  grpc.web.MethodType.UNARY,
  proto.kazatel.user.TeacherWork,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.kazatel.user.TeacherWork} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.kazatel.user.TeacherWork} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.UserControlClient.prototype.createTeacherWork =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.user.UserControl/CreateTeacherWork',
      request,
      metadata || {},
      methodDescriptor_UserControl_CreateTeacherWork,
      callback);
};


/**
 * @param {!proto.kazatel.user.TeacherWork} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.user.UserControlPromiseClient.prototype.createTeacherWork =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.user.UserControl/CreateTeacherWork',
      request,
      metadata || {},
      methodDescriptor_UserControl_CreateTeacherWork);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.user.UpdatableTeacherWork,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_UserControl_UpdateTeacherWork = new grpc.web.MethodDescriptor(
  '/kazatel.user.UserControl/UpdateTeacherWork',
  grpc.web.MethodType.UNARY,
  proto.kazatel.user.UpdatableTeacherWork,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.kazatel.user.UpdatableTeacherWork} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.kazatel.user.UpdatableTeacherWork} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.UserControlClient.prototype.updateTeacherWork =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.user.UserControl/UpdateTeacherWork',
      request,
      metadata || {},
      methodDescriptor_UserControl_UpdateTeacherWork,
      callback);
};


/**
 * @param {!proto.kazatel.user.UpdatableTeacherWork} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.user.UserControlPromiseClient.prototype.updateTeacherWork =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.user.UserControl/UpdateTeacherWork',
      request,
      metadata || {},
      methodDescriptor_UserControl_UpdateTeacherWork);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Int32Value,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_UserControl_DeleteTeacherWork = new grpc.web.MethodDescriptor(
  '/kazatel.user.UserControl/DeleteTeacherWork',
  grpc.web.MethodType.UNARY,
  google_protobuf_wrappers_pb.Int32Value,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Int32Value} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Int32Value} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.UserControlClient.prototype.deleteTeacherWork =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.user.UserControl/DeleteTeacherWork',
      request,
      metadata || {},
      methodDescriptor_UserControl_DeleteTeacherWork,
      callback);
};


/**
 * @param {!proto.google.protobuf.Int32Value} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.user.UserControlPromiseClient.prototype.deleteTeacherWork =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.user.UserControl/DeleteTeacherWork',
      request,
      metadata || {},
      methodDescriptor_UserControl_DeleteTeacherWork);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.StringValue,
 *   !proto.kazatel.user.TeacherWork>}
 */
const methodDescriptor_UserControl_ListTeacherWork = new grpc.web.MethodDescriptor(
  '/kazatel.user.UserControl/ListTeacherWork',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_wrappers_pb.StringValue,
  proto.kazatel.user.TeacherWork,
  /**
   * @param {!proto.google.protobuf.StringValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.user.TeacherWork.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.StringValue} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.user.TeacherWork>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.UserControlClient.prototype.listTeacherWork =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.user.UserControl/ListTeacherWork',
      request,
      metadata || {},
      methodDescriptor_UserControl_ListTeacherWork);
};


/**
 * @param {!proto.google.protobuf.StringValue} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.user.TeacherWork>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.UserControlPromiseClient.prototype.listTeacherWork =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.user.UserControl/ListTeacherWork',
      request,
      metadata || {},
      methodDescriptor_UserControl_ListTeacherWork);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.user.TeacherEducation,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_UserControl_CreateTeacherEducation = new grpc.web.MethodDescriptor(
  '/kazatel.user.UserControl/CreateTeacherEducation',
  grpc.web.MethodType.UNARY,
  proto.kazatel.user.TeacherEducation,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.kazatel.user.TeacherEducation} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.kazatel.user.TeacherEducation} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.UserControlClient.prototype.createTeacherEducation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.user.UserControl/CreateTeacherEducation',
      request,
      metadata || {},
      methodDescriptor_UserControl_CreateTeacherEducation,
      callback);
};


/**
 * @param {!proto.kazatel.user.TeacherEducation} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.user.UserControlPromiseClient.prototype.createTeacherEducation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.user.UserControl/CreateTeacherEducation',
      request,
      metadata || {},
      methodDescriptor_UserControl_CreateTeacherEducation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.user.UpdatableTeacherEducation,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_UserControl_UpdateTeacherEducation = new grpc.web.MethodDescriptor(
  '/kazatel.user.UserControl/UpdateTeacherEducation',
  grpc.web.MethodType.UNARY,
  proto.kazatel.user.UpdatableTeacherEducation,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.kazatel.user.UpdatableTeacherEducation} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.kazatel.user.UpdatableTeacherEducation} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.UserControlClient.prototype.updateTeacherEducation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.user.UserControl/UpdateTeacherEducation',
      request,
      metadata || {},
      methodDescriptor_UserControl_UpdateTeacherEducation,
      callback);
};


/**
 * @param {!proto.kazatel.user.UpdatableTeacherEducation} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.user.UserControlPromiseClient.prototype.updateTeacherEducation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.user.UserControl/UpdateTeacherEducation',
      request,
      metadata || {},
      methodDescriptor_UserControl_UpdateTeacherEducation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Int32Value,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_UserControl_DeleteTeacherEducation = new grpc.web.MethodDescriptor(
  '/kazatel.user.UserControl/DeleteTeacherEducation',
  grpc.web.MethodType.UNARY,
  google_protobuf_wrappers_pb.Int32Value,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Int32Value} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Int32Value} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.UserControlClient.prototype.deleteTeacherEducation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.user.UserControl/DeleteTeacherEducation',
      request,
      metadata || {},
      methodDescriptor_UserControl_DeleteTeacherEducation,
      callback);
};


/**
 * @param {!proto.google.protobuf.Int32Value} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.user.UserControlPromiseClient.prototype.deleteTeacherEducation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.user.UserControl/DeleteTeacherEducation',
      request,
      metadata || {},
      methodDescriptor_UserControl_DeleteTeacherEducation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.StringValue,
 *   !proto.kazatel.user.TeacherEducation>}
 */
const methodDescriptor_UserControl_ListTeacherEducation = new grpc.web.MethodDescriptor(
  '/kazatel.user.UserControl/ListTeacherEducation',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_wrappers_pb.StringValue,
  proto.kazatel.user.TeacherEducation,
  /**
   * @param {!proto.google.protobuf.StringValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.user.TeacherEducation.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.StringValue} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.user.TeacherEducation>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.UserControlClient.prototype.listTeacherEducation =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.user.UserControl/ListTeacherEducation',
      request,
      metadata || {},
      methodDescriptor_UserControl_ListTeacherEducation);
};


/**
 * @param {!proto.google.protobuf.StringValue} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.user.TeacherEducation>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.UserControlPromiseClient.prototype.listTeacherEducation =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.user.UserControl/ListTeacherEducation',
      request,
      metadata || {},
      methodDescriptor_UserControl_ListTeacherEducation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.kazatel.user.BonusInfo>}
 */
const methodDescriptor_UserControl_GetCurrentBonuses = new grpc.web.MethodDescriptor(
  '/kazatel.user.UserControl/GetCurrentBonuses',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.kazatel.user.BonusInfo,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.user.BonusInfo.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.user.BonusInfo)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.user.BonusInfo>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.UserControlClient.prototype.getCurrentBonuses =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.user.UserControl/GetCurrentBonuses',
      request,
      metadata || {},
      methodDescriptor_UserControl_GetCurrentBonuses,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.user.BonusInfo>}
 *     Promise that resolves to the response
 */
proto.kazatel.user.UserControlPromiseClient.prototype.getCurrentBonuses =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.user.UserControl/GetCurrentBonuses',
      request,
      metadata || {},
      methodDescriptor_UserControl_GetCurrentBonuses);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.kazatel.user.BonusOperation>}
 */
const methodDescriptor_UserControl_GetBonusOperations = new grpc.web.MethodDescriptor(
  '/kazatel.user.UserControl/GetBonusOperations',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.kazatel.user.BonusOperation,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.user.BonusOperation.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.user.BonusOperation>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.UserControlClient.prototype.getBonusOperations =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.user.UserControl/GetBonusOperations',
      request,
      metadata || {},
      methodDescriptor_UserControl_GetBonusOperations);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.user.BonusOperation>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.UserControlPromiseClient.prototype.getBonusOperations =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.user.UserControl/GetBonusOperations',
      request,
      metadata || {},
      methodDescriptor_UserControl_GetBonusOperations);
};


module.exports = proto.kazatel.user;

