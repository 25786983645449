<template>
  <svg class="svg-icon" :class="[getSizeClass]" aria-hidden="true">
    <use :href="getHref"/>
  </svg>
</template>

<script>
export default {
  name: 'svg-icon',

  props: {
    iconName: {
      type: String,
      required: true
    },
    size: {
      type: String,
      required: true
    }
  },

  computed: {
    getHref() {
      try {
        return `${require(`@/sprites/sprite_${this.size}.svg`)}#${this.iconName}`
      } catch (error) {
        console.warn(error);
      }
    },
    getSizeClass() {
      let sizeClass = '';
      switch (this.size) {
        case '24px':
          sizeClass = 'svg-icon_size_24px';
          break;
        case '16px':
          sizeClass = 'svg-icon_size_16px';
          break;
        default:
          console.warn(`Unsupported size [${this.size}]`);
      }
      return sizeClass
    }
  }
}
</script>

<style scoped>
@property --color-a {
  syntax: '<color>';
  initial-value: #000000;
  inherits: true;
}

.svg-icon {
  fill: currentColor;
  overflow: hidden;
}

.svg-icon_size_16px {
  width: 16px;
  height: 16px;
}

.svg-icon_size_24px {
  width: 24px;
  height: 24px;
}

/* using custom properties (src/sprites/sprite.svg) */
.test-svg-1 {
  --color-a: green;
  transition: --color-a 1s;
}

.test-svg-1:hover {
  --color-a: red;
}
</style>