<template>
  <card-background ref="wrapper">
    <div 
      v-if="getVariantsLoadingState === LOADING_STATES.LOADED"
      class="content-wrapper"
    >
      <kaz-breadcrumbs :links="getBreadcrumbsLinks" :state="LOADING_STATES.LOADED" />
      <div class="task-header">
        <div class="task-title" :class="{ 'column': shouldApplyColumnClass }">
          <div class="task-title__main">
            <span v-if="getTaskProps.isPremium" class="task-title__icon_premium"></span>
            <h2 class="heading heading_size_h2">
              {{ getNodeTitleFull }}
              <KazBadge
                v-if="isCompetition"
                size="L"
                appearance="accent"
                label="Это пособие участвует в конкурсе"
                style="display: inline-block; vertical-align: middle;"
              />
            </h2>
          </div>
          <div class="task-title__buttons">
            <SkeletonLoader
              v-if="getNodesLoadingState === LOADING_STATES.INIT || getNodesLoadingState === LOADING_STATES.LOADING"
              width="168px"
              height="44px"
              borderRadius="12px"
            />
            <book-pagination
              v-else
              :currentNodeId="$route.params.tid"
              :bookId="bookId"
              :nodesList="getNodesByLevel"
              :isNodesLoading="getNodesLoadingState === LOADING_STATES.INIT || getNodesLoadingState === LOADING_STATES.LOADING"
              @updateColumnClass="updateTaskTitleClass"
            />
          </div>
        </div>

        <div class="task-title__aside">
          <span class="task-title__limit task-title__limit_premium">
            <paragraph-size-medium textColor="var(--white)">
              {{ getRestTaskPremium }}/{{ premiumTaskLimit }}
            </paragraph-size-medium>
          </span>
          <span class="task-title__limit task-title__limit_usual">
            <paragraph-size-medium textColor="var(--white)">
              {{ getRestTask }}/{{ usualTaskLimit }}
            </paragraph-size-medium>
          </span>
          <button class="button button_type_question">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z" stroke="#97989C" stroke-width="1.5"/><path d="M10.125 8.875a1.874 1.874 0 1 1 2.828 1.615c-.475.28-.953.708-.953 1.26V13" stroke="#97989C" stroke-width="1.5" stroke-linecap="round"/><path d="M12 17a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" fill="#97989C"/>
            </svg>
          </button>
          <div class="tooltip">
            <paragraph-size-small>Это счетчик твоих доступных просмотров. Улучши подписку, чтобы увеличить лимит</paragraph-size-small>
          </div>
        </div>

        <div v-if="!noSulutions" class="controls">
          <div class="task__button-controls">
            <div class="controls__variants">
              <tabs
                :tabs="taskTypes"
                :initIndex="taskTypes.findIndex(t => t.id === selectorState.activeType)"
                @input="tabInd => changeSelectorState(taskTypes[tabInd].id, 'activeType')"
                size="M"
              />
            </div>
            <kaz-action
              v-if="taskConditions.length"
              title="Открыть условие текущего задания"
              iconBgColor="var(--kaz-status-info-bg)"
              size="S"
              @click="conVisibility = !conVisibility"
            >
              <template v-slot:icon>
                <svg-icon 
                  class="controls__icon_condition"
                  iconName="kuiIconFileLarge"
                  size="24px"
                />
              </template>
            </kaz-action>
          </div>

          <div v-if="tasksVariants[selectorState.activeType].length" class="selector">
            <paragraph-size-small textWeight="600">вариант автора</paragraph-size-small>
            <div class="avatar-variants__wrapper">
              <loader v-if="isAvatarLoading" :size="'M'"/>
              <avatar
                v-else
                v-for="ind in getTaskVariants.length"
                :key="ind"
                :size="'S'"
                :avatarImage="getRightPhoto(ind - 1)"
                :class="deriveSelectorAvatarClass(ind - 1, 1)"
                @click="changeSelectorState(ind - 1, 'activeVariant')"
              />
            </div>
          </div>
        </div>

        <div v-else class="empty-node">
          <div class="empty-node__image"></div>
          <div class="empty-node__message">
            <p class="heading heading_size_h2">
              {{ isCompetition 
                ? 'Добавь решение этой задачи и участвуй в конкурсе' 
                : 'У нас нет решений для этой задачи' 
              }}
            </p>
            <paragraph-size-large v-if="!isCompetition">
              Но это возможность заработать! Добавь решение для задачи и получи «денежку»
            </paragraph-size-large>
          </div>
          <div class="empty-node__actions">
            <KazButton
              v-if="isCompetition"
              @click="gotoEditor"
              appearance="accent"
              label="Добавить решение для конкурса"
            />
            <button-primary
              v-else 
              @click="gotoEditor">
              Перейти в редактор
            </button-primary>
          </div>
        </div>
      </div>

      <div
        v-if="tasksNumber && !noSulutions && tasksVariants[selectorState.activeType].length" class="task-entity"
      >
        <task-entity-2
          v-bind="getTaskProps"
          :nodeId="nodeId"
          :reportLink="{ path: '#' }"
          @change-task-state="changeTaskState"
          @over-limit="setOverLimit()"
        />
      </div>
      <popup-condition 
        :conditions="taskConditions" 
        :visible="conVisibility"
        @close="conVisibility = false"
        @change-condition-state="changeConditionState"
        @over-limit="setOverLimit()"
      />
    </div>
    <div
      v-else-if="getVariantsLoadingState === LOADING_STATES.ERROR"
      class="error-case"
    >
      <paragraph-size-large>Что-то пошло не так</paragraph-size-large>
      <img
        :src="require('@/assets/images/errors/sadcat.webp')"
        style="border-radius: 4px;"
        width="48"
        height="48"
      >
    </div>
  </card-background>

  <related-lessons
    :bookId="bookId"
    :nodeId="nodeId"
    :srcLessons="prepareRelatedLessons"
  />

  <LinkedBooks 
    v-if="showLinkedBooks"
    :bookId="bookId"
    @has-books="payload => showLinkedBooks = payload"
  />
 
  <!-- moderation chat -->
  <!-- <card-background v-if="isModerator" class="card-wrapper">
    <heading-size-h4 :textColor="'var(--text-black)'" :textWeight="600">
      Чат с пользователем по задаче
    </heading-size-h4>
    <moderation-chat></moderation-chat>
  </card-background> -->

  <!-- DEATH's WARNING: popups section -->
  <popup-edit-confirm :taskId="nodeId" />
  <popup-tarif-change
    :isVisible="popupTarifChangeVisibility"
    :options="tarifChangeOptions"
    @close="popupTarifChangeVisibility = false"
  />
  <popup-account-login
    :isVisible="popupAccountLoginVisibility"
    :targetRoute="popupAccountLoginTargetRoute"
    @close="popupAccountLoginVisibility = false"
  />

  <!-- promo banners -->
  <section class="promo-section">
    <div class="promo-section__wrapper">
      <banner
        bannerClass="banner_type_tasks"
        :headingText="'Знаешь, как решить задание лучше или оригинальнее?'"
        headingTextColor="var(--white)"
        :paragraphText="'Мы платим за решения! Зарабатывай на своих знаниях'"
        paragraphTextColor="var(--white)"
        :buttonText="'Решить задание и заработать'"
        :buttonStyle="'light10'"
        @click="gotoEditor"
      />
      <banner
        bannerClass="banner_type_gpt"
        :headingText="'Зацени КазательGPT. Бесплатно!'"
        headingTextColor="var(--white)"
        :paragraphText="'Поможет решить уравнение, напишет сочинение и объяснит сложные темы простыми словами'"
        paragraphTextColor="var(--white)"
        :buttonText="'Хочу попробовать'"
        :buttonStyle="'light10'"
        @click="gotoGPT"
      />
    </div>
  </section>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';

import CardBackground from '@/components/UI/card/CardBackground.vue';
import HeadingSizeH4 from '@/components/UI/texts/headings/HeadingSizeH4.vue';
import HeadingSizeH2 from '@/components/UI/texts/headings/HeadingSizeH2.vue';
import ParagraphSizeLarge from '@/components/UI/texts/paragraphs/ParagraphSizeLarge.vue';
import ParagraphSizeMedium from '@/components/UI/texts/paragraphs/ParagraphSizeMedium.vue';
import ParagraphSizeSmall from '@/components/UI/texts/paragraphs/ParagraphSizeSmall.vue';
import ButtonPrimary from '@/components/UI/buttons/ButtonPrimary.vue';
import ButtonSecondary from '@/components/UI/buttons/ButtonSecondary.vue';
import Banner from '@/components/UI/banner/Banner.vue';
import KazAction from '@/components/KazUI/atoms/action';
import KazBreadcrumbs from '@/components/KazUI/molecules/breadcrumbs';
import RelatedLessons from '@/views/tasks/components/RelatedLessons.vue';
import BookPagination from '@/components/UI/BookPagination.vue';
import KazBadge from '@/components/KazUI/atoms/badge';
import KazButton from '@/components/KazUI/atoms/button';

import PopupTarifChange from './components/PopupTarifChange.vue';
import PopupAccountLogin from './components/PopupAccountLogin.vue';
import PopupCondition from './components/PopupCondition.vue';
import TaskEntity2 from './components/TaskEntity2.vue';

import useLoadingStates from '@/hooks/loading/useLoadingStates.js';
import { useIntersectionObserver } from '@/hooks/browserAPI/IntersectionObserver';
import { 
  HEADER_IO_OPTIONS,
  HEADER_IO_TOGGLER
} from '@/hooks/browserAPI/IntersectionObserver';
import useSubscriptions from '@/hooks/billing/useSubscriptions.js';
import useContentTypes from '../hooks/useContentTypes.js';
import useTaskTypes from '../hooks/useTaskTypes.js';

import { TaskReaderClient } from '@/generated/taskreader/taskreader_grpc_web_pb.js';
import { fetchNode, fetchBook } from '@/services/TaskReader';
import { guessLevelId } from '@/views/tasks/utils/breadcrumbs.js';
import { createMetadataWithToken } from '@/services/utils';
import { EntitiesServiceClient } from '@/generated/entities/entities_service_grpc_web_pb.js';
import { fetchStreamData } from '@/utils/loaders';

import tabs from '@/components/KazUI/atoms/tabs'
import avatar from '@/components/KazUI/atoms/avatar'
import loader from '@/components/KazUI/atoms/loader'
import SkeletonLoader from '@/components/KazUI/skeleton'
import LinkedBooks from '@/views/generation/components/LinkedBooks.vue';

import { _arrayBufferToBase64 } from "@/utils/converter";


const SERVICE_URL = process.env.VUE_APP_REGISTRATION_SERVICE_URL;
const entitiesService = new EntitiesServiceClient(SERVICE_URL, null, null);


export default {
  name: 'task-page',

  beforeRouteEnter(to, from) {
    console.log(`trying enter to: ${to.fullPath}, from: ${from.fullPath}`);
  },

  components: {
    CardBackground,
    HeadingSizeH4,
    HeadingSizeH2,
    ParagraphSizeLarge,
    ParagraphSizeMedium,
    ParagraphSizeSmall,
    ButtonPrimary,
    ButtonSecondary,
    Banner,
    PopupTarifChange,
    PopupAccountLogin,
    PopupCondition,
    KazAction,
    TaskEntity2,
    KazBreadcrumbs,
    tabs,
    avatar,
    loader,
    RelatedLessons,
    BookPagination,
    KazBadge,
    KazButton,
    SkeletonLoader,
    LinkedBooks,
    PopupEditConfirm: defineAsyncComponent({
        loader: () => import('@/components/UI/popups/PopupEditConfirm.vue')
    }),
    ModerationChat: defineAsyncComponent({
        loader: () => import('@/components/moderation/components/ModerationChat.vue')
    }),
    TaskEntity: defineAsyncComponent({
        loader: () => import('./components/TaskEntity.vue')
    }),
    WrapperTaskEntities: defineAsyncComponent({
        loader: () => import('../components/WrapperTaskEntities.vue')
    })
  },

  setup() {
    const { LOADING_STATES } = useLoadingStates();
    const { TASK_TYPES } = useTaskTypes();
    const { CONTENT_TYPES } = useContentTypes();
    const { startObservation } = useIntersectionObserver({
      options: HEADER_IO_OPTIONS,
      callback: HEADER_IO_TOGGLER('task-header_sticky')
    });
    const { fetchAllSubscriptions, fetchActiveSubscriptions } = useSubscriptions();
    return {
      LOADING_STATES,
      TASK_TYPES,
      CONTENT_TYPES,
      startObservation,
      fetchAllSubscriptions,
      fetchActiveSubscriptions
    }
  },

  data() {
    return {
      bookId: '',
      nodeId: null,
      nodeTitle: '',
      bookTitle: '',
      isPopupEditConfirmVisible: false,
      serviceURL: process.env.VUE_APP_REGISTRATION_SERVICE_URL,
      taskReaderService: null,
      node: null,
      nodeTypeId: null,

      isVariantsLoaded: this.LOADING_STATES.INIT,
      isTaskLimitsLoaded: false,
      isBookLoaded: this.LOADING_STATES.INIT,

      selectorState: {
        activeType: this.TASK_TYPES.SOLUTION,
        activeVariant: 0
      },

      tasksNumber: 0,
      taskTypes: [],
      tasksVariants: {
        [this.TASK_TYPES.SOLUTION]: [],
        [this.TASK_TYPES.CONDITION]: [],
        [this.TASK_TYPES.VIDEO]: [],
        [this.TASK_TYPES.AUDIO]: [],
      },
      taskConditions: [],
      noSulutions: false,

      usualTaskLimit: 2,
      premiumTaskLimit: 0,

      actionList: [
        { title: 'Оценить условие задачи', link: { path: '#' } },
        { title: 'Добавить автора в избранное', link: { path: '#' } },
      ],

      popupTarifChangeVisibility: false,
      tarifChangeOptions: {},
      popupAccountLoginVisibility: false,
      popupAccountLoginTargetRoute: null,
      intersectionObserver: null,
      conVisibility: null,

      authorImage: '',
      userLogin: null,
      avatars: [],
      isAvatarLoading: false,
      shouldApplyColumnClass: false,
      relatedLessons: [],
      showLinkedBooks: false,
    }
  },

  watch: {
    $route: {
      handler() {
        this.nodeId = this.$route.params.tid;
        this.bookId = this.$route.params.bid;
        if (this.nodeId && this.$route.name === 'task') {
          this.initContent();
        }
      },
      immediate: true
    },
    getBookOverview: {
      async handler(newOverview) {
        if (newOverview) {
          try {
            await this.loadLessons(newOverview.subjectId, newOverview.levelsList);
          } catch (error) {
            console.warn(error);
          }
        }
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters({
      isModerator: 'userData/isModerator',
      getLoadingStateRestInfo: 'tasksRestInfo/getLoadingState',
      getRestTask: 'tasksRestInfo/getRestTask',
      getRestTaskPremium: 'tasksRestInfo/getRestTaskPremium',
      sessionActive: 'authData/sessionActive',
      getGrades: 'gradeData/getGrades',
      getCourses: 'courseData/getCourses',
      getGradeState: 'gradeData/getState',
      getCourseState: 'courseData/getState',
      getRelatedLessons: 'relatedLessons/getLessons',
      getNodeTypes: 'bookTree/getNodeTypes',
      isNodeTypesFetched: 'bookTree/isNodeTypesFetched',
      getBookOverview: 'bookContent/getOverview',
      getNodesList: 'bookContent/getNodes',
      getNodesTree: 'bookContent/createNodesTree',
      getNodesLoadingState: 'bookContent/getNodesLoadingState',
      getIdsToSubjects: 'coursesMapping/getIdsToSubjects',
      getIdsToLevels: 'coursesMapping/getIdsToLevels',
    }),

    getNodesByLevel() {
      var isFound = false;
      var node = null;
      function findNode(nodeId, nodes) {
        for (const n of nodes) {
          if (n.id === nodeId) {
            isFound = true;
            node = n;
            break;
          } else {
            findNode(nodeId, n.children);
            findNode(nodeId, n.content);
          }
        }
      }
      const nodes = {};
      if (this.getNodesLoadingState === this.LOADING_STATES.LOADED) {
        findNode(this.nodeId, this.getNodesTree);
        if (isFound) {
          const parentId = this.getNodesList[node.parentId]?.id || null;
          isFound = false;
          node = null;
          findNode(parentId, this.getNodesTree);
          if (isFound) {
            node?.content?.forEach(c => nodes[c.id] = c);
          }
        }
      }
      return nodes;
    },

    getVariantsLoadingState() {
      if ((this.getLoadingStateRestInfo === this.LOADING_STATES.LOADED)
        && (this.isVariantsLoaded === this.LOADING_STATES.LOADED)
        && this.isTaskLimitsLoaded
      ) {
        return this.LOADING_STATES.LOADED
      } else if ((this.getLoadingStateRestInfo === this.LOADING_STATES.ERROR)
        || (this.isVariantsLoaded === this.LOADING_STATES.ERROR)
      ) {
        return this.LOADING_STATES.ERROR
      }
      return this.LOADING_STATES.LOADING
    },

    getTaskProps() {
      const activeTaskType = this.selectorState.activeType;
      const activeVariant = this.selectorState.activeVariant;
      const activeTask = this.tasksVariants[activeTaskType][activeVariant];

      if (activeTask && activeTask.taskId) {
        let activeContentType = null;
        switch (activeTaskType) {
          case this.TASK_TYPES.CONDITION:
          case this.TASK_TYPES.SOLUTION:
            activeContentType = this.CONTENT_TYPES.QUILL;
            break;
          case this.TASK_TYPES.VIDEO:
            activeContentType = this.CONTENT_TYPES.VIDEO;
            break;
          case this.TASK_TYPES.AUDIO:
            activeContentType = this.CONTENT_TYPES.AUDIO;
            break;
          default:
            console.warn('unknown task type:', activeTaskType);
        }
        console.log(
          `Task props: task type = ${activeTaskType}; task variant = ${activeVariant}`
        );
        return {
          taskId: activeTask.taskId,
          isViewed: activeTask.isViewed,
          isPremium: activeTask.isPremium,
          contentType: activeContentType,
          taskType: activeTaskType,
          timespamp: new Date(),
          userId: activeTask
        };
      } else {
        console.warn('activeTask or activeTask.taskId is undefined');
        return {
          taskId: null,
          isViewed: false,
          isPremium: false,
          contentType: null,
          taskType: null
        };
      }
    },

    getTaskVariants() {
      return this.tasksVariants[this.selectorState.activeType]
    },

    getBreadcrumbsLinks() {
      const homeLink = { path: { name: 'tasks' }, title: 'Решения' };
      const links = [ homeLink ];

      if (
        this.isBookLoaded === this.LOADING_STATES.LOADED &&
        this.getGradeState === this.LOADING_STATES.LOADED &&
        this.getCourseState === this.LOADING_STATES.LOADED
      ) {
        const lvlId = guessLevelId(this.book.levelsList, this.book.name);
        const subjId = this.book.subjectId;
        // Level link
        if (lvlId) {
          const lvlTitle = this.getGrades.find(lvl => lvl.id === +lvlId).title;
          links.push({
            path: {
              name: 'level-books',
              params: { level: this.getIdsToLevels[lvlId] }
            },
            title: lvlTitle
          });
        }
        // Subject link
        if (subjId) {
          const subjTitle = this.getCourses.find(sub => sub.id === +subjId).title;
          links.push({
            path: {
              name: 'subject-books', 
              params: { subject: this.getIdsToSubjects[subjId] }
            },
            title: subjTitle
          });
        }
        // Book link
        links.push({
          path: { name: 'book-content', params: { bid: this.bookId } },
          title: this.book.nameOfficial
        });
      }
      // Task link
      links.push({
        path: { name: 'task', params: { bid: this.bookId, tid: this.nodeId } },
        title: `Задание ${this.nodeTitle}`
      });
      return links
    },

    prepareRelatedLessons() {
      // define initial lessons state: selected/non-selected
      return Object.values(this.getRelatedLessons || {});
    },

    getNodeTitleFull() {
      const nodePrefix = this.getNodeTypes[this.nodeTypeId]?.title || 'Упражнение';
      return `${nodePrefix} ${this.nodeTitle}`;
    },

    isCompetition() {
      // return this.getNodesList?.[this.nodeId]?.competition || false;
      return false;
    }
  },

  methods: {
    ...mapActions({
      fetchRestTasksInfo: 'tasksRestInfo/fetchRestTasksInfo',
      findUserByLogin: 'userData/findUserByLogin',
      fetchNodeTypes: 'bookTree/fetchNodeTypes',
    }),
    ...mapMutations({
      setRelatedLessons: 'relatedLessons/setLessons'
    }),

    updateTaskTitleClass(value) {
      this.shouldApplyColumnClass = value;
    },
    // updateBookTitle() {
    //   const ogTitleMeta = document.querySelector('meta[property="og:title"]');
    //   if (ogTitleMeta) {
    //     this.bookTitle = ogTitleMeta.content;
    //   }
    // },
    async fetchBook_() {
      try {
        this.isBookLoaded = this.LOADING_STATES.LOADING;
        const response = await fetchBook(this.bookId);
        this.book = response.toObject().book;
        this.isBookLoaded = this.LOADING_STATES.LOADED;
      } catch (error) {
        console.error(error);
        this.isBookLoaded = this.LOADING_STATES.ERROR;
      }
    },

    async fetchNode_() {
      try {
        this.tasks = [];
        this.isVariantsLoaded = this.LOADING_STATES.LOADING;
        if (!this.isNodeTypesFetched) {
          await this.fetchNodeTypes();
        }
        const response = await fetchNode(this.nodeId);
        this.resultHandlerNode(null, response);
      } catch (e) {
        this.isVariantsLoaded = this.LOADING_STATES.ERROR;
        this.resultHandlerNode(e);
        console.error('fetchNode:', e);
      }
    },

    resultHandlerNode(err, response) {
      if (err) {
        this.isVariantsLoaded = this.LOADING_STATES.ERROR;
        let errMsg = null;
        let isAlert = false;
        switch (err.code) {
          case 2:
            errMsg = 'Сервис недоступен\n' + err.message;
            isAlert = true;
            break;
          case 9:
            errMsg = 'Идентификатор структурного элемента не задан или не соответствует формату';
            isAlert = true;
          case 11:
            errMsg = 'Пользователь превысил лимит просмотра решений';
            isAlert = true;
            break;
          case 13:
            errMsg = 'Ошибка верификации токена';
            isAlert = false;
            this.$router.push({ name: 'login' });
            break;
          case 14:
            errMsg = 'Сервис чтения заданий недоступен\n' + err.message;
            isAlert = true;
            break;
          default:
            errMsg = `Code: ${err.code}\n message: ${err.message}`;
          //isAlert = true;
        }
        console.error(errMsg);
        if (isAlert) {
          window.alert(errMsg);
        }
      } else {
        console.log(
          '%ctasks response [editor]:',
          'background-color: #ff7d71; padding: 4px;',
          response.toObject()
        );
        
        this.tasksNumber = 0;
        const solutions = [];
        const conditions = [];
        const videos = [];
        const audios = [];

        this.node = response.getNode();
        this.nodeTitle = this.node.getTitle();
        this.nodeTypeId = this.node.getTypeId();
        const tasksByContentType = response.getTaskByContentList();
        for (const keyType in tasksByContentType) {
          const contentType = tasksByContentType[keyType];
          const tasks = contentType.getTasksList();

          const requestTask = new proto.kazatel.books.TaskCardRequest();
          const tokens = this.$getTokenInfo();
          const metadata = { 'token': tokens.accessToken.token };

          switch (contentType.getTypeId()) {
            case this.CONTENT_TYPES.IMAGE:
            case this.CONTENT_TYPES.QUILL:
              for (const keyTask in tasks) {
                requestTask.setTaskId(tasks[keyTask].getId());
                this.taskReaderService.task(requestTask, metadata, this.resultHandlerTask);
                this.avatars = [];
                solutions.push({
                  taskId: tasks[keyTask].getId(),
                  isPremium: tasks[keyTask].getPremium(),
                  isViewed: tasks[keyTask].getVisible(),
                });
                this.tasksNumber += 1;
                console.log('tasks [SOLUTION]', tasks[keyTask].toObject());
              }
              break;

            case this.CONTENT_TYPES.AUDIO:
              for (const keyTask in tasks) {
                requestTask.setTaskId(tasks[keyTask].getId());
                this.taskReaderService.task(requestTask, metadata, this.resultHandlerTask);
                this.avatars = [];
                audios.push({
                  taskId: tasks[keyTask].getId(),
                  isPremium: tasks[keyTask].getPremium(),
                  isViewed: tasks[keyTask].getVisible(),
                });
                this.tasksNumber += 1;
                console.log('tasks [AUDIO]', tasks[keyTask].toObject());
              }
              break;

            case this.CONTENT_TYPES.VIDEO:
              for (const keyTask in tasks) {
                requestTask.setTaskId(tasks[keyTask].getId());
                this.taskReaderService.task(requestTask, metadata, this.resultHandlerTask);
                this.avatars = [];
                videos.push({
                  taskId: tasks[keyTask].getId(),
                  isPremium: tasks[keyTask].getPremium(),
                  isViewed: tasks[keyTask].getVisible(),
                });
                this.tasksNumber += 1;
                console.log('tasks [VIDEO]:', tasks[keyTask].toObject());
              }
              break;
          }
        }

        const tasksForCondition = response.getTaskForConditionList();
        for (const keyTask in tasksForCondition) {
          conditions.push({
            taskId: tasksForCondition[keyTask].getId(),
            isPremium: tasksForCondition[keyTask].getPremium(),
            isViewed: tasksForCondition[keyTask].getVisible(),
          });
          this.tasksNumber += 1;
          console.log('tasks [CONDITION]', tasksForCondition[keyTask].toObject());
        }

        this.tasksVariants[this.TASK_TYPES.SOLUTION] = solutions;
        // this.tasksVariants[this.TASK_TYPES.CONDITION] = conditions;
        this.taskConditions = conditions;
        this.tasksVariants[this.TASK_TYPES.AUDIO] = audios;
        this.tasksVariants[this.TASK_TYPES.VIDEO] = videos;

        this.taskTypes = this.createTaskTypes(this.tasksVariants);

        if (solutions.length) {
          this.selectorState.activeType = this.TASK_TYPES.SOLUTION;
        } else if (videos.length) {
          this.selectorState.activeType = this.TASK_TYPES.VIDEO;
        } else if (audios.length) {
          this.selectorState.activeType = this.TASK_TYPES.AUDIO;
        } else {
          this.noSulutions = true;
        }
        
        this.isVariantsLoaded = this.LOADING_STATES.LOADED;
      }
    },

    async getUserAvatar(id) {
      this.isAvatarLoading = true;
      let login = id;

      switch (login) {
        case 'gdz-1':
        case 'gdz-2':
        case 'gdz-3':
        case 'gdz-4':
          this.authorImage = require('@/assets/KazIllustrations/avatars/var_1.png');
          this.isAvatarLoading = false;
          return this.authorImage;

        default:
          try {
            const userLookingFor = await this.$store.dispatch('userData/findUserByLogin', login);
            this.authorImage = "data:image/jpeg;base64," + _arrayBufferToBase64(userLookingFor.photo);
            this.isAvatarLoading = false;
            return this.authorImage;
          } catch (err) {
            console.log('Error in getUser:', err);
          }
      }
    },

    deriveSelectorClass(index, type = 0) {
      /*
      type = 0 corresponds to task types.
      type = 1 corresponds to task variants.
      */
      let currentIndex = this.selectorState.activeType;
      if (!this.tasksVariants[currentIndex].length) {
        return ['button__selector_base']
      }
      if (type) {
        currentIndex = this.selectorState.activeVariant;
      }
      const classes = [];
      if (currentIndex === index) {
        classes.push('button__selector_active');
      } else {
        classes.push('button__selector_base');
      }
      return classes
    },

    deriveSelectorAvatarClass(index, type = 0) {
      let currentIndex = this.selectorState.activeType;
      if (!this.tasksVariants[currentIndex].length) {
        return ['avatar_type_inactive']
      }
      if (type) {
        currentIndex = this.selectorState.activeVariant;
      }
      const classes = [];
      if (currentIndex === index) {
        classes.push('avatar_type_active');
      } else {
        classes.push('avatar_type_inactive');
      }
      return classes
    },


    changeSelectorState(newState, state = 'activeType') {
      this.getRightPhoto(newState);
      if (state in this.selectorState) {
        this.selectorState[state] = newState;
        if (state === 'activeType') {
          this.selectorState.activeVariant = 0;
        }
      } else {
        console.warn('unknow selector state:', state);
      }
    },

    changeSelectState(event) {
      this.selectorState.activeType = +event.target.value;
      this.selectorState.activeVariant = 0;
    },

    gotoEditor(event) {
      const targetRoute = { name: 'editor-task', params: { tid: this.nodeId } };
      if (this.sessionActive) {
        this.$router.push(targetRoute);
      } else {
        this.popupAccountLoginVisibility = true;
        this.popupAccountLoginTargetRoute = this.$router.resolve(targetRoute).fullPath;
      }
    },

    gotoGPT(event){
      this.$router.push({ name: 'KazGPT'});
    },

    gotoTasks(event) {
      this.$router.push({ name: 'book-content' });
    },

    scrollToElement() {
      const neededEl = this.$refs['wrapper'];
      if (neededEl?.$el) {
        // console.log('neededEl', neededEl);
        neededEl.$el.scrollIntoView({ behavior: 'smooth' });
      }
    },

    createTaskTypes(tasksVariants) {
      /* 
      Gets "existed" task types based on presense of corresponding 
      task variants.
      */
      const taskTypes = [];
      for (const [type, tasks] of Object.entries(tasksVariants)) {
        if (tasks.length) {
          let title = '';
          switch (+type) {
            case this.TASK_TYPES.CONDITION:
              title = 'условие задачи';
              break;
            case this.TASK_TYPES.SOLUTION:
              title = 'текст решения задачи';
              break;
            case this.TASK_TYPES.VIDEO:
              title = 'видео объяснение решения';
              break;
            case this.TASK_TYPES.AUDIO:
              title = 'аудио объяснение решения';
              break;
          }
          taskTypes.push({id: +type, title});
        }
      }
      return taskTypes
    },

    setOverLimit() {
      if (this.sessionActive) {
        this.tarifChangeOptions = this.getTaskProps;
        this.popupTarifChangeVisibility = true;
      } else {
        this.popupAccountLoginVisibility = true;
      }
    },

    async initContent() {
      try {
        if (!this.taskReaderService) {
          this.taskReaderService = new TaskReaderClient(this.serviceURL, null, null);
        }
        this.isVariantsLoaded = this.LOADING_STATES.INIT;
        this.isBookLoaded = this.LOADING_STATES.INIT;
        this.tasksNumber = 0;
        this.taskTypes = [];
        this.taskConditions = [];
        this.tasksVariants = {
          [this.TASK_TYPES.SOLUTION]: [],
          [this.TASK_TYPES.CONDITION]: [],
          [this.TASK_TYPES.VIDEO]: [],
          [this.TASK_TYPES.AUDIO]: [],
        };
        this.selectorState = {
          activeType: this.TASK_TYPES.SOLUTION,
          activeVariant: 0
        }
        this.noSulutions = false;
        await this.fetchNode_();
        await this.fetchBook_(); 
      } catch (error) {
        console.error('INIT CONTENT:', error);
      }
    },

    async resultHandlerTask(err, response) {
      if (err) {
        let errMsg = null;
        let isAlert = false;
        switch (err.code) {
          case 2:
            errMsg = 'Сервис недоступен\n' + err.message;
            break;
          case 6:
            errMsg = 'Пользователь с указанными данными уже зарегистрирован';
            break;
          case 11:
            errMsg = 'Пользователь превысил лимит просмотра решений';
            isAlert = true;
            break;
          case 14:
            errMsg = 'Сервис чтения заданий недоступен\n' + err.message;
            break;
          default:
            errMsg = `Code: ${err.code}\n message: ${err.message}`;
          // isAlert = true;
        }
        console.error(errMsg);
        if (isAlert) {
          window.alert(errMsg);
        }
      } else {
        const taskResponse = response.toObject();
        console.log('AVATAR RESPONSE:', taskResponse);
        const userId = taskResponse.userId;
        this.avatars.push({
          id: taskResponse.taskId,
          photo: await this.getUserAvatar(userId),
          user: userId,
        });
        return userId;
      }
    },

    getRightPhoto(ind) {
      if (this.avatars[ind]) {
        const taskId = this.getTaskVariants[ind]?.taskId;
        const avatar = this.avatars.find(a => a.id === taskId);
        if (avatar) {
          return avatar.photo;
        }
      }
    },

    changeTaskState(data) {
      const { isViewed } = data;
      const activeTaskType = this.selectorState.activeType;
      const activeVariant = this.selectorState.activeVariant;
      this.tasksVariants[activeTaskType][activeVariant].isViewed = isViewed;
    },

    changeConditionState(data) {
      const { isViewed, taskId } = data;
      const taskIndex = this.taskConditions.findIndex(task => task.taskId === taskId);
      if (taskIndex === -1) {
        console.warn(`Unable to find condition with id = ${taskId}`);
        return
      }
      this.taskConditions[taskIndex].isViewed = isViewed;
    },

    async fetchTaskLimit() {
      this.isTaskLimitsLoaded = false;
      if (!this.sessionActive) {
        this.isTaskLimitsLoaded = true;
        return
      }
      try {
        const allSubs = await this.fetchAllSubscriptions();
        const activeSubs = await this.fetchActiveSubscriptions();
        Object.keys(activeSubs).forEach(id => {
          if (allSubs.hasOwnProperty(id)) {
            this.usualTaskLimit = allSubs[id].usualSolution;
            this.premiumTaskLimit = allSubs[id].uniqueSolution;
          }
        })
      } catch (e) {
        console.error('Fetch task limits:', e)
      } finally {
        this.isTaskLimitsLoaded = true;
      }
    },

    async loadLessons(subjectId, levelsIds, typeId = 125) {
      /**
       * typeId:
       *  125 - chapters for given subject and level
       *  123 - lessons for given subject adn all levels
       */
      console.warn(`Load lessons: subject=${subjectId}, level=${levelsIds}, typeID=${typeId}`);
      if (!subjectId) return
      try {
        const request = new proto.kazatel.entities.EntitiesRequest();
        if (typeId === 125) {
          request.setLevelsList(levelsIds);
        }
        request.setTypesList([typeId]);
        request.setSubjectsList([subjectId]);
        request.setStatusList([1]);
        request.setPageSize(10);
        const metadata = createMetadataWithToken();
        const stream = entitiesService.entities(request, metadata);
        const streamData = await fetchStreamData(stream);
        let programId;
        let programRootNode;
        streamData.forEach(response => {
          programId = response.getId();
          programRootNode = response.toObject()
          console.log('Lessons Root Node:', programRootNode);
        })
        if (programId) {
          await this.fetchLessonNodes(programId, levelsIds, subjectId);
        } 
      } catch (err) {
        console.error('Loading [Lessons]:', err);
      }
    },


    async fetchLessonNodes(programId, levelsIds, subjectId) {
      try {
        if (!programId) {
          throw new Error(`Lesson node ID is empty: ${programId}`)
        }
        const metadata = createMetadataWithToken();
        const requestNodes = new proto.kazatel.entities.NodesRequest();
        requestNodes.setEntityId(programId);
        requestNodes.setLevelsList(levelsIds);
        const streamNodes = entitiesService.nodes(requestNodes, metadata);

        const lessons = {};
        let firstLessonId = null;
        const streamData = await fetchStreamData(streamNodes);

        streamData.forEach(response => {
          // console.log('GGGGGGGGGGGGG', response.toObject());
          const lessonId = response.getId();
          if (!response.getPrevId()) {
            firstLessonId = lessonId;
          }
          lessons[lessonId] = {
            id: lessonId,
            nextId: response.getNextId(),
            parentId: response.getParentId(),
            title: response.getTitle(),
            content: [],
            type: response.getType(),
            image: response.getViewUrl()
          };
        });

        /*
        // sorting doesn't work now because of prev/next ids are broken
        const sortedLessons = [];
        while (firstLessonId) {
          sortedLessons.push(lessons[firstLessonId]);
          firstLessonId = lessons[firstLessonId].nextId;
        }*/
      this.setRelatedLessons({
          subjectId: subjectId,
          levelId: levelsIds,
          lessons: lessons,
        });
        console.log('Fetch lesson nodes has been done', lessons.value);
      } catch(err) {
        // console.error('Fetch lesson nodes:', err);
        throw err
      } 
    }
  },

  async created() {
    await this.fetchRestTasksInfo();
    await this.fetchTaskLimit();
  },

  mounted() {
    this.scrollToElement();
    this.getNodesByLevel;
  },

  beforeUnmount() {
    // console.warn('task before unmount');
  },

}
</script>

<style src="@/components/UI/buttons/button.css"></style>
<style scoped>

.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
}

.task-header {
  /* position: sticky; */
  /* top: 0; */
  background-color: var(--white);
  transition: .3s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 24px;
  /* z-index: 10; */
}

.task-header_sticky {
  padding: 16px;
  box-shadow: 4px 0px 16px 0px rgba(74, 74, 74, 0.15);
  border-radius: 0px 0px 8px 8px;
}

.controls {
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* margin-top: 24px; */
}

.controls__button {
  max-width: min-content;
}

.controls__icon_condition {
  --kaz-base-base-09: var(--kaz-status-info-fill);
}

.selector-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.selector {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.selector__visibility_select {
  display: block;
}

.selector__buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

.selector__visibility_buttons {
  display: none;
}

.selector__select,
.selector__option {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.06px;
  color: #000;
}

.selector__select {
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border-style: solid;
  background-color: var(--white);
}

.button__selector {
  min-width: 44px;
  padding: 12px;
  border-radius: 8px;
  transition: background-color 0.3s ease-in-out;
}

.button__selector:disabled {
  background: var(--white);
  opacity: .3;
}

.button__selector:not(:disabled):hover {
  background: var(--red-indicator);
}

.button__selector_base {
  background: rgba(151, 152, 156, 0.10);
}

.button__selector_active {
  background: rgba(131, 50, 42, 0.10);
}

.button__text {
  color: var(--text-secondary) !important;
  white-space: nowrap;
  transition: color 0.3s ease-in-out;
}

.button__selector_active > .button__text {
  color: var(--background-brown) !important;
}

.button__selector:hover > .button__text {
  color: var(--white) !important;
}

.button__selector:disabled > .button__text {
  color: var(--text-secondary) !important;
}
.task__button-controls{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.controls__variants{
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.empty-node {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 32px;
}

.empty-node__image {
  width: 780px;
  aspect-ratio: 39 / 15;
  background-image: url('@/assets/images/tasks/no-tasks.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.empty-node__message {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.empty-node__actions {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
  width: 100%;
}

.buttons__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.card-wrapper {
  margin-top: 16px;
}

.nav__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.variants__wrapper {
  display: flex;
  flex-direction: row;
  gap: 4px;
  overflow: auto;
  white-space: nowrap;
  padding: 8px 0px;
  max-width: 50%;
}

.variants__wrapper::-webkit-scrollbar {
  height: 10px;
  background-color: #ADDAFF;
  border-radius: 4px;
}

.variants__wrapper::-webkit-scrollbar-thumb {
  background-color: var(--accent);
  border-radius: 4px;
}

.variants__wrapper::-webkit-scrollbar-thumb:hover {
  background-color: var(--accent-hover);
}

.promo-section__wrapper{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.task-title,
.task-title__main,
.task-title__aside {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.task-title {
  justify-content: space-between;
  position: relative;
}

.column{
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.task-title__icon_premium {
  width: 24px;
  height: 24px;
  padding: 4px;
  border-radius: 4px;
  background: var(--yellow);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'%3E%3Cg clip-path='url(%23a)' stroke='%23fff' stroke-width='1.2'%3E%3Cpath d='m14.409 9.044.153-1.624c.12-1.275.18-1.913-.039-2.176a.661.661 0 0 0-.45-.245c-.317-.028-.715.426-1.512 1.332-.412.47-.618.704-.847.74a.615.615 0 0 1-.375-.06c-.213-.106-.353-.396-.636-.976L9.21 2.98c-.535-1.095-.802-1.643-1.209-1.643-.406 0-.674.548-1.208 1.643L5.302 6.035c-.282.58-.424.87-.636.976a.617.617 0 0 1-.375.06c-.23-.036-.435-.27-.847-.74C2.647 5.425 2.249 4.971 1.932 5a.661.661 0 0 0-.45.245c-.218.263-.158.901-.038 2.176l.152 1.624c.252 2.675.378 4.013 1.167 4.819.787.806 1.969.806 4.333.806h1.812c2.364 0 3.545 0 4.334-.806.788-.807.914-2.144 1.166-4.82Z'/%3E%3Cpath d='M6 12h4' stroke-linecap='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h16v16H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 4px 0px 16px 0px var(--shadow-pattern);
}

.task-title__main,
.task-title__aside {
  gap: 8px;
}

.task-title__limit {
  padding: 4px 4px 3px 4px;
  border-radius: 4px;
  box-shadow: 4px 0px 16px 0px var(--shadow-pattern);
}

.task-title__limit_premium {
  background: var(--yellow);
}

.task-title__limit_usual {
  background: var(--text-light);
}

.tooltip{
  padding: 8px;
  background-color: var(--white);
  opacity: 0;
  box-shadow: 4px 0px 16px 0px rgba(74, 74, 74, 0.15);
  border-radius: 8px;
  position: absolute;
  left: 52px;
  top: 32px;
  max-width: 232px;
  /* margin-top: 92px; */
  transition: .3s ease-in-out;
  pointer-events: none;
}

.button_type_question:hover + .tooltip{
  opacity: 1;
}

.error-case {
  display: flex;
  align-items: center;
  gap: 8px
}

.avatar-variants__wrapper{
  display: flex;
  flex-direction: row;
  gap: 8px;
}

@media screen and (max-width: 992px) {
  .promo-section__wrapper{
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .promo-section__wrapper p:last-of-type{
    display: none;
  }
  .promo-section__wrapper p:last-of-type{
    padding: 0px 16px;
  }
  .empty-node h2, p{
    text-align: center;
  }
  .empty-node__image{
    width: 100%;
  }
  .empty-node__actions{
    justify-content: center;
  }
  .task__button-controls{
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 24px;
  }
}

@media screen and (min-width: 992px) {
  .selector__visibility_buttons {
    display: flex;
  }
  .selector__visibility_select {
    display: none;
  }
  .selector-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between
  }
  .selector-wrapper_direction_usual {
    flex-direction: row;
  }
  .selector-wrapper_direction_inverse {
    flex-direction: row-reverse;
  }
}

@media screen and (max-width: 568px) {
  .task-title{
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
  }
}
</style>