function handleErrorLogin(errorResponse) {
  let errorMessage = '';
  switch (errorResponse.code) {
    case 6:
      errorMessage = 'Пользователь с указанными данными уже зарегистрирован';
      break;
    case 9:
      errorMessage = 'Пользователь с указанными данными не найден.';
      break;
    case 13:
      errorMessage = 'Неправильная пара логин/пароль';
      break;
    case 14:
      errorMessage = `Сервис недоступен: ${err.message}`
      break;
    default:
      errorMessage = `Error code: ${errorResponse.code}. `;
      errorMessage += errorResponse.message;
  }
  return errorMessage
}

function handleErrorRegister(errorResponse) {
  let errorMessage = '';
  switch (errorResponse.code) {
    case 6:
      errorMessage = 'Пользователь с указанными данными уже зарегистрирован';
      break;
    case 14:
      errorMessage = 'Сервис регистрации недоступен';
      break;
    default:
      errorMessage = `Код ошибки: ${errorResponse.code}. `;
      errorMessage += errorResponse.message;
  }
  console.log('Internal auth error:', errorResponse.message, errorResponse.metadata);
  return errorMessage
}

function handleErrorConfirmation(errorResponse) {
  let errorMessage = '';
  switch (errorResponse?.code) {
    case 5:
      errorMessage = 'Введенный код не совпадает с отправленным';
      break;
    default:
      errorMessage = `Код ошибки: ${errorResponse?.code}. `;
      errorMessage += errorResponse?.message;
  }
  return errorMessage
}

function handleErrorPasswordSetup(errorResponse) {
  let errorMessage = '';
  switch (errorResponse.code) {
    case 14:
      errorMessage = 'Сервис недоступен';
      break;
    case 16:
      errorMessage = 'Метаданные не содержат токен';
      break;
    default:
      errorMessage = `Код ошибки: ${errorResponse.code}. `;
      errorMessage += errorResponse.message;
  }
  return errorMessage
}

function handleErrorAccessRestore(errorResponse) {
  let errorMessage = '';
  switch (errorResponse.code) {
    case 14:
      errorMessage = 'Сервис недоступен ' + errorResponse.message;
      break;
    default:
      errorMessage = `Код ошибки: ${errorResponse.code}. `;
      errorMessage += errorResponse.message;
  }
  return errorMessage
}


export {
  handleErrorLogin,
  handleErrorRegister,
  handleErrorConfirmation,
  handleErrorPasswordSetup,
  handleErrorAccessRestore,
}