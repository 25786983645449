import { createStore } from 'vuex'

import { gradeData } from '@/store/gradeData'
import { courseData } from '@/store/courseData'
import { paragraphData } from '@/store/paragraphData'
import { bookData } from '@/store/bookData'
import { bookContent } from '@/store/bookContent'
import { userData } from '@/store/userData'
import { quillData } from '@/store/quillData'
import { tasksRestInfo } from '@/store/tasksRestInfo'
import { multistepUpload } from '@/store/multistepUpload'
import { popupAuth } from '@/store/popupAuth'
import { monetizationReports } from '@/store/monetizationReports'
import { editorIntro } from '@/store/editorIntro'
import { authData } from '@/store/authData'
import { taskHomeChanger } from '@/store/taskHomeChanger'
import { lessonsData } from '@/store/lessonsData'
import { lessonsRouter, lessonsFilter } from '@/store/modules/lessons';
import { moderationFilters } from '@/store/moderationFilters';
import { nodeInsertionDialog, bookTree, relatedLessons, nodeSelection } from '@/store/modules/book-structure';
import { coursesMapping } from '@/store/modules/route-mappers';


export default createStore({
  modules: {
    gradeData: gradeData,
    courseData: courseData,
    coursesMapping: coursesMapping,
    paragraphData: paragraphData,
    bookData: bookData,
    bookContent: bookContent,
    userData: userData,
    quillData: quillData,
    tasksRestInfo: tasksRestInfo,
    multistepUpload: multistepUpload,
    popupAuth: popupAuth,
    monetizationReports: monetizationReports,
    editorIntro: editorIntro,
    authData: authData,
    taskHomeChanger: taskHomeChanger,
    lessonsData: lessonsData,
    lessonsRouter: lessonsRouter,
    lessonsFilter: lessonsFilter,
    moderationFilters: moderationFilters,
    nodeInsertionDialog: nodeInsertionDialog,
    bookTree: bookTree,
    relatedLessons: relatedLessons,
    nodeSelection: nodeSelection,
  }
})