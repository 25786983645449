<template>
  <component :is="layout" v-if="loginCompleted">
    <router-view/>
  </component>
  <PopupRegistration/>
</template>

<script>
import { mapActions } from 'vuex';

import MainLayout from '@/layouts/MainLayout.vue';
import LandingLayout from '@/layouts/LandingLayout.vue';
import AuthLayout from '@/layouts/AuthLayout.vue';
import ProfileLayout from '@/layouts/ProfileLayout.vue';
import TasksLayout from '@/layouts/TasksLayout.vue';
import MonetizationLayout from '@/layouts/MonetizationLayout.vue';
import ModerationLayout from '@/layouts/ModerationLayout.vue';
import GPTLayout from '@/layouts/GPTLayout.vue'
import DocsLayout from '@/layouts/DocsLayout.vue'
import PopupRegistration from '@/components/UI/popups/PopupRegistration.vue';


export default {
  data() {
    return {
      stylesLoaded: false,
      loginCompleted: false
    };
  },

  components: {
    MainLayout,
    AuthLayout,
    LandingLayout,
    ProfileLayout,
    TasksLayout,
    MonetizationLayout,
    PopupRegistration,
    ModerationLayout,
    GPTLayout,
    DocsLayout,
  },

  computed: {
    layout() {
      // this available only after mounted hook - this is bug source
      // return (this.$route.meta.layout || 'landing') + '-layout'
      let layout = this.$route.meta.layout;
      if (layout === 'tasks' || layout === 'profile') {
        layout = 'main';
      }
      return (layout || 'landing') + '-layout'
    }
  },

  methods: {
    ...mapActions({
      initLogin: 'authData/initLogin',
      fetchCoursesMapping: 'coursesMapping/fetchMapping',
    }),
    async loadStyles() {
      await import('bootstrap/dist/css/bootstrap.min.css');
      await import('@/assets/reset.css');
      await import('@/assets/style.css');
      await import('@/assets/variables.css');
      this.stylesLoaded = true;
    }
  },

  async created() {
    this.loadStyles();
    try {
      await this.initLogin();
    } catch (error) {
      console.warn(error);
      this.$router.push({ name: 'login' });
    }
    try {
      await this.fetchCoursesMapping();
    } catch (error) {
      console.error('LOADING [COURSE-MAPPING]:', error);
    }
    this.loginCompleted = true;
  }
}
</script>

<style lang="scss">
/* @import '~bootstrap/dist/css/bootstrap.min.css';
@import '@/assets/reset.css';
@import '@/assets/style.css';
@import '@/assets/variables.css'; */
</style>
