const MODERATION_STATUS = Object.freeze({
  DRAFT: 1,         // Черновик
  NEW: 2,           // Новое для модерации
  EXAMINATION: 3,   // Проверка модератором
  CORRECTION: 4,    // Работа над замечаниями
  PUBLISH: 5,       // Опубликовано
  REJECT: 6,        // Отклонено
});

const ACTION_TYPE = Object.freeze({
  NONE: 0,
  PUBLISH: 1,
  DRAFT: 2,
  DELETE: 3,
});


export {
  MODERATION_STATUS,
  ACTION_TYPE,
}