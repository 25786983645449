const routes = [
  {
    path: '/cooperation/:option',
    name: 'Обратная связь',
    meta: { layout: "profile", auth: false },
    component: () => import('@/views/cooperation/Cooperation.vue')
  },
]

export default routes
