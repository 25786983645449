export const nodeSelection = {
  namespaced: true,

  state: () => ({
    selectedNode: {
      parentId: null,
      id: null,
      nodeName: '',
      nodeType: [],
      nodePage: '',
      order: -1,
      isTask: false,
    }
  }),

  mutations: {
    setSelectedNode(state, node) {
      state.selectedNode = node;
    },
    resetSelectedNode(state) {
      state.selectedNode = {
        parentId: null,
        id: null,
        nodeName: '',
        nodeType: [],
        nodePage: '',
        order: -1,
        isTask: false,
      };
    }
  },

  actions: {
    selectNode({ commit }, node) {
      commit('setSelectedNode', node);
    },
    resetNodeSelection({ commit }) {
      commit('resetSelectedNode');
    }
  },

  getters: {
    getSelectedNode: (state) => state.selectedNode
  }
};
