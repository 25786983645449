const swapObjectEntries = obj => {
  /**
   * Convert input object in swapped key value one: { key: val } --> { val: key }
   */
  return Object
    .keys(obj)
    .reduce(
      (acc, key) => ({ ...acc, [obj[key]]: key }),
      {}
    );
}


export {
  swapObjectEntries,
}