const routes = [
  {
    path: "/subscriptions",
    name: "subscriptions",
    meta: { layout: "profile", auth: false },
    component: () => import("@/views/subscriptions"),
  },
];

export default routes;
