<template>
<div class="navigation">
  <nav class="pagination">
    <ul class="pagination__list" :class="{ 'pagination__list_row': itemsInColumn }">
      <li v-for="(task, index) in getNeighborTasks" :key="task.id">
        <router-link 
          class="pagination__link" 
          :to="{name: 'task', params: {bid: bookId, tid: task.id} }"
          @click="handleNodeClick(task)"
        >
          <Button
            :label="task.title"
            :appearance="'outline'"
            :size="'M'"
            :rightIcon='task.id === nodesList[currentNodeId].nextId'
            :leftIcon='task.id === nodesList[currentNodeId].prevId'
            :rightIconName="'kuiIconArrowRightLarge'"
            :leftIconName="'kuiIconArrowLeftLarge'"
            :title="task.title"
          />
        </router-link>
      </li>
    </ul>
  </nav>
</div>
</template>

<script>
import Button from '@/components/KazUI/atoms/button'

export default {
  name: 'book-pagination',

  components:{
    Button
},

  props: {
    currentNodeId: {
      type: [String, Number],
      required: true,
    },
    nodesList: {
      type: Object,
      required: true,
    },
    bookId: {
      types: [String, Number],
      required: true,
    },
    isNodesLoading: {
      type: Boolean,
      required: true,
    }
  },

  methods: {
    emitColumnClass() {
      this.$emit('updateColumnClass', this.itemsInColumn);
    },

    handleNodeClick(task) {
      this.$emit('currentNodeChange', task);
      this.emitColumnClass();
    },
  },

  computed: {
    getUrl() {
      return `/books/${this.bookId}/resh/${this.data.taskId}`
    },

    getNeighborTasks() {
      const nodes = [];
      if (this.isNodesLoading) {
        return nodes
      }

      try {
        let prevNode = this.nodesList[this.currentNodeId].prevId;
        let nextNode = this.nodesList[this.currentNodeId].nextId;
        if(this.nodesList[prevNode]) {
          nodes.push({
            title: this.nodesList[prevNode].title,
            id: this.nodesList[prevNode].id,
          });
        }
        if(this.nodesList[nextNode]) {
          nodes.push({
            title: this.nodesList[nextNode].title,
            id: this.nodesList[nextNode].id,
          });
        } 
      } catch (error) {
        console.warn(error);
      } finally {
        return nodes;
      }
    },

    itemsInColumn() {
      const tasks = this.getNeighborTasks;
      if (tasks.length > 0) {
        return tasks[0].title.length > 10;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.navigation {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.pagination__list {
  display: flex;
  list-style: none;
  gap: 24px;
  flex-wrap: wrap;
}

.pagination__list_row{
  flex-direction: row;
  gap: 16px;
}

.pagination__link {
  text-decoration: none;
}

@media screen and (max-width: 992px) {
  .pagination__list {
    gap: 8px;
  }
}
</style>
