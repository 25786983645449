<template>
  <div v-if="getVariantsLoadingState === LOADING_STATES.LOADED" class="content-wrapper">
    <div class="task-header">
      <div class="task-title">
        <div class="task-title__main">
          <span v-if="getTaskProps.isPremium" class="task-title__icon_premium"></span>
          <!-- <heading-size-h2>{{ nodeTitle }}</heading-size-h2> -->
          <h1 class="heading heading_size_h2">{{ nodeTitle }}</h1>
        </div>
        <!-- <div class="task-title__aside">
          <span class="task-title__limit task-title__limit_premium">
            <paragraph-size-medium textColor="var(--white)">
              {{ getRestTaskPremium || 0 }}/{{ premiumTaskLimit }}
            </paragraph-size-medium>
          </span>
          <span class="task-title__limit task-title__limit_usual">
            <paragraph-size-medium textColor="var(--white)">
              {{ getRestTask || 0 }}/{{ usualTaskLimit }}
            </paragraph-size-medium>
          </span>
          <button class="button button_type_question">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z" stroke="#97989C" stroke-width="1.5"/><path d="M10.125 8.875a1.874 1.874 0 1 1 2.828 1.615c-.475.28-.953.708-.953 1.26V13" stroke="#97989C" stroke-width="1.5" stroke-linecap="round"/><path d="M12 17a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" fill="#97989C"/>
            </svg>
          </button>
          <div class="tooltip">
            <paragraph-size-small>Это счетчик твоих доступных просмотров. Улучши подписку, чтобы увеличить лимит</paragraph-size-small>
          </div>
        </div> -->
      </div>

      <nav class="lesson-nav">
        <router-link
          v-if="getPrevLessonId"
          class="lesson-nav__link" 
          :to="createNavigationRoute(getPrevLessonId)"
        >
          <Button
            :size="'M'"
            :label="'предыдущий урок'"
            :appearance="'flat'"
            :leftIcon="true"
            :leftIconName="'kuiIconArrowLeftLarge'"
          />
        </router-link>
        <router-link
          v-if="getNextLessonId" 
          class="lesson-nav__link" 
          :to="createNavigationRoute(getNextLessonId)"
        >
          <Button
            :size="'M'"
            :label="'следующий урок'"
            :appearance="'flat'"
            :rightIcon="true"
            :rightIconName="'kuiIconArrowRightLarge'"
          />
        </router-link>
      </nav>

      <div v-if="tasksNumber && !noSulutions" class="controls">
        <tabs
          :tabs="taskTypes"
          :initIndex="taskTypes.findIndex(t => t.id === selectorState.activeType)"
          @input="tabInd => changeSelectorStateButton(taskTypes[tabInd].id, 'activeType')"
          size="M"
        />

        <div v-if="tasksVariants[selectorState.activeType].length" class="selector">
          <paragraph-size-small textWeight="600">вариант автора</paragraph-size-small>
          <div class="avatar-variants__wrapper">
            <loader v-if="isAvatarLoading" :size="'M'"/>
            <avatar
              v-else
              v-for="(task, ind) in getTaskVariants"
              :class="{'avatar_type_active': isActive, 'avatar_type_inactive': !isActive}"
              :key="task.taskId"
              :size="'S'"
              :avatarImage="task.authorAvatar"
              @click="changeSelectorStateSelect('variant')"
            />
          </div>
        </div>
      </div>

      <div v-if="!tasksNumber || noSulutions" class="empty-node">
        <div class="empty-node__image"></div>
        <div class="empty-node__message">
          <heading-size-h2>У нас нет решений для этой задачи</heading-size-h2>
          <paragraph-size-large>
            Но это возможность заработать! Добавь решение для задачи и получи «денежку»
          </paragraph-size-large>
        </div>
        <div class="empty-node__actions">
          <button-primary @click="gotoEditor">Перейти в редактор</button-primary>
          <!-- <button-primary @click="gotoTasks">Вернуться к списку заданий</button-primary> -->
        </div>
      </div>
    </div>

    <div v-if="tasksNumber && tasksVariants[selectorState.activeType].length" class="task-entity">
      <task-entity-2
        v-bind="getTaskProps"
        :nodeId="nodeId"
        :reportLink="{ path: '#' }"
        @change-task-state="changeTaskState"
        @over-limit="setOverLimit"
      />
    </div>
  </div>
  <loader v-else-if="getVariantsLoadingState === LOADING_STATES.LOADING" size="L"/>
  <div v-else-if="getVariantsLoadingState === LOADING_STATES.ERROR">
    <paragraph-size-large>
      {{ errorText || 'Что-то пошло не так' }}
    </paragraph-size-large>
  </div>

  <!-- moderation chat -->
  <!-- <card-background v-if="isModerator" class="card-wrapper">
    <heading-size-h4 :textColor="'var(--text-black)'" :textWeight="600">
      Чат с пользователем по задаче
    </heading-size-h4>
    <moderation-chat></moderation-chat>
  </card-background> -->

  <!-- DEATH's WARNING: popups section -->
  <popup-edit-confirm :taskId="nodeId" />
  <popup-tarif-change
    :isVisible="popupTarifChangeVisibility"
    :options="tarifChangeOptions"
    @close="popupTarifChangeVisibility = false"
  />
  <popup-account-login
    :isVisible="popupAccountLoginVisibility" 
    @close="popupAccountLoginVisibility = false"
  />
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';

import CardBackground from '@/components/UI/card/CardBackground.vue';
import HeadingSizeH4 from '@/components/UI/texts/headings/HeadingSizeH4.vue';
import HeadingSizeH2 from '@/components/UI/texts/headings/HeadingSizeH2.vue';
import ParagraphSizeLarge from '@/components/UI/texts/paragraphs/ParagraphSizeLarge.vue';
import ParagraphSizeMedium from '@/components/UI/texts/paragraphs/ParagraphSizeMedium.vue';
import ParagraphSizeSmall from '@/components/UI/texts/paragraphs/ParagraphSizeSmall.vue';
import ButtonPrimary from '@/components/UI/buttons/ButtonPrimary.vue';
import ButtonSecondary from '@/components/UI/buttons/ButtonSecondary.vue';

import Avatar from '@/components/KazUI/atoms/avatar'
import Loader from '@/components/KazUI/atoms/loader'
import Tabs from '@/components/KazUI/atoms/tabs'
import Button from '@/components/KazUI/atoms/button'


import PopupTarifChange from '@/views/tasks/task/components/PopupTarifChange.vue';
import PopupAccountLogin from '@/views/tasks/task/components/PopupAccountLogin.vue';
import TaskEntity2 from '@/views/tasks/task/components/TaskEntity2.vue';

import useLoadingStates from '@/hooks/loading/useLoadingStates.js';
/* import { useIntersectionObserver } from '@/hooks/browserAPI/IntersectionObserver';
import { 
  HEADER_IO_OPTIONS,
  HEADER_IO_TOGGLER
} from '@/hooks/browserAPI/IntersectionObserver'; */
import useSubscriptions from '@/hooks/billing/useSubscriptions.js';

import useContentTypes from '@/views/tasks/hooks/useContentTypes.js';
import useTaskTypes from '@/views/tasks/hooks/useTaskTypes.js';
import useVariantsChanger from '@/hooks/lessons/useVariantsChanger.js';
import useNodesList from '@/hooks/lessons/useNodesList.js';

import { EntitiesServiceClient } from '@/generated/entities/entities_service_grpc_web_pb.js';

import { _arrayBufferToBase64 } from "@/utils/converter";


const LESSONS_TASK_LINK = { title: '', path: '#', order: 1 };


export default {
  name: 'lesson-task',

  beforeRouteEnter(to, from) {
    console.log(`trying enter to: ${to.fullPath}, from: ${from.fullPath}`);
  },

  components: {
    CardBackground,
    HeadingSizeH4,
    HeadingSizeH2,
    ParagraphSizeLarge,
    ParagraphSizeMedium,
    ParagraphSizeSmall,
    ButtonPrimary,
    ButtonSecondary,
    PopupTarifChange,
    PopupAccountLogin,
    TaskEntity2,
    Tabs,
    Button,
    Avatar,
    Loader,
    PopupEditConfirm: defineAsyncComponent({
        loader: () => import('@/components/UI/popups/PopupEditConfirm.vue')
    }),
    ModerationChat: defineAsyncComponent({
        loader: () => import('@/components/moderation/components/ModerationChat.vue')
    }),
    WrapperTaskEntities: defineAsyncComponent({
        loader: () => import('@/views/tasks/components/WrapperTaskEntities.vue')
    })
  },

  props: {
    bookId: {
      type: String,
      default: null,
    },
  },

  setup() {
    const { LOADING_STATES } = useLoadingStates();
    const { TASK_TYPES } = useTaskTypes();
    const { CONTENT_TYPES } = useContentTypes();

    /*const { startObservation } = useIntersectionObserver({
      options: HEADER_IO_OPTIONS,
      callback: HEADER_IO_TOGGLER('task-header_sticky')
    });*/

    const { fetchAllSubscriptions, fetchActiveSubscriptions } = useSubscriptions();

    const { tasksVariants, initTaskVariants } = useVariantsChanger(TASK_TYPES);

    const { getSortedNodes, fetchLessonNodes, fetchNode } = useNodesList();

    return {
      LOADING_STATES,
      TASK_TYPES,
      CONTENT_TYPES,
      // startObservation,
      fetchAllSubscriptions,
      fetchActiveSubscriptions,
      tasksVariants, initTaskVariants,
      getSortedNodes, fetchLessonNodes, fetchNode,
    }
  },

  data() {
    return {
      nodeId: null,
      nodeTitle: '',
      bookTitle: '',
      isPopupEditConfirmVisible: false,
      serviceURL: process.env.VUE_APP_REGISTRATION_SERVICE_URL,
      entitiesService: null,
      node: null,

      isVariantsLoaded: this.LOADING_STATES.INIT,
      isTaskLimitsLoaded: false,

      // active variant is index of corresponding array
      selectorState: {
        activeType: this.TASK_TYPES.SOLUTION,
        activeVariant: 0
      },

      tasksNumber: 0,
      taskTypes: [],  // like VIDEO, LESSON, AUDIO, and their names
      noSulutions: false,

      usualTaskLimit: 2,
      premiumTaskLimit: 0,

      actionList: [
        { title: 'Оценить условие задачи', link: { path: '#' } },
        { title: 'Добавить автора в избранное', link: { path: '#' } },
      ],

      popupTarifChangeVisibility: false,
      tarifChangeOptions: {},
      popupAccountLoginVisibility: false,
      intersectionObserver: null,

      isActive: true,
      isAvatarLoading: false,
      authorImage: '',

      linkIndex: null,
      errorText: '',
    }
  },

  watch: {
    $route() {
      if (this.$route.name !== 'lesson-task') {
        return;
      }
      this.nodeId = this.$route.params.tid;
      if (this.nodeId) {
        this.initContent();
      }
    },
    loadingStates: {
      handler(newStates) {
        if (newStates.lessons === this.LOADING_STATES.LOADED) {
          // this.setMetaInfo();
        }
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters({
      isModerator: 'userData/isModerator',
      getLoadingStateRestInfo: 'tasksRestInfo/getLoadingState',
      getRestTask: 'tasksRestInfo/getRestTask',
      getRestTaskPremium: 'tasksRestInfo/getRestTaskPremium',
      sessionActive: 'authData/sessionActive',
      getLessons: 'lessonsData/getLessons',
      getFilterState: 'lessonsFilter/getCurrentState',
    }),

    loadingStates() {
      return {
        lessons: this.isVariantsLoaded,
        nodeId: this.nodeId,
      }
    },

    getVariantsLoadingState() {
      if ((this.getLoadingStateRestInfo === this.LOADING_STATES.LOADED)
        && (this.isVariantsLoaded === this.LOADING_STATES.LOADED)
        && this.isTaskLimitsLoaded
      ) {
        return this.LOADING_STATES.LOADED
      } else if ((this.getLoadingStateRestInfo === this.LOADING_STATES.ERROR)
        || (this.isVariantsLoaded === this.LOADING_STATES.ERROR)
      ) {
        return this.LOADING_STATES.ERROR
      }
      return this.LOADING_STATES.LOADING
    },

    getTaskProps() {
      const activeTaskType = this.selectorState.activeType;
      const activeVariant = this.selectorState.activeVariant;
      const activeTask = this.tasksVariants[activeTaskType][activeVariant];

      if (activeTask && activeTask.taskId) {
        let activeContentType = null;
        switch (activeTaskType) {
          case this.TASK_TYPES.CONDITION:
          case this.TASK_TYPES.SOLUTION:
            activeContentType = this.CONTENT_TYPES.QUILL;
            break;
          case this.TASK_TYPES.VIDEO:
            activeContentType = this.CONTENT_TYPES.VIDEO;
            break;
          case this.TASK_TYPES.AUDIO:
            activeContentType = this.CONTENT_TYPES.AUDIO;
            break;
          case this.TASK_TYPES.LESSON:
            activeContentType = this.CONTENT_TYPES.HTML;
            break;
          default:
            console.warn('unknown task type:', activeTaskType);
        }
        console.warn('get task ID:', activeTask.taskId, 'task type:', activeTaskType)
        return {
          taskId: activeTask.taskId,
          isViewed: activeTask.isViewed,
          isPremium: activeTask.isPremium,
          contentType: activeContentType,
          taskType: this.TASK_TYPES.LESSON,
          timespamp: new Date()
        };
      } else {
        console.warn('activeTask or activeTask.taskId is undefined');
        return {
          taskId: null,
          isViewed: false,
          isPremium: false,
          contentType: null,
          taskType: null
        };
      }
    },

    getTaskVariants() {
      // console.warn('aaaaaaaahahahahhaaaaaaaaaaaaaaaaa', this.tasksVariants[this.selectorState.activeType])
      return this.tasksVariants[this.selectorState.activeType]
    },

    getNextLessonId() {
      const currentLessonInd = this.getLessons?.findIndex(l => l.id === this.nodeId);
      if (
        currentLessonInd === undefined || 
        currentLessonInd === -1 || 
        currentLessonInd === this.getLessons.length - 1
      ) {
        return null
      }
      return this.getLessons[currentLessonInd + 1].id
    },

    getPrevLessonId() {
      const currentLessonInd = this.getLessons?.findIndex(l => l.id === this.nodeId);
      if (currentLessonInd === undefined || currentLessonInd <= 0) {
        return null
      }
      return this.getLessons[currentLessonInd - 1].id
    }
  },

  methods: {
    ...mapActions({
      findUserByLogin: 'userData/findUserByLogin',
      fetchRestTasksInfo: 'tasksRestInfo/fetchRestTasksInfo',
    }),
    ...mapMutations({
      setLessons: 'lessonsData/setLessons',
      addLinkLessons: 'lessonsRouter/addLink',
      removeLinkLessons: 'lessonsRouter/removeLink',
      changeLinkState: 'lessonsRouter/changeLinkState',
      changeLinkContent: 'lessonsRouter/changeLinkContent',
      setFilterState: 'lessonsFilter/setCurrentState',
    }),

    // async getUserAvatar() {
    //   this.isAvatarLoading = true;
    //   let loginEdu = 'edu'
    //   try {
    //     const userLookingFor = await this.$store.dispatch('userData/findUserByLogin', loginEdu)
    //     if (userLookingFor && userLookingFor.photo) {
    //       const authorImage = "data:image/jpeg;base64," + _arrayBufferToBase64(userLookingFor.photo);
    //       Object.values(this.tasksVariants[this.selectorState.activeType]).forEach(task => {
    //         task.authorAvatar = authorImage;
    //       });
    //     } else {
    //       console.log('User photo not found');
    //     }
    //   } catch (err) {
    //     console.log('Error in getUser:', err);
    //   } finally {
    //     this.isAvatarLoading = false;
    //   }
    // },


    // I promise that I will come back to refactor and make this function working
    async getUserAvatar() {
      this.isAvatarLoading = true;
      let login = 'edu';
      try {
        const userLookingFor = await this.$store.dispatch('userData/findUserByLogin', login)
        this.authorImage = "data:image/jpeg;base64," + _arrayBufferToBase64(userLookingFor.photo);
        this.isAvatarLoading = false;
      } catch (err) {
        console.log('Error in getUser:', err);
      }
    },

    loadAuthorAvatars() {
      for (const tasks of Object.values(this.tasksVariants)) {
        tasks.forEach(async (t) => {
          try {
            const userData = await this.findUserByLogin(t.author);
            t.authorAvatar = "data:image/jpeg;base64," + _arrayBufferToBase64(userData.photo);
          } catch (error) {
            t.authorAvatar = require('@/assets/KazIllustrations/avatars/var_1.png');
          }
        });
      }
    },

    toggleActive() {
      // this.isActive = !this.isActive;
    },

    async startFetchNode() {
      try {
        this.tasks = [];
        this.isVariantsLoaded = this.LOADING_STATES.LOADING;
        this.changeLinkState({
          linkIndex: this.linkIndex, 
          newState: this.LOADING_STATES.LOADING
        });

        const response = await this.fetchNode(this.nodeId, { 
          request: { setTasksContent: false } 
        });

        if (!this.getLessons) {
          const level = this.$route.query.lv || null;
          const rootId = response.getNode().getParentId();
          await this.fetchLessonNodes(rootId, level);
          // console.log('Node Info:', this.getSortedNodes);
          this.setLessons(this.getSortedNodes);
        }

        this.resultHandlerNode(null, response);
        // this.isVariantsLoaded = this.LOADING_STATES.LOADED;
      } catch (e) {
        // console.error('Fetch lesson task:', e);
        this.resultHandlerNode(e, null);
      }
    },

    resultHandlerNode(err, response) {
      if (err) {
        let errMsg = null;
        switch (err.code) {
          case 2:
            errMsg = 'Сервис недоступен\n' + err.message;
            break;
          case 9:
            errMsg = 'Идентификатор структурного элемента не задан или не соответствует формату';
          case 11:
            errMsg = 'Пользователь превысил лимит просмотра решений';
            break;
          case 13:
            errMsg = 'Ошибка верификации токена';
            break;
          case 14:
            errMsg = 'Сервис чтения заданий недоступен\n' + err.message;
            break;
          default:
            errMsg = `Code: ${err.code}\n message: ${err.message}`;
        }
        this.errorText = errMsg;
        console.error('Lesson handler:', errMsg);
        this.changeLinkState({
          linkIndex: this.linkIndex, 
          newState: this.LOADING_STATES.ERROR
        });
        this.isVariantsLoaded = this.LOADING_STATES.ERROR;
      } else {
        this.tasksNumber = 0;
        const videos = [];
        const audios = [];
        const lessons = [];

        const node = response.getNode();
        this.nodeTitle = node.getTitle();

        this.changeLinkContent({ linkIndex: this.linkIndex, title: this.nodeTitle });
        this.changeLinkState({
          linkIndex: this.linkIndex, 
          newState: this.LOADING_STATES.LOADED
        });

        const types = response.getTypesList();
        types.forEach(typeItem => {
          const contentType = typeItem.getType();
          if (contentType === 4) {
            // 4 - lesson
            typeItem.getFormatsList().forEach(formatItem => {
              const lessonsContent = formatItem.getContentsList();
              const formatType = formatItem.getFormat();
              switch (formatType) {
                case this.CONTENT_TYPES.IMAGE:
                case this.CONTENT_TYPES.QUILL:
                  console.log('Quill items =', lessonsContent.length);
                  break;

                case this.CONTENT_TYPES.AUDIO:
                  console.log('Auidio items =', lessonsContent.length);
                  break;

                case this.CONTENT_TYPES.VIDEO:
                  console.log('Video items =', lessonsContent.length);
                  lessonsContent.forEach(video => {
                    videos.push({
                      taskId: video.getId(),
                      isPremium: video.getPremium(),
                      isViewed: video.getVisible(),
                      author: video.getUserId(),
                      authorAvatar: '',
                    });
                    this.tasksNumber += 1;
                  });
                  break;

                default:
                  console.warn(`Unknown format [${formatType}]: ${lessonsContent}`);
              }
            });
          } else if (contentType === 5) {
            typeItem.getFormatsList().forEach(formatItem => {
              const lessonsContent = formatItem.getContentsList();
              const formatType = formatItem.getFormat();
              if (formatType === this.CONTENT_TYPES.HTML) {
                console.log('HTML items =', lessonsContent.length);
                lessonsContent.forEach(html => {
                  lessons.push({
                    taskId: html.getId(),
                    isPremium: html.getPremium(),
                    isViewed: html.getVisible(),
                    author: html.getUserId(),
                    authorAvatar: '',
                  });
                  this.tasksNumber += 1;
                  console.warn('Lessons:', lessons);
                })
              }
            });
          }
        });

        this.tasksVariants[this.TASK_TYPES.AUDIO] = audios;
        this.tasksVariants[this.TASK_TYPES.VIDEO] = videos;
        this.tasksVariants[this.TASK_TYPES.LESSON] = lessons;

        this.loadAuthorAvatars();
        this.taskTypes = this.createTaskTypes(this.tasksVariants);

        if (lessons.length) {
          this.selectorState.activeType = this.TASK_TYPES.LESSON;
        } else if (videos.length) {
          this.selectorState.activeType = this.TASK_TYPES.VIDEO;
        } else if (audios.length) {
          this.selectorState.activeType = this.TASK_TYPES.AUDIO;
        } else {
          this.noSulutions = true;
        }

        this.isVariantsLoaded = this.LOADING_STATES.LOADED;
      }
    },

    deriveSelectorClass(index, type = 0) {
      /*
      type = 0 corresponds to task types.
      type = 1 corresponds to task variants.
      */
      let currentIndex = this.selectorState.activeType;
      if (!this.tasksVariants[currentIndex].length) {
        return ['button__selector_base']
      }
      if (type) {
        currentIndex = this.selectorState.activeVariant;
      }
      const classes = [];
      if (currentIndex === index) {
        classes.push('button__selector_active');
      } else {
        classes.push('button__selector_base');
      }
      return classes
    },

    changeSelectorStateButton(newState, state = 'activeType') {
      if (state in this.selectorState) {
        this.selectorState[state] = newState;
        if (state === 'activeType') {
          this.selectorState.activeVariant = 0;
        }
      } else {
        console.warn('unknow selector state:', state);
      }
    },

    changeSelectorStateSelect(state = 'type') {
      this.toggleActive()
      return (event) => {
        const targetId = event.target.value;
        if (state === 'type') {
          this.selectorState.activeType = +targetId;
          this.selectorState.activeVariant = 0;
        } else if (state = 'variant') {
          const variantInd = this.getTaskVariants.findIndex(
            variant => variant.taskId === targetId
          );
          if (variantInd !== -1) {
            this.selectorState.activeVariant = variantInd;
          }
        }
      }
    },

    gotoEditor(event) {
      this.$router.push({ name: 'editor-task', params: { tid: this.nodeId } });
    },

    gotoTasks(event) {
      this.$router.push({ name: 'book-content' });
    },

    createTaskTypes(tasksVariants) {
      /* 
      Gets "existed" task types based on presense of corresponding 
      task variants.
      */
      const taskTypes = [];
      for (const [type, tasks] of Object.entries(tasksVariants)) {
        if (tasks.length) {
          let title = '';
          switch (+type) {
            case this.TASK_TYPES.VIDEO:
              title = 'видеоурок';
              break;
            case this.TASK_TYPES.AUDIO:
              title = 'аудиоурок';
              break;
            case this.TASK_TYPES.LESSON:
              title = 'конспект урока';
              break;
          }
          taskTypes.push({id: +type, title});
        }
      }
      return taskTypes
    },

    setOverLimit() {
      if (this.sessionActive) {
        this.tarifChangeOptions = this.getTaskProps;
        this.popupTarifChangeVisibility = true;
      } else {
        this.popupAccountLoginVisibility = true;
      }
    },

    async initContent() {
      try {
        if (!this.entitiesService) {
          this.entitiesService = new EntitiesServiceClient(this.serviceURL, null, null);
        }
        this.isVariantsLoaded = this.LOADING_STATES.INIT;
        this.tasksNumber = 0;
        this.taskTypes = [];
        this.initTaskVariants();
        this.selectorState = {
          activeType: this.TASK_TYPES.SOLUTION,
          activeVariant: 0
        }
        this.noSulutions = false;
        this.errorText = '';
        await this.startFetchNode(); 
      } catch (error) {
        console.warn('INIT LESSON TASK [ERROR]:', error);
      }
    },

    changeTaskState(data) {
      const { isViewed } = data;
      const activeTaskType = this.selectorState.activeType;
      const activeVariant = this.selectorState.activeVariant;
      this.tasksVariants[activeTaskType][activeVariant].isViewed = isViewed;
    },

    async fetchTaskLimit() {
      this.isTaskLimitsLoaded = false;
      if (!this.sessionActive) {
        this.isTaskLimitsLoaded = true;
        return
      }
      try {
        const allSubs = await this.fetchAllSubscriptions();
        const activeSubs = await this.fetchActiveSubscriptions();
        Object.keys(activeSubs).forEach(id => {
          if (allSubs.hasOwnProperty(id)) {
            this.usualTaskLimit = allSubs[id].usualSolution;
            this.premiumTaskLimit = allSubs[id].uniqueSolution;
          }
        })
      } catch (e) {
        console.error('Fetch task limits:', e)
      } finally {
        this.isTaskLimitsLoaded = true;
      }
    },

    createNavigationRoute(lessonId) {
      const route = { name: 'lesson-task', params: { tid: lessonId } };
      if (this.$route.query.lv) {
        route.query = { lv: this.$route.query.lv };
      }
      return route;
    },

    setMetaInfo() {
      try {
        console.log('Setting meta info');
      } catch (error) {
        console.warn('SET META INFO [LESSON-TASK]:', error);
      }
    }
  },

  async created() {
    this.nodeId = this.$route.params.tid;
    
    // setting breadcrumbs link (better call as early as possible)
    this.addLinkLessons(LESSONS_TASK_LINK);
    this.linkIndex = LESSONS_TASK_LINK.linkIndex;

    try {
      // WARNING: these actions cause token verification token
      await this.fetchRestTasksInfo();
      await this.fetchTaskLimit(); 
      await this.initContent();
    } catch (error) {
      console.warn(error);
    }

    // setting filter state: real data can't be fetched at the moment
    /*setTimeout(() => {
      const { levelId, subjectId } = this.getFilterState;
      if (levelId === null || subjectId === null) {
        this.setFilterState({levelId: 9, subjectId: 1});
      }
    }, 5000);*/
  },

  async mounted() {
    // await this.initContent();
    // await this.getUserAvatar();
    // this.updateBookTitle();
  },

  beforeUnmount() {
    this.removeLinkLessons(this.linkIndex);
  },

}
</script>

<style src="@/components/UI/buttons/button.css"></style>
<style scoped>

.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
}

.task-header {
  /* position: sticky; */
  /* top: 0; */
  background-color: var(--white);
  transition: .3s ease-in-out;
  /* z-index: 10; */
}

.task-header_sticky {
  padding: 16px;
  box-shadow: 4px 0px 16px 0px rgba(74, 74, 74, 0.15);
  border-radius: 0px 0px 8px 8px;
}

.controls {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
}

.lesson-nav {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  margin-top: 16px;
}

.lesson-nav__link {
  display: block;
  text-decoration: none;
}

.lesson-nav__link:hover > * {
  color: var(--background-red) !important;
}

.selector {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.selector__visibility_buttons {
  display: none;
}

.selector__visibility_select {
  display: block;
}


.selector__buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

.selector__select,
.selector__option {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.06px;
  color: #000;
}

.selector__select {
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border-style: solid;
  background-color: var(--white);
}

.button__selector {
  min-width: 44px;
  padding: 12px;
  border-radius: 8px;
  transition: background-color 0.3s ease-in-out;
}

.button__selector:disabled {
  background: var(--white);
  opacity: .3;
}

.button__selector:not(:disabled):hover {
  background: var(--red-indicator);
}

.button__selector_base {
  background: rgba(151, 152, 156, 0.10);
}

.button__selector_active {
  background: rgba(131, 50, 42, 0.10);
}

.button__text {
  color: var(--text-secondary) !important;
  white-space: nowrap;
  transition: color 0.3s ease-in-out;
}

.button__selector_active > .button__text {
  color: var(--background-brown) !important;
}

.button__selector:hover > .button__text {
  color: var(--white) !important;
}

.button__selector:disabled > .button__text {
  color: var(--text-secondary) !important;
}

.empty-node {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 32px;
}

.empty-node__image {
  width: 780px;
  aspect-ratio: 39 / 15;
  background-image: url('@/assets/images/tasks/no-tasks.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.empty-node__message {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.empty-node__actions {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
  width: 100%;
}

.buttons__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.card-wrapper {
  margin-top: 16px;
}

.nav__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.variants__wrapper {
  display: flex;
  flex-direction: row;
  gap: 4px;
  overflow: auto;
  white-space: nowrap;
  padding: 8px 0px;
  max-width: 50%;
}

.variants__wrapper::-webkit-scrollbar {
  height: 10px;
  background-color: #ADDAFF;
  border-radius: 4px;
}

.variants__wrapper::-webkit-scrollbar-thumb {
  background-color: var(--accent);
  border-radius: 4px;
}

.variants__wrapper::-webkit-scrollbar-thumb:hover {
  background-color: var(--accent-hover);
}

.promo-section__wrapper{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.task-title {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
 }

.task-title__main,
.task-title__aside {
  display: flex;
  flex-direction: row;
  align-items: center;
 }

.task-title {
  justify-content: space-between;
  position: relative;
}

.task-title__icon_premium {
  width: 24px;
  height: 24px;
  padding: 4px;
  border-radius: 4px;
  background: var(--yellow);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'%3E%3Cg clip-path='url(%23a)' stroke='%23fff' stroke-width='1.2'%3E%3Cpath d='m14.409 9.044.153-1.624c.12-1.275.18-1.913-.039-2.176a.661.661 0 0 0-.45-.245c-.317-.028-.715.426-1.512 1.332-.412.47-.618.704-.847.74a.615.615 0 0 1-.375-.06c-.213-.106-.353-.396-.636-.976L9.21 2.98c-.535-1.095-.802-1.643-1.209-1.643-.406 0-.674.548-1.208 1.643L5.302 6.035c-.282.58-.424.87-.636.976a.617.617 0 0 1-.375.06c-.23-.036-.435-.27-.847-.74C2.647 5.425 2.249 4.971 1.932 5a.661.661 0 0 0-.45.245c-.218.263-.158.901-.038 2.176l.152 1.624c.252 2.675.378 4.013 1.167 4.819.787.806 1.969.806 4.333.806h1.812c2.364 0 3.545 0 4.334-.806.788-.807.914-2.144 1.166-4.82Z'/%3E%3Cpath d='M6 12h4' stroke-linecap='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h16v16H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 4px 0px 16px 0px var(--shadow-pattern);
}

.task-title__main,
.task-title__aside {
  gap: 8px;
}

.task-title__limit {
  padding: 4px 4px 3px 4px;
  border-radius: 4px;
  box-shadow: 4px 0px 16px 0px var(--shadow-pattern);
}

.task-title__limit_premium {
  background: var(--yellow);
}

.task-title__limit_usual {
  background: var(--text-light);
}

.tooltip{
  padding: 8px;
  background-color: var(--white);
  opacity: 0;
  box-shadow: 4px 0px 16px 0px rgba(74, 74, 74, 0.15);
  border-radius: 8px;
  position: absolute;
  right: 0;
  max-width: 232px;
  margin-top: 92px;
  transition: .3s ease-in-out;
}

.button_type_question:hover + .tooltip{
  opacity: 1;
}

.avatar-variants__wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
/* .avatar_type_active{
  outline: 2px solid var(--kaz-base-primary);
}
.avatar_type_inactive{
  outline: 2px solid var(--kaz-base-secondary);
} */


@media screen and (max-width: 992px) {
  .promo-section__wrapper{
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .promo-section__wrapper p:last-of-type{
    display: none;
  }
  .promo-section__wrapper p:last-of-type{
    padding: 0px 16px;
  }
  .empty-node h2, p{
    text-align: center;
  }
  .empty-node__image{
    width: 100%;
  }
  .empty-node__actions{
    justify-content: center;
  }
}

@media screen and (min-width: 768px) {
  .selector__visibility_buttons {
    display: block;
  }

  .selector__visibility_select {
    display: none;
  }
}
</style>