<template>
  <div
    :class="[
      'link__wrapper',
      `link__wrapper_type_${appearance}`,
    ]"
  >
    <router-link :to="link" class="link__router" :target="target" @click="linkHandler">
      <svg-icon
        v-if="leftIcon" 
        class="link__icon link__icon_left"
        :iconName="leftIconName" 
        :size="'16px'"
      />
      <span
        :class="[
          'label', 
          `label_size_${size}`, 
          { [`label_type_${appearance}_underlined`]: underline }
        ]"
      >
        {{ label }}
      </span>
    </router-link>
    <svg-icon
      v-if="rightIcon" 
      class="link__icon link__icon_left" 
      :iconName="rightIconName" 
      :size="'16px'"
    />
  </div>
</template>

<script>
export default{
  name: 'link-element',

  props: {
    label:{
      type: String,
      default: 'Get 100$ just for click!',
      requred: false
    },
    size:{
      type: String,
      requred: false,
      default: 'L'
    },
    appearance:{
      type: String,
      requred: false,
      default: 'primary'
    },
    underline:{
      type: Boolean,
      requred: false,
      default: false,
    },
    link:{
      type: [String, Object],
      requred: false,
      default: '/',
    },
    leftIcon:{
      type: Boolean,
      requred: false,
      default: false,
    },
    rightIcon:{
      type: Boolean,
      requred: false,
      default: false,
    },
    leftIconName:{
      type: String,
      requred: false,
      default: 'kuiIconAlert'
    },
    rightIconName:{
      type: String,
      requred: false,
      default: 'kuiIconChevronDown'
    },
    target:{
      type: String,
      requred: false,
      default: '_blank'
    },
    programNavigation: {
      type: [Function, null],
      default: null
    }
  },

  methods: {
    linkHandler(event) {
      try {
        if (this.programNavigation) {
          event.preventDefault();
          this.programNavigation();
        } 
      } catch (error) {
        console.warn('Link handler:', error);
        this.$router.push(this.link);
      }
    }
  }
}
</script>

<style scoped>
.link__wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.link__router{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  text-decoration: none;
}

.link__wrapper_type_primary .label{
  transition: var(--kaz-transition-atoms);
  color: var(--kaz-base-primary);
}
.link__wrapper_type_primary:hover .label{
  transition: var(--kaz-transition-atoms);
  color: var(--kaz-base-primary-hover);
}
.link__wrapper_type_onLight .label{
  transition: var(--kaz-transition-atoms);
  color: var(--kaz-textIcons-text-02);
}
.link__wrapper_type_onLight:hover .label{
  transition: var(--kaz-transition-atoms);
  color: var(--kaz-textIcons-text-01);
}
.link__wrapper_type_primary .link__icon{
  --kaz-base-base-09: var(--kaz-base-primary);
}
.link__wrapper_type_primary:hover .link__icon{
  --kaz-base-base-09: var(--kaz-base-primary-hover);
}
.link__wrapper_type_onLight .link__icon{
  --kaz-base-base-09: var(--kaz-textIcons-text-02);
}
.link__wrapper_type_onLight:hover .link__icon{
  --kaz-base-base-09: var(--kaz-textIcons-text-01);
}
</style>