<template>
  <div class="divide-wrapper">
    <header-common/>
    <!-- <header-component/> -->
    <div class="container__wrapper">
      <card-background class="nav__wrapper" v-if="isModerator">
        <tabs-navigation
          :tabs="moderationLinks"
        />
      </card-background>
      <main class="container__main" ref="mainContent">
        <router-view v-if="isModerator" />
        <card-background v-if="isLoading" >
          <Loader :size="'XL'" />
        </card-background>
        <card-background v-if="!isModerator && !isLoading">
          <div class="content__no-access">
            <img src="@/assets/KazIllustrations/states/noAccess.png" class="no-access__image" />
            <div class="no-access__text">
              <h2 class="heading heading_size_h2">Упс... сюда нельзя</h2>
              <p class="label label_size_M">Эта страница доступна только пользователям с ролью «Модератор» или «Методист», на текущем аккаунте таких ролей нет.</p>
            </div>
            <div class="no-access__buttons">
              <Button
                :label="'Вернуться на главную'"
                :appearance="'secondary'"
                :mode="'normal'"
                @click="$router.push({name: 'tasks'})"
              />

              <Button
                :label="'Войти в аккаунт модератора'"
                :appearance="'primary'"
                @click="$router.push({name: 'login'})"
              />
            </div>
          </div>
        </card-background>
      </main>
    </div>
    <footer-component
      :cooperation="cooperation"
      :documents="documents"
      :commonLinks="common"
      :socials="socials"
    />
</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

import SubjectClassFilter from '@/components/common/SubjectClassFilter.vue';
import HeaderComponent from '@/components/common/HeaderComponent';
import FooterComponent from '@/components/common/FooterComponent';
import FavoritesComponent from '@/components/common/FavoritesComponent.vue';
import SubHeaderComponent from '@/components/common/SubHeaderComponent';
import EditorIntro from '@/views/tasks/components/EditorIntro.vue';
import CookieNotify from '@/components/UI/CookieNotify.vue';

import CardBackground from '@/components/UI/card/CardBackground.vue';
import TabsNavigation from '@/components/KazUI/atoms/tabsNavigation';
import Loader from '@/components/KazUI/atoms/loader'
import Button from '@/components/KazUI/atoms/button'
import HeaderCommon from '@/components/common/Header';

import { documents, common, cooperation, socials } from '@/components/common/FooterComponent/links';


export default {
  name: 'moderation-layout',

  components: {
    HeaderComponent,
    FooterComponent,
    SubjectClassFilter,
    FavoritesComponent,
    SubHeaderComponent,
    EditorIntro,
    CookieNotify,
    CardBackground,
    TabsNavigation,
    HeaderCommon,
    Loader,
    Button
},

  data() {
    return {
      books: [],
      clsSubjects: [],
      listSubjectsLevel: [],
      grades:[],
      serviceURL: process.env.VUE_APP_REGISTRATION_SERVICE_URL,
      windowWidth: window.innerWidth,
      userHaveBooks: true,
      isLoading: true,
      
      // for FooterComponent
      documents,
      common,
      cooperation,
      socials,
      moderationLinks:[
        {
          title: 'Модерация контента',
          link: '/moderation',
          iconName: 'kuiIconIndentLarge',
          disabled: false
        },
        {
          title: 'Отчеты ГПХ',
          link: '/moderation/reports',
          iconName: 'kuiIconFileLarge',
          disabled: false
        },
        {
          title: 'Создание пособия',
          link: '/generation/book',
          iconName: 'kuiIconStructureLarge',
          disabled: false
        },
        {
          title: 'Редактирование пособий',
          link: '/generation/books',
          iconName: 'kuiIconStructureLarge',
          disabled: false
        },
        {
          title: 'Поиск пособий',
          link: '/generation/search',
          iconName: 'kuiIconSearchLarge',
          disabled: false
        },
        {
          title: 'Аналитика',
          link: { name: 'analytics' },
          iconName: 'kuiIconChartLineLarge',
          disabled: false,
        },
      ]
    }
  },

  computed: {
    ...mapGetters({
      getGrades: 'gradeData/getGrades',
      sessionActive: 'authData/sessionActive',
      isModerator: 'userData/isModerator',
    }),

    showAccordion() {
      return this.$route.meta.layout === 'tasks'
    },

    showEditorIntro() {
      return this.$route.path.includes('/editor/resh')
    }
  },

  methods: {
    ...mapActions({
      fetchGrades: 'gradeData/fetchGrades',
      fetchCourses: 'courseData/fetchCourses',
      fetchUser: 'userData/fetchUser',
      fetchUserRoles: 'userData/fetchUserRoles'
    }),

    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },

    async getMenuGrades() {
      try {
        if (!this.getGrades.length) {
          await this.fetchGrades();
        }
        await this.fetchCourses();
      } catch(err) {
        console.error('Error in getMenuGrades:', err);
      }
    },

    async getUser() {
      try {
        await this.fetchUser();
      }
      catch(err) {
        console.log('Error in getUser:', err);
      }
    },

    async getRoles() {
      try {
        // this.isLoading = true;
        await this.fetchUserRoles();
      }
      catch(err) {
        console.log('Error in getRoles:', err);
      }
      finally{
        // this.isLoading = false;
      }
    },
  },

  async created() {
    await this.getMenuGrades();


    console.warn(this.isModerator)
    console.warn(this.getActiveRole)
    // await this.fetchUserRoles

    if (this.sessionActive) {
      console.warn('session is active, fetching user data...');
      await this.getUser();
      await this.getRoles();
      this.isLoading = false
    } else {
      console.warn('session is nonactive');
    }
    // console.log('BIG PROBLEM:', this.$getTokenInfo());
  },

  mounted() {
    window.addEventListener('resize', this.updateWindowWidth);
  },
}
</script>

<style scoped>

.divide-wrapper{
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 1600px;
  margin: 0 auto;
}

.content__no-access{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.no-access__image{
  max-width: 280px;
  max-height: 280px;
}

.no-access__text{
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  text-align: center
}

.no-access__text p{
  color: var(--kaz-textIcons-text-02);
}

.no-access__buttons{
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  gap: 24px;
}

@media screen and (max-width: 1200px) {
  .divide-wrapper{
    max-width: 1140px;
  }
}
@media screen and (max-width: 992px) {
  .divide-wrapper{
    max-width: 720px;
  }
}
@media screen and (max-width: 768px) {
  .divide-wrapper{
    max-width: 540px;
  }
}
@media screen and (max-width: 568px) {
  .divide-wrapper{
    max-width: 100vw;
  }
  .no-access__buttons{
    margin-top: 8px;
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }
}
.nav__wrapper{
  padding: 16px 32px;
}

@media screen and (max-width: 568px) {
  .nav__wrapper{
    padding: 16px;
  }
}
.container__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.container__aside,
.container__main {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .container__wrapper {
    max-width: 1140px;  
  }
}

@media screen and (max-width: 1400px) {
  .container__wrapper {
    max-width: 1320px;  
  }
}
</style>
