import { ref } from 'vue';


export default function useVariantsChanger(taskTypes) {
  const tasksVariants = ref({});

  const initTaskVariants = () => {
    const emptyVariants = Object
      .values(taskTypes)
      .reduce((acc, it) => ({...acc, [it]: []}), {});
    Object.assign(tasksVariants.value, emptyVariants);
    // console.warn(tasksVariants.value);
  }

  initTaskVariants();

  return {
    tasksVariants,
    initTaskVariants,
  }
}