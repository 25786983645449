import STATES from '@/utils/states';

import { extractTokenInfo } from '@/utils/auth';
import { fetchMethodData } from '@/utils/loaders.js';
import { TaskReaderClient } from '@/generated/taskreader/taskreader_grpc_web_pb.js';


const REGISTRATION_SERVICE_URL = process.env.VUE_APP_REGISTRATION_SERVICE_URL;


export const tasksRestInfo = {
  namespaced: true,
  state: () => ({
    loadingState: STATES.INIT,
    errorMessage: null,
    overLimit: true,
    restTask: 0,
    restTaskPremium: 0,
  }),

  getters: {
    getLoadingState(state) {
      return state.loadingState
    },
    isOverLimit(state) {
      return state.overLimit
    },
    getRestTask(state) {
      return state.restTask
    },
    getRestTaskPremium(state) {
      return state.restTaskPremium
    }
  },

  mutations: {
    setLoadingState(state, newState) {
      state.loadingState = newState;
    },
    setErrorMessage(state, errorMessage) {
      state.errorMessage = errorMessage;
    },
    setOverLimit(state, newFlag) {
      state.overLimit = newFlag;
    },
    setRestTask(state, newValue) {
      state.restTask = newValue;
    },
    setRestTaskPremium(state, newValue) {
      state.restTaskPremium = newValue;
    }
  },

  actions: {
    async fetchRestTasksInfo({ commit }) {
      try {
        commit('setLoadingState', STATES.LOADING);

        const taskReaderService = new TaskReaderClient(
          REGISTRATION_SERVICE_URL, 
          null, 
          null
        );

        const requestEmpty = new proto.google.protobuf.Empty();
        const tokens = extractTokenInfo();
        const metadata = { 'token': tokens.accessToken.token };

        const response = await fetchMethodData(
          taskReaderService,
          'rest',
          {request: requestEmpty, metadata}
        );
        const { overLimit, restTask, restTaskPremium } = response.toObject();
        commit('setOverLimit', overLimit);
        commit('setRestTask', restTask);
        commit('setRestTaskPremium', restTaskPremium);
        commit('setLoadingState', STATES.LOADED);
        console.log('success [fetchRestTasksInfo]:', overLimit, restTask, restTaskPremium);
      } catch (error) {
        console.error(error);
        commit('setErrorMessage', error);
        commit('setLoadingState', STATES.ERROR);
      }
    }
  }
}