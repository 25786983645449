import {RatingsServiceClient} from '@/generated/ratings/ratings_service_grpc_web_pb.js';
import { fetchStreamData, fetchMethodData } from '@/utils/loaders.js';
import { createMetadataWithToken } from '@/services/utils';


const ratingsService = new RatingsServiceClient(
  process.env.VUE_APP_REGISTRATION_SERVICE_URL,
  null,
  null
);

async function fetchRatings(contentId) {
  try {
    const request = new proto.kazatel.ratings.ContentId();
    request.setId(contentId);
    const metadata = createMetadataWithToken();
    return await fetchMethodData(ratingsService, 'ratingsUsers', { request, metadata });
  } catch (error) {
    throw error;
  }
}

async function setRating(contentId, value) {
  try {
    const content = new proto.kazatel.ratings.ContentId();
    content.setId(contentId);
    const request = new proto.kazatel.ratings.ContentRating();
    request.setId(content);
    request.setValue(value);
    const metadata = createMetadataWithToken();
    return await fetchMethodData(ratingsService, 'setRating', { request, metadata });
  } catch (error) {
    throw error;
  }
}


export {
  fetchRatings,
  setRating,
}