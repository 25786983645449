import Lesson from "@/views/lessons/Lesson.vue";
import LessonContent from "@/views/lessons/LessonContent.vue";
import LessonTasks from "@/views/lessons/task/index.vue"

const routes = [
  {
    path: '/lessons',
    name: "lessons",
    meta: { layout: "profile", auth: false },
    component: () => import('@/views/lessons/Lessons.vue'),
    children: [
      {
        path: "/lesson/:tid",
        name: "lesson-task",
        meta: { layout: "tasks", auth: false },
        component: LessonTasks,
      },
    ]
  },
  // next routes are deprecated; need to remove them
  {
    path: "/lessons/:lid",
    meta: { layout: "tasks", auth: false },
    component: Lesson,
    name: 'lesson-root',
    children: [
      {
        path: "",
        name: "lesson",
        meta: { layout: "tasks", auth: false },
        component: LessonContent
      },
      {
        path: "lesson-task/:tid",
        name: "_lesson-task",
        meta: { layout: "tasks", auth: false },
        component: LessonTasks,
      },
    ]
  },
]

export default routes
