<template>
  <button
    class="button close-btn"
    @click="emitClick"
    tabindex="-1"
  ></button>
</template>

<script>
export default {
  name: 'close-button',

  methods: {
    emitClick() {
      this.$emit('emitClick');
    }
  }
}
</script>

<style src="./button.css"></style>
<style scoped>
.close-btn::after {
  display: inline-block;
  content: '\00d7';
  font-size: 32px;
  line-height: 100%;
  color: var(--text-light);
}
</style>