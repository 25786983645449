export default [
  /*{
    id: 1,
    title: 'Российская Электронная Школа',
    link: 'https://resh.edu.ru/'
  },*/
  {
    id: 2,
    title: 'Министерство Просвещения РФ',
    link: 'https://edu.gov.ru/'
  },
  {
    id: 3,
    title: 'АО «Просвещение»',
    link: 'https://prosv.ru/'
  },
  {
    id: 7,
    title: 'Адыгейский государственный университет',
    link: 'https://www.adygnet.ru/'
  },
  {
    id: 9,
    title: 'Российский государственный гуманитарный университет',
    link: 'https://www.rsuh.ru/'
  },
  {
    id: 4,
    title: 'Профессии Будущего',
    link: 'https://mosfuture.ru/'
  },
  
  {
    id: 5,
    title: 'Моя работа',
    link: 'https://czn.mos.ru/EE/'
  },
  {
    id: 8,
    title: 'Работа России',
    link: 'https://trudvsem.ru/'
  },
  {
    id: 10,
    title: 'Факультетус',
    link: 'https://facultetus.ru/'
  },
  {
    id: 6,
    title: 'Моя карьера',
    link: 'https://mycareer.moscow/'
  },
]