<template>
  <!-- <header-component/>
  <main id="main" class="container">
    <div class="row justify-content-center justify-content-start-lg gap-3">
      <aside class="col-auto col-lg-3 col-xl-2 px-0">
        <accordion-sidebar :data="getGrades"/>
      </aside>
      <div class="col px-0">
        <router-view/>
      </div>
    </div>
  </main>
  <footer-component
    :grades="getGrades"
    :courses="getCourses"
    :documents="documents"
    :commonLinks="commonLinks"
  />-->
</template>

<script>
/**
 * WARNING: THIS LAYOUT IS NOT USED AT THE MOMENT.
 * All pages of this layout are used in MainLayout via replacement in App.vue.
 */

import { mapGetters, mapMutations, mapActions } from 'vuex';
// import AccordionSidebar from '@/components/common/AccordionSidebar.vue';
import HeaderComponent from '@/components/common/HeaderComponent';
// import FooterComponent from '@/components/common/FooterComponent/FooterComponent.vue';
import SidebarFooter from '@/components/common/SidebarFooter.vue';

import {fetchStreamData} from '@/utils/loaders';

const {TaskReaderClient, default: books} = require(
  '@/generated/taskreader/taskreader_grpc_web_pb.js'
);

export default {
  name: 'task-layout',

  components: {
    // AccordionSidebar,
    HeaderComponent,
    // FooterComponent,
    SidebarFooter,
  },

  data() {
    return {
      books: [],
      clsSubjects: [],
      listSubjectsLevel: [],
      grades:[],
      serviceURL: process.env.VUE_APP_REGISTRATION_SERVICE_URL,
      // for FooterComponent
      documents: [
        {
          title: "Пользовательское соглашение",
          link: "./user-agreement.pdf",
          target: '_blank',
        },
        {
          title: "Обработка персональных данных",
          link: "./personal-data.pdf",
          target: '_blank',
        },
        {
          title: "Договор-оферта",
          link: "./oferta.pdf",
          target: '_blank',
        }
      ],
      commonLinks: [
        {
          title: "О проекте",
          link: "#"
        },
        {
          title: "Авторы пособий",
          link: "#"
        },
        {
          title: "Методисты решений",
          link: "#"
        },
        {
          title: "Контакты",
          link: "#"
        },
        {
          title: "Онлайн-школа",
          link: "#"
        }
      ],
    }
  },

  computed: {
    ...mapGetters({
      getGrades: 'gradeData/getGrades',
      getCourses: 'courseData/getCourses'
    })
  },

  methods: {
    ...mapMutations({
      addGrade: 'gradeData/addGrade',
      addCourse: 'courseData/addCourse',
    }),

    ...mapActions({
      fetchGrades: 'gradeData/fetchGrades',
      fetchUser: 'userData/fetchUser',
    }),


    async getClsSubjects() {
      try {
        const taskreaderService = new TaskReaderClient(this.serviceURL, null, null);
        const streamRequest = new proto.google.protobuf.Empty();
        const stream = taskreaderService.subjects(streamRequest, {});

        const streamData = await fetchStreamData(stream);
        streamData.forEach(res => {
          this.clsSubjects.push([res.getId(), res.getName()]);
        });
        console.log('Справочник предметов получен'); 
      } catch(err) {
        console.error('Error in getClsSubjects:', err);
      }
    },

    async getMenuGrades() {
      try {
        if (!this.getGrades.length) {
          await this.fetchGrades();
        }
      } catch(err) {
        console.error('Error in getMenuGrades:', err);
      }
    },
    
    async getUser() {
      try {        
        await this.fetchUser();
      } 
      catch(err) {
        console.log('Error in getUser:', err);
      }
    },
  },

  async created() {       
    await this.getClsSubjects(); 
    await this.getMenuGrades();
    await this.getUser();
    // await this.getLevelsBySubject();
  }
}
</script>

<style scoped>

</style>