<template>
  <div class="input-select">
    <div
      class="select-container input input_size_L" 
      :class="{
        'select-container_filled': modelValue.length && !multiple, 
        'input_error_active': error,
        'select-container_multiple': multiple,
        'select-container_disabled': disabled,
      }"
      tabindex="0"
      @keydown.space="spaceToggle"
      @keydown.tab="tabToggle"
      @click.stop="clickToggle"
      @blur="closeSelectMenu"
    >
      <p
        v-if="!(multiple && modelValue.length)"
        class="select-container__label"
        :class="{ 'select-container__label_filled': modelValue.length && !multiple }"
      >
        {{ label }}
      </p>
      <ul v-if="multiple" class="selected-tags">
        <li v-for="item in getSelectedOptions" :key="item.id">
          <kaz-tag
            :label="item.option.title"
            :editable="true"
            @remove-tag="removeOption(item.id)"
          />
        </li>
      </ul>
      <template v-else>
        <p 
          v-if="modelValue.length"
          class="select-container__current-value"
        >
          {{ modelValue[0].title }}
        </p>
      </template>
      <div class="select-container__actions">
        <svg-icon
          v-if="hint"
          class="action-icon hint-icon" 
          iconName="kuiIconHelpCircleLarge" 
          size="24px"
        />
        <svg-icon
          class="action-icon toggle-icon"
          :class="{ 'toggle-icon__open': menuVisible }"
          iconName="kuiIconChevronDownLarge" 
          size="24px"
        />
      </div>
      <div
        class="select-menu"
        :class="{ 
          'select-menu_visible': menuVisible,
          'select-menu_small': menuSmall, 
        }"
        v-click-outside="closeSelectMenu"
        :data-uuid="inputId"
        :style="{ scrollbarGutter: getFilteredOptions.length > MAX_VISIBILITY_OPTIONS ? 'stable' : 'auto' }"
      >
        <KazSearch
          v-if="search"
          v-model:value.trim="searchQuery"
          :isErrorActive="false"
          :errorText="''"
          :readOnly="false"
          :placeholder="searchPlaceholder"
          inputId="generation_input_select_search"
          class="search-options"
          size="M"
          @focusin=""
          @focusout=""
        />
        <ul class="options-list">
          <li
            v-if="getFilteredOptions.length"
            v-for="option in getFilteredOptions"
            class="options-list__item"
            :class="{
              'options-list__item_selected': multiple && selectedOptions.has(valuesToIndexOptions.get(option.value)) 
            }"
            @click.stop="pickOptions(valuesToIndexOptions.get(option.value))"
          >
            {{ option.title }}
          </li>
          <span v-else>
            Ничего не найдено
          </span>
        </ul>
      </div>
    </div>
    <p v-if="error" class="label label_size_S error">
      {{ errorText }}
    </p>
  </div>
</template>

<script>
import KazTag from '@/components/KazUI/atoms/tag';
import KazSearch from '@/components/KazUI/atoms/inputs/search';

import { v4 as uuidv4 } from 'uuid';


const SEARCH_DELAY_MS = 500;
let searchTimeoutId = null;


export default {
  name: 'node-input-select',

  components: {
    KazTag,
    KazSearch,
  },

  props: {
    modelValue: {
      /**
       * This prop is array of current (selected) `options`.
       * The each item MUST contain unique property `value` 
       * and any property `title`.
       */
      type: Array,
      required: true
    },
    options: {
      /**
       * This prop is array of available `options`.
       * The each item MUST contain unique property `value` 
       * and any property `title`.
       */
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: '',
    },
    error:{
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
      default: '',
    },
    menuSmall: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Boolean,
      default: false,
    },
    searchPlaceholder: {
      type: String,
      default: 'Поиск',
    }
  },

  data() {
    return {
      inputId: '',
      menuVisible: false,
      selectedOptions: new Set(),
      valuesToIndexOptions: new Map(),
      searchQuery: '',
      searchResult: [],
      MAX_VISIBILITY_OPTIONS: 7,
    }
  },

  watch: {
    getModelAndOptionsValue: {
      handler(newValues, prevValues) {
        // const { modelValue: prevModelValue, optionsValue: prevOptionsValue } = prevValues;
        const { modelValue, optionsValue } = newValues;
        this.valuesToIndexOptions = new Map(
          optionsValue.map((opt, ind) => [opt.value, ind])
        );
        
        this.selectedOptions.clear();
        if (optionsValue.length && this.valuesToIndexOptions.size) {
          modelValue?.forEach(opt => {
            if (this.valuesToIndexOptions.has(opt.value)) {
              this.selectedOptions.add(this.valuesToIndexOptions.get(opt.value));
            }
          });
        }
      },
      immediate: true,
      deep: true,
    },
    searchQuery(newQuery) {
      clearTimeout(searchTimeoutId);
      if (newQuery.trim().length > 2) {
        searchTimeoutId = setTimeout(
          () => {
            try {
              this.searchResult = this.options.filter(o => o.title?.toLowerCase().includes(newQuery.toLowerCase()));
            } catch (error) {
              this.searchResult = [];
              console.warn(error);
            }
          }, 
          SEARCH_DELAY_MS
        );
      } else {
        // this.searchResult = this.options;
      }
    }
  },

  computed: {
    getModelAndOptionsValue() {
      return { modelValue: this.modelValue, optionsValue: this.options };
    },
    getSelectedOptions() {
      return [...this.selectedOptions.values()].map(
        i => ({ option: this.options[i], id: i, })
      );
    },
    getFilteredOptions() {
      var options = this.options;
      if (this.searchQuery.trim().length > 2) {
        options = this.searchResult;
      }
      return options;
    }
  },

  methods: {
    pickOptions(index) {
      let options;
      if (this.multiple) {
        if (this.selectedOptions.has(index)) {
          this.selectedOptions.delete(index);
        } else {
          this.selectedOptions.add(index);
        }
        options = [...this.selectedOptions.values()].map(i => this.options[i]);
      } else {
        options = [this.options[index]];
        this.menuVisible = false;
      }
      this.$emit('update:modelValue', options);
    },
    openSelectMenu() {
      this.menuVisible = true;
    },
    closeSelectMenu(event) {
      if (!this.fromSearchEvent(event?.relatedTarget)) {
        this.menuVisible = false;
      }
    },
    clickToggle(event) {
      if (!this.disabled && !this.fromSearchEvent(event.target)) {
        this.menuVisible = !this.menuVisible;
      }
    },
    spaceToggle(event) {
      if (!this.disabled && !this.fromSearchEvent(event.target)) {
        event.preventDefault();
        this.menuVisible = !this.menuVisible;
      }
    },
    tabToggle(event) {
      if (this.menuVisible) {
        event.preventDefault();
        this.menuVisible = false;
      }
    },
    fromSearchEvent(target) {
      return target?.dataset?.inputId === 'generation_input_select_search';
    },
    removeOption(index) {
      if (this.selectedOptions.has(index)) {
        this.selectedOptions.delete(index);
        this.$emit(
          'update:modelValue', 
          [...this.selectedOptions.values()].map(i => this.options[i])
        );
      }
    }
  },

  created() {
    this.inputId = uuidv4();
  }
}
</script>

<style scoped>
@import '@/components/KazUI/atoms/inputs/inputs.css';

.input-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.select-container {
  position: relative;
  min-height: 56px;
  min-width: initial;
  cursor: pointer;
  width: 100%;
}

.select-container_multiple {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 8px 10px 16px;
  cursor: default;
}

.select-container_disabled,
.select-container_disabled .action-icon {
  cursor: default;
}

.select-container_filled {
  padding-top: 22px;
  padding-bottom: 10px;
  padding-left: 16px;
}

.select-container__label {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  transition: all 0.3s ease;

  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #505156;
}

.select-container_disabled > .select-container__label {
  color: var(--kaz-textIcons-text-03);
}

.select-container_disabled > .select-container__current-value {
  color: rgb(109, 109, 109);
}

.select-container__label_filled {
  top: 6px;
  transform: translateY(0%);
  font-size: 0.75em;
  color: var(--kaz-textIcons-text-02);
}

.select-container__current-value {
  padding-right: 28px;
}

.select-menu {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  min-width: 100%;

  visibility: hidden;
  opacity: 0;
  max-width: 360px;
  max-height: 440px;
  padding: 8px;
  margin-top: 4px;
  overflow-y: auto;
  background: var(--kaz-base-base-01);
  border: 1px solid var(--kaz-base-base-04);
  border-radius: 12px;
  box-shadow: 0px 25px 15px rgba(0, 0, 0, 0.03), 0px 11px 11px rgba(0, 0, 0, 0.04), 0px 3px 6px rgba(0, 0, 0, 0.05);
  z-index: 1;
  transition: all var(--kaz-transition-molecules);
}

.select-menu_small {
  max-height: 220px;
}

.select-menu.select-menu_visible {
  visibility: visible;
  opacity: 1;
}

.select-container__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
}

.options-list__item {
  padding: 10px 8px;
  border-radius: 8px;
  cursor: pointer;
}

.options-list__item:not(:last-child) {
  margin-bottom: 4px;
}

.options-list__item_selected {
  background-color: var(--kaz-base-base-03);
}

.options-list__item:hover {
  background-color: var(--kaz-base-base-02);
}

.action-icon {
  cursor: pointer;
  --kaz-base-base-09: var(--kaz-textIcons-text-03);
}

.action-icon:hover {
  --kaz-base-base-09: var(--kaz-textIcons-text-02);
}

.select-container_disabled .action-icon:hover {
  --kaz-base-base-09: var(--kaz-textIcons-text-03);
}

.hint-icon {
  cursor: help;
}

.toggle-icon {
  transform: rotate(0deg);
  transition: 0.3s ease-in-out;
}

.toggle-icon__open {
  transform: rotate(-180deg);
}

.selected-tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
  padding-right: 28px;
}

.error{
  color: var(--kaz-status-error-fill);
}

.search-options {
  position: sticky;
  top: 0;
  width: 100%;
  background: var(--white);
}
</style>
