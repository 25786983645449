<template>
  <p
    :style="{ color: textColor, fontWeight: textWeight }"
    class="paragraph paragraph_size_M"
  >
    <slot></slot>
  </p>
</template>
<script>
export default {
  name: 'paragraph-size-medium',
  props: {
    textColor: {
      type: String,
      default: "var(--text-light)"
    },
    textWeight: {
      type: String,
      default: '500',
    }
  }
};
</script>
<style scoped>

.paragraph_size_M {
  font-size: 16px;
  /* line-height: 100%; */
  line-height: 20px;
  letter-spacing: 0.048px;
  /* overflow: hidden;
  text-overflow: ellipsis; */
}
</style>