<template>
  <div class="task">
    <div class="task__content">
      <task-shade
        v-if="shouldShowTaskShade"
        :isPremium="isPremium"
        @over-limit="onOverLimit"
      />
      <div v-else>
        <content-viewer-quill
          v-if="(contentType === CONTENT_TYPES.QUILL || contentType === CONTENT_TYPES.IMAGE) && taskId"
          :isTaskViewed="isViewed"
          :taskId="taskId"
          :taskType="taskType"
          @over-limit="onOverLimit"
          @change-task-state="handleTaskState"
        />
        <content-viewer-video
          v-else-if="(contentType === CONTENT_TYPES.VIDEO) && taskId"
          :taskId="taskId"
          :taskType="taskType"
          :isTaskViewed="isViewed"
          :isPremium="isPremium"
          @over-limit="onOverLimit"
          @change-task-state="handleTaskState"
        />
        <content-viewer-audio 
          v-else-if="(contentType === CONTENT_TYPES.AUDIO) && taskId"
          :taskId="taskId"
          :taskType="taskType"
          :isTaskViewed="isViewed"
          @over-limit="onOverLimit"
          @change-task-state="handleTaskState"
        />
        <content-viewer-html
          v-else-if="(contentType === CONTENT_TYPES.HTML) && taskId"
          :taskId="taskId"
          :taskType="taskType"
          :isTaskViewed="isViewed"
          :isPremium="isPremium"
          @over-limit="onOverLimit"
          @change-task-state="handleTaskState"
        />
      </div>
    </div>

    <skeleton-loader
      v-if="firstRatingLoading" 
      width="172px" 
      height="48px"
      borderRadius="12px"
      style="align-self: flex-end;"
    />
    <TaskAssessment
      v-else
      :likes="likes"
      :dislikes="dislikes"
      :state="assessmentState"
      @assessment="handleAssessmentEvent"
    />

    <div v-if="showReportLink" class="task__report">
      <link-report
        :link="reportLink"
        :isLeft="false"
        @click.prevent="showPopupReport = true"
      >
        Пожаловаться на содержимое
      </link-report>
      <popup-report
        :taskId="taskId"
        :taskType="taskType"
        :isPopupShown="showPopupReport"
        @closePopup="showPopupReport = false"
      />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { mapGetters } from 'vuex';

import PopupReport from '@/components/UI/popups/PopupReport.vue';
import SkeletonLoader from '@/components/KazUI/skeleton';

import { 
  STATES, 
  mapRatingFront2Back, 
  mapRatingBack2Front 
} from '@/views/tasks/utils/assessmentStates.js';
import { fetchRatings, setRating } from '@/services/Ratings';

import LinkReport from './LinkReport.vue';
import TaskShade from './TaskShade.vue';
import TaskAssessment from './Assessment.vue';
import contentTypes from '../../utils/contentTypes.js';


export default {
  name: 'task-entity-2',

  components: {
    LinkReport,
    PopupReport,
    TaskShade,
    TaskAssessment,
    SkeletonLoader,
    ContentViewerQuill: defineAsyncComponent({
      loader: () => import('../../components/ContentViewerQuill.vue')
    }),
    ContentViewerVideo: defineAsyncComponent({
      loader: () => import('../../components/ContentViewerVideo.vue')
    }),
    ContentViewerAudio: defineAsyncComponent({
      loader: () => import('../../components/ContentViewerAudio.vue')
    }),
    ContentViewerHtml: defineAsyncComponent({
      loader: () => import('../../components/ContentViewerHtml.vue')
    })
  },

  props: {
    nodeId: {
      type: String || Number,
      required: true
    },
    taskId: {
      type: String || Number,
      required: true
    },
    isViewed: {
      type: Boolean,
      default: false
    },
    isPremium: {
      type: Boolean,
      default: false
    },
    contentType: {
      type: Number,
      required: true
    },
    taskType: {
      type: Number,
      required: true
    },
    reportLink: {
      type: Object,
      required: true
    },
    timespamp: {
      type: Object
    }
  },

  emits: ['change-task-state', 'over-limit'],

  data() {
    return {
      CONTENT_TYPES: contentTypes,
      contentId: null,
      showPopupReport: false,
      showReportLink: true,
      
      likes: 0,
      dislikes: 0, 
      assessmentState: STATES.INIT,  // valid values: -1, 0, 1
      firstRatingLoading: true,
    }
  },

  watch: {
    taskType() {
      this.showReportLink = true;
    },
    contentId: {
      async handler(newId) {
        try {
          if (newId) {
            await this.setTaskRating(newId);
          }
        } catch (error) {
          console.warn(error);
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      getRestTask: 'tasksRestInfo/getRestTask',
      getRestTaskPremium: 'tasksRestInfo/getRestTaskPremium'
    }),
    shouldShowTaskShade() {
      return !this.isViewed && (this.getRestTask === 0 || (this.isPremium && this.getRestTaskPremium === 0));
    },
  },

  methods: {
    addSolution() {
      this.$router.push({ name: 'editor', params: { tid: this.nodeId } });
    },

    onOverLimit(event) {
      this.$emit('over-limit');
      this.showReportLink = false;
    },

    handleTaskState(data) {
      this.contentId = data.contentId;
      this.$emit('change-task-state', data);
    },

    async setTaskRating(contentId) {
      try {
        const res = await fetchRatings(contentId);
        this.setAssessmentProps(res);
      } catch (error) {
        console.error('RATING:', error);
      } finally {
        if (this.firstRatingLoading) {
          this.firstRatingLoading = false;
        }
      }
    },

    setAssessmentProps(res) {
      const { ratingsList, currentUserValue } = res.toObject();
      this.likes = this.dislikes = 0;
      ratingsList.forEach(r => {
        if (r.rating === 1) {
          this.dislikes = r.value;
        } else if (r.rating === 2) {
          this.likes = r.value;
        }
      });
      this.assessmentState = mapRatingBack2Front(currentUserValue);
    },

    async handleAssessmentEvent(assessment) {
      try {
        if (this.contentId) {
          const rating = mapRatingFront2Back(assessment);
          const res = await setRating(this.contentId, rating);
          this.setAssessmentProps(res);
        }
        /*if (this.assessmentState === STATES.LIKE) {
          this.likes--;
          if (state === STATES.DISLIKE) {
            this.dislikes++;
          }
        } else if (this.assessmentState === STATES.DISLIKE) {
          this.dislikes--;
          if (state === STATES.LIKE) {
            this.likes++;
          }
        } else {
          if (state === STATES.LIKE) {
            this.likes++;
          } else if (state === STATES.DISLIKE) {
            this.dislikes++;
          }
        }
        this.assessmentState = state;*/
      } catch (error) {
        console.error('ASSESSMENT:', error);
      }
    }
  }
}
</script>

<style scoped>
.task {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  /* background: var(--background-light, #F3F3FF); */
  background: var(--kaz-base-secondary);
}

.task__content,
.task__report {
  width: 100%;
}
</style>
