<template>
  <div class="divide-wrapper">
    <header-common/>

    <main class="monetization-layout">
      <router-view/>
    </main>
    <footer-component
      :cooperation="cooperation"
      :documents="documents"
      :commonLinks="common"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import HeaderCommon from '@/components/common/Header';
import FooterComponent from '@/components/common/FooterComponent';

import { documents, common, cooperation, } from '@/components/common/FooterComponent/links';


export default {
  name: 'monetization-layout',
  components: {
    HeaderCommon,
    FooterComponent,
  },
  data(){
    return{
      // for FooterComponent
      documents, common, cooperation,
    }
  },
  methods:{

    ...mapActions({
      fetchGrades: 'gradeData/fetchGrades',
      fetchUser: 'userData/fetchUser',
    }),
    async getUser() {
      try {
        await this.fetchUser();
      }
      catch(err) {
        console.log('Error in getUser:', err);
      }
    },
  },
  async created() {
    await this.getUser();
  },
}
</script>

<style scoped>
.divide-wrapper{
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 1272px;
  margin: 0 auto;
}
.monetization-layout{
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>