<template>
  <div class="wrapper" v-if="message.message">
    <avatar
      class="avatar"
      :size="'XS'"
      :avatarImage="message.author.avatar"
    />
    <div class="message__content">
      <div class="content__author">
        <p class="label label_size_S">{{ message.author.name }}</p>
      </div>
      <div class="content__text">
        <p class="body body_size_M">
          {{ message.message }}
        </p>
        <p class="label label_size_S label_type_absolute">
          {{ message.time }}
        </p>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import Avatar from '@/components/KazUI/atoms/avatar'
  import { _arrayBufferToBase64 } from "@/utils/converter";
  import { mapActions } from 'vuex';

  export default{
    name: 'mod-message',
    components:{
      Avatar
    },
    props:{
      message:{
        type: Object,
        default: {},
        required: true
      }
    },
    methods:{
      ...mapActions({
        findUserByLogin: 'userData/findUserByLogin',
      }),
      async getUserAvatar() {
        let login = this.message.author.name || 'Модератор'
        try {
          const userLookingFor = await this.$store.dispatch('userData/findUserByLogin', login)
          this.message.author.avatar = "data:image/jpeg;base64," + _arrayBufferToBase64(userLookingFor.photo);
          this.message.author.name = userLookingFor.firstName || 'Модератор'
        } catch (err) {
          console.log('Error in getUser:', err);
        }
      },
    },
    async mounted() {
      await this.getUserAvatar();
      // this.updateBookTitle();
    },

  }
  </script>
  
  <style scoped>
  .wrapper{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 8px;
  }
  .message__content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 688px;
  }
  .content__author{
    padding-left: 8px;
    padding-bottom: 8px;
  }
  .content__text{
    background-color: var(--kaz-base-base-02);
    border-radius: 8px;
    padding: 8px 92px 8px 8px;
    position: relative;
  }
  .label_type_absolute{
    position: absolute;
    top: 8px;
    right: 8px;
    color: var(--kaz-textIcons-text-03)
  }
  
  @media screen and (max-width: 992px) {
    .avatar{
      display: block;
    }
    .message__content{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 80%;
    }
  }
  </style>
  
  