<template>
  <router-link :to="createLink" class="link">
    <div class="lesson__card">
      <div class="card__number-info" v-if="additionInfoNeeded">
        <p class="label label_size_M label_type_bold">{{ lessonNumber }} урок, {{ lessonGrade }} класс</p>
      </div>
      <div class="card__main">
        <div class="main__title">
          <h3 class="heading heading_size_h3">{{ lessonName }}</h3>
        </div>
        <div class="main__info">
          <div class="info__tags">
            <Tag
              v-for="(tag, index) in tags" :key="index"
              :size="'S'"
              :appearance="'default'"
              :label="tag"
            />
          </div>
          <!-- <Button
            :label="'Поделиться'"
            :appearance="'flat'"
            :size="'S'"
          /> -->
        </div>
      </div>
    </div>
  </router-link>
</template>


<script>
import Tag from '@/components/KazUI/atoms/tag'
import Button from '@/components/KazUI/atoms/button'

export default{
  name: 'better-lesson-card',
  components:{
    Tag,
    Button
  },
  props:{
    id: {
      type: String,
      required: true
    },
    additionInfoNeeded:{
      type: Boolean,
      default: false,
    },
    lessonNumber:{
      type: Number,
      default: 1,
    },
    lessonGrade:{
      type: Number,
      default: null,
    },
    lessonName:{
      type: String,
      default: 'Я карточка! Я просто красивая карточка!',
      requred: false
    },
    tags:{
      type: Array,
      requred: false,
      default: ['Конспект', 'Видеоурок']
    },
    nodeRouteName: {
      type: String,
      default: 'lesson-task'
    }
  },

  computed: {
    createLink() {
      const link = { name: 'lesson-task', params: { tid: this.id } };
      if (this.lessonGrade) {
        link.query = { lv: this.lessonGrade}
      }
      return link
    }
  }
}

</script>

<style scoped>
.link{
  text-decoration: none;
}
.lesson__card{
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: flex-start;
}
.card__number-info{

}

.label{
  color: var(--kaz-textIcons-text-03);
}
.card__main{
  display: flex;
  flex-direction: column;
  background-color: var(--kaz-base-base-01);
  padding: 16px;
  gap: 8px;
  border-radius: 16px;
  box-shadow: var(--kaz-shadow-default);
  transition: var(--kaz-transition-atoms);
}
.card__main:hover{
  box-shadow: var(--kaz-shadow-default-hover);
  transition: var(--kaz-transition-atoms);
  transform: translateY(-4px);
}
.main__info{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.info__tags{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

@media screen and (max-width: 992px) {
  .card__main{
    min-height: 104px;
  }
  .main__title{
    min-height: 44px;
  }
}

</style>

