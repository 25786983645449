import STATES from "@/utils/states";
import {
  AUTH_STORAGE_KEY,
  TOKEN_STATE,
  extractTokenInfo,
  saveTokenInfo,
  normalizeTokenInfo,
  refresh,
  registerGuest,
  registerUserPasswordSetup,
  loginGuest,
  loginUser as loginUserCore,
} from '@/utils/auth';


export const authData = {
  namespaced: true,

  state: () => ({
    loadingState: STATES.INIT,
    accessToken: null,
    refreshToken: null,
    tokenTimeCreated: 0,
    isRegistered: false,
    guestId: null
  }),

  getters: {
    checkToken(state) {
      if (!state.isRegistered) {
        return TOKEN_STATE.INVALID
      }
      const tokenLifetime = (new Date().getTime() - state.tokenTimeCreated) / 1000;
      if (tokenLifetime < state.accessToken.lifetime) {
        return TOKEN_STATE.VALID
      } else if (tokenLifetime < state.refreshToken.lifetime) {
        return TOKEN_STATE.REFRESH
      } else {
        return TOKEN_STATE.INVALID
      }
    },
    sessionActive(state, getters) {
      return getters.checkToken === TOKEN_STATE.VALID
    },
    getGuesId(state) {
      return state.guestId
    },
    getTokenInfo(state) {
      return {
        [AUTH_STORAGE_KEY.ACCESS_TOKEN]: state.accessToken,
        [AUTH_STORAGE_KEY.REFRESH_TOKEN]: state.refreshToken,
        [AUTH_STORAGE_KEY.TIME_CREATION]: state.tokenTimeCreated,
        [AUTH_STORAGE_KEY.IS_REGISTERED]: state.isRegistered,
        [AUTH_STORAGE_KEY.GUEST_ID]: state.guestId
      }
    },
    getRefreshToken(state) {
      return state.refreshToken
    }
  },

  mutations: {
    updateState(state, {accessToken, refreshToken, tokenTimeCreated, isRegistered, guestId}) {
      if (typeof accessToken !== 'undefined') {
        state.accessToken = accessToken;
      }
      if (typeof refreshToken !== 'undefined') {
        state.refreshToken = refreshToken;
      }
      if (typeof tokenTimeCreated !== 'undefined') {
        state.tokenTimeCreated = tokenTimeCreated;
      }
      if (typeof isRegistered !== 'undefined') {
        state.isRegistered = isRegistered;
      }
      if (typeof guestId !== 'undefined') {
        state.guestId = guestId;
      }
    },
    setLoadingState(state, newState) {
      state.loadingState = newState;
    }
  },

  actions: {
    async initLogin({ getters, dispatch, commit }) {
      try {
        commit('setLoadingState', STATES.LOADING);

        // Restore data from localStorage
        await dispatch('restoreState');

        // Check token: loginGuest | refreshToken | ok
        switch (getters.checkToken) {
          case TOKEN_STATE.VALID:
            // it's ok - nothing to do
            console.log('current token is valid');
            break;
          case TOKEN_STATE.INVALID:
            // create guest token
            console.log('login as guest');
            await dispatch('_guestLoginAction');
            break;
          case TOKEN_STATE.REFRESH:
            // try to refresh token
            console.log('try to refresh token');
            await dispatch('_refreshTokenAction');
            break;
          default:
            console.warn(`Unknow token state [${getters.checkToken}]`);
        }

        commit('setLoadingState', STATES.LOADED);
      } catch (error) {
        commit('setLoadingState', STATES.ERROR);
        console.error('Init login:', error);
      }
    },

    async restoreState({ commit }) {
      try {
        const tokens = extractTokenInfo();
        const normalizedTokenInfo = normalizeTokenInfo(tokens);
        commit('updateState', normalizedTokenInfo); 
      } catch (error) {
        throw new Error('Restore state: ' + error)
      }
    },

    async createState({ commit, getters }, tokens) {
      const normalizedTokenInfo = normalizeTokenInfo({
        ...tokens, 
        [AUTH_STORAGE_KEY.TIME_CREATION]: new Date().getTime()
      });
      await commit('updateState', normalizedTokenInfo);
      saveTokenInfo(getters.getTokenInfo);
    },

    async _refreshTokenAction({ dispatch, getters }) {
      try {
        const refreshToken = getters.getRefreshToken;
        const tokens = await refresh(refreshToken);
        await dispatch('createState', tokens);
      } catch (error) {
        throw new Error('Refresh token: ' + error)
      }
    },

    async _guestLoginAction({ dispatch, getters }) {
      try {
        let guestId = getters.getGuesId;
        let tokens = null;
        if (!guestId) {
          console.log('create guest account');
          guestId = await registerGuest();
        }
        tokens = await loginGuest(guestId);

        tokens.guestId = guestId;
        tokens.isRegistered = false;
        await dispatch('createState', tokens);
      } catch (error) {
        throw new Error('Guest login: ' + error)
      }
    },

    async loginUser({ dispatch, commit }, {email, password, source, loginInSource}) {
      try {
        commit('setLoadingState', STATES.LOADING);
        console.log('loginInSource', loginInSource);
        const tokens = await loginUserCore(email, password, source, loginInSource);
        await dispatch('createState', tokens);
        commit('setLoadingState', STATES.LOADED);
      } catch (error) {
        commit('setLoadingState', STATES.ERROR);
        throw error
      }
    },

    async setupUserPassword({ dispatch, commit }, { password, accessToken }) {
      // This action mostly indentical to corresponding function.
      // This is just for proper setup of token state as final step of registration.
      try {
        commit('setLoadingState', STATES.LOADING);
        const tokens = await registerUserPasswordSetup(password, accessToken);
        await dispatch('createState', tokens);
        commit('setLoadingState', STATES.LOADED);
      } catch (error) {
        commit('setLoadingState', STATES.ERROR);
        throw error
      }
    }
  }
}